export const locationSalesForecastData = (res: any) => {
    const DataObj: any = []
    res.map((row: any) => {
        DataObj.push({
            plan_area_id: row.plan_area_id,
            branch: row.branch,
            create_date: row.create_date,
            update_date: row.update_date,
            delete_flag: row.delete_flag,
            temp_flag: row.temp_flag,
            sales_forecast_excel_path: row.sales_forecast_excel_path,
            sales_forecast_pdf_path: row.sales_forecast_pdf_path,
            sales_forecast_upload_user: row.sales_forecast_upload_user,
            sales_forecast_upload_mail: row.sales_forecast_upload_mail,
            sales_forecast_color: row.sales_forecast_color,
            daily_sales_year_1: row.daily_sales_year_1,
            daily_sales_year_2: row.daily_sales_year_2,
            daily_sales_year_3: row.daily_sales_year_3,
            daily_sales_year_4: row.daily_sales_year_4,
            daily_sales_year_5: row.daily_sales_year_5,
            impact_store_name_1: row.impact_store_name_1,
            impact_store_1: row.impact_store_1,
            impact_store_name_2: row.impact_store_name_2,
            impact_store_2: row.impact_store_2,
            impact_store_name_3: row.impact_store_name_3,
            impact_store_3: row.impact_store_3,
            impact_store_name_4: row.impact_store_name_4,
            impact_store_4: row.impact_store_4,
            approval_research_output_flag: row.approval_research_output_flag,
            approval_research_output_user: row.approval_research_output_user,
            approval_research_output_department: row.approval_research_output_department,
            approval_research_output_date: row.approval_research_output_date,
            approval_research_output_stamp: row.approval_research_output_stamp,
            approval_research_mgr_flag: row.approval_research_mgr_flag,
            approval_research_mgr_user: row.approval_research_mgr_user,
            approval_research_mgr_department: row.approval_research_mgr_department,
            approval_research_mgr_date: row.approval_research_mgr_date,
            approval_research_mgr_stamp: row.approval_research_mgr_stamp,
            approval_research_gm_flag: row.approval_research_gm_flag,
            approval_research_gm_user: row.approval_research_gm_user,
            approval_research_gm_department: row.approval_research_gm_department,
            approval_research_gm_date: row.approval_research_gm_date,
            approval_research_gm_stamp: row.approval_research_gm_stamp,
        })
    })

    return DataObj;
};