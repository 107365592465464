import { useState } from 'react';
import {
  Box,
  Button,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  CircularProgress
} from '@mui/material';
import { apiRequest, postPython } from "../../api/ApiCall";
import InvalidInputList from "./InvalidInputList";
import LocationExcelAndN15AddMarktCheck from '../components/LocationExcelAndN15AddMarktCheck';
import { useAppSelector } from '../../app/hooks';
import { LOCAL_CONSTANT } from '../Const';

export interface resultDialogProps {
  props: {
    modalOpen: boolean,
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    closeHandle: (response: boolean) => void,
    plan_area_id: string | null,
    branch: string | null
  }
}

//スタイル
const ColorButton = styled(Button)({
  backgroundColor: "#00CC5B",
  color: "white",
  '&: hover':{
    backgroundColor: '#29e17a'
  }
});
const CancelButton = styled(Button)({
  backgroundColor: "#ffffff00",
  color: "#f00",
  border: "1px solid #f00",
});
const DialogContentInner = styled(DialogContent)({
  display: "flex !important",
  justifyContent: "center !important",
  alignItems: "center !important",
  paddingTop: "0px !important",
  transform: "initial !important",
  minWidth: "550px",
  minHeight: "280px",
});

const RequestTaskModal: React.FC<resultDialogProps> = ({ props }) => {

  //モーダルページ切替
  const [pageing, setPageing] = useState<string>("0");
  //タスクID
  const [taskId, setTaskId] = useState<string>("");
  //依頼種別
  const [requestType, setRequestType] = useState<string>("");
  //不正な入力項目
  const [errorList, setErrorList] = useState<any>();
  //親調査書 不正な入力項目
  const [errorParentList, setErrorParentList] = useState<any>();
  //子調査書 不正な入力項目
  const [errorChildList, setErrorChildList] = useState<any>();
  //中間生成物フラグ
  const [locationExcelFlag, setLocationExcelFlag] = useState<string>("");
  //n15追加商圏フラグ
  const [n15AddMarketFlag, setN15AddMarketFlag] = useState<string>("");
  //調査書種別
  const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
  //子調査書のplan_area_id
  const usePlanAareaId = useAppSelector((state) => state.longPage.usePlanAareaId);
  
  // 完了モーダルクローズ
  const handleRequestModalClose = () => {
    //API通信中はモーダルクローズさせない
    if (pageing === "5") { return }
    props.closeHandle(true);
    setTimeout(() => {
      setPageing("0");
    }, 500);
  };
  
  //モーダルクローズ
  const handleMarktCheckClose = () => {
    //API通信中はモーダルクローズさせない
    if (pageing === "5") { return }
    if (locationExcelFlag === "" && n15AddMarketFlag === "") {
      props.closeHandle(true);
    } else {
      props.closeHandle(false);
    }
    setTimeout(() => {
      setPageing("0");
    }, 500);
  };
  
  //キャンセルモーダルクローズ
  const handleRequestModalCancelClose = () => {
    //API通信中はモーダルクローズさせない
    if (pageing === "5") { return }
    props.closeHandle(false);
    setTimeout(() => {
      setPageing("0");
    }, 500);
  };
  
  //エラーモーダルクローズ
  const handleRequestModalErrorClose = () => {
    //API通信中はモーダルクローズさせない
    if (pageing === "5") { return }
    props.closeHandle(false);
    setTimeout(() => {
      setPageing("0");
    }, 500);
  };

  //親、子調査書のそれぞれエラー項目があるかないかでモーダルクローズ
  const handleErrorClose = () => {
    if (errorChildList.length > 0) {
      //親と子の調査書にエラー項目がある場合 親を閉じて子調査書のエラー項目を表示
      setPageing("errorChildCheck");
    } else {
      //モーダルクローズ
      handleRequestModalClose();
    }
  }

  //タスクIDセット
  const setTaskIdAndsetPage = (buttonId:string) => {
    switch(buttonId){
      case "normal":
        return setPageing("3"), setTaskId("B001"), setRequestType("0");
      case "provisional":
        return setPageing("3"), setTaskId("C001"), setRequestType("1");
      case "tradeAreaCut":
        return setPageing("3"), setTaskId("D001"), setRequestType("2");
      case "reference":
        return setPageing("3"), setTaskId("E004"), setRequestType("3");
      case "decision":
        return setPageing("3"), setTaskId("E008"), setRequestType("4");
    }
  };

  //パラメータ作成
  const setParams = (postType: string) => {
    // requestCheckかrequestかどちらか
    let params = {
      mode: "regist",
      endPoint: "",
      query: {}
    }
    switch (postType) {
      case 'requestCheck':
        params['endPoint'] = "/location/Task/v1/survey-request-check"
        params['query'] = { plan_area_id: props.plan_area_id,
                            branch: props.branch,
                            request_type: requestType }
        break;
      case 'requestChildCheck':
        params['endPoint'] = "/location/Task/v1/survey-request-check"
        params['query'] = { plan_area_id: usePlanAareaId,
                            branch: props.branch,
                            request_type: requestType }
        break;
      case 'request':
        params['endPoint'] = "/location/Task/v1/survey-request"
        params['query'] = { plan_area_id: props.plan_area_id,
                            branch: props.branch,
                            task_id: taskId }
        break;
      case 'requestChild':
        params['endPoint'] = "/location/Task/v1/survey-request"
        params['query'] = { plan_area_id: usePlanAareaId,
                            branch: props.branch,
                            task_id: taskId }
        break;
    }
    return params;
  };

  //入力チェック
  const onErrorCheck = () => {
    // call_user_type
    // 0：RFC
    // 1：RR
    // ２：システム
    const pythonParams = {
      mode: "regist",
      endPoint: "/excel-print-pdf",
      query: {
          prop_site_no: props.plan_area_id,
          branch: props.branch,
          call_user_type: '2',
      }
    }
    setPageing("4");
    //調査書種別が02、03、04の場合、子調査書も入力チェック
    if (objLocationBase.location_type === '02' || objLocationBase.location_type === '03' || objLocationBase.location_type === '04') {
      //子調査書が存在しない場合 「既存店の調査書が作成されていません」のエラーメッセージを表示
      if (usePlanAareaId === "") {
        setPageing("errorNoChildCheck");
      //子調査書が存在する場合
      } else {
        //入力チェックと出力依頼のパラメータ作成
        const parentCheckParams = setParams('requestCheck');
        const childCheckParams = setParams('requestChildCheck');
        const parentRequestParams = setParams('request');
        const childRequestParams = setParams('requestChild');
        (async () => {
          try {
            const resultParentCheck = await apiRequest(parentCheckParams);
            const resultChildCheck = await apiRequest(childCheckParams);
            if (resultParentCheck.data.dataList.length > 0 && resultChildCheck.data.dataList.length > 0) {
              const dataParentList = JSON.parse(resultParentCheck.data.dataList);
              const dataChildList = JSON.parse(resultChildCheck.data.dataList);
              const resultLocationExcelFlag = dataParentList.LocationExcelFlag === "0" || dataChildList.LocationExcelFlag === "0";
              const resultN15AddMarketFlag = dataParentList.n15AddMarketFlag === "0" || dataChildList.n15AddMarketFlag === "0";
              console.log("中間生成物フラグ：" + resultLocationExcelFlag);
              console.log("n15存在フラグ：" + resultN15AddMarketFlag);
              //中間生成物が取り込まれていない場合 「中間生成物が取り込まれていません」のエラーメッセージを表示
              if (resultLocationExcelFlag && !resultN15AddMarketFlag){
                console.log("中間取り込み無し")
                setPageing("errorLocationExcelFlagAndN15AddMarketFlagCheck");
                setLocationExcelFlag("0");
              //n15追加商圏にデータが存在しない場合「n15追加商圏が取り込まれていません」のエラーメッセージを表示
              }else if(resultN15AddMarketFlag && !resultLocationExcelFlag){
                console.log("n15取り込み無し")
                setPageing("errorLocationExcelFlagAndN15AddMarketFlagCheck");
                setN15AddMarketFlag("0");
              //中間生成物とn15追加商圏どちらも取り込まれていない場合
              }else if(resultLocationExcelFlag && resultN15AddMarketFlag){
                console.log("中間＆n15取り込み無し")
                setPageing("errorLocationExcelFlagAndN15AddMarketFlagCheck");
                setLocationExcelFlag("0");
                setN15AddMarketFlag("0");
              //中間生成物とn15追加商圏が存在する場合は入力チェック、出力依頼
              } else {
                setErrorParentList(dataParentList.errorList);
                setErrorChildList(dataChildList.errorList);
                if (dataParentList.Response === "error" && dataChildList.Response === "error") {
                  setPageing("errorParentCheck"); //親、子調査書の順でエラー項目を表示
                } else if (dataParentList.Response === "error" && dataChildList.Response === "success") {
                  setPageing("errorParentCheck"); //親調査書のみエラー項目を表示
                } else if (dataParentList.Response === "success" && dataChildList.Response === "error") {
                  setPageing("errorChildCheck");  //子調査書のみエラー項目を表示
                } else if (dataParentList.Response === "success" && dataChildList.Response === "success") {
                  setPageing("5");
                  // PDF作成
                  await postPython(pythonParams);
                  //親、子調査書の出力依頼
                  await apiRequest(parentRequestParams);
                  await apiRequest(childRequestParams);
                  setPageing("6");
                }
              }
            }
          } catch (e) {
            setPageing("sendError");
            console.log(e);
          }
        })();
      }
    //調査書種別が02、03、04以外を入力チェック
    } else {
      //入力チェックと出力依頼のパラメータ作成
      const checkParams = setParams('requestCheck');
      const requestParams = setParams('request');
      (async () => {
        try {
          const resultCheck = await apiRequest(checkParams);
          if (resultCheck.data.dataList.length > 0) {
            const dataList = JSON.parse(resultCheck.data.dataList);
            const resultLocationExcelFlag = dataList.LocationExcelFlag === "0";
            const resultN15AddMarketFlag = dataList.n15AddMarketFlag === "0";
            //中間生成物が取り込まれていない場合 「中間生成物が取り込まれていません」のメッセージ表示
            if (resultLocationExcelFlag && !resultN15AddMarketFlag) {
              setPageing("errorLocationExcelFlagAndN15AddMarketFlagCheck");
              setLocationExcelFlag("0");
            //n15追加商圏にデータが存在しない場合「n15追加商圏が取り込まれていません」のエラーメッセージを表示
            } else if (resultN15AddMarketFlag && !resultLocationExcelFlag){
              setPageing("errorLocationExcelFlagAndN15AddMarketFlagCheck");
              setN15AddMarketFlag("0");
            //中間生成物とn15追加商圏どちらも取り込まれていない場合
            } else if (resultLocationExcelFlag && resultN15AddMarketFlag){
              setPageing("errorLocationExcelFlagAndN15AddMarketFlagCheck");
              setLocationExcelFlag("0");
              setN15AddMarketFlag("0");
            //中間生成物が存在する場合は入力チェック、出力依頼
            } else {
              if (dataList.Response === "error") {
                setErrorList(dataList.errorList);
                setPageing("errorCheck");
              } else if (dataList.Response === "success") {
                setPageing("5");
                // PDF作成
                await postPython(pythonParams);
                await apiRequest(requestParams);
                setPageing("6");
              }
            }
          }
        } catch (e) {
          setPageing("sendError");
          console.log(e);
        }
      })();
    }
  };

  return (
    <Box>
      {/* 依頼モーダル */}
      <Dialog open={props.modalOpen} onClose={handleRequestModalCancelClose} sx={{ minWidth: "700px" }}>
        <DialogContentInner>
          {pageing === "0" &&
            <Box>
              <DialogTitle>依頼種別を選択してください</DialogTitle>
              <Box sx={{ margin: "30px" }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <ColorButton onClick={() => { setPageing("1") }}>予測出力依頼</ColorButton>
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <ColorButton onClick={() => { setPageing("2") }} disabled={props.branch === "1"}>追加調査出力依頼</ColorButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }

          {pageing === "1" &&
            <Box>
              <DialogTitle>予測出力依頼のパターンを選択してください</DialogTitle>
              <Box sx={{ margin: "30px" }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <ColorButton onClick={() => { setTaskIdAndsetPage("normal") }}>通常出力</ColorButton>
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <ColorButton onClick={() => { setTaskIdAndsetPage("provisional") }}>仮出力</ColorButton>
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <ColorButton onClick={() => { setTaskIdAndsetPage("tradeAreaCut") }}>通常+5m外・商圏カット出力</ColorButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }

          {pageing === "2" &&
            <Box>
              <DialogTitle>追加調査出力依頼のパターンを選択してください</DialogTitle>
              <Box sx={{ margin: "30px" }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <ColorButton onClick={() => { setTaskIdAndsetPage("reference") }}>参考値算出</ColorButton>
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <ColorButton onClick={() => { setTaskIdAndsetPage("decision") }}>確定依頼</ColorButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }

          {pageing === "3" &&
            <Box>
              <DialogTitle>出力依頼を送信しますか？</DialogTitle>
              <Box sx={{ margin: "30px" }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                    <CancelButton onClick={handleRequestModalCancelClose}>キャンセル</CancelButton>
                  </Grid>
                  <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                    <ColorButton onClick={onErrorCheck}>送信</ColorButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }

          {pageing === "4" &&
            <Box>
              <DialogTitle>入力チェック中</DialogTitle>
              <Box sx={{ margin: "30px" }}>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress color="inherit" />
                </Grid>
              </Box>
            </Box>
          }

          {pageing === "errorNoChildCheck" &&
            <Box>
              <DialogTitle>{LOCAL_CONSTANT.ERROR_MESSAGE.ERROR_NO_LOCATION_CHILD}</DialogTitle>
              <Box sx={{ margin: "30px" }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <CancelButton onClick={handleRequestModalErrorClose}>閉じる</CancelButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          } 

          {pageing === "errorLocationExcelFlagAndN15AddMarketFlagCheck" &&
            //中間生成物とN15追加商圏の判定結果
            <LocationExcelAndN15AddMarktCheck
              props={{
                locationExcelFlag:locationExcelFlag,
                n15AddMarketFlag:n15AddMarketFlag,
                handleModalClose:handleMarktCheckClose
              }}
            />
          }

          {pageing === "errorCheck" &&
            // エラー項目一覧
            <InvalidInputList
              props={{
                errorMessage:LOCAL_CONSTANT.ERROR_MESSAGE.ERROR_LOCATION_ITEM,
                errorList: errorList,
                handleModalClose:handleRequestModalErrorClose}}
            />
          }

          {pageing === "errorParentCheck" &&
            // エラー項目一覧
            <InvalidInputList
              props={{
                errorMessage:LOCAL_CONSTANT.ERROR_MESSAGE.ERROR_LOCATION_ITEM,
                errorList: errorParentList,
                handleModalClose:handleErrorClose}}
            />
          }

          {pageing === "errorChildCheck" &&
            // エラー項目一覧
            <InvalidInputList
              props={{
                errorMessage:LOCAL_CONSTANT.ERROR_MESSAGE.ERROR_LOCATION_CHILD_ITEM,
                errorList: errorChildList,
                handleModalClose:handleRequestModalErrorClose}}
            />
          }

          {pageing === "5" &&
            <Box>
              <DialogTitle>出力依頼を送信中</DialogTitle>
              <Box sx={{ margin: "30px" }}>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress color="inherit" />
                </Grid>
              </Box>
            </Box>
          }

          {pageing === "6" &&
            <Box>
              <DialogTitle>出力依頼を送信しました</DialogTitle>
              <Box sx={{ margin: "30px" }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <ColorButton onClick={handleRequestModalClose}>閉じる</ColorButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }

          {pageing === "sendError" &&
            <Box>
              <DialogTitle>出力依頼に失敗しました</DialogTitle>
              <Box sx={{ margin: "30px" }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <CancelButton onClick={handleRequestModalErrorClose}>閉じる</CancelButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }

        </DialogContentInner>
      </Dialog>
    </Box>
  );

}


export default RequestTaskModal;