import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  styled,
  List,
  ListItem,
  ListItemButton,
  CircularProgress,
} from "@mui/material";
import { apiRequest } from "../../api/ApiCall";
import { getReceptionBookByIdAndBranch } from '../../data/DataManage';
import RequestComment from '../ResearchReport/RequestComment';

interface ModalProps {
  props: {
    modalOpen: boolean,
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    user: string,
    planAreaId: string | null,
    branch: string | null,
    obj: mailModalObjTypeList[] | undefined,
    planAreaRfc: string,
    planAreaDistrictCode: string
  }
};

interface mailModalObjTypeList {
  statusId: string,
  taskId: string,
  charge: string,
  picNameValue: string,
  picNumberValue: string,
  assemblySelectValue: string,
  patternValue: string
  patternDetailsValue: string,
  commentValue: string,
  commentTypeValue: string,
  receptionNo: string,
  subjectTitle: string,
  planAreaName: string
}

interface mailType {
  pic_name: string[],
  daily_sales_year_1: string,
  daily_sales_year_2: string,
  daily_sales_year_3: string,
  daily_sales_year_4: string,
  daily_sales_year_5: string,
  impact_store_name_1: string,
  impact_store_name_2: string,
  impact_store_name_3: string,
  impact_store_name_4: string,
  impact_store_1: string,
  impact_store_2: string,
  impact_store_3: string,
  impact_store_4: string
}

type listType = {
  value: string;
  label: string;
}

//プルダウンリスト表示用
let picRfcList: listType[] = [];
let picRmList: listType[] = [];
let picGmList: listType[] = [];
let picEtcList: listType[] = [];

//スタイル
const DialogComp = styled(Dialog)({
  ".MuiPaper-root": {
    width: "100%"
  },
});
const GridContainer = styled(Grid)({
  marginBottom: "10px"
});
const DisabledTextField = styled(TextField)({
  ".MuiInputBase-root": {
    backgroundColor: "#7b7b7b5c"
  }
});
const TextFieldGrid = styled(Grid)({
  ".MuiFormControl-root": {
    width: "100%"
  }
});
const SendButton = styled(Button)({
  backgroundColor: "#00CC5B",
  color: "white",
  float: "right",
  margin: "10px 20px",
  '&: hover':{
    backgroundColor: '#29e17a'
  }
});
const CancelButton = styled(Button)({
  backgroundColor: "#ffffff00",
  color: "#f00",
  border: "1px solid #f00",
  float: "left",
  margin: "10px 20px"
});
const ModalButton = styled(ListItemButton)({
  justifyContent: "center",
  backgroundColor: "#f5f5f5",
  color: "#00CC5B",
});
const ColorButton = styled(Button)({
  backgroundColor: "#00CC5B",
  color: "white",
  '&: hover':{
    backgroundColor: '#29e17a'
  }
});

const MailCreateModal: React.FC<ModalProps> = ({ props }) => {

  //ログインユーザーid
  const [userId, setUserId] = useState<string>("");
  //モーダルページ切替
  const [pageing, setPageing] = useState<number>(0);
  //件名
  const [subjectTitle, setSubjectTitle] = useState<string>("");
  //メール情報オブジェクト
  const [mailTextObj, setMailTextObj] = useState<mailType>();
  //本文
  const [mailText, setMailText] = useState<string>("");
  //受付No
  const [receptionNo, setReceptionNo] = useState<string>("");
  //候補地No
  const [planAreaName, setPlanAreaName] = useState<string>("");
  //RFC選択値
  const [picRfc, setPicRfc] = useState<string | null>(null);
  //RM選択値
  const [picRm, setPicRm] = useState<string | null>(null);
  //GM選択値
  const [picGm, setPicGm] = useState<string | null>(null);
  //その他選択値
  const [picEtc, setPicEtc] = useState<string | null>(null);
  //タスクID
  const [taskId, setTaskId] = useState<string | undefined>("");
  //ステータスID
  const [statusId, setStatusId] = useState<string | undefined>("");
  //担当者タイプ
  const [picType, setPicType] = useState<string | undefined>("");
  //担当者名
  const [picName, setPicName] = useState<string | undefined>("");
  //社員No
  const [picStaffNumber, setPicStaffNumber] = useState<string | undefined>("");
  //組立
  const [assembly, setAssembly] = useState<string | undefined>("");
  //パターン
  const [pattern, setPattern] = useState<string | undefined>("");
  //パターン詳細
  const [patternDetails, setPatternDetails] = useState<string | undefined>("");
  //コメント
  const [comment, setComment] = useState<string | undefined>("");
  //コメントタイプ
  const [commentType, setCommentType] = useState<string | undefined>("");

  //モーダルクローズ
  const handleMailModalClose = () => {
    props.setModalOpen(false);
    setPageing(0);
  };

  //社員マスタセット
  const setStaffList = () =>{
    const staffMasterObj = localStorage.getItem("staffMaster");
    if (staffMasterObj !== null) {
      const jsonStaffMasterObj = JSON.parse(staffMasterObj);
      jsonStaffMasterObj.map((e: any) => {
        if (e.job_category_code_oa === "360" &&
          !picRfcList.some(t => t.value === e.staff_number)) {
          picRfcList.push({
            label: e.staff_name,
            value: e.staff_number
          })
        } else if (e.job_category_code_oa === "230" &&
          !picRmList.some(t => t.value === e.staff_number)) {
          picRmList.push({
            label: e.staff_name,
            value: e.staff_number
          })
        } else if (e.job_category_code_oa === "101" &&
          !picGmList.some(t => t.value === e.staff_number)) {
          picGmList.push({
            label: e.staff_name,
            value: e.staff_number
          })
        } else {
          picEtcList.push({
            label: e.staff_name,
            value: e.staff_number
          })
        }
      })
    }
  }

  //RM検索処理
  const searchRM = (district_code: string) => {
    if (district_code === null) {
      setPicRm(null);
    }
    const staffMasterObj = localStorage.getItem("staffMaster");
    if (staffMasterObj !== null) {
      const jsonStaffMasterObj = JSON.parse(staffMasterObj);
      jsonStaffMasterObj.map((elem: any) => {
        if (elem.department_code_oa === district_code && elem.job_category_code_oa === "230") {
          setPicRm(elem.staff_number);
        }
      })
    }
  };

  //パラメータセット
  useEffect(()=>{
    console.log(props.obj);
    if (typeof props.obj !== "undefined") {
      setTaskId(props.obj[0].taskId);
      setStatusId(props.obj[0].statusId);
      setPicName(props.obj[0].picNameValue);
      setPicType(props.obj[0].charge);
      setPicStaffNumber(props.obj[0].picNumberValue);
      setAssembly(props.obj[0].assemblySelectValue);
      setPattern(props.obj[0].patternValue);
      setPatternDetails(props.obj[0].patternDetailsValue);
      setComment(props.obj[0].commentValue);
      setCommentType(props.obj[0].commentTypeValue);
      setReceptionNo(props.obj[0].receptionNo);
      setSubjectTitle(props.obj[0].subjectTitle);
      setPlanAreaName(props.obj[0].planAreaName);
    }
  },[props.obj]);

  //RFC、RM情報セット
  useEffect(()=>{
    setPicRfc(props.planAreaRfc);
    searchRM(props.planAreaDistrictCode);
  },[props.planAreaRfc, props.planAreaDistrictCode]);

  //候補地情報取得
  useEffect(()=>{
    //RFC、RM、GM、その他のプルダウンセット
    setStaffList();
    //ユーザー情報セット
    const usetData = JSON.parse(props.user || 'null');
    setUserId(usetData?.substitute_staff_number);
  },[]);

  //依頼コメント登録＆メール送信
  const handleRequestCommentMailSend = () => {
    setPageing(2);
    const params = {
      mode: "regist",
      endPoint: "/location/Task/v1/requestComment-send-Mail",
      query: {
        mail:{
          detail: {
            title: subjectTitle,
            body: mailText,
            staffNoList:[
              userId,
              picRfc,
              picRm,
              picGm,
              picEtc
            ].filter(staffNo => staffNo !== null && staffNo !== undefined),
            notficationType: '01'
          },
          mailType: 3,
          laterSendFlag: 0
        },
        requestComment: {
          plan_area_id: props.planAreaId,
          branch: props.branch,
          status_id: statusId,
          task_id: taskId,
          pic_type: picType,
          pic_name: picName,
          pic_staff_number: picStaffNumber,
          assembly: assembly,
          pattern: pattern,
          pattern_details: patternDetails,
          comment: comment,
          comment_type: commentType
        }
      }
    };
    console.log(params.query);
    (async () => {
      try {
        await apiRequest(params);
        setPageing(3);
      } catch (e) {
        console.log(e);
        setPageing(4);
      }
    })();
  }

  return (
    <Box>
      {/* メール作成モーダル */}
      <DialogComp open={props.modalOpen} onClose={handleMailModalClose}>

        {/* メール本文作成 */}
        {pageing === 0 &&
          <Box>
            <DialogTitle sx={{ textAlign: "center" }}>修正依頼　メール作成</DialogTitle>
            <Box sx={{ padding: "10px" }}>
              <GridContainer container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2} sx={{ display: "flex" }}>
                      <Typography>RFC</Typography>
                      <Typography sx={{ color: "red" }}>＊</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {picRfcList.length &&
                        <Autocomplete
                          size="small"
                          options={picRfcList ?? []}
                          value={picRfcList.find(e => e.value === picRfc ?? null)}
                          onChange={(e, newVal) => { setPicRfc(newVal?.value ?? null) }}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, v) => option.value === v.value}
                          renderInput={(params) => (<TextField {...params} variant="outlined" ></TextField>)}
                        ></Autocomplete>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </GridContainer>
              <GridContainer container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2} sx={{ display: "flex" }}>
                      <Typography>RM</Typography>
                      <Typography sx={{ color: "red" }}>＊</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {picRmList.length &&
                        <Autocomplete
                          size="small"
                          value={picRmList.find(e => e.value === picRm ?? null)}
                          onChange={(e, newVal) => { setPicRm(newVal?.value ?? null) }}
                          options={picRmList ?? []}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, v) => option.value === v.value}
                          renderInput={(params) => (<TextField {...params} variant="outlined" ></TextField>)}
                        ></Autocomplete>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </GridContainer>
              <GridContainer container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2} sx={{ display: "flex" }}>
                      <Typography>GM</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {picGmList.length &&
                        <Autocomplete
                          size="small"
                          value={picGmList.find(e => e.value === picGm ?? null)}
                          onChange={(e, newVal) => { setPicGm(newVal?.value ?? null) }}
                          options={picGmList ?? []}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, v) => option.value === v.value}
                          renderInput={(params) => (<TextField {...params} variant="outlined" ></TextField>)}
                        ></Autocomplete>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </GridContainer>
              <GridContainer container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                      <Typography>その他</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {picEtcList.length &&
                        <Autocomplete
                          size="small"
                          value={picEtcList.find(e => e.value === picEtc ?? null)}
                          onChange={(e, newVal) => { setPicEtc(newVal?.value ?? null) }}
                          options={picEtcList ?? []}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, v) => option.value === v.value}
                          renderInput={(params) => (<TextField {...params} variant="outlined" ></TextField>)}
                        ></Autocomplete>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </GridContainer>
              <GridContainer container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={2}>
                      <Typography>受付No</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <DisabledTextField value={receptionNo} variant="outlined" disabled></DisabledTextField>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>店名</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <DisabledTextField value={planAreaName} variant="outlined" disabled></DisabledTextField>
                    </Grid>
                  </Grid>
                </Grid>
              </GridContainer>
              <GridContainer container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                      <Typography>件名</Typography>
                    </Grid>
                    <TextFieldGrid item xs={10}>
                      <TextField value={subjectTitle}></TextField>
                    </TextFieldGrid>
                  </Grid>
                </Grid>
              </GridContainer>
              <GridContainer container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                      <Typography>本文</Typography>
                    </Grid>
                    <TextFieldGrid item xs={10}>
                      <TextField value={mailText} multiline rows={8} onChange={(e)=>{setMailText(e.target.value)}}></TextField>
                    </TextFieldGrid>
                  </Grid>
                </Grid>
              </GridContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <CancelButton onClick={handleMailModalClose}>キャンセル</CancelButton>
                    </Grid>
                    <Grid item xs={6}>
                      <SendButton onClick={()=>{setPageing(1)}}>送信</SendButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        }

        {pageing === 1 &&
          <Box>
            <DialogTitle sx={{ textAlign: "center" }}>送信しますか？</DialogTitle>
            <Box sx={{ margin: "30px" }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                  <CancelButton onClick={()=>{setPageing(0)}}>キャンセル</CancelButton>
                </Grid>
                <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                  <ColorButton onClick={handleRequestCommentMailSend}>送信</ColorButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        }

        {pageing === 2 &&
          <Box>
            <DialogTitle sx={{ textAlign: "center" }}>送信中</DialogTitle>
            <Box sx={{ margin: "30px" }}>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress color="inherit" />
              </Grid>
            </Box>
          </Box>
        }

        {pageing === 3 &&
          <Box>
            <DialogTitle sx={{ textAlign: "center" }}>送信完了</DialogTitle>
            <Box sx={{ margin: "30px" }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                  <ColorButton onClick={handleMailModalClose}>閉じる</ColorButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        }

        {pageing === 4 &&
          <Box>
            <DialogTitle sx={{ textAlign: "center" }}>送信失敗</DialogTitle>
            <Box sx={{ margin: "30px" }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                  <CancelButton onClick={handleMailModalClose}>閉じる</CancelButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        }
      </DialogComp>
    </Box>
  );
};

export default MailCreateModal;