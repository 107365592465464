import * as React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationImpactDetail from './LocationImpactDetail';

export interface LocationImpactProps {
    props:{
        plan_area_id: string;
        indexKey: number;
        locationImpact: any;
        impact: any;
        latlng: any;
        handleSetReadApi:any;
    }
}

const LocationImpact:React.FC<LocationImpactProps> = ({props}) => {
    const [expanded, setExpanded] = React.useState(false);
    const handleChangeExpanded = (event: any, newValue: any) => {
        setExpanded(newValue);
    };

    return (
        <>
            {
                <Accordion key={props.indexKey} expanded={expanded} onChange={handleChangeExpanded}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="StoreOpeningConditions-content"
                        id="StoreOpeningConditions-header"
                        sx={{
                            '& .MuiAccordionSummary-expandIconWrapper': {
                                position: 'absolute',
                                left: '10px',
                            }
                        }}
                    ><Typography sx={{paddingLeft: '30px'}}>{props.impact.tenpo_name}店（{props.impact.store_code}）</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <LocationImpactDetail props={{
                            plan_area_id: props.plan_area_id,
                            locationImpact: props.locationImpact,
                            impactData: props.impact,
                            handleSetReadApi: props.handleSetReadApi,
                            expanded: expanded,
                            latlng: props.latlng,
                        }} />
                    </AccordionDetails>
                </Accordion>
            }
        </>
    )
}

export default LocationImpact;
