import { createSlice } from '@reduxjs/toolkit';
import { locationSalesForecastObj } from '../data/type';



//Sliceの作成
export const locationSalesForecastSlice = createSlice({
    name: 'obj',
    initialState: {
        obj: []
    },

    reducers: {
        setLocationSalesForecastObj: (state, action) => {
            state.obj = action.payload;
        },
    }

});

export const {
    setLocationSalesForecastObj,
} = locationSalesForecastSlice.actions;
export default locationSalesForecastSlice.reducer;