import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  styled,
  List,
  ListItem,
  ListItemButton,
  CircularProgress,
} from "@mui/material";
import { apiRequest } from "../../api/ApiCall";
import { useAppSelector } from '../../app/hooks';

interface ModalProps {
  props: {
    modalOpen: boolean,
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    obj: dataTypeList | undefined,
    user: string
  }
};

interface dataTypeList {
  id: string,
  conversion_create_date: string,
  elapsedTime: string,
  type: string,
  department: string,
  district: string,
  rfc: string,
  plan_area_id: string,
  candidateSiteName: string,
  assembly: string,
  pattern: string,
  branch: string,
  status_id: string,
  pic_type: string,
  pic_name: string,
  task_id: string,
  reception_no: string,
  plan_area_name: string,
  plan_area_staff_number: string,
  plan_area_staff_name: string,
  plan_area_district_code: string,
  plan_area_district_name: string,
  plan_area_department_code: string,
  plan_area_department_name: string
}

interface mailType {
  pic_name: string[],
  daily_sales_year_1: string,
  daily_sales_year_2: string,
  daily_sales_year_3: string,
  daily_sales_year_4: string,
  daily_sales_year_5: string,
  impact_store_name_1: string,
  impact_store_name_2: string,
  impact_store_name_3: string,
  impact_store_name_4: string,
  impact_store_1: string,
  impact_store_2: string,
  impact_store_3: string,
  impact_store_4: string
}

type listType = {
  value: string;
  label: string;
}

//プルダウンリスト表示用
let picRfcList: listType[] = [];
let picRmList: listType[] = [];
let picGmList: listType[] = [];
let picEtcList: listType[] = [];

//スタイル
const DialogComp = styled(Dialog)({
  ".MuiPaper-root": {
    width: "100%"
  },
});
const GridContainer = styled(Grid)({
  marginBottom: "10px"
});
const DisabledTextField = styled(TextField)({
  ".MuiInputBase-root": {
    backgroundColor: "#7b7b7b5c"
  }
});
const TextFieldGrid = styled(Grid)({
  ".MuiFormControl-root": {
    width: "100%"
  }
});
const SendButton = styled(Button)({
  backgroundColor: "#00CC5B",
  color: "white",
  float: "right",
  margin: "10px 20px",
  '&: hover':{
    backgroundColor: '#29e17a'
  }
});
const CancelButton = styled(Button)({
  backgroundColor: "#ffffff00",
  color: "#f00",
  border: "1px solid #f00",
  float: "left",
  margin: "10px 20px"
});
const ModalButton = styled(ListItemButton)({
  justifyContent: "center",
  backgroundColor: "#f5f5f5",
  color: "#00CC5B",
  '&: hover':{
    backgroundColor: '#f5f5f5'
  }
});
const ColorButton = styled(Button)({
  backgroundColor: "#00CC5B",
  color: "white",
  '&: hover':{
    backgroundColor: '#29e17a'
  }
});

const MailCreateModal: React.FC<ModalProps> = ({ props }) => {

  //ポータルオブジェクト
  const objPortal = useAppSelector((state) => state.portal);
  //ログインユーザーid
  const [userId, setUserId] = useState<string>("");
  //モーダルページ切替
  const [pageing, setPageing] = useState<number>(0);
  //メール作成タイトル
  const [mailTitle, setMailTitle] = useState<string>("");
  //件名
  const [subjectTitle, setSubjectTitle] = useState<string>("");
  //メール情報オブジェクト
  const [mailTextObj, setMailTextObj] = useState<mailType>();
  //本文
  const [mailText, setMailText] = useState<string>("");
  //受付No
  const [receptionNo, setReceptionNo] = useState<string>("");
  //候補地No
  const [planAreaName, setPlanAreaName] = useState<string>("");
  //RFC選択値
  const [picRfc, setPicRfc] = useState<string | null>(null);
  //RM選択値
  const [picRm, setPicRm] = useState<string | null>(null);
  //GM選択値
  const [picGm, setPicGm] = useState<string | null>(null);
  //その他選択値
  const [picEtc, setPicEtc] = useState<string | null>(null);
  //メールタイプ
  const [mailType, setMailType] = useState<number | null>(null);
  //必須フラグ
  const [requiredFlag, setRequiredFlag] = useState(false);
  //タスク
  const [taskId, setTaskId] = useState<string>("");
  //メール送信種別フラグ
  const [laterSendFlag, setLaterSendFlag] = useState<number>(0);

  //モーダルクローズ
  const handleMailModalClose = () => {
    props.setModalOpen(false);
    setPageing(0);
    setMailText("");
    setMailType(null);
  };

  //画面遷移
  const handleMove = (mailType: number) => {
    setPageing(1);
    setMailType(mailType);
    if (mailType === 0) {
      setMailTitle("5m外・商圏カット　RM/GM承認依頼 メール作成");
      setRequiredFlag(true);
      if (typeof props.obj !== "undefined") {
        setSubjectTitle(`【立地調査】${props.obj.plan_area_name}　5m外・商圏カットRM/GM承認依頼`);
        searchGM(props.obj.plan_area_department_code);
        setCreateMailText(props.obj.plan_area_staff_name, mailTextObj, false);
      }
    } else if (mailType === 1) {
      setMailTitle("追加調査　参考値確認依頼 メール作成");
      setRequiredFlag(false);
      setPicGm(null);
      if (typeof props.obj !== "undefined") {
        setSubjectTitle(`【立地調査】${props.obj.plan_area_name}　追加調査　参考値確認依頼`);
        setCreateMailText(props.obj.plan_area_staff_name, mailTextObj, false);
      }
    } else if (mailType === 2) {
      setMailTitle("返却 メール作成");
      setRequiredFlag(false);
      setPicGm(null);
      setLaterSendFlag(1);
      if (typeof props.obj !== "undefined") {
        setSubjectTitle(`【立地調査】${props.obj.plan_area_name}　売上予測結果`);
        setCreateMailText(props.obj.plan_area_staff_name, mailTextObj, true);
        GMrequiredCheck(mailTextObj);
      }
    }
  }

  //メールテキストの作成
  const setCreateMailText = (RfcName:string, mailObj:mailType|undefined, otherInfo:boolean) => {
    let rfcInfo = "担当RFC："
    let picNameInfo = "出力担当者："
    //担当RFCセット
    if(RfcName !== null){
      rfcInfo = rfcInfo + RfcName;
    }
    //出力担当者セット
    if(typeof mailObj !== "undefined"){
      const picName:string[] =  mailObj?.pic_name;
      picName.map((e, i)=>{
        if(i === 0){
          picNameInfo = picNameInfo + e;
        }else if(i > 0){
          picNameInfo = picNameInfo + "、" + e;
        }
      });
    }
    //担当RFCと出力担当セット
    let text = rfcInfo + "\n" + picNameInfo + "\n";

    //返却の場合
    if(otherInfo){
      if(typeof mailObj !== "undefined"){
        //予測日販セット
        let dailySales = "●予測日販" + "\n" 
                        + "初年度平均日販：" + (mailObj?.daily_sales_year_1 ? mailObj.daily_sales_year_1 : "") + "\n"
                        + "2年度平均日販："  + (mailObj?.daily_sales_year_2 ? mailObj.daily_sales_year_2 : "") + "\n"
                        + "3年度平均日販："  + (mailObj?.daily_sales_year_3 ? mailObj.daily_sales_year_3 : "") + "\n"
                        + "4年度平均日販："  + (mailObj?.daily_sales_year_4 ? mailObj.daily_sales_year_4 : "") + "\n"
                        + "5年度平均日販："  + (mailObj?.daily_sales_year_5 ? mailObj.daily_sales_year_5 : "") + "\n"
        
        //既存店影響度セット
        let storesImpact = "●既存店影響度" + "\n"
        if(mailObj?.impact_store_name_1){
          storesImpact = storesImpact + mailObj?.impact_store_name_1 + "：" + mailObj?.impact_store_1 + "\n"
        }else if(mailObj?.impact_store_name_2){
          storesImpact = storesImpact + mailObj?.impact_store_name_2 + "：" + mailObj?.impact_store_2 + "\n"
        }else if(mailObj?.impact_store_name_3){
          storesImpact = storesImpact + mailObj?.impact_store_name_3 + "：" + mailObj?.impact_store_3 + "\n"
        }else if(mailObj?.impact_store_name_4){
          storesImpact = storesImpact + mailObj?.impact_store_name_4 + "：" + mailObj?.impact_store_4 + "\n"
        }
        //担当者情報 + 予測日販 + 既存店影響度セット
        text = text + dailySales + storesImpact;
      }
    }
    //メール本文セット
    setMailText(text);
  }

  //GM必須項目チェック
  const GMrequiredCheck = (mailInfoObj:mailType|undefined) => {
    if(typeof mailInfoObj !== "undefined"){
      let valueStr = mailInfoObj?.impact_store_1
                   + mailInfoObj?.impact_store_2
                   + mailInfoObj?.impact_store_3
                   + mailInfoObj?.impact_store_4
      if(String(valueStr).includes("高")){
        setRequiredFlag(true);
      }
    }
  }

  //押下レコードの情報セット
  useEffect(() => {
    const usetData = JSON.parse(props.user || 'null');
    setUserId(usetData?.substitute_staff_number);
    if (typeof props.obj !== "undefined") {
      console.log(props.obj);
      setReceptionNo(props.obj.reception_no);
      setPlanAreaName(props.obj.plan_area_name);
      setPicRfc(props.obj.plan_area_staff_number);
      searchRM(props.obj.plan_area_district_code);
      setTaskId(props.obj.task_id);
      searchCommentText(props.obj.plan_area_id, props.obj.branch);
    }
  }, [props.obj]);

  //RM検索処理
  const searchRM = (district_code: string) => {
    if (district_code === null) {
      setPicRm(null);
    }
    const staffMasterObj = localStorage.getItem("staffMaster");
    if (staffMasterObj !== null) {
      const jsonStaffMasterObj = JSON.parse(staffMasterObj);
      jsonStaffMasterObj.map((elem: any) => {
        if (elem.department_code_oa === district_code && elem.job_category_code_oa === "230") {
          setPicRm(elem.staff_number);
        }
      })
    }
  };

  //GM検索処理
  const searchGM = (department_code: string) => {
    if (department_code === null) {
      setPicGm(null);
    }
    const staffMasterObj = localStorage.getItem("staffMaster");
    if (staffMasterObj !== null) {
      const jsonStaffMasterObj = JSON.parse(staffMasterObj);
      jsonStaffMasterObj.map((elem: any) => {
        if (elem.department_code_oa === department_code && elem.job_category_code_oa === "101") {
          setPicGm(elem.staff_number);
        }
      })
    }
  }

  //社員マスタ情報セット
  const setPullDownList = () => {
    const staffMasterObj = localStorage.getItem("staffMaster");
    if (staffMasterObj !== null) {
      const jsonStaffMasterObj = JSON.parse(staffMasterObj);
      jsonStaffMasterObj.map((e: any) => {
        if (e.job_category_code_oa === "360" &&
          !picRfcList.some(t => t.value === e.staff_number)) {
          picRfcList.push({
            label: e.staff_name,
            value: e.staff_number
          })
        } else if (e.job_category_code_oa === "230" &&
          !picRmList.some(t => t.value === e.staff_number)) {
          picRmList.push({
            label: e.staff_name,
            value: e.staff_number
          })
        } else if (e.job_category_code_oa === "101" &&
          !picGmList.some(t => t.value === e.staff_number)) {
          picGmList.push({
            label: e.staff_name,
            value: e.staff_number
          })
        } else {
          picEtcList.push({
            label: e.staff_name,
            value: e.staff_number
          })
        }
      })
    }
  };

  useEffect(()=>{
    setPullDownList();
  },[objPortal.localStorageFlag]);

  //メール本文情報取得
  const searchCommentText = (planAreaId: string, branch: string) => {
    const params = {
      mode: "regist",
      endPoint: "/location/Reception/v1/get-mail-info",
      query: {
        plan_area_id: planAreaId,
        branch: branch
      }
    };
    (async () => {
      try {
        const res = await apiRequest(params);
        if(res.data.dataList.length > 0){
          const data = JSON.parse(res.data.dataList);
          setMailTextObj(data);
        } else {
          setMailTextObj(undefined);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  };

  //メール送信
  const handleMailSend = () => {
    setPageing(3);
    console.log(props.obj?.branch)
    const params = {
      mode: "regist",
      endPoint: "/sfdc/api/v1/send-mail",
      query: {
        detail:{
          title: subjectTitle,
          body: mailText,
          type: '01',
          staffNoList:[
            userId,
            picRfc,
            picRm,
            picGm,
            picEtc
          ].filter(staffNo => staffNo !== null && staffNo !== undefined)
        },
        mailType: mailType,
        laterSendFlag: laterSendFlag,
        plan_area_id: props.obj?.plan_area_id,
        branch: props.obj?.branch,
        task_id: taskId
      }
    };
    (async () => {
      try {
        console.log(params);
        await apiRequest(params);
        setPageing(4);
      } catch (e) {
        console.log(e);
        setPageing(5);
      }
    })();
  };

  //テキストフィールドチェンジメソッド
  const changeText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, textType: string) =>{
    const textValue = e.target.value;
    //件名
    if(textType === "subjectTitle"){
      setSubjectTitle(textValue);
    //本文
    }else if(textType === "mailText"){
      setMailText(textValue);
    }
  };

  return (
    <Box>
      {/* メール作成モーダル */}
      <DialogComp open={props.modalOpen} onClose={handleMailModalClose}>
        {/* メールタイプ選択 */}
        {pageing === 0 &&
          <Box>
            <DialogTitle sx={{ textAlign: "center" }}>メール種別を選択してください</DialogTitle>
            <Box sx={{ padding: "10px" }}>
              <List>
                <ListItem>
                  <ModalButton onClick={() => { handleMove(0) }} disabled={taskId !== "D006"}>5m外・商圏カット　RM/GM承認依頼</ModalButton>
                </ListItem>
                <ListItem>
                  <ModalButton onClick={() => { handleMove(1) }} disabled={taskId !== "E016"}>追加調査　参考値確認依頼</ModalButton>
                </ListItem>
                <ListItem>
                  <ModalButton onClick={() => { handleMove(2) }} disabled={taskId !== "C006" && taskId !== "B011" && taskId !== "E017"}>返却</ModalButton>
                </ListItem>
              </List>
            </Box>
          </Box>
        }

        {/* メール本文作成 */}
        {pageing === 1 &&
          <Box>
            <DialogTitle sx={{ textAlign: "center" }}>{mailTitle}</DialogTitle>
            <Box sx={{ padding: "10px" }}>
              <GridContainer container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2} sx={{ display: "flex" }}>
                      <Typography>RFC</Typography>
                      <Typography sx={{ color: "red" }}>＊</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Autocomplete
                        size="small"
                        options={picRfcList}
                        value={picRfcList.find(e => e.value === picRfc ?? null)}
                        onChange={(e, newVal) => { setPicRfc(newVal?.value ?? null) }}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, v) => option.value === v.value}
                        renderInput={(params) => (<TextField {...params} variant="outlined" ></TextField>)}
                      ></Autocomplete>
                    </Grid>
                  </Grid>
                </Grid>
              </GridContainer>
              <GridContainer container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2} sx={{ display: "flex" }}>
                      <Typography>RM</Typography>
                      <Typography sx={{ color: "red" }}>＊</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Autocomplete
                        size="small"
                        value={picRmList.find(e => e.value === picRm ?? null)}
                        onChange={(e, newVal) => { setPicRm(newVal?.value ?? null) }}
                        options={picRmList}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, v) => option.value === v.value}
                        renderInput={(params) => (<TextField {...params} variant="outlined" ></TextField>)}
                      ></Autocomplete>
                    </Grid>
                  </Grid>
                </Grid>
              </GridContainer>
              <GridContainer container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2} sx={{ display: "flex" }}>
                      <Typography>GM</Typography>
                      {requiredFlag &&
                        <Typography sx={{ color: "red" }}>＊</Typography>
                      }
                    </Grid>
                    <Grid item xs={10}>
                      <Autocomplete
                        size="small"
                        value={picGmList.find(e => e.value === picGm ?? null)}
                        onChange={(e, newVal) => { setPicGm(newVal?.value ?? null) }}
                        options={picGmList}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, v) => option.value === v.value}
                        renderInput={(params) => (<TextField {...params} variant="outlined" ></TextField>)}
                      ></Autocomplete>
                    </Grid>
                  </Grid>
                </Grid>
              </GridContainer>
              <GridContainer container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                      <Typography>その他</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Autocomplete
                        size="small"
                        value={picEtcList.find(e => e.value === picEtc ?? null)}
                        onChange={(e, newVal) => { setPicEtc(newVal?.value ?? null) }}
                        options={picEtcList}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, v) => option.value === v.value}
                        renderInput={(params) => (<TextField {...params} variant="outlined" ></TextField>)}
                      ></Autocomplete>
                    </Grid>
                  </Grid>
                </Grid>
              </GridContainer>
              <GridContainer container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={2}>
                      <Typography>受付No</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <DisabledTextField value={receptionNo} variant="outlined" disabled></DisabledTextField>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>店名</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <DisabledTextField value={planAreaName} variant="outlined" disabled></DisabledTextField>
                    </Grid>
                  </Grid>
                </Grid>
              </GridContainer>
              <GridContainer container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                      <Typography>件名</Typography>
                    </Grid>
                    <TextFieldGrid item xs={10}>
                      <TextField value={subjectTitle} onChange={(e)=>{changeText(e, "subjectTitle")}}></TextField>
                    </TextFieldGrid>
                  </Grid>
                </Grid>
              </GridContainer>
              <GridContainer container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                      <Typography>本文</Typography>
                    </Grid>
                    <TextFieldGrid item xs={10}>
                      <TextField value={mailText} multiline rows={8} onChange={(e)=>{changeText(e, "mailText")}} ></TextField>
                    </TextFieldGrid>
                  </Grid>
                </Grid>
              </GridContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <CancelButton onClick={handleMailModalClose}>キャンセル</CancelButton>
                    </Grid>
                    <Grid item xs={6}>
                      <SendButton onClick={()=>{setPageing(2)}}>送信</SendButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        }

        {pageing === 2 &&
          <Box>
            <DialogTitle sx={{ textAlign: "center" }}>送信しますか？</DialogTitle>
            <Box sx={{ margin: "30px" }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                  <CancelButton onClick={()=>{setPageing(1)}}>キャンセル</CancelButton>
                </Grid>
                <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                  <ColorButton onClick={handleMailSend}>送信</ColorButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        }

        {pageing === 3 &&
          <Box>
            <DialogTitle sx={{ textAlign: "center" }}>メール送信中</DialogTitle>
            <Box sx={{ margin: "30px" }}>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress color="inherit" />
              </Grid>
            </Box>
          </Box>
        }

        {pageing === 4 &&
          <Box>
            <DialogTitle sx={{ textAlign: "center" }}>メール送信完了</DialogTitle>
            <Box sx={{ margin: "30px" }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                  <ColorButton onClick={handleMailModalClose}>閉じる</ColorButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        }

        {pageing === 5 &&
          <Box>
            <DialogTitle sx={{ textAlign: "center" }}>メール送信失敗</DialogTitle>
            <Box sx={{ margin: "30px" }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                  <CancelButton onClick={handleMailModalClose}>閉じる</CancelButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        }
      </DialogComp>
    </Box>
  );
};

export default MailCreateModal;