import * as React from "react";

import { Box, Button, Checkbox, TextField } from "@mui/material";

export interface pageProps {
    props: {};
}

const Color: React.FC<pageProps> = ({ props }) => {
    const [labelName, setLabelName] = React.useState("サンプルラベル");
    const [sampleValue, setSampleValue] = React.useState("サンプル入力値");
    const [sampleColor, setSampleColor] = React.useState("FFFCD6");
    const [checked, setChecked] = React.useState<boolean>(false);

    const resetHandle = () => {
        setLabelName("サンプルラベル");
        setSampleValue("サンプル入力値");
        setSampleColor("FFFCD6");
        setChecked(false);
    };

    return (
        <Box
            style={{
                width: window.innerWidth,
                height: window.innerHeight,
                backgroundColor: "#F8FAF8",
            }}
        >
            <Box
                style={{
                    padding: "80px 0 0 60px",
                }}
            >
                <Box sx={{ display: "flex", margin: "0 0 10px 0" }}>
                    <Box sx={{ width: "120px", margin: "16px 0" }}>
                        サンプルラベル
                    </Box>
                    <TextField
                        onChange={(e) => setLabelName(e.target.value)}
                        value={labelName}
                    ></TextField>
                </Box>
                <Box sx={{ display: "flex", margin: "0 0 10px 0" }}>
                    <Box sx={{ width: "120px", margin: "16px 0" }}>
                        サンプル入力値
                    </Box>
                    <TextField
                        onChange={(e) => setSampleValue(e.target.value)}
                        value={sampleValue}
                    ></TextField>
                </Box>
                <Box sx={{ display: "flex", margin: "0 0 10px 0" }}>
                    <Box sx={{ width: "120px", margin: "16px 0" }}>
                        HTMLカラー
                    </Box>
                    <TextField
                        onChange={(e) => setSampleColor(e.target.value)}
                        value={sampleColor}
                    ></TextField>
                </Box>
                <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "120px", margin: "16px 0" }}>
                        {labelName}
                    </Box>
                    <TextField
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                backgroundColor: checked
                                    ? "rgba(0, 0, 0, 0.06)"
                                    : "#" + sampleColor,
                            },
                        }}
                        value={sampleValue}
                        disabled={checked}
                    ></TextField>
                </Box>
                <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "120px", margin: "16px 0" }}>
                        活性/非活性
                    </Box>
                    <Checkbox
                        onChange={(e) => setChecked(e.target.checked)}
                        checked={checked}
                    ></Checkbox>
                </Box>
                <Box sx={{ display: "flex" }}>
                    <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={resetHandle}
                    >
                        リセット
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Color;
