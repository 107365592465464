import { configureStore } from '@reduxjs/toolkit';
import donationReducer from '../features/donationSlice';
import storeOpeningConditionsReducer from '../features/storeOpeningConditionsSlice';
import coverReducer from '../features/coverSlice';
import impactReducer from '../features/impactSlice'
import addConflictReducer from '../features/addConflictSlice';
import salesForecastSummaryMainElementListSliceReducer from '../features/salesForecastSummaryMainElementListSlice';
import tradeAreaReducer from '../features/tradeAreaSlice';
import pictureReducer from '../features/pictureSlice';
import pdfSummaryReducer from '../features/pdfSummarySlice'
import additionalResearchReducer from '../features/additionalResearchSlice';
import otherDocumentReducer from '../features/otherDocumentSlice';
import appHeaderReducer from '../features/appHeaderSlice';
import portalReducer from '../features/portalSlice';
import mapPdfReducer from '../features/mapPdfSlice';
import drawingReducer from '../features/drawingSlice';
import conflictReducer from '../features/conflictSlice';
import trafficVolumeReducer from '../features/trafficVolumeSlice';
import n15AddMarketReducer from '../features/n15AddMarketSlice';
import longPageReducer from '../features/longPageSlice';
import locationTraffic5mcutReducer from '../features/locationTraffic5mcutSlice';
import locationWalk5minCircleReducer from '../features/locationWalk5minCircleSlice';
import deficiencyCommentReducer from '../features/deficiencyCommentSlice';
import rrInternalUseSliceReducer from '../features/rrInternalUseSlice';
import approval5mcutSliceReducer from '../features/approval5mcutSlice';
import locationAddSurveySliceReducer from '../features/locationAddSurveySlice';
import approvalSurveySliceReducer from '../features/approvalSurveySlice';
import locationSalesForecastSliceReducer from '../features/locationSalesForecastSlice';

// Redux Store
export const store = configureStore({
    reducer: {
        donation: donationReducer,
        storeOpeningConditions: storeOpeningConditionsReducer,
        cover: coverReducer,
        impact: impactReducer,
        addConflict: addConflictReducer,
        salesForecastSummaryMainElementList: salesForecastSummaryMainElementListSliceReducer,
        tradeArea: tradeAreaReducer,
        picture: pictureReducer,
        pdfSummary: pdfSummaryReducer,
        additionalResearch: additionalResearchReducer,
        otherDocument: otherDocumentReducer,
        appHeader: appHeaderReducer,
        portal: portalReducer,
        mapPdf: mapPdfReducer,
        drawing: drawingReducer,
        conflict: conflictReducer,
        trafficVolume: trafficVolumeReducer,
        n15AddMarket: n15AddMarketReducer,
        longPage: longPageReducer,
        locationTraffic5mcut: locationTraffic5mcutReducer,
        locationWalk5minCircle: locationWalk5minCircleReducer,
        deficiencyComment: deficiencyCommentReducer,
        rrInternalUse: rrInternalUseSliceReducer,
        approval5mcut: approval5mcutSliceReducer,
        locationAddSurvey: locationAddSurveySliceReducer,
        approvalSurvey: approvalSurveySliceReducer,
        locationSalesForecast: locationSalesForecastSliceReducer,
    }
});

// RootStateとdispatchの型定義
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
