import { LOCAL_CONSTANT } from "../Const";
// const RFC = 'z003';
// const RR = 'z001';  //リサーチ
// const RM = 'z002';
// const GM = 'z005';
// const OTHER = 'z004'; //その他
//活性か非活性か判定する関数
export const setPermissionFunc = (userType: any, taskId: any, page: string, item: string) => {
    //false 活性、 trueが非活性
    const taskGroup = groupSearchFunc(taskId);
    const locationPermissionObj = localStorage.getItem("locationPermission");

    //権限種別
    let is_permission = true
    if (locationPermissionObj !== null && locationPermissionObj !== "undefined") {
        const jsonObj = JSON.parse(locationPermissionObj);
        jsonObj.map((e: any) => {
            if (
                e.user_type === userType &&
                e.task_group === taskGroup &&
                e.page === page &&
                e.item === item
            ) {
                is_permission =  e.is_permission
            }
        })
    }
    return is_permission
};


//表示か非表示か判定する関数
export const showHideFunc = (userType: any, taskId: any, page: string, item: string) => {
    //false 非表示、 trueが表示

    const taskGroup = groupSearchFunc(taskId);
    const locationPermissionObj = localStorage.getItem("locationPermission");

    //権限種別
    let is_show = true
    if (locationPermissionObj !== null && locationPermissionObj !== "undefined") {
        const jsonObj = JSON.parse(locationPermissionObj);
        jsonObj.map((e: any) => {
            if (
                e.user_type === userType &&
                e.task_group === taskGroup &&
                e.page === page &&
                e.item === item
            ) {
                is_show =  e.is_show
            }
        })
    }
    return is_show
};


//非表示か表示か判定する関数 デフォルト非表示
export const HideShowFunc = (userType: any, taskId: any, page: string, item: string) => {
    //false 非表示、 trueが表示

    const taskGroup = groupSearchFunc(taskId);
    const locationPermissionObj = localStorage.getItem("locationPermission");

    //権限種別
    let is_show = false
    if (locationPermissionObj !== null && locationPermissionObj !== "undefined") {
        const jsonObj = JSON.parse(locationPermissionObj);
        jsonObj.map((e: any) => {
            if (
                e.user_type === userType &&
                e.task_group === taskGroup &&
                e.page === page &&
                e.item === item
            ) {
                is_show =  e.is_show
            }
        })
    }
    return is_show
};

//タスクIDからタスクグループを探す関数
export const groupSearchFunc = (taskId: any) => {

    const taskGroupObj = localStorage.getItem("taskGroup");
    let group = ''
    if (taskGroupObj !== null && taskGroupObj !== "undefined") {
        const jsonTaskGroupObj = JSON.parse(taskGroupObj);
        jsonTaskGroupObj.map((e: any) => {
            if (e.task_id === taskId) {
                group = e.group_code 
            }
        })
    }

    return group
};