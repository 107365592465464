import { createSlice } from '@reduxjs/toolkit';
import { initApprovalSurveyObj } from '../data/type';

//Sliceの作成
export const approvalSurveySlice = createSlice({
    name: 'approval5mcut',
    //初期State(Typeからインポート)
    initialState: {
        approvalStatus: initApprovalSurveyObj,
    },
    //toolKitを利用した値変更処理
    reducers: {
        setApprovalStatus: (state, action) => {
            state.approvalStatus = action.payload;
        },
    }
});

export const {
    setApprovalStatus,
} = approvalSurveySlice.actions;
export default approvalSurveySlice.reducer;

