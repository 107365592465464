import * as React from 'react';

import dayjs from 'dayjs';
import { useUserContext } from '../../user-provider';
import { Link, useSearchParams } from "react-router-dom";
import { useUtilityContext } from '../../utility-provider';
import { LOCAL_CONSTANT } from '../Const';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { getAccessToken } from '../Common';
import { getLongPageData } from '../../data/DataManage';
import {
    getFileMetadata,
    uploadFile,
    deleteUploadFile,
    getFileData
} from '../../utility/storage';
import {
    mpData,
    calculateColumnTotal,
} from '../subPage/TradeArea/TradeAreaFunction';
import ApprovalSurveyModal from '../subPage/ApprovalSurvey/ApprovalSurveyModal';
import {
    Box,
    Typography,
    Checkbox,
    Button,
	Grid,
	TextField,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
    IconButton,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { setLocationAddSurveyObj } from '../../features/locationAddSurveySlice';
import { addSurveyData } from '../components/LocationAddSurveyFunc';




const DEFAULT_IMAGE_01 = '/image/TrafficVolume/image001.png'
const DEFAULT_IMAGE_02 = '/image/TrafficVolume/image002.png'
const listHeaderStyle = {
    width: '150px',
    height: '50px',
    padding: '12px 0',
    border: '1px solid black',
    backgroundColor: '#D9D9D9',
}
const headerCell = {
	backgroundColor: '#D9D9D9',
	fontSize: '12px',
	color: 'black',
	borderBottom: '1px solid black',
	fontWeight: 'Bold'
};
const rowCell = {
    border: 'none',
	// backgroundColor: '#D9D9D9',
	// fontSize: '12px',
	// color: 'black',
	// borderBottom: '1px solid black',
	// fontWeight: 'Bold'
};

//参照値算出結果のラベル
const labels = [
	{ header: '初年度', Name: 'reference_value_1'},
	{ header: '2年度', Name: 'reference_value_2'},
	{ header: '3年度', Name: 'reference_value_3'},
	{ header: '4年度', Name: 'reference_value_4'},
	{ header: '5年度', Name: 'reference_value_5'}
];

interface resultProps {
    props: {
    }
}
const ApprovalSurvey: React.FC<resultProps> = ({ props }) => {
    const user = useUserContext();
    const dispatch = useAppDispatch();
    const utilityCtx = useUtilityContext();
    const [windowSize, setWindowSize] = React.useState({ "width": window.innerWidth, "height": window.innerHeight });
	//追加調査を取得
	const objLocationAddSurvey = useAppSelector((state) => state.locationAddSurvey.obj);
	const [coverExcelPath, setCoverExcelPath] = React.useState<any>({});

    //URLパラメータ
    const [searchParams] = useSearchParams();
    const planAreaId = searchParams.get("planAreaId");
    const branch = searchParams.get("branch");
    const [locationTraffic5mcut, setLocationTraffic5mcut] = React.useState<any>([])
    const [locationConflictMarketCut, setLocationConflictMarketCut] = React.useState<any>([])
    // const [image001, setImage001] = React.useState<string>(DEFAULT_IMAGE_01)
    // const [image002, setImage002] = React.useState<string>(DEFAULT_IMAGE_02)
    // const [marketCutImage001, setMarketCutImage001] = React.useState<string>("./image/conflict/market_cut_01.png")
    // const [marketCutImage002, setMarketCutImage002] = React.useState<string>("./image/conflict/market_cut_02.png")
    const [approvalModalOpen, setApprovalModalOpen] = React.useState<boolean>(false);
    const [userData, setUserData] = React.useState<any>({});
    const [approvalMgrStamp, setApprovalMgrStamp] = React.useState<string>("")
    const [approvalGmStamp, setApprovalGmStamp] = React.useState<string>("")
    const [approvalResearchMgrStamp, setApprovalResearchMgrStamp] = React.useState<string>("")
    const [approvalResearchGmStamp, setApprovalResearchGmStamp] = React.useState<string>("")
    // const [marketCutApprovalMgrStamp, setMarketCutApprovalMgrStamp] = React.useState<string>("")
    // const [marketCutApprovalGmStamp, setMarketCutApprovalGmStamp] = React.useState<string>("")
    const [coverPdfPath, setCoverPdfPath] = React.useState<any>({});
    const [receptionBook, setReceptionBook] = React.useState<any>([])

	//参照値算出結果のテキストフィールド
	const [addSurveyReferenceValue, setAddSurveyReferenceValue] = React.useState<any>({
		reference_value_1: '',
		reference_value_2: '',
		reference_value_3: '',
		reference_value_4: '',
		reference_value_5: '',
	});

    // const [marketCut, setMarketCut] = React.useState({
    //     'households': 0,
    //     'employees': 0,
    //     'delete_households': 0,
    //     'delete_employees': 0,
    //     'substance_households': 0,
    //     'substance_employees': 0,
    // })

    React.useEffect(() => {
        loadApi()
        function handleResize() {
            // ヘッダーの分を引く
            const tempHeight = window.innerHeight - 48;
            // ハンバーガーメニューの分を引く
            const tempWidth = window.innerWidth - 56;
            setWindowSize({ "width": tempWidth, "height": tempHeight });
        };

        // 初回ロード時のサイズに基づいてスケーリング
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    React.useEffect(() => {
        if (JSON.parse(user.userData || 'null') !== null) {
            setUserData(JSON.parse(user.userData))
        }
    }, [user]);

    const loadApi = async () => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        callApi()
            .then((res: any) => {
                //追加調査
                dispatch(setLocationAddSurveyObj(addSurveyData(res.getLocationAddSurvey)));
                // 画像
                const bucket_path = process.env.REACT_APP_GOOGLE_STORAGE
                    + '/' + process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
                    + '/' + planAreaId
                    + '/' + branch
                const access_token = getAccessToken()

                if (res.getLocationAddSurvey[0].approval_research_mgr_stamp !== '') {
                    setApprovalResearchMgrStamp(bucket_path + res.getLocationAddSurvey[0].approval_research_mgr_stamp + access_token)
                }
                if (res.getLocationAddSurvey[0].approval_research_gm_stamp !== '') {
                    setApprovalResearchGmStamp(bucket_path + res.getLocationAddSurvey[0].approval_research_gm_stamp + access_token)
                }
                if (res.getLocationAddSurvey[0].approval_mgr_stamp !== '') {
                    setApprovalMgrStamp(bucket_path + res.getLocationAddSurvey[0].approval_mgr_stamp + access_token)
                }
                if (res.getLocationAddSurvey[0].approval_gm_stamp !== '') {
                    setApprovalGmStamp(bucket_path + res.getLocationAddSurvey[0].approval_gm_stamp + access_token)
                }
                setReceptionBook(res.getReceptionBookByIdAndBranch[0])
            }).catch((e) => {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }).finally(() => {
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            })
    }

    const callApi = async () => {
        const param: any = []

        param.push({
            mode: 'getLocationAddSurvey',
            plan_area_id: planAreaId,
            branch: branch,
        })
        param.push({
            mode: 'getReceptionBookByIdAndBranch',
            plan_area_id: planAreaId,
            branch: branch,
        })

        return await getLongPageData(param);
    }


    const openApprovalModal = () => {
        setApprovalModalOpen(true)
    }

    const closeApprovalModal = () => {
        setApprovalModalOpen(false)
    }

    React.useEffect(() => {
        (async () => {
            try {
                if (objLocationAddSurvey.cover_pdf_path) {
                    const resultFile = await getFileMetadata('/' + planAreaId + '/' + branch + objLocationAddSurvey.cover_pdf_path);
                    setCoverPdfPath(resultFile)
                }
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }, [objLocationAddSurvey.cover_excel_user])

    React.useEffect(() => {
        //ReduxをuseStateに保存
        setAddSurveyReferenceValue({
          ...objLocationAddSurvey,
            reference_value_1: objLocationAddSurvey.reference_value_1,
            reference_value_2: objLocationAddSurvey.reference_value_2,
            reference_value_3: objLocationAddSurvey.reference_value_3,
            reference_value_4: objLocationAddSurvey.reference_value_4,
            reference_value_5: objLocationAddSurvey.reference_value_5
        })
    },[objLocationAddSurvey]);

    const gotoLocation = () => {
        window.location.href = "/LongPage?planAreaId=" + planAreaId + "&branch=" + branch
    }

    // ファイルDL
    const fileDownload = (data: any, target: string) => {
        (async () => {
        try {
            let fullPath = '/' + data.fullPath
            let fileName = '/' + data.name
            if (target === 'descriptionDocument') {
                fullPath = '/' + planAreaId + '/' + branch + data.file_path
                fileName = data.file_path.substring(5);
            }
            const url = await getFileData(fullPath);
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
            const blob = xhr.response;
            const link = document.createElement("a");
            document.body.appendChild(link);
            link.download = fileName;
            link.href = window.URL.createObjectURL(blob);
            link.click();
            document.body.removeChild(link);
            };
            xhr.open('GET', url as string);
            xhr.send();
        }catch(e){
            console.log(e);
        }
        })();
    }


    return (
        <Box sx={{ width: windowSize.width + 56, height: windowSize.height + 56, overflowY: 'scroll' }}>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ padding: '70px 0 20px 40px' }}>
					<Box sx={{
						display: 'flex',
						justifyContent: 'end',
						marginRight: '30px',
					}}>
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{ padding: '10px 20px' }}
                            onClick={gotoLocation}
                        >調査書</Button>
                    </Box>
					<Box><h3>追加調査</h3></Box>
					<Box>
						<Box sx={{ display: 'flex' }}>
                            <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center'}}>リサーチ Mgr</Box>
                            <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center'}}>リサーチ GM</Box>
                            <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center'}}>リクルート Mgr</Box>
                            <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center'}}>リクルート GM</Box>
						</Box>
						<Box sx={{ display: 'flex' }}>
                            <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center'}}><img src={approvalResearchMgrStamp}></img></Box>
                            <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center'}}><img src={approvalResearchGmStamp}></img></Box>
                            <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center'}}><img src={approvalMgrStamp}></img></Box>
                            <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center'}}><img src={approvalGmStamp}></img></Box>
						</Box>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ marginRight: '20px', marginTop: '40px', alignSelf: 'flex-start', whiteSpace: 'nowrap', fontWeight: 'bold' }}>参考値算出結果</Typography>
						<Grid container alignItems="center" maxWidth="lg">
							{labels.map((label, index) => (
							<Grid item xs={2} key={index}>
								<Typography align="center" sx={{ fontWeight: 'bold' }}>{label.header}</Typography>
								<TextField
								value={addSurveyReferenceValue[label.Name]}
								inputProps={{
									sx: {textAlign: 'center', backgroundColor: '#FFFCD6'}
								}}
								disabled={true}
								/>
							</Grid>
							))}
						</Grid>
					</Box>
					<Box><h5>表紙</h5></Box>
					<Grid container spacing={1}>
						<Grid item xs={12} sx={{ paddingRight: "40px" }}>
                            <TableContainer sx={{ height: "132px", width: "100%", border: '1px solid black' }}>
                                <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                    <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.FILE_NAME}</TableCell>
                                    <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOAD_DATE}</TableCell>
                                    <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOADER}</TableCell>
                                    <TableCell sx={headerCell}>PDF</TableCell>
                                    <TableCell sx={headerCell}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={rowCell}>{coverPdfPath?.name}</TableCell>
                                        <TableCell sx={rowCell}>{dayjs(coverPdfPath?.timeCreated).format('YYYY/MM/DD HH:mm:ss')}</TableCell>
                                        <TableCell sx={rowCell}>{objLocationAddSurvey.cover_excel_user}</TableCell>
                                        <TableCell sx={rowCell}>
                                            <IconButton sx={{ color: 'black' }} onClick={() => fileDownload(coverPdfPath, 'cover')}><DownloadIcon /></IconButton>
                                        </TableCell>
                                        <TableCell sx={rowCell}></TableCell>
                                    </TableRow>
                                </TableBody>
                                </Table>
                            </TableContainer>
						</Grid>
					</Grid>
					<Box><h5>説明資料</h5></Box>
					<Grid container spacing={1}>
						<Grid item xs={12} sx={{ paddingRight: "40px" }}>
						<TableContainer sx={{ height: "424px", width: "100%", border: '1px solid black' }}>
							<Table stickyHeader>
							<TableHead>
								<TableRow>
								<TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.FILE_NAME}</TableCell>
								<TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOAD_DATE}</TableCell>
								<TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOADER}</TableCell>
								<TableCell sx={headerCell}></TableCell>
								<TableCell sx={headerCell}></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{objLocationAddSurvey.doc_pdf_path.map((data: any, index: any) => (
								<TableRow className="tableRow" key={index}>
									<TableCell>{data.file_name}</TableCell>
									<TableCell>{data.upload_date}</TableCell>
									<TableCell>{data.upload_staff_number}</TableCell>
									<TableCell>
									    <IconButton sx={{ color: 'black' }} onClick={() => fileDownload(data, 'descriptionDocument')}><DownloadIcon /></IconButton>
									</TableCell>
									<TableCell></TableCell>
								</TableRow>
								))}
							</TableBody>
							</Table>
						</TableContainer>
						</Grid>
					</Grid>

                </Box>
                <Box sx={{
                    position: 'fixed',
                    bottom: '50px',
                    right: '50px',
                }}>
                    <Button
                        variant="contained"
                        color="success"
                        size="small"
                        sx={{ padding: '10px 20px' }}
                        onClick={openApprovalModal}
                    >承認/差戻</Button>
                </Box>
                <ApprovalSurveyModal props={{
                    open: approvalModalOpen,
                    handleModal: closeApprovalModal,
                    planAreaId: planAreaId,
                    branch: branch,
                    userData: userData,
                    receptionBook: receptionBook,
                }}
                />
            </Box>
        </Box>
    )
}



export default ApprovalSurvey;
