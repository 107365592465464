import { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";

import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import NaviListItems from "./NaviListItems";
import Portal from "./page/Portal";
import ReportListIndex from "./page/ReportList/ReportListIndex";
import RequestComment from "./page/ResearchReport/RequestComment";
import AppHeader from "./AppHeader";
import LongPage from "./page/ResearchReport/LongPage";
import CircularHistory from "./page/ResearchReport/CircularHistory";
import PortalReport from "./page/PortalReport";
import PdfSummary from "./page/ResearchReport/PdfSummary";
import DeficiencyComment from "./page/ResearchReport/DeficiencyComment";
import EvaluateChangeHistory from "./page/ResearchReport/EvaluateChangeHistory";
import ItemChangeHistory from "./page/ResearchReport/ItemChangeHistory";
import Approval5mcut from "./page/ResearchReport/Approval5mcut";
import ApprovalSurvey from "./page/ResearchReport/ApprovalSurvey";
import Color from "./page/ResearchReport/Color";
import { UserContext } from "./user-provider";

import { createTheme, styled, ThemeProvider } from "@mui/material/styles";

import "./index.css";

const drawerWidth: number = 240;

const mdTheme = createTheme({
    palette: {
        text: {
            primary: "#000000", // 基本文字色
        },
    },
    /* components: {
    MuiButton:{
      styleOverrides: {
        root: {
          '&: hover': {
            backgroundColor: 'red',
          }
        }
      }
    }
  } */
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    display: "none",
    zIndex: 10,
    "& .MuiToolbar-root": {
        minHeight: 0,
    },
    "& .MuiPaper-root": {
        marginTop: "55px",
        position: "fixed",
    },
    "& .MuiDrawer-paper": {
        position: "absolute",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(0),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(7),
            },
        }),
    },
}));

function LocationSurveyDigitalContent() {
    const [open, setOpen] = useState(false);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const userData = useContext(UserContext);

    //調査書関係以外の画面の場合メニュー非表示
    useEffect(() => {
        const pathname = window.location.pathname;
        const element = document.getElementById("naviDrawer");
        if (
            pathname === "/" ||
            pathname === "/ReportListIndex" ||
            pathname === "/PortalReport" ||
            pathname === "/Portal/CircularHistory"
        ) {
            if (element) {
                element.style.display = "none";
            }
        } else {
            if (element) {
                element.style.display = "block";
            }
        }
    }, []);

    //各画面コンテンツ内サイズ調整
    useEffect(() => {
        function handleResize() {
            // ヘッダーの分を引く
            const tempHeight = window.innerHeight - 48;
            // ハンバーガーメニューの分を引く
            const tempWidth = window.innerWidth - 56;
            setWindowSize({ width: tempWidth, height: tempHeight });
        }

        // 初回ロード時のサイズに基づいてスケーリング
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <ThemeProvider theme={mdTheme}>
            <BrowserRouter>
                {/* アプリヘッダー */}
                <AppHeader toggleDrawer={toggleDrawer} userdata={userData} />

                {/* ハンバーガーメニュー */}
                {window.location.pathname !== "/approval-5mcut" &&
                    window.location.pathname !== "/approval-survey" &&
                    window.location.pathname !== "/Color" && (
                        <Drawer id="naviDrawer" variant="permanent" open={open}>
                            {/* サイドメニュー */}
                            <List component="nav" sx={{ padding: 0 }}>
                                <NaviListItems
                                    props={{ open: open, setOpen: setOpen }}
                                />
                            </List>
                        </Drawer>
                    )}
                <Box>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Portal
                                    props={{
                                        width: windowSize.width,
                                        height: windowSize.height,
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/PortalReport"
                            element={
                                <PortalReport
                                    props={{
                                        width: windowSize.width,
                                        height: windowSize.height,
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/Portal/CircularHistory"
                            element={
                                <CircularHistory
                                    props={{
                                        width: windowSize.width,
                                        height: windowSize.height,
                                    }}
                                />
                            }
                        />
                        {/* 調査書リスト */}
                        <Route
                            path="/ReportListIndex"
                            element={<ReportListIndex props={{}} />}
                        />
                        {/* 調査書 */}
                        <Route
                            path="/RequestComment"
                            element={
                                <RequestComment
                                    props={{
                                        width: windowSize.width,
                                        height: windowSize.height,
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/PdfSummary"
                            element={
                                <PdfSummary
                                    props={{
                                        width: windowSize.width,
                                        height: windowSize.height,
                                    }}
                                />
                            }
                        />
                        {/* 調査書 ロングページ */}
                        <Route
                            path="/LongPage"
                            element={
                                <LongPage
                                    props={{
                                        width: windowSize.width,
                                        height: windowSize.height,
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/CircularHistory"
                            element={
                                <CircularHistory
                                    props={{
                                        width: windowSize.width,
                                        height: windowSize.height,
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/DeficiencyComment"
                            element={
                                <DeficiencyComment
                                    props={{
                                        width: windowSize.width,
                                        height: windowSize.height,
                                    }}
                                />
                            }
                        />
                        {/* 項目名変更履歴 */}
                        <Route
                            path="/ItemChangeHistory"
                            element={
                                <ItemChangeHistory
                                    props={{
                                        width: windowSize.width,
                                        height: windowSize.height,
                                    }}
                                />
                            }
                        />
                        {/* 評価変更履歴 */}
                        <Route
                            path="/EvaluateChangeHistory"
                            element={
                                <EvaluateChangeHistory
                                    props={{
                                        width: windowSize.width,
                                        height: windowSize.height,
                                    }}
                                />
                            }
                        />
                        {/* 5m外、商圏カット承認 */}
                        <Route
                            path="/approval-5mcut"
                            element={
                                <Approval5mcut
                                    props={{
                                        width: windowSize.width,
                                        height: windowSize.height,
                                    }}
                                />
                            }
                        />
                        {/* 追加調査承認 */}
                        <Route
                            path="/approval-survey"
                            element={
                                <ApprovalSurvey
                                    props={{
                                        width: windowSize.width,
                                        height: windowSize.height,
                                    }}
                                />
                            }
                        />
                        {/* カラーサンプルページ */}
                        <Route
                            path="/color"
                            element={
                                <Color
                                    props={{
                                        width: windowSize.width,
                                        height: windowSize.height,
                                    }}
                                />
                            }
                        />
                    </Routes>
                </Box>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default function LocationSurveyDigital() {
    return <LocationSurveyDigitalContent />;
}
