import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import PdfViewer from '../../utility/PdfViewer';
import { setSalesForecastSummaryMainElementList } from '../../features/salesForecastSummaryMainElementListSlice';
import '../subPage/SalesForecastSummaryMainElementList/style.css';
import { LOCAL_CONSTANT } from "../Const";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Grid,
    Box,
    Button,
    TableContainer,
    IconButton,
} from '@mui/material';
import { useUtilityContext } from '../../utility-provider';
import { getFileData, getFileMetadata, uploadFile, deleteUploadFile } from '../../utility/storage';
import SelectModal from '../subPage/SalesForecastSummaryMainElementList/SelectModal';
import { postPython } from '../../api/ApiCall';
import MessageDialog from '../components/MessageDialog';
import {
    setLocationBase
} from '../../features/longPageSlice';
import { getLongPageData } from '../../data/DataManage';
import { useUserContext } from '../../user-provider';
import { locationBaseData } from '../components/LocationBaseFunc';
import dayjs from 'dayjs';
import { DeleteConfirmationModal } from '../ResearchReport/DeleteConfirmationModal';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { setPermissionFunc, showHideFunc } from '../components/PermissionFunc';
import { staffName } from '../Common';
import { locationSalesForecastData } from '../components/LocationSalesForecastFunc';
import { setLocationSalesForecastObj } from '../../features/locationSalesForecastSlice';


const templateExcelList = () => {
    return [
        { "template": true, "recreate": 'none', "label": "【" + LOCAL_CONSTANT.LABEL.TEMPORARY_OUTPUT + "】" + LOCAL_CONSTANT.LABEL.SALES_FORECAST_SUMMARY_TABLE, "uploadDate": "", "uplowder": "", "pdfPath": "" },
        { "template": false, "recreate": 'none', "label": LOCAL_CONSTANT.LABEL.SALES_FORECAST_SUMMARY_TABLE, "uploadDate": "", "uplowder": "", "pdfPath": "" },
    ]
}

const headerCell = {
    backgroundColor: '#D9D9D9',
    fontSize: '12px',
    color: 'black',
    borderBottom: '1px solid black',
    fontWeight: 'Bold'
}
export interface salesForecastSummaryMainElementListProps {
    props: {
    }
}

const SalesForecastSummaryMainElementList: React.FC<salesForecastSummaryMainElementListProps> = ({ props }) => {

    const dispatch = useAppDispatch();
    const objflict = useAppSelector((state) => state.salesForecastSummaryMainElementList);
    const handleValueChange = (path: string, labelName: string) => {
        let pdfPath = path
        if (path) {
            pdfPath = 'gs://' + process.env.REACT_APP_FIREBASE_STORAGE_BUCKET + '/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + pdfPath
        }
        pdfPath = objflict.pdfPath === pdfPath ? "" : pdfPath
        //表示中のPDFレコードを押下時非表示
        dispatch(
            setSalesForecastSummaryMainElementList({
                ...objflict,
                [labelName]: pdfPath,
            })
        );
    }
    const [userType, setUserType] = React.useState('');
    const user = useUserContext();
    //ユーザー取得タイミングによる画面制御
    React.useEffect(() => {
        if (JSON.parse(user.userData || 'null') !== null) {
            setUserType(JSON.parse(user.userData || 'null').user_type);
        }
    }, [user]);
    //ロングページオブジェクト
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
    const objLSalesForecast = useAppSelector((state) => state.locationSalesForecast);

    const utilityCtx = useUtilityContext();
    const [file, setFile] = React.useState<File | null>(null)
    const [message, setMessage] = React.useState<string>("")
    const [recreate, setRecreate] = React.useState<boolean>(false)
    const [hasButton, setHasButton] = React.useState<boolean>(false)
    const [template, setTemplate] = React.useState<boolean>(false)
    const [salesForecastsColor, setSalesForecastsColor] = React.useState<string>("")
    const [open, setOpen] = React.useState<boolean>(false)
    const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
    const [excelList, setExcelList] = React.useState<any[]>([]);
    const [deleteConfModalOpen, setDeleteConfModalOpen] = React.useState(false);

    const handleClickFile = (isTemplate: boolean) => {
        setTemplate(isTemplate)
        const fileElem = document.getElementById("salesForecasFileElem");
        if (fileElem) {
            fileElem.click()
        }
    }

    const handleChangeFile = (newFile: any) => {
        setFile(newFile.target.files[0]);
        newFile.target.value = '';
    };

    React.useEffect(() => {
        if (file) {
            setOpen(true)
        }
    }, [file])

    const handleRecreate = (template: boolean) => {
        handleValueChange("", 'pdfPath')
        setTemplate(template)
        setRecreate(true)
        setOpen(true)
    }

    React.useEffect(() => {
        if (objLSalesForecast) {
            setExcelList(templateExcelList)
        }
    }, [objLSalesForecast])

    React.useEffect(() => {
        if (excelList.length > 0 && objLongPageParam.planAreaId) {
            setExcelList(templateExcelList)
            objLSalesForecast.obj.map((row: any) => {
                if (row.sales_forecast_pdf_path) {
                    let index = 0
                    let label = 'temp_sales_forecast_upload_date'
                    if (row.temp_flag === '0') {
                        index = 1
                        label = 'sales_forecast_upload_date'
                    }
                    excelList[index]['recreate'] = 'block'
                    excelList[index]['pdfPath'] = row.sales_forecast_pdf_path
                    excelList[index]['uplowder'] = row.sales_forecast_upload_user
                    getFile(row.sales_forecast_pdf_path, index, label)    
                }
            })
            setExcelList(excelList)
        }
    }, [excelList, objLongPageParam])

    const getFile = (filePath: string, index: number, labelName: string) => {
        (async () => {
            const path = '/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + filePath
            console.log(path)
            await getFileMetadata(path)
                .then((fileData: any) => {
                    excelList[index]['uploadDate'] = fileData.updated
                    dispatch(
                        setSalesForecastSummaryMainElementList({
                            ...objflict,
                            [labelName]: fileData.updated,
                        })
                    );
                })
        })();
    }

    const showUploadDate = (data: any) => {
        let date = ""
        if (data.uploadDate) {
            date = dayjs(data.uploadDate).format('YYYY/MM/DD HH:mm:ss')
        }
        return date
    }

    //ファイル保存
    const fileDownload = (filePath: string, fileName: string) => {
        (async () => {
            try {
                const url = await getFileData('/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + filePath);
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                    const link = document.createElement("a");
                    document.body.appendChild(link);
                    link.download = fileName + '.pdf';
                    link.href = window.URL.createObjectURL(blob);
                    link.click();
                    document.body.removeChild(link);
                };
                xhr.open('GET', url as string);
                xhr.send();
            } catch (e) {
                console.log(e);
            }
        })();
    }

    const fileUpload = (hasRecreate: boolean) => {
        const userData = JSON.parse(user.userData)
        const upload_file_name = template
            ? LOCAL_CONSTANT.LABEL.TEMPORARY_OUTPUT + '_' + LOCAL_CONSTANT.LABEL.SALES_FORECAST_SUMMARY_TABLE
            : LOCAL_CONSTANT.LABEL.SALES_FORECAST_SUMMARY_TABLE
        const params = {
            mode: "regist",
            endPoint: "/excel-to-pdf",
            query: {
                prop_site_no: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
                file_name: upload_file_name + '.xlsx',
                manager: "",
                color: salesForecastsColor,
                temp_flag: template ? '1' : '0',
                isInei: hasRecreate ? '1' : '0',
                mode: recreate ? "recreate" : "create",
                approval_type: "",
                approval_user: userData.user_name,
                approval_department: userData.user_name,
                user_data: {
                    update_top_department: userData.top_department_code_OA,
                    update_department: userData.department_code_OA,
                    update_job: userData.job_category_code_OA,
                    update_mail: userData.email.toString(),
                    update_stuff_number: userData.staff_number,
                    update_name: userData.user_name
                }
            }
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.CREATE_PDF)
                setMessageDialogOpen(true)
                if (!hasRecreate) {
                    const uploadResult = await uploadFile(file, objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/excel/' + upload_file_name + '.xlsx')
                }
                const result = await postPython(params)
                setFile(null)
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.COMPLETE_CREATE_PDF)
                setHasButton(true)
                setRecreate(false)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UNCOMPLETE_CREATE_PDF)
                setHasButton(true)
                setRecreate(false)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }

    const getLocationSalesForecast = () => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        try {
            // API呼び出し
            callApi('getLocationSalesForecast').then((res: any) => {
                dispatch(setLocationSalesForecastObj(locationSalesForecastData(res.getLocationSalesForecast)));
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }).catch((e) => {
                console.log(e)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            })
        } catch (e) {
            console.log(e)
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
        }
    }

    const callApi = async (target: string) => {
        if (target === 'getLocationSalesForecast') {
            // テストデータ固定値
            const param: any = [
                {
                    mode: 'getLocationSalesForecast',
                    plan_area_id: objLongPageParam.planAreaId,
                    branch: objLongPageParam.branch,
                },
            ];
            return await getLongPageData(param);
        }
    };


    // PDF作成モダールクローズ後
    const closeMessageDialog = () => {
        setMessage("")
        setHasButton(false)
        getLocationSalesForecast()
        setMessageDialogOpen(false)
    }
    /* const templateDisplay: boolean = (
        objLocationBase.task_id === 'C006' && userType === 'z001'
    )
    const salesForecasDisplay: boolean = (
        objLocationBase.task_id === 'B010' && userType === 'z001'
    ) */

    const handleDeleteConfModalOpen = (rowData: any) => {
        setDeleteConfModalOpen(true);
        setTemplate(rowData.template)
    }

    //ファイル削除
    const handleDeleteChange = () => {
        handleValueChange("", 'pdfPath')
        deleteFile()
    }

    const deleteFile = () => {
        const userData = JSON.parse(user.userData)
        const temp_flag = template ? '1' : '0'
        const params = {
            mode: "regist",
            endPoint: "/excel-to-pdf",
            query: {
                prop_site_no: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
                file_name: '',
                manager: "",
                color: salesForecastsColor,
                temp_flag: temp_flag,
                isInei: '0',
                mode: "delete",
                approval_type: "",
                approval_user: '',
                approval_department: '',
                user_data: {
                    update_top_department: userData.top_department_code_OA,
                    update_department: userData.department_code_OA,
                    update_job: userData.job_category_code_OA,
                    update_mail: userData.email.toString(),
                    update_stuff_number: userData.staff_number,
                    update_name: userData.user_name
                }
            }
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_PDF)
                setMessageDialogOpen(true)
                // ファイル削除
                let sales_forecast_excel_path = ""
                let sales_forecast_pdf_path = ""
                let deleteFile = null
                const path = '/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/'
                objLSalesForecast.obj.map(async (row: any) => {
                    if (temp_flag === row.temp_flag) {
                        // excel削除
                        deleteFile = await getFileMetadata(path + row.sales_forecast_excel_path);
                        await deleteUploadFile(deleteFile, path + row.sales_forecast_excel_path)
                        // pdf削除
                        deleteFile = await getFileMetadata(path + row.sales_forecast_pdf_path);
                        await deleteUploadFile(deleteFile, path + row.sales_forecast_pdf_path)
                    }
                })
                await postPython(params)
                setFile(null)
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_DELETE_PDF)
                setHasButton(true)
                setRecreate(false)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_DELETE_PDF)
                setHasButton(true)
                setRecreate(false)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }

    return (
        <Box sx={{ width: "100%", margin: "30px" }}>
            <input
                id="salesForecasFileElem"
                type="file"
                style={{ display: 'none' }}
                onChange={handleChangeFile}
                accept=".xls,.xlsx"
            />
            {showHideFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST, 'tempUploadButton') &&
                <Box>
                    <Button
                        sx={{ margin: '20px 0' }}
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={(e) => { handleClickFile(true) }}
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST, 'tempUploadButton')}
                    >{"【" + LOCAL_CONSTANT.LABEL.TEMPORARY_OUTPUT + "】" +
                        LOCAL_CONSTANT.LABEL.SALES_FORECAST_SUMMARY_TABLE +
                        LOCAL_CONSTANT.LABEL.UPLOAD}</Button>
                </Box>
            }
            {showHideFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST, 'uploadButton') &&
                <Box>
                    <Button
                        sx={{ margin: '20px 0' }}
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={(e) => { handleClickFile(false) }}
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST, 'uploadButton')}
                    >{LOCAL_CONSTANT.LABEL.SALES_FORECAST_SUMMARY_TABLE + LOCAL_CONSTANT.LABEL.UPLOAD}</Button>
                </Box>
            }
            <SelectModal
                props={{
                    open: open,
                    handleModal: setOpen,
                    handleRegistColor: setSalesForecastsColor,
                    handleRegistModal: fileUpload,
                    recreate: recreate
                }} />
            <MessageDialog props={{
                open: messageDialogOpen,
                handleModal: closeMessageDialog,
                body: message,
                hasButton: hasButton,
                buttonBody: LOCAL_CONSTANT.LABEL.CLOSE,
            }}
            />
            <Grid container spacing={1}>
                <Grid item xs={12} sx={{ paddingRight: "40px" }}>
                    <TableContainer sx={{ width: "100%", border: '1px solid black' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={headerCell}></TableCell>
                                    <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOAD_DATE}</TableCell>
                                    <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOADER}</TableCell>
                                    <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.PDF_CREATION}</TableCell>
                                    <TableCell sx={headerCell}></TableCell>
                                    <TableCell sx={headerCell}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {excelList.map((val, index) => (
                                    <TableRow className="tableRow" key={index}>
                                        <TableCell onClick={() => handleValueChange(val.pdfPath, "pdfPath")}>{val.label}</TableCell>
                                        <TableCell onClick={() => handleValueChange(val.pdfPath, "pdfPath")}>{showUploadDate(val)}</TableCell>
                                        <TableCell onClick={() => handleValueChange(val.pdfPath, "pdfPath")}>{staffName(val.uplowder)}</TableCell>
                                        <TableCell>
                                            <Button
                                                sx={{ display: val.recreate }}
                                                variant="contained"
                                                color="success"
                                                size="small"
                                                onClick={(e) => { handleRecreate(val.template) }}
                                            >{LOCAL_CONSTANT.LABEL.RECREATE}</Button>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton sx={{ color: 'black', display: val.pdfPath != "" ? 'block' : 'none' }} onClick={() => fileDownload(val.pdfPath, val.label)}><DownloadIcon /></IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton sx={{ color: 'black', display: val.pdfPath != "" ? 'block' : 'none' }} onClick={() => handleDeleteConfModalOpen(val)}><DeleteIcon /></IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            {objflict.pdfPath !== "" &&
                <Box sx={{ paddingTop: "60px" }}>
                    <PdfViewer props={{ pdfPath: objflict.pdfPath }} />
                </Box>
            }

            {/* 削除確認モーダル */}
            <DeleteConfirmationModal modalOpen={deleteConfModalOpen} setModalOpen={setDeleteConfModalOpen} deleteMethod={handleDeleteChange} />
        </Box>
    );

}

export default SalesForecastSummaryMainElementList;
