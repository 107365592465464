import dayjs from 'dayjs';

//表紙ページ項目名の型定義
export type CoverTypeList = {
    createDate: string;
    receptionNo: string;
    storeNo: string;
    surveyName: string;
    address: string;
    addressDetail: string;
    contractDueDate: string;
    openDueDate: string;
    prefecturesCode: string;
    newAreaNo: string;
    branchNo: string;
    existingStoreDesign: boolean;
    sAndBCase: boolean;
    conflictRuinsCase: boolean;
    targetContractNotOpened: String;
    surveyCreator: string;
    confirmer: string;
};

//表紙ページ項目名の初期値
export const initCoverObj: CoverTypeList = {
    createDate: dayjs().format("YYYY-MM-DD"),
    receptionNo: '',
    storeNo: '',
    surveyName: '',
    address: '',
    addressDetail: '',
    contractDueDate: dayjs().format("YYYY-MM-DD"),
    openDueDate: dayjs().format("YYYY-MM-DD"),
    prefecturesCode: '',
    newAreaNo: '',
    branchNo: '',
    existingStoreDesign: false,
    sAndBCase: false,
    conflictRuinsCase: false,
    targetContractNotOpened: 'ある',
    surveyCreator: '',
    confirmer: '',
};

//表紙ページのstring型の型定義
export type CoverStringTypeList = {
    contractDueDate: string;
    openDueDate: string;
    targetContractNotOpened: string;
    [coverStringKey: string]: string; //string型でキーを指定するため
};

//表紙ページの不備コメントモーダル内のテキストフィールドの初期値
export const initCoverCommentTextFieldObj: CoverStringTypeList = {
    contractDueDate: '',
    openDueDate: '',
    targetContractNotOpened: '',
};

//表紙ページのboolean型の定義
export type CoverBooleanTypeList = {
    address: boolean;
    contractDueDate: boolean;
    openDueDate: boolean;
    targetContractNotOpened: boolean;
    [coverBooleanKey: string]: boolean; //string型でキーを指定するため
};

//表紙ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initCoverReadOnlyObj: CoverBooleanTypeList = {
    address: true,
    contractDueDate: true,
    openDueDate: true,
    targetContractNotOpened: true,
};

// 表紙ページの鉛筆アイコンの表示非表示のステータスの初期値 trueが表示、falseが非表示
export const initCoverShowEditIconObj: CoverBooleanTypeList = {
    address: true,
    contractDueDate: true,
    openDueDate: true,
    targetContractNotOpened: true,
}

//出店条件ページ項目名の型定義
export type StoreOpeningConditionsTypeList = {
    settingReason: string;
    candidateLocationRuins: string;
    candidateLocationRuinsLongText: string;
    isStoreNameSelectReason: boolean;
    removalReason: string;
    open24Hours: string;
    notOpen24Hours: string;
    alcoholLicense: string;
    noAlcoholLicense: string;
    tobaccoLicence: string;
    noTobaccoLicence: string;
    areaType: string;
    nearestAlreadystoreDistance: string;
    environmentType: string;
    acquisitionRequirement: string;
    tobaccoDistanceStandard: string;
    distanceStandardAlreadystoreCount: string;
    previousJob: string;
    conflictCVS: string;
    cvsLastNippan: string;
    relocationDistance: string;
    ownershipType: string;
    storeType: string;
    storefrontage: string;
    storeArea: string;
    salsesArea: string;
    openCase: string;
    nakajimaChilled: string;
    islandChilled: string;
    gondolaUnits: string;
    gondolaHeight: string;
    iceCaseLarge: string;
    iceCaseMedium: string;
    iceCaseSmall: string;
    compactStocker: string;
    lowTempReachIn: string;
    walkIn: string;
    conterWidth: string;
    existingStoreName: string;
    storeNo: string;
    averageNippan: string;
    achievementAcquisitionStart: string;
    achievementAcquisitionEnd: string;
    receptionNo: string;
    sStoreOpenDate: string;
    sStoreCloseDueDate: string;
    closingDays: string;
    inSalesConstructionDays: string;
    carCommonRatio: string;
    personCommonRatio: string;
    sStoreLineDistance: string;
    sStoreWayDistance: string;
    sStoreCarCustomerRatio: string;
    lastfixedMonth: string;
    lastOneYearNippan: string;
    yearOnYear: string;
    yearMonth: string;
    changeType: string;
    content: string;
    lastOneYearNippan0: string;
    lastOneYearNippan1: string;
    lastOneYearNippan2: string;
    lastOneYearNippan3: string;
    lastOneYearNippan4: string;
    lastOneYearNippan5: string;
    lastOneYearNippan6: string;
    lastOneYearNippan7: string;
    lastOneYearNippan8: string;
    lastOneYearNippan9: string;
    lastOneYearNippan10: string;
    lastOneYearNippan11: string;
    yearOnYear0: string;
    yearOnYear1: string;
    yearOnYear2: string;
    yearOnYear3: string;
    yearOnYear4: string;
    yearOnYear5: string;
    yearOnYear6: string;
    yearOnYear7: string;
    yearOnYear8: string;
    yearOnYear9: string;
    yearOnYear10: string;
    yearOnYear11: string;
    storeMoveType: string;
    storeMoveDistance: string;
    storeTypeMultipleStore: boolean;
    lastOneYearNippanAverage: string;
    yearOnYearAverage: string;
    [StoreOpeningConditionsKey: string]: any; //string型でキーを指定するため
};

//出店条件ページ項目名の初期値
export const initStoreOpeningConditionsObj: StoreOpeningConditionsTypeList = {
    settingReason: '',
    candidateLocationRuins: '',
    candidateLocationRuinsLongText: '',
    isStoreNameSelectReason: false,
    removalReason: '',
    open24Hours: '',
    notOpen24Hours: '',
    alcoholLicense: '',
    noAlcoholLicense: '',
    tobaccoLicence: '',
    noTobaccoLicence: '',
    areaType: '',
    nearestAlreadystoreDistance: '',
    environmentType: '',
    acquisitionRequirement: '',
    tobaccoDistanceStandard: '',
    distanceStandardAlreadystoreCount: '',
    previousJob: '',
    conflictCVS: '',
    cvsLastNippan: '',
    relocationDistance: '',
    ownershipType: '',
    storeType: '',
    storefrontage: '',
    storeArea: '',
    salsesArea: '',
    openCase: '',
    nakajimaChilled: '',
    islandChilled: '',
    gondolaUnits: '',
    gondolaHeight: '',
    iceCaseLarge: '',
    iceCaseMedium: '',
    iceCaseSmall: '',
    compactStocker: '',
    lowTempReachIn: '',
    walkIn: '',
    conterWidth: '',
    existingStoreName: '',
    storeNo: '',
    averageNippan: '',
    achievementAcquisitionStart: '',
    achievementAcquisitionEnd: '',
    receptionNo: '',
    sStoreOpenDate: '',
    sStoreCloseDueDate: '',
    sStoreLineDistance: '',
    sStoreWayDistance: '',
    closingDays: '',
    inSalesConstructionDays: '',
    carCommonRatio: '',
    personCommonRatio: '',
    sStoreCarCustomerRatio: '',
    lastfixedMonth: '',
    lastOneYearNippan: '',
    yearOnYear: '',
    yearMonth: '',
    changeType: '',
    content: '',
    lastOneYearNippan0: '',
    lastOneYearNippan1: '',
    lastOneYearNippan2: '',
    lastOneYearNippan3: '',
    lastOneYearNippan4: '',
    lastOneYearNippan5: '',
    lastOneYearNippan6: '',
    lastOneYearNippan7: '',
    lastOneYearNippan8: '',
    lastOneYearNippan9: '',
    lastOneYearNippan10: '',
    lastOneYearNippan11: '',
    yearOnYear0: '',
    yearOnYear1: '',
    yearOnYear2: '',
    yearOnYear3: '',
    yearOnYear4: '',
    yearOnYear5: '',
    yearOnYear6: '',
    yearOnYear7: '',
    yearOnYear8: '',
    yearOnYear9: '',
    yearOnYear10: '',
    yearOnYear11: '',
    storeMoveType: '',
    storeMoveDistance: '',
    storeTypeMultipleStore: false,
    lastOneYearNippanAverage: '',
    yearOnYearAverage: ''
};

//出店条件ページのboolean型の定義
export type StoreOpeningConditionsBooleanTypeList = {
    settingReason: boolean;
    candidateLocationRuins: boolean;
    candidateLocationRuinsLongText: boolean;
    isStoreNameSelectReason: boolean;
    removalReason: boolean;
    open24Hours: boolean;
    notOpen24Hours: boolean;
    alcoholLicense: boolean;
    noAlcoholLicense: boolean;
    tobaccoLicence: boolean;
    noTobaccoLicence: boolean;
    areaType: boolean;
    nearestAlreadystoreDistance: boolean;
    environmentType: boolean;
    acquisitionRequirement: boolean;
    tobaccoDistanceStandard: boolean;
    distanceStandardAlreadystoreCount: boolean;
    previousJob: boolean;
    conflictCVS: boolean;
    cvsLastNippan: boolean;
    relocationDistance: boolean;
    ownershipType: boolean;
    storeType: boolean;
    storefrontage: boolean;
    storeArea: boolean;
    salsesArea: boolean;
    openCase: boolean;
    nakajimaChilled: boolean;
    islandChilled: boolean;
    gondolaUnits: boolean;
    gondolaHeight: boolean;
    iceCaseLarge: boolean;
    iceCaseMedium: boolean;
    iceCaseSmall: boolean;
    compactStocker: boolean;
    lowTempReachIn: boolean;
    walkIn: boolean;
    conterWidth: boolean;
    existingStoreName: boolean;
    storeNo: boolean;
    averageNippan: boolean;
    achievementAcquisitionStart: boolean;
    achievementAcquisitionEnd: boolean;
    receptionNo: boolean;
    sStoreOpenDate: boolean;
    sStoreCloseDueDate: boolean;
    closingDays: boolean;
    inSalesConstructionDays: boolean;
    carCommonRatio: boolean;
    personCommonRatio: boolean;
    sStoreLineDistance: boolean;
    sStoreWayDistance: boolean;
    sStoreCarCustomerRatio: boolean;
    lastfixedMonth: boolean;
    lastOneYearNippan: boolean;
    yearOnYear: boolean;
    yearMonth: boolean;
    changeType: boolean;
    content: boolean;
    storeMoveType: boolean;
    storeMoveDistance: boolean;
    storeTypeMultipleStore: boolean;
    sStoreDailySalesAndPreYearComparison: boolean;
    lastOneYearNippanAverage: boolean;
    yearOnYearAverage: boolean;
    [storeOpeningConditionsBooleanKey: string]: boolean; //string型でキーを指定するため
};

//出店条件ページのアイコン全体の表示非表示のステータスの初期値
export const initStoreOpeningConditionsShowIconObj: StoreOpeningConditionsBooleanTypeList = {
    settingReason: false,
    candidateLocationRuins: false,
    candidateLocationRuinsLongText: false,
    isStoreNameSelectReason: false,
    removalReason: false,
    open24Hours: false,
    notOpen24Hours: false,
    alcoholLicense: false,
    noAlcoholLicense: false,
    tobaccoLicence: false,
    noTobaccoLicence: false,
    areaType: false,
    nearestAlreadystoreDistance: false,
    environmentType: false,
    acquisitionRequirement: false,
    tobaccoDistanceStandard: false,
    distanceStandardAlreadystoreCount: false,
    previousJob: false,
    conflictCVS: false,
    cvsLastNippan: false,
    relocationDistance: false,
    ownershipType: false,
    storeType: false,
    storefrontage: false,
    storeArea: false,
    salsesArea: false,
    openCase: false,
    nakajimaChilled: false,
    islandChilled: false,
    gondolaUnits: false,
    gondolaHeight: false,
    iceCaseLarge: false,
    iceCaseMedium: false,
    iceCaseSmall: false,
    compactStocker: false,
    lowTempReachIn: false,
    walkIn: false,
    conterWidth: false,
    existingStoreName: false,
    storeNo: false,
    averageNippan: false,
    achievementAcquisitionStart: false,
    achievementAcquisitionEnd: false,
    receptionNo: false,
    sStoreOpenDate: false,
    sStoreCloseDueDate: false,
    sStoreLineDistance: false,
    sStoreWayDistance: false,
    closingDays: false,
    inSalesConstructionDays: false,
    carCommonRatio: false,
    personCommonRatio: false,
    sStoreCarCustomerRatio: false,
    lastfixedMonth: false,
    lastOneYearNippan: false,
    yearOnYear: false,
    yearMonth: false,
    changeType: false,
    content: false,
    storeMoveType: false,
    storeMoveDistance: false,
    storeTypeMultipleStore: false,
    sStoreDailySalesAndPreYearComparison: false,
    lastOneYearNippanAverage: false,
    yearOnYearAverage: false
};

//出店条件ページの鉛筆アイコンの表示非表示のステータスの初期値 trueが表示、falseが非表示
export const initStoreOpeningConditionsShowEditIconObj: StoreOpeningConditionsBooleanTypeList = {
    settingReason: true,
    candidateLocationRuins: true,
    candidateLocationRuinsLongText: true,
    isStoreNameSelectReason: true,
    removalReason: true,
    open24Hours: true,
    notOpen24Hours: true,
    alcoholLicense: true,
    noAlcoholLicense: true,
    tobaccoLicence: true,
    noTobaccoLicence: true,
    areaType: true,
    nearestAlreadystoreDistance: true,
    environmentType: true,
    acquisitionRequirement: true,
    tobaccoDistanceStandard: true,
    distanceStandardAlreadystoreCount: true,
    previousJob: true,
    conflictCVS: true,
    cvsLastNippan: true,
    relocationDistance: true,
    ownershipType: true,
    storeType: true,
    storefrontage: true,
    storeArea: true,
    salsesArea: true,
    openCase: true,
    nakajimaChilled: true,
    islandChilled: true,
    gondolaUnits: true,
    gondolaHeight: true,
    iceCaseLarge: true,
    iceCaseMedium: true,
    iceCaseSmall: true,
    compactStocker: true,
    lowTempReachIn: true,
    walkIn: true,
    conterWidth: true,
    existingStoreName: true,
    storeNo: true,
    averageNippan: true,
    achievementAcquisitionStart: true,
    achievementAcquisitionEnd: true,
    receptionNo: true,
    sStoreOpenDate: true,
    sStoreCloseDueDate: true,
    sStoreLineDistance: true,
    sStoreWayDistance: true,
    closingDays: true,
    inSalesConstructionDays: true,
    carCommonRatio: true,
    personCommonRatio: true,
    sStoreCarCustomerRatio: true,
    lastfixedMonth: true,
    lastOneYearNippan: true,
    yearOnYear: true,
    yearMonth: true,
    changeType: true,
    content: true,
    storeMoveType: true,
    storeMoveDistance: true,
    storeTypeMultipleStore: true,
    sStoreDailySalesAndPreYearComparison: true,
    lastOneYearNippanAverage: true,
    yearOnYearAverage: true
};

//出店条件ページのstring型の型定義
export type StoreOpeningConditionsStringTypeList = {
    settingReason: string;
    candidateLocationRuins: string;
    candidateLocationRuinsLongText: string;
    isStoreNameSelectReason: string;
    removalReason: string;
    open24Hours: string;
    notOpen24Hours: string;
    alcoholLicense: string;
    noAlcoholLicense: string;
    tobaccoLicence: string;
    noTobaccoLicence: string;
    areaType: string;
    nearestAlreadystoreDistance: string;
    environmentType: string;
    acquisitionRequirement: string;
    tobaccoDistanceStandard: string;
    distanceStandardAlreadystoreCount: string;
    previousJob: string;
    conflictCVS: string;
    cvsLastNippan: string;
    relocationDistance: string;
    ownershipType: string;
    storeType: string;
    storefrontage: string;
    storeArea: string;
    salsesArea: string;
    openCase: string;
    nakajimaChilled: string;
    islandChilled: string;
    gondolaUnits: string;
    gondolaHeight: string;
    iceCaseLarge: string;
    iceCaseMedium: string;
    iceCaseSmall: string;
    compactStocker: string;
    lowTempReachIn: string;
    walkIn: string;
    conterWidth: string;
    existingStoreName: string;
    storeNo: string;
    averageNippan: string;
    achievementAcquisitionStart: string;
    achievementAcquisitionEnd: string;
    receptionNo: string;
    sStoreOpenDate: string;
    sStoreCloseDueDate: string;
    closingDays: string;
    inSalesConstructionDays: string;
    carCommonRatio: string;
    personCommonRatio: string;
    sStoreLineDistance: string;
    sStoreWayDistance: string;
    sStoreCarCustomerRatio: string;
    lastfixedMonth: string;
    lastOneYearNippan: string;
    yearOnYear: string;
    yearMonth: string;
    changeType: string;
    content: string;
    storeMoveType: string;
    storeMoveDistance: string;
    storeTypeMultipleStore: string;
    sStoreDailySalesAndPreYearComparison: string;
    lastOneYearNippanAverage: string;
    yearOnYearAverage: string;
    [storeOpeningConditionsStringKey: string]: string; //string型でキーを指定するため
};

//出店条件ページのモーダル内のテキストフィールドの初期値
export const initStoreOpeningConditionsCommentTextFieldObj: StoreOpeningConditionsStringTypeList = {
    settingReason: '',
    candidateLocationRuins: '',
    candidateLocationRuinsLongText: '',
    isStoreNameSelectReason: '',
    removalReason: '',
    open24Hours: '',
    notOpen24Hours: '',
    alcoholLicense: '',
    noAlcoholLicense: '',
    tobaccoLicence: '',
    noTobaccoLicence: '',
    areaType: '',
    nearestAlreadystoreDistance: '',
    environmentType: '',
    acquisitionRequirement: '',
    tobaccoDistanceStandard: '',
    distanceStandardAlreadystoreCount: '',
    previousJob: '',
    conflictCVS: '',
    cvsLastNippan: '',
    relocationDistance: '',
    ownershipType: '',
    storeType: '',
    storefrontage: '',
    storeArea: '',
    salsesArea: '',
    openCase: '',
    nakajimaChilled: '',
    islandChilled: '',
    gondolaUnits: '',
    gondolaHeight: '',
    iceCaseLarge: '',
    iceCaseMedium: '',
    iceCaseSmall: '',
    compactStocker: '',
    lowTempReachIn: '',
    walkIn: '',
    conterWidth: '',
    existingStoreName: '',
    storeNo: '',
    averageNippan: '',
    achievementAcquisitionStart: '',
    achievementAcquisitionEnd: '',
    receptionNo: '',
    sStoreOpenDate: '',
    sStoreCloseDueDate: '',
    closingDays: '',
    inSalesConstructionDays: '',
    carCommonRatio: '',
    personCommonRatio: '',
    sStoreLineDistance: '',
    sStoreWayDistance: '',
    sStoreCarCustomerRatio: '',
    lastfixedMonth: '',
    lastOneYearNippan: '',
    yearOnYear: '',
    yearMonth: '',
    changeType: '',
    content: '',
    storeMoveType: '',
    storeMoveDistance: '',
    storeTypeMultipleStore: '',
    sStoreDailySalesAndPreYearComparison: '',
    lastOneYearNippanAverage: '',
    yearOnYearAverage: ''
};

//出店条件ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initStoreOpeningConditionsReadOnlyObj: StoreOpeningConditionsBooleanTypeList = {
    settingReason: true,
    candidateLocationRuins: true,
    candidateLocationRuinsLongText: true,
    isStoreNameSelectReason: true,
    removalReason: true,
    open24Hours: true,
    notOpen24Hours: true,
    alcoholLicense: true,
    noAlcoholLicense: true,
    tobaccoLicence: true,
    noTobaccoLicence: true,
    areaType: true,
    nearestAlreadystoreDistance: true,
    environmentType: true,
    acquisitionRequirement: true,
    tobaccoDistanceStandard: true,
    distanceStandardAlreadystoreCount: true,
    previousJob: true,
    conflictCVS: true,
    cvsLastNippan: true,
    relocationDistance: true,
    ownershipType: true,
    storeType: true,
    storefrontage: true,
    storeArea: true,
    salsesArea: true,
    openCase: true,
    nakajimaChilled: true,
    islandChilled: true,
    gondolaUnits: true,
    gondolaHeight: true,
    iceCaseLarge: true,
    iceCaseMedium: true,
    iceCaseSmall: true,
    compactStocker: true,
    lowTempReachIn: true,
    walkIn: true,
    conterWidth: true,
    existingStoreName: true,
    storeNo: true,
    averageNippan: true,
    achievementAcquisitionStart: true,
    achievementAcquisitionEnd: true,
    receptionNo: true,
    sStoreOpenDate: true,
    sStoreCloseDueDate: true,
    sStoreLineDistance: true,
    sStoreWayDistance: true,
    closingDays: true,
    inSalesConstructionDays: true,
    carCommonRatio: true,
    personCommonRatio: true,
    sStoreCarCustomerRatio: true,
    lastfixedMonth: true,
    lastOneYearNippan: true,
    yearOnYear: true,
    yearMonth: true,
    changeType: true,
    content: true,
    storeMoveType: true,
    storeMoveDistance: true,
    storeTypeMultipleStore: true,
    sStoreDailySalesAndPreYearComparison: true,
    lastOneYearNippanAverage: true,
    yearOnYearAverage: true
};

//出店条件ページロングテキスト表示非表示型定義
export type StoreOpeningConditionsShowLongText = {
    candidateLocationRuins: boolean;
    removalReason: boolean;
}

//出店条件ページロングテキスト表示非表示初期値
export const initStoreOpeningConditionsShowLongTextObj: StoreOpeningConditionsShowLongText = {
    candidateLocationRuins: false, //候補地跡地 ロングテキスト
    removalReason: false,          //店名選定の理由について ロングテキスト
}


//商圏ページ①周辺の国税調査（H27）データ型定義
export type TradeAreaTaxAuditTypeList = {
    taxAuditAdministrativeName: string;
    taxAuditHouseholdCount: any;
    taxAuditPopulation: any;
    taxAuditAveragePopulation: any;
    taxAuditSingleHouseholdRatio: any;
    taxAuditAgeGroup20Ratio: any;
    taxAuditAgeGroup65MoreRatio: any;
    taxAuditOffice: any;
    taxAuditEmployees: any;
    taxAuditAverageEmployees: any;
};

//商圏ページ①周辺の国税調査（H27）データ初期値
export const initTradeAreaTaxAuditObj: TradeAreaTaxAuditTypeList = {
    taxAuditAdministrativeName: '',
    taxAuditHouseholdCount: 0,
    taxAuditPopulation: 0,
    taxAuditAveragePopulation: 0,
    taxAuditSingleHouseholdRatio: 0,
    taxAuditAgeGroup20Ratio: 0,
    taxAuditAgeGroup65MoreRatio: 0,
    taxAuditOffice: 0,
    taxAuditEmployees: 0,
    taxAuditAverageEmployees: 0,
};

//商圏ページ②車5分商圏データ型定義
export type TradeAreaCar5MinMpTypeList = {
    car5MinTradeAreaHouseholdCount: any;
    car5MinSingleHouseholdCount: any;
    car5MinSingleHouseholdRatio: any;
    car5MinPopulation: any;
    car5MinAgeGroup20Total: any;
    car5MinAgeGroup20Ratio: any;
    car5MinAgeGroup30Total: any;
    car5MinAgeGroup30Ratio: any;
    car5MinAgeGroup50MoreTotal: any;
    car5MinAgeGroup50MoreRatio: any;
    car5MinAgeGroup65MoreTotal: any;
    car5MinAgeGroup65MoreRatio: any;
    car5MinOfficeCount: any;
    car5MinEmployeesCount: any;
    car5MinAllocationOffice: any;
    car5MinAllocationEmployees: any;
    car5MinDeductionOffice: any;
    car5MinDeductionEmployees: any;
    car5MinIncomeUpTo300: any;
    car5MinIncomeUpTo500: any;
    car5MinIncomeUpTo700: any;
    car5MinIncomeUpTo1000: any;
    car5MinIncomeOver1000: any;
};

//商圏ページ②車5分商圏データ初期値
export const initTradeAreaCar5MinMpObj: TradeAreaCar5MinMpTypeList = {
    car5MinTradeAreaHouseholdCount: 0,
    car5MinSingleHouseholdCount: 0,
    car5MinSingleHouseholdRatio: 0,
    car5MinPopulation: 0,
    car5MinAgeGroup20Total: 0,
    car5MinAgeGroup20Ratio: 0,
    car5MinAgeGroup30Total: 0,
    car5MinAgeGroup30Ratio: 0,
    car5MinAgeGroup50MoreTotal: 0,
    car5MinAgeGroup50MoreRatio: 0,
    car5MinAgeGroup65MoreTotal: 0,
    car5MinAgeGroup65MoreRatio: 0,
    car5MinOfficeCount: 0,
    car5MinEmployeesCount: 0,
    car5MinAllocationOffice: 0,
    car5MinAllocationEmployees: 0,
    car5MinDeductionOffice: 0,
    car5MinDeductionEmployees: 0,
    car5MinIncomeUpTo300: 0,
    car5MinIncomeUpTo500: 0,
    car5MinIncomeUpTo700: 0,
    car5MinIncomeUpTo1000: 0,
    car5MinIncomeOver1000: 0,
};

//商圏ページ③勾配加味徒歩5分商圏と④商圏内データAPI型定義
export type TradeAreaInitMpDataTypeList = {
    id: number;
    proration_retail_count: string;            //引分事業所
    proration_retail_employees_count: string;  //引分従業員
    income_up_to_300: string;                  //～300万
    income_up_to_500: string;                  //～500万
    income_up_to_700: string;                  //～700万
    income_up_to_1000: string;                 //～1000万
    income_over1000: string;                   //1000万～
    oaza_name: string;                         //大字名
    section_name: string;                      //字名
    single_household_ratio: string;            //単独世帯数の比率
    popilation_total: string;                  //人口総数
    household_count: string;                   //世帯数
    age_group_20ratio: string;                 //20代比率
    age_group_30ratio: string;                 //30代比率
    age_group_50moreratio: string;             //50才以上比率
    age_group_65moreratio: string;             //65才以上比率
    mp_data_office_count: string;              //location_excel_04_mp_data 事業所数
    employees_count: string;                   //従業者数
    residences_count: string;                  //住居戸数
    mp_data2_office_count: string;             //location_excel_05_mp_data2 事業所数
    trade_area_seq: String                     //商圏sequence
    k_chocode: string;                         //k_chocode
    reality_survey_household_count: string;    //実査世帯数
    reality_survey_office: string;             //実査事業所
    reality_survey_employed_workers: string;   //実査就業者
};

//商圏ページ③勾配加味徒歩5分商圏と④商圏内データAPI型定義の初期値
export const initTradeAreaMpDataTypeList: TradeAreaInitMpDataTypeList[] = [];

//商圏ページ③勾配加味徒歩5分商圏と④商圏内データ型定義
export type TradeAreaMpDataTableTypeList = {
    id: number;
    gradientWalk5MinAllocationOffice: string;     //引分事業所
    gradientWalk5MinAllocationEmployees: string;  //引分従業員
    gradientWalk5MinIncomeUpTo300: string;        //～300万
    gradientWalk5MinIncomeUpTo500: string;        //～500万
    gradientWalk5MinIncomeUpTo700: string;        //～700万
    gradientWalk5MinIncomeUpTo1000: string;       //～1000万
    gradientWalk5MinIncomeOver1000: string;       //1000万～
    townDistrict: string;                         //町丁字
    householdCount: string;                       //世帯数
    singleHousehold: string;                      //単身世帯
    population: string;                           //人口
    ageGroup20: string;                           //20代
    ageGroup30: string;                           //30代
    ageGroup50: string;                           //50代  
    ageGroup65More: string;                       //65上         
    office: string;                               //事業所
    employees: string;                            //従業員
    tradeAreaSeq: string;                         //商圏sequence
    kChocode: string;                             //k_chocode
    realitySurveyHouseholdCount: string;          //実査世帯数
    realitySurveyOffice: string;                  //実査事業所
    realitySurveyEmployedWorkers: string;         //実査就業者
};

//商圏ページ③勾配加味徒歩5分商圏と④商圏内データ型定義の初期値
export const initTradeAreaMpDataTableTypeList: TradeAreaMpDataTableTypeList[] = [];

//商圏ページの④商圏内データ箇所のstring型の型定義
export type tradeAreaMpDataStringTypeList = {
    actualSurveyTradeAreaData: string;
    [coverStringKey: string]: string; //string型でキーを指定するため
};

//商圏ページのモーダル内のテキストフィールドの初期値
export const initTradeAreaMpDataCommentTextFieldObj: tradeAreaMpDataStringTypeList = {
    actualSurveyTradeAreaData: '',
};

//商圏ページのboolean型の定義
export type TradeAreaMpDataBooleanTypeList = {
    actualSurveyTradeAreaData: boolean;
};

//商圏ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initTradeAreaMpDataReadOnlyObj: TradeAreaMpDataBooleanTypeList = {
    actualSurveyTradeAreaData: true,
};

//現況写真ページ項目名の型定義
export type PictureTypeList = {
    plan_area_id: string;
    branch: string;
    create_date: string;
    update_date: string;
    delete_flag: string;
    pictuer_1_path: string;
    pictuer_2_path: string;
    pictuer_3_path: string;
    pictuer_4_path: string;
    pictuer_5_path: string;
    pictuer_6_path: string;
    pictuer_7_path: string;
    pictuer_8_path: string;
    viewing_distance: number;
    upload_image: string;
    [pictureKey: string]: any; //string型でキーを指定するため
};

//現況写真ページ項目名の初期値
export const initPictureObj: PictureTypeList = {
    plan_area_id: "",
    branch: "",
    create_date: "",
    update_date: "",
    delete_flag: "",
    pictuer_1_path: "",
    pictuer_2_path: "",
    pictuer_3_path: "",
    pictuer_4_path: "",
    pictuer_5_path: "",
    pictuer_6_path: "",
    pictuer_7_path: "",
    pictuer_8_path: "",
    viewing_distance: 0,
    upload_image: ""
};

//現況写真ページのboolean型の定義
export type PictureBooleanTypeList = {
    viewing_distance: boolean;
    [pictureBooleanKey: string]: boolean; //string型でキーを指定するため
};

//現況写真ページのアイコン全体の表示非表示のステータスの初期値
export const initPictureShowIconObj: PictureBooleanTypeList = {
    viewing_distance: false
};

//現況写真ページの鉛筆アイコンの表示非表示のステータスの初期値 trueが表示、falseが非表示
export const initPictureShowEditIconObj: PictureBooleanTypeList = {
    viewing_distance: true
};

//現況写真ページのstring型の型定義
export type PictureStringTypeList = {
    pictuer_1_path: string;
    pictuer_2_path: string;
    pictuer_3_path: string;
    pictuer_4_path: string;
    pictuer_5_path: string;
    pictuer_6_path: string;
    pictuer_7_path: string;
    pictuer_8_path: string;
    viewing_distance: string;
    [pictureStringKey: string]: string; //string型でキーを指定するため

};

//現況写真ページのモーダル内のテキストフィールドの初期値
export const initPictureCommentTextFieldObj: PictureStringTypeList = {
    pictuer_1_path: '',
    pictuer_2_path: '',
    pictuer_3_path: '',
    pictuer_4_path: '',
    pictuer_5_path: '',
    pictuer_6_path: '',
    pictuer_7_path: '',
    pictuer_8_path: '',
    viewing_distance: ''
};

//現況写真ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initPictureReadOnlyObj: PictureBooleanTypeList = {
    viewing_distance: true
};

//競合ページのboolean型の定義
export type ConflictBooleanTypeList = {
    households: boolean;
    employees: boolean;
    [conflictBooleanKey: string]: boolean; //string型でキーを指定するため
};

//競合ページのアイコン全体の表示非表示のステータスの初期値
export const initConflictShowIconObj: ConflictBooleanTypeList = {
    households: false,
    employees: false
};

//競合ページの鉛筆アイコンの表示非表示のステータスの初期値 trueが表示、falseが非表示
export const initConflictShowEditIconObj: ConflictBooleanTypeList = {
    households: true,
    employees: true
};

//競合ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initConflictReadOnlyObj: ConflictBooleanTypeList = {
    households: true,
    employees: true
};

//競合ページのstring型の型定義
export type ConflictStringTypeList = {
    households: string;
    employees: string;
    marketCutBasis: string;
    [conflictStringKey: string]: string; //string型でキーを指定するため

};

//競合ページのモーダル内のテキストフィールドの初期値
export const initConflictCommentTextFieldObj: ConflictStringTypeList = {
    households: "",
    employees: "",
    marketCutBasis: ""
};


export type ImpactTypeList = {
    plan_area_id: String;
    branch: String;
    store_code: String;
    create_date: String;
    update_date: String;
    delete_flag: String;
    store_position: String;
    common_road: String;
    plan_median_strip: String;
    store_median_strip: String;
    straight_count: String;
    turn_count: String;
    railroad_crossing: String;
    river_width: String;
    crossing_road_count: String;
    conflict_sej: String;
    conflict_main_cvs: String;
    conflict_other_cvs: String;
    near_store_code: String;
    average_common_ratio: String;
    tenpo_name: String;
    tobaccolicense: String;
}

export const initImpactObj: ImpactTypeList = {
    plan_area_id: '',
    branch: '',
    store_code: '',
    create_date: '',
    update_date: '',
    delete_flag: '',
    store_position: '',
    common_road: '',
    plan_median_strip: '',
    store_median_strip: '',
    straight_count: '',
    turn_count: '',
    railroad_crossing: '',
    river_width: '',
    crossing_road_count: '',
    conflict_sej: '',
    conflict_main_cvs: '',
    conflict_other_cvs: '',
    near_store_code: '',
    average_common_ratio: '',
    tenpo_name: '',
    tobaccolicense: '',
}


type ConflictTypeList = {
    cvsConflictTargetOwnStore: string;
    cvsConflictTargetRival1: string;
    cvsConflictTargetRival2: string;
    cvsConflictTargetRival3: string;
    cvsConflictTargetRival4: string;
    cvsConflictTargetRival5: string;
    cvsConflictTargetRival6: string;
    cvsConflictTargetRival7: string;
    cvsConflictTargetRival8: string;
    cvsConflictTargetRival9: string;
    cvsConflictTargetRival10: string;
    cvsConflictTargetRival11: string;
    cvsConflictTargetRival12: string;
    cvsConflictTargetRival13: string;
    cvsConflictTargetRival14: string;
    cvsConflictTargetRival15: string;
    cvsConflictTargetRival16: string;
    cvsConflictTargetRival17: string;
    cvsConflictTargetRival18: string;
    cvsConflictTargetRival19: string;
    cvsConflictTargetRival20: string;
    cvsWalk5MinTradeAreaInOutOwnStore: string;
    cvsWalk5MinTradeAreaInOutRival1: string;
    cvsWalk5MinTradeAreaInOutRival2: string;
    cvsWalk5MinTradeAreaInOutRival3: string;
    cvsWalk5MinTradeAreaInOutRival4: string;
    cvsWalk5MinTradeAreaInOutRival5: string;
    cvsWalk5MinTradeAreaInOutRival6: string;
    cvsWalk5MinTradeAreaInOutRival7: string;
    cvsWalk5MinTradeAreaInOutRival8: string;
    cvsWalk5MinTradeAreaInOutRival9: string;
    cvsWalk5MinTradeAreaInOutRival10: string;
    cvsWalk5MinTradeAreaInOutRival11: string;
    cvsWalk5MinTradeAreaInOutRival12: string;
    cvsWalk5MinTradeAreaInOutRival13: string;
    cvsWalk5MinTradeAreaInOutRival14: string;
    cvsWalk5MinTradeAreaInOutRival15: string;
    cvsWalk5MinTradeAreaInOutRival16: string;
    cvsWalk5MinTradeAreaInOutRival17: string;
    cvsWalk5MinTradeAreaInOutRival18: string;
    cvsWalk5MinTradeAreaInOutRival19: string;
    cvsWalk5MinTradeAreaInOutRival20: string;
    cvsChainName: string;
    cvsChainNameRival1: string;
    cvsChainNameRival2: string;
    cvsChainNameRival3: string;
    cvsChainNameRival4: string;
    cvsChainNameRival5: string;
    cvsChainNameRival6: string;
    cvsChainNameRival7: string;
    cvsChainNameRival8: string;
    cvsChainNameRival9: string;
    cvsChainNameRival10: string;
    cvsChainNameRival11: string;
    cvsChainNameRival12: string;
    cvsChainNameRival13: string;
    cvsChainNameRival14: string;
    cvsChainNameRival15: string;
    cvsChainNameRival16: string;
    cvsChainNameRival17: string;
    cvsChainNameRival18: string;
    cvsChainNameRival19: string;
    cvsChainNameRival20: string;
    cvsStoreNameOwnStore: string;
    cvsStoreNameRival1: string;
    cvsStoreNameRival2: string;
    cvsStoreNameRival3: string;
    cvsStoreNameRival4: string;
    cvsStoreNameRival5: string;
    cvsStoreNameRival6: string;
    cvsStoreNameRival7: string;
    cvsStoreNameRival8: string;
    cvsStoreNameRival9: string;
    cvsStoreNameRival10: string;
    cvsStoreNameRival11: string;
    cvsStoreNameRival12: string;
    cvsStoreNameRival13: string;
    cvsStoreNameRival14: string;
    cvsStoreNameRival15: string;
    cvsStoreNameRival16: string;
    cvsStoreNameRival17: string;
    cvsStoreNameRival18: string;
    cvsStoreNameRival19: string;
    cvsStoreNameRival20: string;
    cvsPrefecturesOwnStore: string;
    cvsPrefecturesRival1: string;
    cvsPrefecturesRival2: string;
    cvsPrefecturesRival3: string;
    cvsPrefecturesRival4: string;
    cvsPrefecturesRival5: string;
    cvsPrefecturesRival6: string;
    cvsPrefecturesRival7: string;
    cvsPrefecturesRival8: string;
    cvsPrefecturesRival9: string;
    cvsPrefecturesRival10: string;
    cvsPrefecturesRival11: string;
    cvsPrefecturesRival12: string;
    cvsPrefecturesRival13: string;
    cvsPrefecturesRival14: string;
    cvsPrefecturesRival15: string;
    cvsPrefecturesRival16: string;
    cvsPrefecturesRival17: string;
    cvsPrefecturesRival18: string;
    cvsPrefecturesRival19: string;
    cvsPrefecturesRival20: string;
    cvsAddressOwnStore: string;
    cvsAddressRival1: string;
    cvsAddressRival2: string;
    cvsAddressRival3: string;
    cvsAddressRival4: string;
    cvsAddressRival5: string;
    cvsAddressRival6: string;
    cvsAddressRival7: string;
    cvsAddressRival8: string;
    cvsAddressRival9: string;
    cvsAddressRival10: string;
    cvsAddressRival11: string;
    cvsAddressRival12: string;
    cvsAddressRival13: string;
    cvsAddressRival14: string;
    cvsAddressRival15: string;
    cvsAddressRival16: string;
    cvsAddressRival17: string;
    cvsAddressRival18: string;
    cvsAddressRival19: string;
    cvsAddressRival20: string;
    cvsBusinessTypeOwnStore: string;
    cvsBusinessTypeRival1: string;
    cvsBusinessTypeRival2: string;
    cvsBusinessTypeRival3: string;
    cvsBusinessTypeRival4: string;
    cvsBusinessTypeRival5: string;
    cvsBusinessTypeRival6: string;
    cvsBusinessTypeRival7: string;
    cvsBusinessTypeRival8: string;
    cvsBusinessTypeRival9: string;
    cvsBusinessTypeRival10: string;
    cvsBusinessTypeRival11: string;
    cvsBusinessTypeRival12: string;
    cvsBusinessTypeRival13: string;
    cvsBusinessTypeRival14: string;
    cvsBusinessTypeRival15: string;
    cvsBusinessTypeRival16: string;
    cvsBusinessTypeRival17: string;
    cvsBusinessTypeRival18: string;
    cvsBusinessTypeRival19: string;
    cvsBusinessTypeRival20: string;
    cvsBusinessHoursOwnStore: string;
    cvsBusinessHoursRival1: string;
    cvsBusinessHoursRival2: string;
    cvsBusinessHoursRival3: string;
    cvsBusinessHoursRival4: string;
    cvsBusinessHoursRival5: string;
    cvsBusinessHoursRival6: string;
    cvsBusinessHoursRival7: string;
    cvsBusinessHoursRival8: string;
    cvsBusinessHoursRival9: string;
    cvsBusinessHoursRival10: string;
    cvsBusinessHoursRival11: string;
    cvsBusinessHoursRival12: string;
    cvsBusinessHoursRival13: string;
    cvsBusinessHoursRival14: string;
    cvsBusinessHoursRival15: string;
    cvsBusinessHoursRival16: string;
    cvsBusinessHoursRival17: string;
    cvsBusinessHoursRival18: string;
    cvsBusinessHoursRival19: string;
    cvsBusinessHoursRival20: string;
    cvsClosingTimeOwnStore: string;
    cvsClosingTimeRival1: string;
    cvsClosingTimeRival2: string;
    cvsClosingTimeRival3: string;
    cvsClosingTimeRival4: string;
    cvsClosingTimeRival5: string;
    cvsClosingTimeRival6: string;
    cvsClosingTimeRival7: string;
    cvsClosingTimeRival8: string;
    cvsClosingTimeRival9: string;
    cvsClosingTimeRival10: string;
    cvsClosingTimeRival11: string;
    cvsClosingTimeRival12: string;
    cvsClosingTimeRival13: string;
    cvsClosingTimeRival14: string;
    cvsClosingTimeRival15: string;
    cvsClosingTimeRival16: string;
    cvsClosingTimeRival17: string;
    cvsClosingTimeRival18: string;
    cvsClosingTimeRival19: string;
    cvsClosingTimeRival20: string;
    cvsAlcoholLicenseOwnStore: string;
    cvsAlcoholLicenseRival1: string;
    cvsAlcoholLicenseRival2: string;
    cvsAlcoholLicenseRival3: string;
    cvsAlcoholLicenseRival4: string;
    cvsAlcoholLicenseRival5: string;
    cvsAlcoholLicenseRival6: string;
    cvsAlcoholLicenseRival7: string;
    cvsAlcoholLicenseRival8: string;
    cvsAlcoholLicenseRival9: string;
    cvsAlcoholLicenseRival10: string;
    cvsAlcoholLicenseRival11: string;
    cvsAlcoholLicenseRival12: string;
    cvsAlcoholLicenseRival13: string;
    cvsAlcoholLicenseRival14: string;
    cvsAlcoholLicenseRival15: string;
    cvsAlcoholLicenseRival16: string;
    cvsAlcoholLicenseRival17: string;
    cvsAlcoholLicenseRival18: string;
    cvsAlcoholLicenseRival19: string;
    cvsAlcoholLicenseRival20: string;
    cvsTobaccoLicenceOwnStore: string;
    cvsTobaccoLicenceRival1: string;
    cvsTobaccoLicenceRival2: string;
    cvsTobaccoLicenceRival3: string;
    cvsTobaccoLicenceRival4: string;
    cvsTobaccoLicenceRival5: string;
    cvsTobaccoLicenceRival6: string;
    cvsTobaccoLicenceRival7: string;
    cvsTobaccoLicenceRival8: string;
    cvsTobaccoLicenceRival9: string;
    cvsTobaccoLicenceRival10: string;
    cvsTobaccoLicenceRival11: string;
    cvsTobaccoLicenceRival12: string;
    cvsTobaccoLicenceRival13: string;
    cvsTobaccoLicenceRival14: string;
    cvsTobaccoLicenceRival15: string;
    cvsTobaccoLicenceRival16: string;
    cvsTobaccoLicenceRival17: string;
    cvsTobaccoLicenceRival18: string;
    cvsTobaccoLicenceRival19: string;
    cvsTobaccoLicenceRival20: string;
    cvsLocationOwnStore: string;
    cvsLocationRival1: string;
    cvsLocationRival2: string;
    cvsLocationRival3: string;
    cvsLocationRival4: string;
    cvsLocationRival5: string;
    cvsLocationRival6: string;
    cvsLocationRival7: string;
    cvsLocationRival8: string;
    cvsLocationRival9: string;
    cvsLocationRival10: string;
    cvsLocationRival11: string;
    cvsLocationRival12: string;
    cvsLocationRival13: string;
    cvsLocationRival14: string;
    cvsLocationRival15: string;
    cvsLocationRival16: string;
    cvsLocationRival17: string;
    cvsLocationRival18: string;
    cvsLocationRival19: string;
    cvsLocationRival20: string;
    cvsSalsesAreaOwnStore: number;
    cvsSalsesAreaRival1: number;
    cvsSalsesAreaRival2: number;
    cvsSalsesAreaRival3: number;
    cvsSalsesAreaRival4: number;
    cvsSalsesAreaRival5: number;
    cvsSalsesAreaRival6: number;
    cvsSalsesAreaRival7: number;
    cvsSalsesAreaRival8: number;
    cvsSalsesAreaRival9: number;
    cvsSalsesAreaRival10: number;
    cvsSalsesAreaRival11: number;
    cvsSalsesAreaRival12: number;
    cvsSalsesAreaRival13: number;
    cvsSalsesAreaRival14: number;
    cvsSalsesAreaRival15: number;
    cvsSalsesAreaRival16: number;
    cvsSalsesAreaRival17: number;
    cvsSalsesAreaRival18: number;
    cvsSalsesAreaRival19: number;
    cvsSalsesAreaRival20: number;
    cvsGondolaUnitsOwnStore: number;
    cvsGondolaUnitsRival1: number;
    cvsGondolaUnitsRival2: number;
    cvsGondolaUnitsRival3: number;
    cvsGondolaUnitsRival4: number;
    cvsGondolaUnitsRival5: number;
    cvsGondolaUnitsRival6: number;
    cvsGondolaUnitsRival7: number;
    cvsGondolaUnitsRival8: number;
    cvsGondolaUnitsRival9: number;
    cvsGondolaUnitsRival10: number;
    cvsGondolaUnitsRival11: number;
    cvsGondolaUnitsRival12: number;
    cvsGondolaUnitsRival13: number;
    cvsGondolaUnitsRival14: number;
    cvsGondolaUnitsRival15: number;
    cvsGondolaUnitsRival16: number;
    cvsGondolaUnitsRival17: number;
    cvsGondolaUnitsRival18: number;
    cvsGondolaUnitsRival19: number;
    cvsGondolaUnitsRival20: number;
    cvsParkingAvailabilityOwnStore: string;
    cvsParkingAvailabilityRival1: string;
    cvsParkingAvailabilityRival2: string;
    cvsParkingAvailabilityRival3: string;
    cvsParkingAvailabilityRival4: string;
    cvsParkingAvailabilityRival5: string;
    cvsParkingAvailabilityRival6: string;
    cvsParkingAvailabilityRival7: string;
    cvsParkingAvailabilityRival8: string;
    cvsParkingAvailabilityRival9: string;
    cvsParkingAvailabilityRival10: string;
    cvsParkingAvailabilityRival11: string;
    cvsParkingAvailabilityRival12: string;
    cvsParkingAvailabilityRival13: string;
    cvsParkingAvailabilityRival14: string;
    cvsParkingAvailabilityRival15: string;
    cvsParkingAvailabilityRival16: string;
    cvsParkingAvailabilityRival17: string;
    cvsParkingAvailabilityRival18: string;
    cvsParkingAvailabilityRival19: string;
    cvsParkingAvailabilityRival20: string;
    cvsStoreFrontageOwnStore: number;
    cvsStoreFrontageRival1: number;
    cvsStoreFrontageRival2: number;
    cvsStoreFrontageRival3: number;
    cvsStoreFrontageRival4: number;
    cvsStoreFrontageRival5: number;
    cvsStoreFrontageRival6: number;
    cvsStoreFrontageRival7: number;
    cvsStoreFrontageRival8: number;
    cvsStoreFrontageRival9: number;
    cvsStoreFrontageRival10: number;
    cvsStoreFrontageRival11: number;
    cvsStoreFrontageRival12: number;
    cvsStoreFrontageRival13: number;
    cvsStoreFrontageRival14: number;
    cvsStoreFrontageRival15: number;
    cvsStoreFrontageRival16: number;
    cvsStoreFrontageRival17: number;
    cvsStoreFrontageRival18: number;
    cvsStoreFrontageRival19: number;
    cvsStoreFrontageRival20: number;
    cvsParkingAreaOwnStore: number;
    cvsParkingAreaRival1: number;
    cvsParkingAreaRival2: number;
    cvsParkingAreaRival3: number;
    cvsParkingAreaRival4: number;
    cvsParkingAreaRival5: number;
    cvsParkingAreaRival6: number;
    cvsParkingAreaRival7: number;
    cvsParkingAreaRival8: number;
    cvsParkingAreaRival9: number;
    cvsParkingAreaRival10: number;
    cvsParkingAreaRival11: number;
    cvsParkingAreaRival12: number;
    cvsParkingAreaRival13: number;
    cvsParkingAreaRival14: number;
    cvsParkingAreaRival15: number;
    cvsParkingAreaRival16: number;
    cvsParkingAreaRival17: number;
    cvsParkingAreaRival18: number;
    cvsParkingAreaRival19: number;
    cvsParkingAreaRival20: number;
    cvsParkingSpacesOwnStore: number;
    cvsParkingSpacesRival1: number;
    cvsParkingSpacesRival2: number;
    cvsParkingSpacesRival3: number;
    cvsParkingSpacesRival4: number;
    cvsParkingSpacesRival5: number;
    cvsParkingSpacesRival6: number;
    cvsParkingSpacesRival7: number;
    cvsParkingSpacesRival8: number;
    cvsParkingSpacesRival9: number;
    cvsParkingSpacesRival10: number;
    cvsParkingSpacesRival11: number;
    cvsParkingSpacesRival12: number;
    cvsParkingSpacesRival13: number;
    cvsParkingSpacesRival14: number;
    cvsParkingSpacesRival15: number;
    cvsParkingSpacesRival16: number;
    cvsParkingSpacesRival17: number;
    cvsParkingSpacesRival18: number;
    cvsParkingSpacesRival19: number;
    cvsParkingSpacesRival20: number;
    cvsFrontageParkingTangentLineOwnStore: number;
    cvsFrontageParkingTangentLineRival1: number;
    cvsFrontageParkingTangentLineRival2: number;
    cvsFrontageParkingTangentLineRival3: number;
    cvsFrontageParkingTangentLineRival4: number;
    cvsFrontageParkingTangentLineRival5: number;
    cvsFrontageParkingTangentLineRival6: number;
    cvsFrontageParkingTangentLineRival7: number;
    cvsFrontageParkingTangentLineRival8: number;
    cvsFrontageParkingTangentLineRival9: number;
    cvsFrontageParkingTangentLineRival10: number;
    cvsFrontageParkingTangentLineRival11: number;
    cvsFrontageParkingTangentLineRival12: number;
    cvsFrontageParkingTangentLineRival13: number;
    cvsFrontageParkingTangentLineRival14: number;
    cvsFrontageParkingTangentLineRival15: number;
    cvsFrontageParkingTangentLineRival16: number;
    cvsFrontageParkingTangentLineRival17: number;
    cvsFrontageParkingTangentLineRival18: number;
    cvsFrontageParkingTangentLineRival19: number;
    cvsFrontageParkingTangentLineRival20: number;
    cvsFrontageEntrace1OwnStore: number;
    cvsFrontageEntrace1Rival1: number;
    cvsFrontageEntrace1Rival2: number;
    cvsFrontageEntrace1Rival3: number;
    cvsFrontageEntrace1Rival4: number;
    cvsFrontageEntrace1Rival5: number;
    cvsFrontageEntrace1Rival6: number;
    cvsFrontageEntrace1Rival7: number;
    cvsFrontageEntrace1Rival8: number;
    cvsFrontageEntrace1Rival9: number;
    cvsFrontageEntrace1Rival10: number;
    cvsFrontageEntrace1Rival11: number;
    cvsFrontageEntrace1Rival12: number;
    cvsFrontageEntrace1Rival13: number;
    cvsFrontageEntrace1Rival14: number;
    cvsFrontageEntrace1Rival15: number;
    cvsFrontageEntrace1Rival16: number;
    cvsFrontageEntrace1Rival17: number;
    cvsFrontageEntrace1Rival18: number;
    cvsFrontageEntrace1Rival19: number;
    cvsFrontageEntrace1Rival20: number;
    cvsFrontageDepth1OwnStore: number;
    cvsFrontageDepth1Rival1: number;
    cvsFrontageDepth1Rival2: number;
    cvsFrontageDepth1Rival3: number;
    cvsFrontageDepth1Rival4: number;
    cvsFrontageDepth1Rival5: number;
    cvsFrontageDepth1Rival6: number;
    cvsFrontageDepth1Rival7: number;
    cvsFrontageDepth1Rival8: number;
    cvsFrontageDepth1Rival9: number;
    cvsFrontageDepth1Rival10: number;
    cvsFrontageDepth1Rival11: number;
    cvsFrontageDepth1Rival12: number;
    cvsFrontageDepth1Rival13: number;
    cvsFrontageDepth1Rival14: number;
    cvsFrontageDepth1Rival15: number;
    cvsFrontageDepth1Rival16: number;
    cvsFrontageDepth1Rival17: number;
    cvsFrontageDepth1Rival18: number;
    cvsFrontageDepth1Rival19: number;
    cvsFrontageDepth1Rival20: number;
    cvsFrontageEntrace2OwnStore: number;
    cvsFrontageEntrace2Rival1: number;
    cvsFrontageEntrace2Rival2: number;
    cvsFrontageEntrace2Rival3: number;
    cvsFrontageEntrace2Rival4: number;
    cvsFrontageEntrace2Rival5: number;
    cvsFrontageEntrace2Rival6: number;
    cvsFrontageEntrace2Rival7: number;
    cvsFrontageEntrace2Rival8: number;
    cvsFrontageEntrace2Rival9: number;
    cvsFrontageEntrace2Rival10: number;
    cvsFrontageEntrace2Rival11: number;
    cvsFrontageEntrace2Rival12: number;
    cvsFrontageEntrace2Rival13: number;
    cvsFrontageEntrace2Rival14: number;
    cvsFrontageEntrace2Rival15: number;
    cvsFrontageEntrace2Rival16: number;
    cvsFrontageEntrace2Rival17: number;
    cvsFrontageEntrace2Rival18: number;
    cvsFrontageEntrace2Rival19: number;
    cvsFrontageEntrace2Rival20: number;
    cvsFrontageDepth2OwnStore: number;
    cvsFrontageDepth2Rival1: number;
    cvsFrontageDepth2Rival2: number;
    cvsFrontageDepth2Rival3: number;
    cvsFrontageDepth2Rival4: number;
    cvsFrontageDepth2Rival5: number;
    cvsFrontageDepth2Rival6: number;
    cvsFrontageDepth2Rival7: number;
    cvsFrontageDepth2Rival8: number;
    cvsFrontageDepth2Rival9: number;
    cvsFrontageDepth2Rival10: number;
    cvsFrontageDepth2Rival11: number;
    cvsFrontageDepth2Rival12: number;
    cvsFrontageDepth2Rival13: number;
    cvsFrontageDepth2Rival14: number;
    cvsFrontageDepth2Rival15: number;
    cvsFrontageDepth2Rival16: number;
    cvsFrontageDepth2Rival17: number;
    cvsFrontageDepth2Rival18: number;
    cvsFrontageDepth2Rival19: number;
    cvsFrontageDepth2Rival20: number;
    cvsSideWayTypeOwnStore: string;
    cvsSideWayTypeRival1: string;
    cvsSideWayTypeRival2: string;
    cvsSideWayTypeRival3: string;
    cvsSideWayTypeRival4: string;
    cvsSideWayTypeRival5: string;
    cvsSideWayTypeRival6: string;
    cvsSideWayTypeRival7: string;
    cvsSideWayTypeRival8: string;
    cvsSideWayTypeRival9: string;
    cvsSideWayTypeRival10: string;
    cvsSideWayTypeRival11: string;
    cvsSideWayTypeRival12: string;
    cvsSideWayTypeRival13: string;
    cvsSideWayTypeRival14: string;
    cvsSideWayTypeRival15: string;
    cvsSideWayTypeRival16: string;
    cvsSideWayTypeRival17: string;
    cvsSideWayTypeRival18: string;
    cvsSideWayTypeRival19: string;
    cvsSideWayTypeRival20: string;
    cvsSideWayBackstreetParkingTangentLineOwnStore: number;
    cvsSideWayBackstreetParkingTangentLineRival1: number;
    cvsSideWayBackstreetParkingTangentLineRival2: number;
    cvsSideWayBackstreetParkingTangentLineRival3: number;
    cvsSideWayBackstreetParkingTangentLineRival4: number;
    cvsSideWayBackstreetParkingTangentLineRival5: number;
    cvsSideWayBackstreetParkingTangentLineRival6: number;
    cvsSideWayBackstreetParkingTangentLineRival7: number;
    cvsSideWayBackstreetParkingTangentLineRival8: number;
    cvsSideWayBackstreetParkingTangentLineRival9: number;
    cvsSideWayBackstreetParkingTangentLineRival10: number;
    cvsSideWayBackstreetParkingTangentLineRival11: number;
    cvsSideWayBackstreetParkingTangentLineRival12: number;
    cvsSideWayBackstreetParkingTangentLineRival13: number;
    cvsSideWayBackstreetParkingTangentLineRival14: number;
    cvsSideWayBackstreetParkingTangentLineRival15: number;
    cvsSideWayBackstreetParkingTangentLineRival16: number;
    cvsSideWayBackstreetParkingTangentLineRival17: number;
    cvsSideWayBackstreetParkingTangentLineRival18: number;
    cvsSideWayBackstreetParkingTangentLineRival19: number;
    cvsSideWayBackstreetParkingTangentLineRival20: number;
    cvsSideWayBackstreetEntrace1OwnStore: number;
    cvsSideWayBackstreetEntrace1Rival1: number;
    cvsSideWayBackstreetEntrace1Rival2: number;
    cvsSideWayBackstreetEntrace1Rival3: number;
    cvsSideWayBackstreetEntrace1Rival4: number;
    cvsSideWayBackstreetEntrace1Rival5: number;
    cvsSideWayBackstreetEntrace1Rival6: number;
    cvsSideWayBackstreetEntrace1Rival7: number;
    cvsSideWayBackstreetEntrace1Rival8: number;
    cvsSideWayBackstreetEntrace1Rival9: number;
    cvsSideWayBackstreetEntrace1Rival10: number;
    cvsSideWayBackstreetEntrace1Rival11: number;
    cvsSideWayBackstreetEntrace1Rival12: number;
    cvsSideWayBackstreetEntrace1Rival13: number;
    cvsSideWayBackstreetEntrace1Rival14: number;
    cvsSideWayBackstreetEntrace1Rival15: number;
    cvsSideWayBackstreetEntrace1Rival16: number;
    cvsSideWayBackstreetEntrace1Rival17: number;
    cvsSideWayBackstreetEntrace1Rival18: number;
    cvsSideWayBackstreetEntrace1Rival19: number;
    cvsSideWayBackstreetEntrace1Rival20: number;
    cvsSideWayBackstreetDepth1OwnStore: number;
    cvsSideWayBackstreetDepth1Rival1: number;
    cvsSideWayBackstreetDepth1Rival2: number;
    cvsSideWayBackstreetDepth1Rival3: number;
    cvsSideWayBackstreetDepth1Rival4: number;
    cvsSideWayBackstreetDepth1Rival5: number;
    cvsSideWayBackstreetDepth1Rival6: number;
    cvsSideWayBackstreetDepth1Rival7: number;
    cvsSideWayBackstreetDepth1Rival8: number;
    cvsSideWayBackstreetDepth1Rival9: number;
    cvsSideWayBackstreetDepth1Rival10: number;
    cvsSideWayBackstreetDepth1Rival11: number;
    cvsSideWayBackstreetDepth1Rival12: number;
    cvsSideWayBackstreetDepth1Rival13: number;
    cvsSideWayBackstreetDepth1Rival14: number;
    cvsSideWayBackstreetDepth1Rival15: number;
    cvsSideWayBackstreetDepth1Rival16: number;
    cvsSideWayBackstreetDepth1Rival17: number;
    cvsSideWayBackstreetDepth1Rival18: number;
    cvsSideWayBackstreetDepth1Rival19: number;
    cvsSideWayBackstreetDepth1Rival20: number;
    cvsSideWayEntrace2OwnStore: number;
    cvsSideWayEntrace2Rival1: number;
    cvsSideWayEntrace2Rival2: number;
    cvsSideWayEntrace2Rival3: number;
    cvsSideWayEntrace2Rival4: number;
    cvsSideWayEntrace2Rival5: number;
    cvsSideWayEntrace2Rival6: number;
    cvsSideWayEntrace2Rival7: number;
    cvsSideWayEntrace2Rival8: number;
    cvsSideWayEntrace2Rival9: number;
    cvsSideWayEntrace2Rival10: number;
    cvsSideWayEntrace2Rival11: number;
    cvsSideWayEntrace2Rival12: number;
    cvsSideWayEntrace2Rival13: number;
    cvsSideWayEntrace2Rival14: number;
    cvsSideWayEntrace2Rival15: number;
    cvsSideWayEntrace2Rival16: number;
    cvsSideWayEntrace2Rival17: number;
    cvsSideWayEntrace2Rival18: number;
    cvsSideWayEntrace2Rival19: number;
    cvsSideWayEntrace2Rival20: number;
    cvsSideWayDepth2OwnStore: number;
    cvsSideWayDepth2Rival1: number;
    cvsSideWayDepth2Rival2: number;
    cvsSideWayDepth2Rival3: number;
    cvsSideWayDepth2Rival4: number;
    cvsSideWayDepth2Rival5: number;
    cvsSideWayDepth2Rival6: number;
    cvsSideWayDepth2Rival7: number;
    cvsSideWayDepth2Rival8: number;
    cvsSideWayDepth2Rival9: number;
    cvsSideWayDepth2Rival10: number;
    cvsSideWayDepth2Rival11: number;
    cvsSideWayDepth2Rival12: number;
    cvsSideWayDepth2Rival13: number;
    cvsSideWayDepth2Rival14: number;
    cvsSideWayDepth2Rival15: number;
    cvsSideWayDepth2Rival16: number;
    cvsSideWayDepth2Rival17: number;
    cvsSideWayDepth2Rival18: number;
    cvsSideWayDepth2Rival19: number;
    cvsSideWayDepth2Rival20: number;
    cvsEstimatedNippanOwnStore: number;
    cvsEstimatedNippanRival1: number;
    cvsEstimatedNippanRival2: number;
    cvsEstimatedNippanRival3: number;
    cvsEstimatedNippanRival4: number;
    cvsEstimatedNippanRival5: number;
    cvsEstimatedNippanRival6: number;
    cvsEstimatedNippanRival7: number;
    cvsEstimatedNippanRival8: number;
    cvsEstimatedNippanRival9: number;
    cvsEstimatedNippanRival10: number;
    cvsEstimatedNippanRival11: number;
    cvsEstimatedNippanRival12: number;
    cvsEstimatedNippanRival13: number;
    cvsEstimatedNippanRival14: number;
    cvsEstimatedNippanRival15: number;
    cvsEstimatedNippanRival16: number;
    cvsEstimatedNippanRival17: number;
    cvsEstimatedNippanRival18: number;
    cvsEstimatedNippanRival19: number;
    cvsEstimatedNippanRival20: number;
    cvsLineDistanceOwnStore: number;
    cvsLineDistanceRival1: number;
    cvsLineDistanceRival2: number;
    cvsLineDistanceRival3: number;
    cvsLineDistanceRival4: number;
    cvsLineDistanceRival5: number;
    cvsLineDistanceRival6: number;
    cvsLineDistanceRival7: number;
    cvsLineDistanceRival8: number;
    cvsLineDistanceRival9: number;
    cvsLineDistanceRival10: number;
    cvsLineDistanceRival11: number;
    cvsLineDistanceRival12: number;
    cvsLineDistanceRival13: number;
    cvsLineDistanceRival14: number;
    cvsLineDistanceRival15: number;
    cvsLineDistanceRival16: number;
    cvsLineDistanceRival17: number;
    cvsLineDistanceRival18: number;
    cvsLineDistanceRival19: number;
    cvsLineDistanceRival20: number;
    cvsWayDistanceOwnStore: number;
    cvsWayDistanceRival1: number;
    cvsWayDistanceRival2: number;
    cvsWayDistanceRival3: number;
    cvsWayDistanceRival4: number;
    cvsWayDistanceRival5: number;
    cvsWayDistanceRival6: number;
    cvsWayDistanceRival7: number;
    cvsWayDistanceRival8: number;
    cvsWayDistanceRival9: number;
    cvsWayDistanceRival10: number;
    cvsWayDistanceRival11: number;
    cvsWayDistanceRival12: number;
    cvsWayDistanceRival13: number;
    cvsWayDistanceRival14: number;
    cvsWayDistanceRival15: number;
    cvsWayDistanceRival16: number;
    cvsWayDistanceRival17: number;
    cvsWayDistanceRival18: number;
    cvsWayDistanceRival19: number;
    cvsWayDistanceRival20: number;
    cvsPreCandidateCommonRoadOwnStore: string;
    cvsPreCandidateCommonRoadRival1: string;
    cvsPreCandidateCommonRoadRival2: string;
    cvsPreCandidateCommonRoadRival3: string;
    cvsPreCandidateCommonRoadRival4: string;
    cvsPreCandidateCommonRoadRival5: string;
    cvsPreCandidateCommonRoadRival6: string;
    cvsPreCandidateCommonRoadRival7: string;
    cvsPreCandidateCommonRoadRival8: string;
    cvsPreCandidateCommonRoadRival9: string;
    cvsPreCandidateCommonRoadRival10: string;
    cvsPreCandidateCommonRoadRival11: string;
    cvsPreCandidateCommonRoadRival12: string;
    cvsPreCandidateCommonRoadRival13: string;
    cvsPreCandidateCommonRoadRival14: string;
    cvsPreCandidateCommonRoadRival15: string;
    cvsPreCandidateCommonRoadRival16: string;
    cvsPreCandidateCommonRoadRival17: string;
    cvsPreCandidateCommonRoadRival18: string;
    cvsPreCandidateCommonRoadRival19: string;
    cvsPreCandidateCommonRoadRival20: string;
    cvsDirectionOwnStore: string;
    cvsDirectionRival1: string;
    cvsDirectionRival2: string;
    cvsDirectionRival3: string;
    cvsDirectionRival4: string;
    cvsDirectionRival5: string;
    cvsDirectionRival6: string;
    cvsDirectionRival7: string;
    cvsDirectionRival8: string;
    cvsDirectionRival9: string;
    cvsDirectionRival10: string;
    cvsDirectionRival11: string;
    cvsDirectionRival12: string;
    cvsDirectionRival13: string;
    cvsDirectionRival14: string;
    cvsDirectionRival15: string;
    cvsDirectionRival16: string;
    cvsDirectionRival17: string;
    cvsDirectionRival18: string;
    cvsDirectionRival19: string;
    cvsDirectionRival20: string;
    cvsPreConflictCommonRoadOwnStore: string;
    cvsPreConflictCommonRoadRival1: string;
    cvsPreConflictCommonRoadRival2: string;
    cvsPreConflictCommonRoadRival3: string;
    cvsPreConflictCommonRoadRival4: string;
    cvsPreConflictCommonRoadRival5: string;
    cvsPreConflictCommonRoadRival6: string;
    cvsPreConflictCommonRoadRival7: string;
    cvsPreConflictCommonRoadRival8: string;
    cvsPreConflictCommonRoadRival9: string;
    cvsPreConflictCommonRoadRival10: string;
    cvsPreConflictCommonRoadRival11: string;
    cvsPreConflictCommonRoadRival12: string;
    cvsPreConflictCommonRoadRival13: string;
    cvsPreConflictCommonRoadRival14: string;
    cvsPreConflictCommonRoadRival15: string;
    cvsPreConflictCommonRoadRival16: string;
    cvsPreConflictCommonRoadRival17: string;
    cvsPreConflictCommonRoadRival18: string;
    cvsPreConflictCommonRoadRival19: string;
    cvsPreConflictCommonRoadRival20: string;
    cvsPreConflictCommonRoadMedianStripPresenceOwnStore: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival1: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival2: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival3: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival4: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival5: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival6: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival7: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival8: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival9: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival10: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival11: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival12: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival13: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival14: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival15: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival16: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival17: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival18: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival19: string;
    cvsPreConflictCommonRoadMedianStripPresenceRival20: string;
    cvsTurnMeterOwnStore: number;
    cvsTurnMeterRival1: number;
    cvsTurnMeterRival2: number;
    cvsTurnMeterRival3: number;
    cvsTurnMeterRival4: number;
    cvsTurnMeterRival5: number;
    cvsTurnMeterRival6: number;
    cvsTurnMeterRival7: number;
    cvsTurnMeterRival8: number;
    cvsTurnMeterRival9: number;
    cvsTurnMeterRival10: number;
    cvsTurnMeterRival11: number;
    cvsTurnMeterRival12: number;
    cvsTurnMeterRival13: number;
    cvsTurnMeterRival14: number;
    cvsTurnMeterRival15: number;
    cvsTurnMeterRival16: number;
    cvsTurnMeterRival17: number;
    cvsTurnMeterRival18: number;
    cvsTurnMeterRival19: number;
    cvsTurnMeterRival20: number;
    cvsStraightMeterOwnStore: number;
    cvsStraightMeterRival1: number;
    cvsStraightMeterRival2: number;
    cvsStraightMeterRival3: number;
    cvsStraightMeterRival4: number;
    cvsStraightMeterRival5: number;
    cvsStraightMeterRival6: number;
    cvsStraightMeterRival7: number;
    cvsStraightMeterRival8: number;
    cvsStraightMeterRival9: number;
    cvsStraightMeterRival10: number;
    cvsStraightMeterRival11: number;
    cvsStraightMeterRival12: number;
    cvsStraightMeterRival13: number;
    cvsStraightMeterRival14: number;
    cvsStraightMeterRival15: number;
    cvsStraightMeterRival16: number;
    cvsStraightMeterRival17: number;
    cvsStraightMeterRival18: number;
    cvsStraightMeterRival19: number;
    cvsStraightMeterRival20: number;
    smConflictTargetOwnStore: string;
    smConflictTargetRival1: string;
    smConflictTargetRival2: string;
    smConflictTargetRival3: string;
    smConflictTargetRival4: string;
    smConflictTargetRival5: string;
    smConflictTargetRival6: string;
    smConflictTargetRival7: string;
    smConflictTargetRival8: string;
    smConflictTargetRival9: string;
    smConflictTargetRival10: string;
    smConflictTargetRival11: string;
    smConflictTargetRival12: string;
    smConflictTargetRival13: string;
    smConflictTargetRival14: string;
    smConflictTargetRival15: string;
    smConflictTargetRival16: string;
    smConflictTargetRival17: string;
    smConflictTargetRival18: string;
    smConflictTargetRival19: string;
    smConflictTargetRival20: string;
    smWalk5MinTradeAreaInOutOwnStore: string;
    smWalk5MinTradeAreaInOutRival1: string;
    smWalk5MinTradeAreaInOutRival2: string;
    smWalk5MinTradeAreaInOutRival3: string;
    smWalk5MinTradeAreaInOutRival4: string;
    smWalk5MinTradeAreaInOutRival5: string;
    smWalk5MinTradeAreaInOutRival6: string;
    smWalk5MinTradeAreaInOutRival7: string;
    smWalk5MinTradeAreaInOutRival8: string;
    smWalk5MinTradeAreaInOutRival9: string;
    smWalk5MinTradeAreaInOutRival10: string;
    smWalk5MinTradeAreaInOutRival11: string;
    smWalk5MinTradeAreaInOutRival12: string;
    smWalk5MinTradeAreaInOutRival13: string;
    smWalk5MinTradeAreaInOutRival14: string;
    smWalk5MinTradeAreaInOutRival15: string;
    smWalk5MinTradeAreaInOutRival16: string;
    smWalk5MinTradeAreaInOutRival17: string;
    smWalk5MinTradeAreaInOutRival18: string;
    smWalk5MinTradeAreaInOutRival19: string;
    smWalk5MinTradeAreaInOutRival20: string;
    smStoreNameOwnStore: string;
    smStoreNameRival1: string;
    smStoreNameRival2: string;
    smStoreNameRival3: string;
    smStoreNameRival4: string;
    smStoreNameRival5: string;
    smStoreNameRival6: string;
    smStoreNameRival7: string;
    smStoreNameRival8: string;
    smStoreNameRival9: string;
    smStoreNameRival10: string;
    smStoreNameRival11: string;
    smStoreNameRival12: string;
    smStoreNameRival13: string;
    smStoreNameRival14: string;
    smStoreNameRival15: string;
    smStoreNameRival16: string;
    smStoreNameRival17: string;
    smStoreNameRival18: string;
    smStoreNameRival19: string;
    smStoreNameRival20: string;
    smSmDsAutoOwnStore: string;
    smSmDsAutoRival1: string;
    smSmDsAutoRival2: string;
    smSmDsAutoRival3: string;
    smSmDsAutoRival4: string;
    smSmDsAutoRival5: string;
    smSmDsAutoRival6: string;
    smSmDsAutoRival7: string;
    smSmDsAutoRival8: string;
    smSmDsAutoRival9: string;
    smSmDsAutoRival10: string;
    smSmDsAutoRival11: string;
    smSmDsAutoRival12: string;
    smSmDsAutoRival13: string;
    smSmDsAutoRival14: string;
    smSmDsAutoRival15: string;
    smSmDsAutoRival16: string;
    smSmDsAutoRival17: string;
    smSmDsAutoRival18: string;
    smSmDsAutoRival19: string;
    smSmDsAutoRival20: string;
    smSmDsSelectOwnStore: string;
    smSmDsSelectRival1: string;
    smSmDsSelectRival2: string;
    smSmDsSelectRival3: string;
    smSmDsSelectRival4: string;
    smSmDsSelectRival5: string;
    smSmDsSelectRival6: string;
    smSmDsSelectRival7: string;
    smSmDsSelectRival8: string;
    smSmDsSelectRival9: string;
    smSmDsSelectRival10: string;
    smSmDsSelectRival11: string;
    smSmDsSelectRival12: string;
    smSmDsSelectRival13: string;
    smSmDsSelectRival14: string;
    smSmDsSelectRival15: string;
    smSmDsSelectRival16: string;
    smSmDsSelectRival17: string;
    smSmDsSelectRival18: string;
    smSmDsSelectRival19: string;
    smSmDsSelectRival20: string;
    smBusinessHoursOwnStore: string;
    smBusinessHoursRival1: string;
    smBusinessHoursRival2: string;
    smBusinessHoursRival3: string;
    smBusinessHoursRival4: string;
    smBusinessHoursRival5: string;
    smBusinessHoursRival6: string;
    smBusinessHoursRival7: string;
    smBusinessHoursRival8: string;
    smBusinessHoursRival9: string;
    smBusinessHoursRival10: string;
    smBusinessHoursRival11: string;
    smBusinessHoursRival12: string;
    smBusinessHoursRival13: string;
    smBusinessHoursRival14: string;
    smBusinessHoursRival15: string;
    smBusinessHoursRival16: string;
    smBusinessHoursRival17: string;
    smBusinessHoursRival18: string;
    smBusinessHoursRival19: string;
    smBusinessHoursRival20: string;
    smClosingTimeOwnStore: string;
    smClosingTimeRival1: string;
    smClosingTimeRival2: string;
    smClosingTimeRival3: string;
    smClosingTimeRival4: string;
    smClosingTimeRival5: string;
    smClosingTimeRival6: string;
    smClosingTimeRival7: string;
    smClosingTimeRival8: string;
    smClosingTimeRival9: string;
    smClosingTimeRival10: string;
    smClosingTimeRival11: string;
    smClosingTimeRival12: string;
    smClosingTimeRival13: string;
    smClosingTimeRival14: string;
    smClosingTimeRival15: string;
    smClosingTimeRival16: string;
    smClosingTimeRival17: string;
    smClosingTimeRival18: string;
    smClosingTimeRival19: string;
    smClosingTimeRival20: string;
    smSalsesAreaOwnStore: number;
    smSalsesAreaRival1: number;
    smSalsesAreaRival2: number;
    smSalsesAreaRival3: number;
    smSalsesAreaRival4: number;
    smSalsesAreaRival5: number;
    smSalsesAreaRival6: number;
    smSalsesAreaRival7: number;
    smSalsesAreaRival8: number;
    smSalsesAreaRival9: number;
    smSalsesAreaRival10: number;
    smSalsesAreaRival11: number;
    smSalsesAreaRival12: number;
    smSalsesAreaRival13: number;
    smSalsesAreaRival14: number;
    smSalsesAreaRival15: number;
    smSalsesAreaRival16: number;
    smSalsesAreaRival17: number;
    smSalsesAreaRival18: number;
    smSalsesAreaRival19: number;
    smSalsesAreaRival20: number;
    smParkingAvailabilityOwnStore: string;
    smParkingAvailabilityRival1: string;
    smParkingAvailabilityRival2: string;
    smParkingAvailabilityRival3: string;
    smParkingAvailabilityRival4: string;
    smParkingAvailabilityRival5: string;
    smParkingAvailabilityRival6: string;
    smParkingAvailabilityRival7: string;
    smParkingAvailabilityRival8: string;
    smParkingAvailabilityRival9: string;
    smParkingAvailabilityRival10: string;
    smParkingAvailabilityRival11: string;
    smParkingAvailabilityRival12: string;
    smParkingAvailabilityRival13: string;
    smParkingAvailabilityRival14: string;
    smParkingAvailabilityRival15: string;
    smParkingAvailabilityRival16: string;
    smParkingAvailabilityRival17: string;
    smParkingAvailabilityRival18: string;
    smParkingAvailabilityRival19: string;
    smParkingAvailabilityRival20: string;
    smParkingAreaOwnStore: number;
    smParkingAreaRival1: number;
    smParkingAreaRival2: number;
    smParkingAreaRival3: number;
    smParkingAreaRival4: number;
    smParkingAreaRival5: number;
    smParkingAreaRival6: number;
    smParkingAreaRival7: number;
    smParkingAreaRival8: number;
    smParkingAreaRival9: number;
    smParkingAreaRival10: number;
    smParkingAreaRival11: number;
    smParkingAreaRival12: number;
    smParkingAreaRival13: number;
    smParkingAreaRival14: number;
    smParkingAreaRival15: number;
    smParkingAreaRival16: number;
    smParkingAreaRival17: number;
    smParkingAreaRival18: number;
    smParkingAreaRival19: number;
    smParkingAreaRival20: number;
    smBusinessTypeOwnStore: string;
    smBusinessTypeRival1: string;
    smBusinessTypeRival2: string;
    smBusinessTypeRival3: string;
    smBusinessTypeRival4: string;
    smBusinessTypeRival5: string;
    smBusinessTypeRival6: string;
    smBusinessTypeRival7: string;
    smBusinessTypeRival8: string;
    smBusinessTypeRival9: string;
    smBusinessTypeRival10: string;
    smBusinessTypeRival11: string;
    smBusinessTypeRival12: string;
    smBusinessTypeRival13: string;
    smBusinessTypeRival14: string;
    smBusinessTypeRival15: string;
    smBusinessTypeRival16: string;
    smBusinessTypeRival17: string;
    smBusinessTypeRival18: string;
    smBusinessTypeRival19: string;
    smBusinessTypeRival20: string;
    smLineDistanceOwnStore: number;
    smLineDistanceRival1: number;
    smLineDistanceRival2: number;
    smLineDistanceRival3: number;
    smLineDistanceRival4: number;
    smLineDistanceRival5: number;
    smLineDistanceRival6: number;
    smLineDistanceRival7: number;
    smLineDistanceRival8: number;
    smLineDistanceRival9: number;
    smLineDistanceRival10: number;
    smLineDistanceRival11: number;
    smLineDistanceRival12: number;
    smLineDistanceRival13: number;
    smLineDistanceRival14: number;
    smLineDistanceRival15: number;
    smLineDistanceRival16: number;
    smLineDistanceRival17: number;
    smLineDistanceRival18: number;
    smLineDistanceRival19: number;
    smLineDistanceRival20: number;
    smWayDistanceOwnStore: number;
    smWayDistanceRival1: number;
    smWayDistanceRival2: number;
    smWayDistanceRival3: number;
    smWayDistanceRival4: number;
    smWayDistanceRival5: number;
    smWayDistanceRival6: number;
    smWayDistanceRival7: number;
    smWayDistanceRival8: number;
    smWayDistanceRival9: number;
    smWayDistanceRival10: number;
    smWayDistanceRival11: number;
    smWayDistanceRival12: number;
    smWayDistanceRival13: number;
    smWayDistanceRival14: number;
    smWayDistanceRival15: number;
    smWayDistanceRival16: number;
    smWayDistanceRival17: number;
    smWayDistanceRival18: number;
    smWayDistanceRival19: number;
    smWayDistanceRival20: number;
    smPreCandidateCommonRoadOwnStore: string;
    smPreCandidateCommonRoadRival1: string;
    smPreCandidateCommonRoadRival2: string;
    smPreCandidateCommonRoadRival3: string;
    smPreCandidateCommonRoadRival4: string;
    smPreCandidateCommonRoadRival5: string;
    smPreCandidateCommonRoadRival6: string;
    smPreCandidateCommonRoadRival7: string;
    smPreCandidateCommonRoadRival8: string;
    smPreCandidateCommonRoadRival9: string;
    smPreCandidateCommonRoadRival10: string;
    smPreCandidateCommonRoadRival11: string;
    smPreCandidateCommonRoadRival12: string;
    smPreCandidateCommonRoadRival13: string;
    smPreCandidateCommonRoadRival14: string;
    smPreCandidateCommonRoadRival15: string;
    smPreCandidateCommonRoadRival16: string;
    smPreCandidateCommonRoadRival17: string;
    smPreCandidateCommonRoadRival18: string;
    smPreCandidateCommonRoadRival19: string;
    smPreCandidateCommonRoadRival20: string;
    smDirectionOwnStore: string;
    smDirectionRival1: string;
    smDirectionRival2: string;
    smDirectionRival3: string;
    smDirectionRival4: string;
    smDirectionRival5: string;
    smDirectionRival6: string;
    smDirectionRival7: string;
    smDirectionRival8: string;
    smDirectionRival9: string;
    smDirectionRival10: string;
    smDirectionRival11: string;
    smDirectionRival12: string;
    smDirectionRival13: string;
    smDirectionRival14: string;
    smDirectionRival15: string;
    smDirectionRival16: string;
    smDirectionRival17: string;
    smDirectionRival18: string;
    smDirectionRival19: string;
    smDirectionRival20: string;
    smPreConflictCommonRoadOwnStore: string;
    smPreConflictCommonRoadRival1: string;
    smPreConflictCommonRoadRival2: string;
    smPreConflictCommonRoadRival3: string;
    smPreConflictCommonRoadRival4: string;
    smPreConflictCommonRoadRival5: string;
    smPreConflictCommonRoadRival6: string;
    smPreConflictCommonRoadRival7: string;
    smPreConflictCommonRoadRival8: string;
    smPreConflictCommonRoadRival9: string;
    smPreConflictCommonRoadRival10: string;
    smPreConflictCommonRoadRival11: string;
    smPreConflictCommonRoadRival12: string;
    smPreConflictCommonRoadRival13: string;
    smPreConflictCommonRoadRival14: string;
    smPreConflictCommonRoadRival15: string;
    smPreConflictCommonRoadRival16: string;
    smPreConflictCommonRoadRival17: string;
    smPreConflictCommonRoadRival18: string;
    smPreConflictCommonRoadRival19: string;
    smPreConflictCommonRoadRival20: string;
    smPreConflictCommonRoadMedianStripPresenceOwnStore: string;
    smPreConflictCommonRoadMedianStripPresenceRival1: string;
    smPreConflictCommonRoadMedianStripPresenceRival2: string;
    smPreConflictCommonRoadMedianStripPresenceRival3: string;
    smPreConflictCommonRoadMedianStripPresenceRival4: string;
    smPreConflictCommonRoadMedianStripPresenceRival5: string;
    smPreConflictCommonRoadMedianStripPresenceRival6: string;
    smPreConflictCommonRoadMedianStripPresenceRival7: string;
    smPreConflictCommonRoadMedianStripPresenceRival8: string;
    smPreConflictCommonRoadMedianStripPresenceRival9: string;
    smPreConflictCommonRoadMedianStripPresenceRival10: string;
    smPreConflictCommonRoadMedianStripPresenceRival11: string;
    smPreConflictCommonRoadMedianStripPresenceRival12: string;
    smPreConflictCommonRoadMedianStripPresenceRival13: string;
    smPreConflictCommonRoadMedianStripPresenceRival14: string;
    smPreConflictCommonRoadMedianStripPresenceRival15: string;
    smPreConflictCommonRoadMedianStripPresenceRival16: string;
    smPreConflictCommonRoadMedianStripPresenceRival17: string;
    smPreConflictCommonRoadMedianStripPresenceRival18: string;
    smPreConflictCommonRoadMedianStripPresenceRival19: string;
    smPreConflictCommonRoadMedianStripPresenceRival20: string;
    smTurnMeterOwnStore: number;
    smTurnMeterRival1: number;
    smTurnMeterRival2: number;
    smTurnMeterRival3: number;
    smTurnMeterRival4: number;
    smTurnMeterRival5: number;
    smTurnMeterRival6: number;
    smTurnMeterRival7: number;
    smTurnMeterRival8: number;
    smTurnMeterRival9: number;
    smTurnMeterRival10: number;
    smTurnMeterRival11: number;
    smTurnMeterRival12: number;
    smTurnMeterRival13: number;
    smTurnMeterRival14: number;
    smTurnMeterRival15: number;
    smTurnMeterRival16: number;
    smTurnMeterRival17: number;
    smTurnMeterRival18: number;
    smTurnMeterRival19: number;
    smTurnMeterRival20: number;
    smStraightMeterOwnStore: number;
    smStraightMeterRival1: number;
    smStraightMeterRival2: number;
    smStraightMeterRival3: number;
    smStraightMeterRival4: number;
    smStraightMeterRival5: number;
    smStraightMeterRival6: number;
    smStraightMeterRival7: number;
    smStraightMeterRival8: number;
    smStraightMeterRival9: number;
    smStraightMeterRival10: number;
    smStraightMeterRival11: number;
    smStraightMeterRival12: number;
    smStraightMeterRival13: number;
    smStraightMeterRival14: number;
    smStraightMeterRival15: number;
    smStraightMeterRival16: number;
    smStraightMeterRival17: number;
    smStraightMeterRival18: number;
    smStraightMeterRival19: number;
    smStraightMeterRival20: number;
    walk5MinTradeAreaHouseholdCount: number;
    walk5MinTradeAreaEmployees: number;
    deleteTradeAreaHouseholdCount: number;
    deleteTradeAreaEmployees: number;
    realTradeAreaHouseholdCount: number;
    realTradeAreaEmployees: number;
    realConflictBrand1: string;
    realConflictStoreName1: string;
    realConflictAlcoholTobacco1: string;
    realConflictBusinessHours1: string;
    realConflictLineDistance1: number;
    realConflictSalsesArea1: number;
    realConflictParkingArea1: number;
    realConflictBrand2: string;
    realConflictStoreName2: string;
    realConflictAlcoholTobacco2: string;
    realConflictBusinessHours2: string;
    realConflictLineDistance2: number;
    realConflictSalsesArea2: number;
    realConflictParkingArea2: number;
    realConflictBrand3: string;
    realConflictStoreName3: string;
    realConflictAlcoholTobacco3: string;
    realConflictBusinessHours3: string;
    realConflictLineDistance3: number;
    realConflictSalsesArea3: number;
    realConflictParkingArea3: number;
    realConflictBrand4: string;
    realConflictStoreName4: string;
    realConflictAlcoholTobacco4: string;
    realConflictBusinessHours4: string;
    realConflictLineDistance4: number;
    realConflictSalsesArea4: number;
    realConflictParkingArea4: number;
    realConflictBrand5: string;
    realConflictStoreName5: string;
    realConflictAlcoholTobacco5: string;
    realConflictBusinessHours5: string;
    realConflictLineDistance5: number;
    realConflictSalsesArea5: number;
    realConflictParkingArea5: number;
    realConflictBrand6: string;
    realConflictStoreName6: string;
    realConflictAlcoholTobacco6: string;
    realConflictBusinessHours6: string;
    realConflictLineDistance6: number;
    realConflictSalsesArea6: number;
    realConflictParkingArea6: number;
    realConflictBrand7: string;
    realConflictStoreName7: string;
    realConflictAlcoholTobacco7: string;
    realConflictBusinessHours7: string;
    realConflictLineDistance7: number;
    realConflictSalsesArea7: number;
    realConflictParkingArea7: number;
    realConflictBrand8: string;
    realConflictStoreName8: string;
    realConflictAlcoholTobacco8: string;
    realConflictBusinessHours8: string;
    realConflictLineDistance8: number;
    realConflictSalsesArea8: number;
    realConflictParkingArea8: number;
    realConflictBrand9: string;
    realConflictStoreName9: string;
    realConflictAlcoholTobacco9: string;
    realConflictBusinessHours9: string;
    realConflictLineDistance9: number;
    realConflictSalsesArea9: number;
    realConflictParkingArea9: number;
    realConflictBrand10: string;
    realConflictStoreName10: string;
    realConflictAlcoholTobacco10: string;
    realConflictBusinessHours10: string;
    realConflictLineDistance10: number;
    realConflictSalsesArea10: number;
    realConflictParkingArea10: number;
    realConflictBrand11: string;
    realConflictStoreName11: string;
    realConflictAlcoholTobacco11: string;
    realConflictBusinessHours11: string;
    realConflictLineDistance11: number;
    realConflictSalsesArea11: number;
    realConflictParkingArea11: number;
    realConflictBrand12: string;
    realConflictStoreName12: string;
    realConflictAlcoholTobacco12: string;
    realConflictBusinessHours12: string;
    realConflictLineDistance12: number;
    realConflictSalsesArea12: number;
    realConflictParkingArea12: number;
    realConflictBrand13: string;
    realConflictStoreName13: string;
    realConflictAlcoholTobacco13: string;
    realConflictBusinessHours13: string;
    realConflictLineDistance13: number;
    realConflictSalsesArea13: number;
    realConflictParkingArea13: number;
    realConflictBrand14: string;
    realConflictStoreName14: string;
    realConflictAlcoholTobacco14: string;
    realConflictBusinessHours14: string;
    realConflictLineDistance14: number;
    realConflictSalsesArea14: number;
    realConflictParkingArea14: number;
    realConflictBrand15: string;
    realConflictStoreName15: string;
    realConflictAlcoholTobacco15: string;
    realConflictBusinessHours15: string;
    realConflictLineDistance15: number;
    realConflictSalsesArea15: number;
    realConflictParkingArea15: number;
    realConflictBrand16: string;
    realConflictStoreName16: string;
    realConflictAlcoholTobacco16: string;
    realConflictBusinessHours16: string;
    realConflictLineDistance16: number;
    realConflictSalsesArea16: number;
    realConflictParkingArea16: number;
    realConflictBrand17: string;
    realConflictStoreName17: string;
    realConflictAlcoholTobacco17: string;
    realConflictBusinessHours17: string;
    realConflictLineDistance17: number;
    realConflictSalsesArea17: number;
    realConflictParkingArea17: number;
    realConflictBrand18: string;
    realConflictStoreName18: string;
    realConflictAlcoholTobacco18: string;
    realConflictBusinessHours18: string;
    realConflictLineDistance18: number;
    realConflictSalsesArea18: number;
    realConflictParkingArea18: number;
    realConflictBrand19: string;
    realConflictStoreName19: string;
    realConflictAlcoholTobacco19: string;
    realConflictBusinessHours19: string;
    realConflictLineDistance19: number;
    realConflictSalsesArea19: number;
    realConflictParkingArea19: number;
    realConflictBrand20: string;
    realConflictStoreName20: string;
    realConflictAlcoholTobacco20: string;
    realConflictBusinessHours20: string;
    realConflictLineDistance20: number;
    realConflictSalsesArea20: number;
    realConflictParkingArea20: number;
};

const initConflictObj: ConflictTypeList = {
    cvsConflictTargetOwnStore: '',
    cvsConflictTargetRival1: '',
    cvsConflictTargetRival2: '',
    cvsConflictTargetRival3: '',
    cvsConflictTargetRival4: '',
    cvsConflictTargetRival5: '',
    cvsConflictTargetRival6: '',
    cvsConflictTargetRival7: '',
    cvsConflictTargetRival8: '',
    cvsConflictTargetRival9: '',
    cvsConflictTargetRival10: '',
    cvsConflictTargetRival11: '',
    cvsConflictTargetRival12: '',
    cvsConflictTargetRival13: '',
    cvsConflictTargetRival14: '',
    cvsConflictTargetRival15: '',
    cvsConflictTargetRival16: '',
    cvsConflictTargetRival17: '',
    cvsConflictTargetRival18: '',
    cvsConflictTargetRival19: '',
    cvsConflictTargetRival20: '',
    cvsWalk5MinTradeAreaInOutOwnStore: '',
    cvsWalk5MinTradeAreaInOutRival1: '',
    cvsWalk5MinTradeAreaInOutRival2: '',
    cvsWalk5MinTradeAreaInOutRival3: '',
    cvsWalk5MinTradeAreaInOutRival4: '',
    cvsWalk5MinTradeAreaInOutRival5: '',
    cvsWalk5MinTradeAreaInOutRival6: '',
    cvsWalk5MinTradeAreaInOutRival7: '',
    cvsWalk5MinTradeAreaInOutRival8: '',
    cvsWalk5MinTradeAreaInOutRival9: '',
    cvsWalk5MinTradeAreaInOutRival10: '',
    cvsWalk5MinTradeAreaInOutRival11: '',
    cvsWalk5MinTradeAreaInOutRival12: '',
    cvsWalk5MinTradeAreaInOutRival13: '',
    cvsWalk5MinTradeAreaInOutRival14: '',
    cvsWalk5MinTradeAreaInOutRival15: '',
    cvsWalk5MinTradeAreaInOutRival16: '',
    cvsWalk5MinTradeAreaInOutRival17: '',
    cvsWalk5MinTradeAreaInOutRival18: '',
    cvsWalk5MinTradeAreaInOutRival19: '',
    cvsWalk5MinTradeAreaInOutRival20: '',
    cvsChainName: '',
    cvsChainNameRival1: '',
    cvsChainNameRival2: '',
    cvsChainNameRival3: '',
    cvsChainNameRival4: '',
    cvsChainNameRival5: '',
    cvsChainNameRival6: '',
    cvsChainNameRival7: '',
    cvsChainNameRival8: '',
    cvsChainNameRival9: '',
    cvsChainNameRival10: '',
    cvsChainNameRival11: '',
    cvsChainNameRival12: '',
    cvsChainNameRival13: '',
    cvsChainNameRival14: '',
    cvsChainNameRival15: '',
    cvsChainNameRival16: '',
    cvsChainNameRival17: '',
    cvsChainNameRival18: '',
    cvsChainNameRival19: '',
    cvsChainNameRival20: '',
    cvsStoreNameOwnStore: '',
    cvsStoreNameRival1: '',
    cvsStoreNameRival2: '',
    cvsStoreNameRival3: '',
    cvsStoreNameRival4: '',
    cvsStoreNameRival5: '',
    cvsStoreNameRival6: '',
    cvsStoreNameRival7: '',
    cvsStoreNameRival8: '',
    cvsStoreNameRival9: '',
    cvsStoreNameRival10: '',
    cvsStoreNameRival11: '',
    cvsStoreNameRival12: '',
    cvsStoreNameRival13: '',
    cvsStoreNameRival14: '',
    cvsStoreNameRival15: '',
    cvsStoreNameRival16: '',
    cvsStoreNameRival17: '',
    cvsStoreNameRival18: '',
    cvsStoreNameRival19: '',
    cvsStoreNameRival20: '',
    cvsPrefecturesOwnStore: '',
    cvsPrefecturesRival1: '',
    cvsPrefecturesRival2: '',
    cvsPrefecturesRival3: '',
    cvsPrefecturesRival4: '',
    cvsPrefecturesRival5: '',
    cvsPrefecturesRival6: '',
    cvsPrefecturesRival7: '',
    cvsPrefecturesRival8: '',
    cvsPrefecturesRival9: '',
    cvsPrefecturesRival10: '',
    cvsPrefecturesRival11: '',
    cvsPrefecturesRival12: '',
    cvsPrefecturesRival13: '',
    cvsPrefecturesRival14: '',
    cvsPrefecturesRival15: '',
    cvsPrefecturesRival16: '',
    cvsPrefecturesRival17: '',
    cvsPrefecturesRival18: '',
    cvsPrefecturesRival19: '',
    cvsPrefecturesRival20: '',
    cvsAddressOwnStore: '',
    cvsAddressRival1: '',
    cvsAddressRival2: '',
    cvsAddressRival3: '',
    cvsAddressRival4: '',
    cvsAddressRival5: '',
    cvsAddressRival6: '',
    cvsAddressRival7: '',
    cvsAddressRival8: '',
    cvsAddressRival9: '',
    cvsAddressRival10: '',
    cvsAddressRival11: '',
    cvsAddressRival12: '',
    cvsAddressRival13: '',
    cvsAddressRival14: '',
    cvsAddressRival15: '',
    cvsAddressRival16: '',
    cvsAddressRival17: '',
    cvsAddressRival18: '',
    cvsAddressRival19: '',
    cvsAddressRival20: '',
    cvsBusinessTypeOwnStore: '',
    cvsBusinessTypeRival1: '',
    cvsBusinessTypeRival2: '',
    cvsBusinessTypeRival3: '',
    cvsBusinessTypeRival4: '',
    cvsBusinessTypeRival5: '',
    cvsBusinessTypeRival6: '',
    cvsBusinessTypeRival7: '',
    cvsBusinessTypeRival8: '',
    cvsBusinessTypeRival9: '',
    cvsBusinessTypeRival10: '',
    cvsBusinessTypeRival11: '',
    cvsBusinessTypeRival12: '',
    cvsBusinessTypeRival13: '',
    cvsBusinessTypeRival14: '',
    cvsBusinessTypeRival15: '',
    cvsBusinessTypeRival16: '',
    cvsBusinessTypeRival17: '',
    cvsBusinessTypeRival18: '',
    cvsBusinessTypeRival19: '',
    cvsBusinessTypeRival20: '',
    cvsBusinessHoursOwnStore: '',
    cvsBusinessHoursRival1: '',
    cvsBusinessHoursRival2: '',
    cvsBusinessHoursRival3: '',
    cvsBusinessHoursRival4: '',
    cvsBusinessHoursRival5: '',
    cvsBusinessHoursRival6: '',
    cvsBusinessHoursRival7: '',
    cvsBusinessHoursRival8: '',
    cvsBusinessHoursRival9: '',
    cvsBusinessHoursRival10: '',
    cvsBusinessHoursRival11: '',
    cvsBusinessHoursRival12: '',
    cvsBusinessHoursRival13: '',
    cvsBusinessHoursRival14: '',
    cvsBusinessHoursRival15: '',
    cvsBusinessHoursRival16: '',
    cvsBusinessHoursRival17: '',
    cvsBusinessHoursRival18: '',
    cvsBusinessHoursRival19: '',
    cvsBusinessHoursRival20: '',
    cvsClosingTimeOwnStore: '',
    cvsClosingTimeRival1: '',
    cvsClosingTimeRival2: '',
    cvsClosingTimeRival3: '',
    cvsClosingTimeRival4: '',
    cvsClosingTimeRival5: '',
    cvsClosingTimeRival6: '',
    cvsClosingTimeRival7: '',
    cvsClosingTimeRival8: '',
    cvsClosingTimeRival9: '',
    cvsClosingTimeRival10: '',
    cvsClosingTimeRival11: '',
    cvsClosingTimeRival12: '',
    cvsClosingTimeRival13: '',
    cvsClosingTimeRival14: '',
    cvsClosingTimeRival15: '',
    cvsClosingTimeRival16: '',
    cvsClosingTimeRival17: '',
    cvsClosingTimeRival18: '',
    cvsClosingTimeRival19: '',
    cvsClosingTimeRival20: '',
    cvsAlcoholLicenseOwnStore: '',
    cvsAlcoholLicenseRival1: '',
    cvsAlcoholLicenseRival2: '',
    cvsAlcoholLicenseRival3: '',
    cvsAlcoholLicenseRival4: '',
    cvsAlcoholLicenseRival5: '',
    cvsAlcoholLicenseRival6: '',
    cvsAlcoholLicenseRival7: '',
    cvsAlcoholLicenseRival8: '',
    cvsAlcoholLicenseRival9: '',
    cvsAlcoholLicenseRival10: '',
    cvsAlcoholLicenseRival11: '',
    cvsAlcoholLicenseRival12: '',
    cvsAlcoholLicenseRival13: '',
    cvsAlcoholLicenseRival14: '',
    cvsAlcoholLicenseRival15: '',
    cvsAlcoholLicenseRival16: '',
    cvsAlcoholLicenseRival17: '',
    cvsAlcoholLicenseRival18: '',
    cvsAlcoholLicenseRival19: '',
    cvsAlcoholLicenseRival20: '',
    cvsTobaccoLicenceOwnStore: '',
    cvsTobaccoLicenceRival1: '',
    cvsTobaccoLicenceRival2: '',
    cvsTobaccoLicenceRival3: '',
    cvsTobaccoLicenceRival4: '',
    cvsTobaccoLicenceRival5: '',
    cvsTobaccoLicenceRival6: '',
    cvsTobaccoLicenceRival7: '',
    cvsTobaccoLicenceRival8: '',
    cvsTobaccoLicenceRival9: '',
    cvsTobaccoLicenceRival10: '',
    cvsTobaccoLicenceRival11: '',
    cvsTobaccoLicenceRival12: '',
    cvsTobaccoLicenceRival13: '',
    cvsTobaccoLicenceRival14: '',
    cvsTobaccoLicenceRival15: '',
    cvsTobaccoLicenceRival16: '',
    cvsTobaccoLicenceRival17: '',
    cvsTobaccoLicenceRival18: '',
    cvsTobaccoLicenceRival19: '',
    cvsTobaccoLicenceRival20: '',
    cvsLocationOwnStore: '',
    cvsLocationRival1: '',
    cvsLocationRival2: '',
    cvsLocationRival3: '',
    cvsLocationRival4: '',
    cvsLocationRival5: '',
    cvsLocationRival6: '',
    cvsLocationRival7: '',
    cvsLocationRival8: '',
    cvsLocationRival9: '',
    cvsLocationRival10: '',
    cvsLocationRival11: '',
    cvsLocationRival12: '',
    cvsLocationRival13: '',
    cvsLocationRival14: '',
    cvsLocationRival15: '',
    cvsLocationRival16: '',
    cvsLocationRival17: '',
    cvsLocationRival18: '',
    cvsLocationRival19: '',
    cvsLocationRival20: '',
    cvsSalsesAreaOwnStore: 0,
    cvsSalsesAreaRival1: 0,
    cvsSalsesAreaRival2: 0,
    cvsSalsesAreaRival3: 0,
    cvsSalsesAreaRival4: 0,
    cvsSalsesAreaRival5: 0,
    cvsSalsesAreaRival6: 0,
    cvsSalsesAreaRival7: 0,
    cvsSalsesAreaRival8: 0,
    cvsSalsesAreaRival9: 0,
    cvsSalsesAreaRival10: 0,
    cvsSalsesAreaRival11: 0,
    cvsSalsesAreaRival12: 0,
    cvsSalsesAreaRival13: 0,
    cvsSalsesAreaRival14: 0,
    cvsSalsesAreaRival15: 0,
    cvsSalsesAreaRival16: 0,
    cvsSalsesAreaRival17: 0,
    cvsSalsesAreaRival18: 0,
    cvsSalsesAreaRival19: 0,
    cvsSalsesAreaRival20: 0,
    cvsGondolaUnitsOwnStore: 0,
    cvsGondolaUnitsRival1: 0,
    cvsGondolaUnitsRival2: 0,
    cvsGondolaUnitsRival3: 0,
    cvsGondolaUnitsRival4: 0,
    cvsGondolaUnitsRival5: 0,
    cvsGondolaUnitsRival6: 0,
    cvsGondolaUnitsRival7: 0,
    cvsGondolaUnitsRival8: 0,
    cvsGondolaUnitsRival9: 0,
    cvsGondolaUnitsRival10: 0,
    cvsGondolaUnitsRival11: 0,
    cvsGondolaUnitsRival12: 0,
    cvsGondolaUnitsRival13: 0,
    cvsGondolaUnitsRival14: 0,
    cvsGondolaUnitsRival15: 0,
    cvsGondolaUnitsRival16: 0,
    cvsGondolaUnitsRival17: 0,
    cvsGondolaUnitsRival18: 0,
    cvsGondolaUnitsRival19: 0,
    cvsGondolaUnitsRival20: 0,
    cvsParkingAvailabilityOwnStore: '',
    cvsParkingAvailabilityRival1: '',
    cvsParkingAvailabilityRival2: '',
    cvsParkingAvailabilityRival3: '',
    cvsParkingAvailabilityRival4: '',
    cvsParkingAvailabilityRival5: '',
    cvsParkingAvailabilityRival6: '',
    cvsParkingAvailabilityRival7: '',
    cvsParkingAvailabilityRival8: '',
    cvsParkingAvailabilityRival9: '',
    cvsParkingAvailabilityRival10: '',
    cvsParkingAvailabilityRival11: '',
    cvsParkingAvailabilityRival12: '',
    cvsParkingAvailabilityRival13: '',
    cvsParkingAvailabilityRival14: '',
    cvsParkingAvailabilityRival15: '',
    cvsParkingAvailabilityRival16: '',
    cvsParkingAvailabilityRival17: '',
    cvsParkingAvailabilityRival18: '',
    cvsParkingAvailabilityRival19: '',
    cvsParkingAvailabilityRival20: '',
    cvsStoreFrontageOwnStore: 0,
    cvsStoreFrontageRival1: 0,
    cvsStoreFrontageRival2: 0,
    cvsStoreFrontageRival3: 0,
    cvsStoreFrontageRival4: 0,
    cvsStoreFrontageRival5: 0,
    cvsStoreFrontageRival6: 0,
    cvsStoreFrontageRival7: 0,
    cvsStoreFrontageRival8: 0,
    cvsStoreFrontageRival9: 0,
    cvsStoreFrontageRival10: 0,
    cvsStoreFrontageRival11: 0,
    cvsStoreFrontageRival12: 0,
    cvsStoreFrontageRival13: 0,
    cvsStoreFrontageRival14: 0,
    cvsStoreFrontageRival15: 0,
    cvsStoreFrontageRival16: 0,
    cvsStoreFrontageRival17: 0,
    cvsStoreFrontageRival18: 0,
    cvsStoreFrontageRival19: 0,
    cvsStoreFrontageRival20: 0,
    cvsParkingAreaOwnStore: 0,
    cvsParkingAreaRival1: 0,
    cvsParkingAreaRival2: 0,
    cvsParkingAreaRival3: 0,
    cvsParkingAreaRival4: 0,
    cvsParkingAreaRival5: 0,
    cvsParkingAreaRival6: 0,
    cvsParkingAreaRival7: 0,
    cvsParkingAreaRival8: 0,
    cvsParkingAreaRival9: 0,
    cvsParkingAreaRival10: 0,
    cvsParkingAreaRival11: 0,
    cvsParkingAreaRival12: 0,
    cvsParkingAreaRival13: 0,
    cvsParkingAreaRival14: 0,
    cvsParkingAreaRival15: 0,
    cvsParkingAreaRival16: 0,
    cvsParkingAreaRival17: 0,
    cvsParkingAreaRival18: 0,
    cvsParkingAreaRival19: 0,
    cvsParkingAreaRival20: 0,
    cvsParkingSpacesOwnStore: 0,
    cvsParkingSpacesRival1: 0,
    cvsParkingSpacesRival2: 0,
    cvsParkingSpacesRival3: 0,
    cvsParkingSpacesRival4: 0,
    cvsParkingSpacesRival5: 0,
    cvsParkingSpacesRival6: 0,
    cvsParkingSpacesRival7: 0,
    cvsParkingSpacesRival8: 0,
    cvsParkingSpacesRival9: 0,
    cvsParkingSpacesRival10: 0,
    cvsParkingSpacesRival11: 0,
    cvsParkingSpacesRival12: 0,
    cvsParkingSpacesRival13: 0,
    cvsParkingSpacesRival14: 0,
    cvsParkingSpacesRival15: 0,
    cvsParkingSpacesRival16: 0,
    cvsParkingSpacesRival17: 0,
    cvsParkingSpacesRival18: 0,
    cvsParkingSpacesRival19: 0,
    cvsParkingSpacesRival20: 0,
    cvsFrontageParkingTangentLineOwnStore: 0,
    cvsFrontageParkingTangentLineRival1: 0,
    cvsFrontageParkingTangentLineRival2: 0,
    cvsFrontageParkingTangentLineRival3: 0,
    cvsFrontageParkingTangentLineRival4: 0,
    cvsFrontageParkingTangentLineRival5: 0,
    cvsFrontageParkingTangentLineRival6: 0,
    cvsFrontageParkingTangentLineRival7: 0,
    cvsFrontageParkingTangentLineRival8: 0,
    cvsFrontageParkingTangentLineRival9: 0,
    cvsFrontageParkingTangentLineRival10: 0,
    cvsFrontageParkingTangentLineRival11: 0,
    cvsFrontageParkingTangentLineRival12: 0,
    cvsFrontageParkingTangentLineRival13: 0,
    cvsFrontageParkingTangentLineRival14: 0,
    cvsFrontageParkingTangentLineRival15: 0,
    cvsFrontageParkingTangentLineRival16: 0,
    cvsFrontageParkingTangentLineRival17: 0,
    cvsFrontageParkingTangentLineRival18: 0,
    cvsFrontageParkingTangentLineRival19: 0,
    cvsFrontageParkingTangentLineRival20: 0,
    cvsFrontageEntrace1OwnStore: 0,
    cvsFrontageEntrace1Rival1: 0,
    cvsFrontageEntrace1Rival2: 0,
    cvsFrontageEntrace1Rival3: 0,
    cvsFrontageEntrace1Rival4: 0,
    cvsFrontageEntrace1Rival5: 0,
    cvsFrontageEntrace1Rival6: 0,
    cvsFrontageEntrace1Rival7: 0,
    cvsFrontageEntrace1Rival8: 0,
    cvsFrontageEntrace1Rival9: 0,
    cvsFrontageEntrace1Rival10: 0,
    cvsFrontageEntrace1Rival11: 0,
    cvsFrontageEntrace1Rival12: 0,
    cvsFrontageEntrace1Rival13: 0,
    cvsFrontageEntrace1Rival14: 0,
    cvsFrontageEntrace1Rival15: 0,
    cvsFrontageEntrace1Rival16: 0,
    cvsFrontageEntrace1Rival17: 0,
    cvsFrontageEntrace1Rival18: 0,
    cvsFrontageEntrace1Rival19: 0,
    cvsFrontageEntrace1Rival20: 0,
    cvsFrontageDepth1OwnStore: 0,
    cvsFrontageDepth1Rival1: 0,
    cvsFrontageDepth1Rival2: 0,
    cvsFrontageDepth1Rival3: 0,
    cvsFrontageDepth1Rival4: 0,
    cvsFrontageDepth1Rival5: 0,
    cvsFrontageDepth1Rival6: 0,
    cvsFrontageDepth1Rival7: 0,
    cvsFrontageDepth1Rival8: 0,
    cvsFrontageDepth1Rival9: 0,
    cvsFrontageDepth1Rival10: 0,
    cvsFrontageDepth1Rival11: 0,
    cvsFrontageDepth1Rival12: 0,
    cvsFrontageDepth1Rival13: 0,
    cvsFrontageDepth1Rival14: 0,
    cvsFrontageDepth1Rival15: 0,
    cvsFrontageDepth1Rival16: 0,
    cvsFrontageDepth1Rival17: 0,
    cvsFrontageDepth1Rival18: 0,
    cvsFrontageDepth1Rival19: 0,
    cvsFrontageDepth1Rival20: 0,
    cvsFrontageEntrace2OwnStore: 0,
    cvsFrontageEntrace2Rival1: 0,
    cvsFrontageEntrace2Rival2: 0,
    cvsFrontageEntrace2Rival3: 0,
    cvsFrontageEntrace2Rival4: 0,
    cvsFrontageEntrace2Rival5: 0,
    cvsFrontageEntrace2Rival6: 0,
    cvsFrontageEntrace2Rival7: 0,
    cvsFrontageEntrace2Rival8: 0,
    cvsFrontageEntrace2Rival9: 0,
    cvsFrontageEntrace2Rival10: 0,
    cvsFrontageEntrace2Rival11: 0,
    cvsFrontageEntrace2Rival12: 0,
    cvsFrontageEntrace2Rival13: 0,
    cvsFrontageEntrace2Rival14: 0,
    cvsFrontageEntrace2Rival15: 0,
    cvsFrontageEntrace2Rival16: 0,
    cvsFrontageEntrace2Rival17: 0,
    cvsFrontageEntrace2Rival18: 0,
    cvsFrontageEntrace2Rival19: 0,
    cvsFrontageEntrace2Rival20: 0,
    cvsFrontageDepth2OwnStore: 0,
    cvsFrontageDepth2Rival1: 0,
    cvsFrontageDepth2Rival2: 0,
    cvsFrontageDepth2Rival3: 0,
    cvsFrontageDepth2Rival4: 0,
    cvsFrontageDepth2Rival5: 0,
    cvsFrontageDepth2Rival6: 0,
    cvsFrontageDepth2Rival7: 0,
    cvsFrontageDepth2Rival8: 0,
    cvsFrontageDepth2Rival9: 0,
    cvsFrontageDepth2Rival10: 0,
    cvsFrontageDepth2Rival11: 0,
    cvsFrontageDepth2Rival12: 0,
    cvsFrontageDepth2Rival13: 0,
    cvsFrontageDepth2Rival14: 0,
    cvsFrontageDepth2Rival15: 0,
    cvsFrontageDepth2Rival16: 0,
    cvsFrontageDepth2Rival17: 0,
    cvsFrontageDepth2Rival18: 0,
    cvsFrontageDepth2Rival19: 0,
    cvsFrontageDepth2Rival20: 0,
    cvsSideWayTypeOwnStore: '',
    cvsSideWayTypeRival1: '',
    cvsSideWayTypeRival2: '',
    cvsSideWayTypeRival3: '',
    cvsSideWayTypeRival4: '',
    cvsSideWayTypeRival5: '',
    cvsSideWayTypeRival6: '',
    cvsSideWayTypeRival7: '',
    cvsSideWayTypeRival8: '',
    cvsSideWayTypeRival9: '',
    cvsSideWayTypeRival10: '',
    cvsSideWayTypeRival11: '',
    cvsSideWayTypeRival12: '',
    cvsSideWayTypeRival13: '',
    cvsSideWayTypeRival14: '',
    cvsSideWayTypeRival15: '',
    cvsSideWayTypeRival16: '',
    cvsSideWayTypeRival17: '',
    cvsSideWayTypeRival18: '',
    cvsSideWayTypeRival19: '',
    cvsSideWayTypeRival20: '',
    cvsSideWayBackstreetParkingTangentLineOwnStore: 0,
    cvsSideWayBackstreetParkingTangentLineRival1: 0,
    cvsSideWayBackstreetParkingTangentLineRival2: 0,
    cvsSideWayBackstreetParkingTangentLineRival3: 0,
    cvsSideWayBackstreetParkingTangentLineRival4: 0,
    cvsSideWayBackstreetParkingTangentLineRival5: 0,
    cvsSideWayBackstreetParkingTangentLineRival6: 0,
    cvsSideWayBackstreetParkingTangentLineRival7: 0,
    cvsSideWayBackstreetParkingTangentLineRival8: 0,
    cvsSideWayBackstreetParkingTangentLineRival9: 0,
    cvsSideWayBackstreetParkingTangentLineRival10: 0,
    cvsSideWayBackstreetParkingTangentLineRival11: 0,
    cvsSideWayBackstreetParkingTangentLineRival12: 0,
    cvsSideWayBackstreetParkingTangentLineRival13: 0,
    cvsSideWayBackstreetParkingTangentLineRival14: 0,
    cvsSideWayBackstreetParkingTangentLineRival15: 0,
    cvsSideWayBackstreetParkingTangentLineRival16: 0,
    cvsSideWayBackstreetParkingTangentLineRival17: 0,
    cvsSideWayBackstreetParkingTangentLineRival18: 0,
    cvsSideWayBackstreetParkingTangentLineRival19: 0,
    cvsSideWayBackstreetParkingTangentLineRival20: 0,
    cvsSideWayBackstreetEntrace1OwnStore: 0,
    cvsSideWayBackstreetEntrace1Rival1: 0,
    cvsSideWayBackstreetEntrace1Rival2: 0,
    cvsSideWayBackstreetEntrace1Rival3: 0,
    cvsSideWayBackstreetEntrace1Rival4: 0,
    cvsSideWayBackstreetEntrace1Rival5: 0,
    cvsSideWayBackstreetEntrace1Rival6: 0,
    cvsSideWayBackstreetEntrace1Rival7: 0,
    cvsSideWayBackstreetEntrace1Rival8: 0,
    cvsSideWayBackstreetEntrace1Rival9: 0,
    cvsSideWayBackstreetEntrace1Rival10: 0,
    cvsSideWayBackstreetEntrace1Rival11: 0,
    cvsSideWayBackstreetEntrace1Rival12: 0,
    cvsSideWayBackstreetEntrace1Rival13: 0,
    cvsSideWayBackstreetEntrace1Rival14: 0,
    cvsSideWayBackstreetEntrace1Rival15: 0,
    cvsSideWayBackstreetEntrace1Rival16: 0,
    cvsSideWayBackstreetEntrace1Rival17: 0,
    cvsSideWayBackstreetEntrace1Rival18: 0,
    cvsSideWayBackstreetEntrace1Rival19: 0,
    cvsSideWayBackstreetEntrace1Rival20: 0,
    cvsSideWayBackstreetDepth1OwnStore: 0,
    cvsSideWayBackstreetDepth1Rival1: 0,
    cvsSideWayBackstreetDepth1Rival2: 0,
    cvsSideWayBackstreetDepth1Rival3: 0,
    cvsSideWayBackstreetDepth1Rival4: 0,
    cvsSideWayBackstreetDepth1Rival5: 0,
    cvsSideWayBackstreetDepth1Rival6: 0,
    cvsSideWayBackstreetDepth1Rival7: 0,
    cvsSideWayBackstreetDepth1Rival8: 0,
    cvsSideWayBackstreetDepth1Rival9: 0,
    cvsSideWayBackstreetDepth1Rival10: 0,
    cvsSideWayBackstreetDepth1Rival11: 0,
    cvsSideWayBackstreetDepth1Rival12: 0,
    cvsSideWayBackstreetDepth1Rival13: 0,
    cvsSideWayBackstreetDepth1Rival14: 0,
    cvsSideWayBackstreetDepth1Rival15: 0,
    cvsSideWayBackstreetDepth1Rival16: 0,
    cvsSideWayBackstreetDepth1Rival17: 0,
    cvsSideWayBackstreetDepth1Rival18: 0,
    cvsSideWayBackstreetDepth1Rival19: 0,
    cvsSideWayBackstreetDepth1Rival20: 0,
    cvsSideWayEntrace2OwnStore: 0,
    cvsSideWayEntrace2Rival1: 0,
    cvsSideWayEntrace2Rival2: 0,
    cvsSideWayEntrace2Rival3: 0,
    cvsSideWayEntrace2Rival4: 0,
    cvsSideWayEntrace2Rival5: 0,
    cvsSideWayEntrace2Rival6: 0,
    cvsSideWayEntrace2Rival7: 0,
    cvsSideWayEntrace2Rival8: 0,
    cvsSideWayEntrace2Rival9: 0,
    cvsSideWayEntrace2Rival10: 0,
    cvsSideWayEntrace2Rival11: 0,
    cvsSideWayEntrace2Rival12: 0,
    cvsSideWayEntrace2Rival13: 0,
    cvsSideWayEntrace2Rival14: 0,
    cvsSideWayEntrace2Rival15: 0,
    cvsSideWayEntrace2Rival16: 0,
    cvsSideWayEntrace2Rival17: 0,
    cvsSideWayEntrace2Rival18: 0,
    cvsSideWayEntrace2Rival19: 0,
    cvsSideWayEntrace2Rival20: 0,
    cvsSideWayDepth2OwnStore: 0,
    cvsSideWayDepth2Rival1: 0,
    cvsSideWayDepth2Rival2: 0,
    cvsSideWayDepth2Rival3: 0,
    cvsSideWayDepth2Rival4: 0,
    cvsSideWayDepth2Rival5: 0,
    cvsSideWayDepth2Rival6: 0,
    cvsSideWayDepth2Rival7: 0,
    cvsSideWayDepth2Rival8: 0,
    cvsSideWayDepth2Rival9: 0,
    cvsSideWayDepth2Rival10: 0,
    cvsSideWayDepth2Rival11: 0,
    cvsSideWayDepth2Rival12: 0,
    cvsSideWayDepth2Rival13: 0,
    cvsSideWayDepth2Rival14: 0,
    cvsSideWayDepth2Rival15: 0,
    cvsSideWayDepth2Rival16: 0,
    cvsSideWayDepth2Rival17: 0,
    cvsSideWayDepth2Rival18: 0,
    cvsSideWayDepth2Rival19: 0,
    cvsSideWayDepth2Rival20: 0,
    cvsEstimatedNippanOwnStore: 0,
    cvsEstimatedNippanRival1: 0,
    cvsEstimatedNippanRival2: 0,
    cvsEstimatedNippanRival3: 0,
    cvsEstimatedNippanRival4: 0,
    cvsEstimatedNippanRival5: 0,
    cvsEstimatedNippanRival6: 0,
    cvsEstimatedNippanRival7: 0,
    cvsEstimatedNippanRival8: 0,
    cvsEstimatedNippanRival9: 0,
    cvsEstimatedNippanRival10: 0,
    cvsEstimatedNippanRival11: 0,
    cvsEstimatedNippanRival12: 0,
    cvsEstimatedNippanRival13: 0,
    cvsEstimatedNippanRival14: 0,
    cvsEstimatedNippanRival15: 0,
    cvsEstimatedNippanRival16: 0,
    cvsEstimatedNippanRival17: 0,
    cvsEstimatedNippanRival18: 0,
    cvsEstimatedNippanRival19: 0,
    cvsEstimatedNippanRival20: 0,
    cvsLineDistanceOwnStore: 0,
    cvsLineDistanceRival1: 0,
    cvsLineDistanceRival2: 0,
    cvsLineDistanceRival3: 0,
    cvsLineDistanceRival4: 0,
    cvsLineDistanceRival5: 0,
    cvsLineDistanceRival6: 0,
    cvsLineDistanceRival7: 0,
    cvsLineDistanceRival8: 0,
    cvsLineDistanceRival9: 0,
    cvsLineDistanceRival10: 0,
    cvsLineDistanceRival11: 0,
    cvsLineDistanceRival12: 0,
    cvsLineDistanceRival13: 0,
    cvsLineDistanceRival14: 0,
    cvsLineDistanceRival15: 0,
    cvsLineDistanceRival16: 0,
    cvsLineDistanceRival17: 0,
    cvsLineDistanceRival18: 0,
    cvsLineDistanceRival19: 0,
    cvsLineDistanceRival20: 0,
    cvsWayDistanceOwnStore: 0,
    cvsWayDistanceRival1: 0,
    cvsWayDistanceRival2: 0,
    cvsWayDistanceRival3: 0,
    cvsWayDistanceRival4: 0,
    cvsWayDistanceRival5: 0,
    cvsWayDistanceRival6: 0,
    cvsWayDistanceRival7: 0,
    cvsWayDistanceRival8: 0,
    cvsWayDistanceRival9: 0,
    cvsWayDistanceRival10: 0,
    cvsWayDistanceRival11: 0,
    cvsWayDistanceRival12: 0,
    cvsWayDistanceRival13: 0,
    cvsWayDistanceRival14: 0,
    cvsWayDistanceRival15: 0,
    cvsWayDistanceRival16: 0,
    cvsWayDistanceRival17: 0,
    cvsWayDistanceRival18: 0,
    cvsWayDistanceRival19: 0,
    cvsWayDistanceRival20: 0,
    cvsPreCandidateCommonRoadOwnStore: '',
    cvsPreCandidateCommonRoadRival1: '',
    cvsPreCandidateCommonRoadRival2: '',
    cvsPreCandidateCommonRoadRival3: '',
    cvsPreCandidateCommonRoadRival4: '',
    cvsPreCandidateCommonRoadRival5: '',
    cvsPreCandidateCommonRoadRival6: '',
    cvsPreCandidateCommonRoadRival7: '',
    cvsPreCandidateCommonRoadRival8: '',
    cvsPreCandidateCommonRoadRival9: '',
    cvsPreCandidateCommonRoadRival10: '',
    cvsPreCandidateCommonRoadRival11: '',
    cvsPreCandidateCommonRoadRival12: '',
    cvsPreCandidateCommonRoadRival13: '',
    cvsPreCandidateCommonRoadRival14: '',
    cvsPreCandidateCommonRoadRival15: '',
    cvsPreCandidateCommonRoadRival16: '',
    cvsPreCandidateCommonRoadRival17: '',
    cvsPreCandidateCommonRoadRival18: '',
    cvsPreCandidateCommonRoadRival19: '',
    cvsPreCandidateCommonRoadRival20: '',
    cvsDirectionOwnStore: '',
    cvsDirectionRival1: '',
    cvsDirectionRival2: '',
    cvsDirectionRival3: '',
    cvsDirectionRival4: '',
    cvsDirectionRival5: '',
    cvsDirectionRival6: '',
    cvsDirectionRival7: '',
    cvsDirectionRival8: '',
    cvsDirectionRival9: '',
    cvsDirectionRival10: '',
    cvsDirectionRival11: '',
    cvsDirectionRival12: '',
    cvsDirectionRival13: '',
    cvsDirectionRival14: '',
    cvsDirectionRival15: '',
    cvsDirectionRival16: '',
    cvsDirectionRival17: '',
    cvsDirectionRival18: '',
    cvsDirectionRival19: '',
    cvsDirectionRival20: '',
    cvsPreConflictCommonRoadOwnStore: '',
    cvsPreConflictCommonRoadRival1: '',
    cvsPreConflictCommonRoadRival2: '',
    cvsPreConflictCommonRoadRival3: '',
    cvsPreConflictCommonRoadRival4: '',
    cvsPreConflictCommonRoadRival5: '',
    cvsPreConflictCommonRoadRival6: '',
    cvsPreConflictCommonRoadRival7: '',
    cvsPreConflictCommonRoadRival8: '',
    cvsPreConflictCommonRoadRival9: '',
    cvsPreConflictCommonRoadRival10: '',
    cvsPreConflictCommonRoadRival11: '',
    cvsPreConflictCommonRoadRival12: '',
    cvsPreConflictCommonRoadRival13: '',
    cvsPreConflictCommonRoadRival14: '',
    cvsPreConflictCommonRoadRival15: '',
    cvsPreConflictCommonRoadRival16: '',
    cvsPreConflictCommonRoadRival17: '',
    cvsPreConflictCommonRoadRival18: '',
    cvsPreConflictCommonRoadRival19: '',
    cvsPreConflictCommonRoadRival20: '',
    cvsPreConflictCommonRoadMedianStripPresenceOwnStore: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival1: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival2: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival3: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival4: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival5: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival6: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival7: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival8: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival9: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival10: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival11: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival12: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival13: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival14: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival15: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival16: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival17: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival18: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival19: '',
    cvsPreConflictCommonRoadMedianStripPresenceRival20: '',
    cvsTurnMeterOwnStore: 0,
    cvsTurnMeterRival1: 0,
    cvsTurnMeterRival2: 0,
    cvsTurnMeterRival3: 0,
    cvsTurnMeterRival4: 0,
    cvsTurnMeterRival5: 0,
    cvsTurnMeterRival6: 0,
    cvsTurnMeterRival7: 0,
    cvsTurnMeterRival8: 0,
    cvsTurnMeterRival9: 0,
    cvsTurnMeterRival10: 0,
    cvsTurnMeterRival11: 0,
    cvsTurnMeterRival12: 0,
    cvsTurnMeterRival13: 0,
    cvsTurnMeterRival14: 0,
    cvsTurnMeterRival15: 0,
    cvsTurnMeterRival16: 0,
    cvsTurnMeterRival17: 0,
    cvsTurnMeterRival18: 0,
    cvsTurnMeterRival19: 0,
    cvsTurnMeterRival20: 0,
    cvsStraightMeterOwnStore: 0,
    cvsStraightMeterRival1: 0,
    cvsStraightMeterRival2: 0,
    cvsStraightMeterRival3: 0,
    cvsStraightMeterRival4: 0,
    cvsStraightMeterRival5: 0,
    cvsStraightMeterRival6: 0,
    cvsStraightMeterRival7: 0,
    cvsStraightMeterRival8: 0,
    cvsStraightMeterRival9: 0,
    cvsStraightMeterRival10: 0,
    cvsStraightMeterRival11: 0,
    cvsStraightMeterRival12: 0,
    cvsStraightMeterRival13: 0,
    cvsStraightMeterRival14: 0,
    cvsStraightMeterRival15: 0,
    cvsStraightMeterRival16: 0,
    cvsStraightMeterRival17: 0,
    cvsStraightMeterRival18: 0,
    cvsStraightMeterRival19: 0,
    cvsStraightMeterRival20: 0,
    smConflictTargetOwnStore: '',
    smConflictTargetRival1: '',
    smConflictTargetRival2: '',
    smConflictTargetRival3: '',
    smConflictTargetRival4: '',
    smConflictTargetRival5: '',
    smConflictTargetRival6: '',
    smConflictTargetRival7: '',
    smConflictTargetRival8: '',
    smConflictTargetRival9: '',
    smConflictTargetRival10: '',
    smConflictTargetRival11: '',
    smConflictTargetRival12: '',
    smConflictTargetRival13: '',
    smConflictTargetRival14: '',
    smConflictTargetRival15: '',
    smConflictTargetRival16: '',
    smConflictTargetRival17: '',
    smConflictTargetRival18: '',
    smConflictTargetRival19: '',
    smConflictTargetRival20: '',
    smWalk5MinTradeAreaInOutOwnStore: '',
    smWalk5MinTradeAreaInOutRival1: '',
    smWalk5MinTradeAreaInOutRival2: '',
    smWalk5MinTradeAreaInOutRival3: '',
    smWalk5MinTradeAreaInOutRival4: '',
    smWalk5MinTradeAreaInOutRival5: '',
    smWalk5MinTradeAreaInOutRival6: '',
    smWalk5MinTradeAreaInOutRival7: '',
    smWalk5MinTradeAreaInOutRival8: '',
    smWalk5MinTradeAreaInOutRival9: '',
    smWalk5MinTradeAreaInOutRival10: '',
    smWalk5MinTradeAreaInOutRival11: '',
    smWalk5MinTradeAreaInOutRival12: '',
    smWalk5MinTradeAreaInOutRival13: '',
    smWalk5MinTradeAreaInOutRival14: '',
    smWalk5MinTradeAreaInOutRival15: '',
    smWalk5MinTradeAreaInOutRival16: '',
    smWalk5MinTradeAreaInOutRival17: '',
    smWalk5MinTradeAreaInOutRival18: '',
    smWalk5MinTradeAreaInOutRival19: '',
    smWalk5MinTradeAreaInOutRival20: '',
    smStoreNameOwnStore: '',
    smStoreNameRival1: '',
    smStoreNameRival2: '',
    smStoreNameRival3: '',
    smStoreNameRival4: '',
    smStoreNameRival5: '',
    smStoreNameRival6: '',
    smStoreNameRival7: '',
    smStoreNameRival8: '',
    smStoreNameRival9: '',
    smStoreNameRival10: '',
    smStoreNameRival11: '',
    smStoreNameRival12: '',
    smStoreNameRival13: '',
    smStoreNameRival14: '',
    smStoreNameRival15: '',
    smStoreNameRival16: '',
    smStoreNameRival17: '',
    smStoreNameRival18: '',
    smStoreNameRival19: '',
    smStoreNameRival20: '',
    smSmDsAutoOwnStore: '',
    smSmDsAutoRival1: '',
    smSmDsAutoRival2: '',
    smSmDsAutoRival3: '',
    smSmDsAutoRival4: '',
    smSmDsAutoRival5: '',
    smSmDsAutoRival6: '',
    smSmDsAutoRival7: '',
    smSmDsAutoRival8: '',
    smSmDsAutoRival9: '',
    smSmDsAutoRival10: '',
    smSmDsAutoRival11: '',
    smSmDsAutoRival12: '',
    smSmDsAutoRival13: '',
    smSmDsAutoRival14: '',
    smSmDsAutoRival15: '',
    smSmDsAutoRival16: '',
    smSmDsAutoRival17: '',
    smSmDsAutoRival18: '',
    smSmDsAutoRival19: '',
    smSmDsAutoRival20: '',
    smSmDsSelectOwnStore: '',
    smSmDsSelectRival1: '',
    smSmDsSelectRival2: '',
    smSmDsSelectRival3: '',
    smSmDsSelectRival4: '',
    smSmDsSelectRival5: '',
    smSmDsSelectRival6: '',
    smSmDsSelectRival7: '',
    smSmDsSelectRival8: '',
    smSmDsSelectRival9: '',
    smSmDsSelectRival10: '',
    smSmDsSelectRival11: '',
    smSmDsSelectRival12: '',
    smSmDsSelectRival13: '',
    smSmDsSelectRival14: '',
    smSmDsSelectRival15: '',
    smSmDsSelectRival16: '',
    smSmDsSelectRival17: '',
    smSmDsSelectRival18: '',
    smSmDsSelectRival19: '',
    smSmDsSelectRival20: '',
    smBusinessHoursOwnStore: '',
    smBusinessHoursRival1: '',
    smBusinessHoursRival2: '',
    smBusinessHoursRival3: '',
    smBusinessHoursRival4: '',
    smBusinessHoursRival5: '',
    smBusinessHoursRival6: '',
    smBusinessHoursRival7: '',
    smBusinessHoursRival8: '',
    smBusinessHoursRival9: '',
    smBusinessHoursRival10: '',
    smBusinessHoursRival11: '',
    smBusinessHoursRival12: '',
    smBusinessHoursRival13: '',
    smBusinessHoursRival14: '',
    smBusinessHoursRival15: '',
    smBusinessHoursRival16: '',
    smBusinessHoursRival17: '',
    smBusinessHoursRival18: '',
    smBusinessHoursRival19: '',
    smBusinessHoursRival20: '',
    smClosingTimeOwnStore: '',
    smClosingTimeRival1: '',
    smClosingTimeRival2: '',
    smClosingTimeRival3: '',
    smClosingTimeRival4: '',
    smClosingTimeRival5: '',
    smClosingTimeRival6: '',
    smClosingTimeRival7: '',
    smClosingTimeRival8: '',
    smClosingTimeRival9: '',
    smClosingTimeRival10: '',
    smClosingTimeRival11: '',
    smClosingTimeRival12: '',
    smClosingTimeRival13: '',
    smClosingTimeRival14: '',
    smClosingTimeRival15: '',
    smClosingTimeRival16: '',
    smClosingTimeRival17: '',
    smClosingTimeRival18: '',
    smClosingTimeRival19: '',
    smClosingTimeRival20: '',
    smSalsesAreaOwnStore: 0,
    smSalsesAreaRival1: 0,
    smSalsesAreaRival2: 0,
    smSalsesAreaRival3: 0,
    smSalsesAreaRival4: 0,
    smSalsesAreaRival5: 0,
    smSalsesAreaRival6: 0,
    smSalsesAreaRival7: 0,
    smSalsesAreaRival8: 0,
    smSalsesAreaRival9: 0,
    smSalsesAreaRival10: 0,
    smSalsesAreaRival11: 0,
    smSalsesAreaRival12: 0,
    smSalsesAreaRival13: 0,
    smSalsesAreaRival14: 0,
    smSalsesAreaRival15: 0,
    smSalsesAreaRival16: 0,
    smSalsesAreaRival17: 0,
    smSalsesAreaRival18: 0,
    smSalsesAreaRival19: 0,
    smSalsesAreaRival20: 0,
    smParkingAvailabilityOwnStore: '',
    smParkingAvailabilityRival1: '',
    smParkingAvailabilityRival2: '',
    smParkingAvailabilityRival3: '',
    smParkingAvailabilityRival4: '',
    smParkingAvailabilityRival5: '',
    smParkingAvailabilityRival6: '',
    smParkingAvailabilityRival7: '',
    smParkingAvailabilityRival8: '',
    smParkingAvailabilityRival9: '',
    smParkingAvailabilityRival10: '',
    smParkingAvailabilityRival11: '',
    smParkingAvailabilityRival12: '',
    smParkingAvailabilityRival13: '',
    smParkingAvailabilityRival14: '',
    smParkingAvailabilityRival15: '',
    smParkingAvailabilityRival16: '',
    smParkingAvailabilityRival17: '',
    smParkingAvailabilityRival18: '',
    smParkingAvailabilityRival19: '',
    smParkingAvailabilityRival20: '',
    smParkingAreaOwnStore: 0,
    smParkingAreaRival1: 0,
    smParkingAreaRival2: 0,
    smParkingAreaRival3: 0,
    smParkingAreaRival4: 0,
    smParkingAreaRival5: 0,
    smParkingAreaRival6: 0,
    smParkingAreaRival7: 0,
    smParkingAreaRival8: 0,
    smParkingAreaRival9: 0,
    smParkingAreaRival10: 0,
    smParkingAreaRival11: 0,
    smParkingAreaRival12: 0,
    smParkingAreaRival13: 0,
    smParkingAreaRival14: 0,
    smParkingAreaRival15: 0,
    smParkingAreaRival16: 0,
    smParkingAreaRival17: 0,
    smParkingAreaRival18: 0,
    smParkingAreaRival19: 0,
    smParkingAreaRival20: 0,
    smBusinessTypeOwnStore: '',
    smBusinessTypeRival1: '',
    smBusinessTypeRival2: '',
    smBusinessTypeRival3: '',
    smBusinessTypeRival4: '',
    smBusinessTypeRival5: '',
    smBusinessTypeRival6: '',
    smBusinessTypeRival7: '',
    smBusinessTypeRival8: '',
    smBusinessTypeRival9: '',
    smBusinessTypeRival10: '',
    smBusinessTypeRival11: '',
    smBusinessTypeRival12: '',
    smBusinessTypeRival13: '',
    smBusinessTypeRival14: '',
    smBusinessTypeRival15: '',
    smBusinessTypeRival16: '',
    smBusinessTypeRival17: '',
    smBusinessTypeRival18: '',
    smBusinessTypeRival19: '',
    smBusinessTypeRival20: '',
    smLineDistanceOwnStore: 0,
    smLineDistanceRival1: 0,
    smLineDistanceRival2: 0,
    smLineDistanceRival3: 0,
    smLineDistanceRival4: 0,
    smLineDistanceRival5: 0,
    smLineDistanceRival6: 0,
    smLineDistanceRival7: 0,
    smLineDistanceRival8: 0,
    smLineDistanceRival9: 0,
    smLineDistanceRival10: 0,
    smLineDistanceRival11: 0,
    smLineDistanceRival12: 0,
    smLineDistanceRival13: 0,
    smLineDistanceRival14: 0,
    smLineDistanceRival15: 0,
    smLineDistanceRival16: 0,
    smLineDistanceRival17: 0,
    smLineDistanceRival18: 0,
    smLineDistanceRival19: 0,
    smLineDistanceRival20: 0,
    smWayDistanceOwnStore: 0,
    smWayDistanceRival1: 0,
    smWayDistanceRival2: 0,
    smWayDistanceRival3: 0,
    smWayDistanceRival4: 0,
    smWayDistanceRival5: 0,
    smWayDistanceRival6: 0,
    smWayDistanceRival7: 0,
    smWayDistanceRival8: 0,
    smWayDistanceRival9: 0,
    smWayDistanceRival10: 0,
    smWayDistanceRival11: 0,
    smWayDistanceRival12: 0,
    smWayDistanceRival13: 0,
    smWayDistanceRival14: 0,
    smWayDistanceRival15: 0,
    smWayDistanceRival16: 0,
    smWayDistanceRival17: 0,
    smWayDistanceRival18: 0,
    smWayDistanceRival19: 0,
    smWayDistanceRival20: 0,
    smPreCandidateCommonRoadOwnStore: '',
    smPreCandidateCommonRoadRival1: '',
    smPreCandidateCommonRoadRival2: '',
    smPreCandidateCommonRoadRival3: '',
    smPreCandidateCommonRoadRival4: '',
    smPreCandidateCommonRoadRival5: '',
    smPreCandidateCommonRoadRival6: '',
    smPreCandidateCommonRoadRival7: '',
    smPreCandidateCommonRoadRival8: '',
    smPreCandidateCommonRoadRival9: '',
    smPreCandidateCommonRoadRival10: '',
    smPreCandidateCommonRoadRival11: '',
    smPreCandidateCommonRoadRival12: '',
    smPreCandidateCommonRoadRival13: '',
    smPreCandidateCommonRoadRival14: '',
    smPreCandidateCommonRoadRival15: '',
    smPreCandidateCommonRoadRival16: '',
    smPreCandidateCommonRoadRival17: '',
    smPreCandidateCommonRoadRival18: '',
    smPreCandidateCommonRoadRival19: '',
    smPreCandidateCommonRoadRival20: '',
    smDirectionOwnStore: '',
    smDirectionRival1: '',
    smDirectionRival2: '',
    smDirectionRival3: '',
    smDirectionRival4: '',
    smDirectionRival5: '',
    smDirectionRival6: '',
    smDirectionRival7: '',
    smDirectionRival8: '',
    smDirectionRival9: '',
    smDirectionRival10: '',
    smDirectionRival11: '',
    smDirectionRival12: '',
    smDirectionRival13: '',
    smDirectionRival14: '',
    smDirectionRival15: '',
    smDirectionRival16: '',
    smDirectionRival17: '',
    smDirectionRival18: '',
    smDirectionRival19: '',
    smDirectionRival20: '',
    smPreConflictCommonRoadOwnStore: '',
    smPreConflictCommonRoadRival1: '',
    smPreConflictCommonRoadRival2: '',
    smPreConflictCommonRoadRival3: '',
    smPreConflictCommonRoadRival4: '',
    smPreConflictCommonRoadRival5: '',
    smPreConflictCommonRoadRival6: '',
    smPreConflictCommonRoadRival7: '',
    smPreConflictCommonRoadRival8: '',
    smPreConflictCommonRoadRival9: '',
    smPreConflictCommonRoadRival10: '',
    smPreConflictCommonRoadRival11: '',
    smPreConflictCommonRoadRival12: '',
    smPreConflictCommonRoadRival13: '',
    smPreConflictCommonRoadRival14: '',
    smPreConflictCommonRoadRival15: '',
    smPreConflictCommonRoadRival16: '',
    smPreConflictCommonRoadRival17: '',
    smPreConflictCommonRoadRival18: '',
    smPreConflictCommonRoadRival19: '',
    smPreConflictCommonRoadRival20: '',
    smPreConflictCommonRoadMedianStripPresenceOwnStore: '',
    smPreConflictCommonRoadMedianStripPresenceRival1: '',
    smPreConflictCommonRoadMedianStripPresenceRival2: '',
    smPreConflictCommonRoadMedianStripPresenceRival3: '',
    smPreConflictCommonRoadMedianStripPresenceRival4: '',
    smPreConflictCommonRoadMedianStripPresenceRival5: '',
    smPreConflictCommonRoadMedianStripPresenceRival6: '',
    smPreConflictCommonRoadMedianStripPresenceRival7: '',
    smPreConflictCommonRoadMedianStripPresenceRival8: '',
    smPreConflictCommonRoadMedianStripPresenceRival9: '',
    smPreConflictCommonRoadMedianStripPresenceRival10: '',
    smPreConflictCommonRoadMedianStripPresenceRival11: '',
    smPreConflictCommonRoadMedianStripPresenceRival12: '',
    smPreConflictCommonRoadMedianStripPresenceRival13: '',
    smPreConflictCommonRoadMedianStripPresenceRival14: '',
    smPreConflictCommonRoadMedianStripPresenceRival15: '',
    smPreConflictCommonRoadMedianStripPresenceRival16: '',
    smPreConflictCommonRoadMedianStripPresenceRival17: '',
    smPreConflictCommonRoadMedianStripPresenceRival18: '',
    smPreConflictCommonRoadMedianStripPresenceRival19: '',
    smPreConflictCommonRoadMedianStripPresenceRival20: '',
    smTurnMeterOwnStore: 0,
    smTurnMeterRival1: 0,
    smTurnMeterRival2: 0,
    smTurnMeterRival3: 0,
    smTurnMeterRival4: 0,
    smTurnMeterRival5: 0,
    smTurnMeterRival6: 0,
    smTurnMeterRival7: 0,
    smTurnMeterRival8: 0,
    smTurnMeterRival9: 0,
    smTurnMeterRival10: 0,
    smTurnMeterRival11: 0,
    smTurnMeterRival12: 0,
    smTurnMeterRival13: 0,
    smTurnMeterRival14: 0,
    smTurnMeterRival15: 0,
    smTurnMeterRival16: 0,
    smTurnMeterRival17: 0,
    smTurnMeterRival18: 0,
    smTurnMeterRival19: 0,
    smTurnMeterRival20: 0,
    smStraightMeterOwnStore: 0,
    smStraightMeterRival1: 0,
    smStraightMeterRival2: 0,
    smStraightMeterRival3: 0,
    smStraightMeterRival4: 0,
    smStraightMeterRival5: 0,
    smStraightMeterRival6: 0,
    smStraightMeterRival7: 0,
    smStraightMeterRival8: 0,
    smStraightMeterRival9: 0,
    smStraightMeterRival10: 0,
    smStraightMeterRival11: 0,
    smStraightMeterRival12: 0,
    smStraightMeterRival13: 0,
    smStraightMeterRival14: 0,
    smStraightMeterRival15: 0,
    smStraightMeterRival16: 0,
    smStraightMeterRival17: 0,
    smStraightMeterRival18: 0,
    smStraightMeterRival19: 0,
    smStraightMeterRival20: 0,
    walk5MinTradeAreaHouseholdCount: 0,
    walk5MinTradeAreaEmployees: 0,
    deleteTradeAreaHouseholdCount: 0,
    deleteTradeAreaEmployees: 0,
    realTradeAreaHouseholdCount: 0,
    realTradeAreaEmployees: 0,
    realConflictBrand1: '',
    realConflictStoreName1: '',
    realConflictAlcoholTobacco1: '',
    realConflictBusinessHours1: '',
    realConflictLineDistance1: 0,
    realConflictSalsesArea1: 0,
    realConflictParkingArea1: 0,
    realConflictBrand2: '',
    realConflictStoreName2: '',
    realConflictAlcoholTobacco2: '',
    realConflictBusinessHours2: '',
    realConflictLineDistance2: 0,
    realConflictSalsesArea2: 0,
    realConflictParkingArea2: 0,
    realConflictBrand3: '',
    realConflictStoreName3: '',
    realConflictAlcoholTobacco3: '',
    realConflictBusinessHours3: '',
    realConflictLineDistance3: 0,
    realConflictSalsesArea3: 0,
    realConflictParkingArea3: 0,
    realConflictBrand4: '',
    realConflictStoreName4: '',
    realConflictAlcoholTobacco4: '',
    realConflictBusinessHours4: '',
    realConflictLineDistance4: 0,
    realConflictSalsesArea4: 0,
    realConflictParkingArea4: 0,
    realConflictBrand5: '',
    realConflictStoreName5: '',
    realConflictAlcoholTobacco5: '',
    realConflictBusinessHours5: '',
    realConflictLineDistance5: 0,
    realConflictSalsesArea5: 0,
    realConflictParkingArea5: 0,
    realConflictBrand6: '',
    realConflictStoreName6: '',
    realConflictAlcoholTobacco6: '',
    realConflictBusinessHours6: '',
    realConflictLineDistance6: 0,
    realConflictSalsesArea6: 0,
    realConflictParkingArea6: 0,
    realConflictBrand7: '',
    realConflictStoreName7: '',
    realConflictAlcoholTobacco7: '',
    realConflictBusinessHours7: '',
    realConflictLineDistance7: 0,
    realConflictSalsesArea7: 0,
    realConflictParkingArea7: 0,
    realConflictBrand8: '',
    realConflictStoreName8: '',
    realConflictAlcoholTobacco8: '',
    realConflictBusinessHours8: '',
    realConflictLineDistance8: 0,
    realConflictSalsesArea8: 0,
    realConflictParkingArea8: 0,
    realConflictBrand9: '',
    realConflictStoreName9: '',
    realConflictAlcoholTobacco9: '',
    realConflictBusinessHours9: '',
    realConflictLineDistance9: 0,
    realConflictSalsesArea9: 0,
    realConflictParkingArea9: 0,
    realConflictBrand10: '',
    realConflictStoreName10: '',
    realConflictAlcoholTobacco10: '',
    realConflictBusinessHours10: '',
    realConflictLineDistance10: 0,
    realConflictSalsesArea10: 0,
    realConflictParkingArea10: 0,
    realConflictBrand11: '',
    realConflictStoreName11: '',
    realConflictAlcoholTobacco11: '',
    realConflictBusinessHours11: '',
    realConflictLineDistance11: 0,
    realConflictSalsesArea11: 0,
    realConflictParkingArea11: 0,
    realConflictBrand12: '',
    realConflictStoreName12: '',
    realConflictAlcoholTobacco12: '',
    realConflictBusinessHours12: '',
    realConflictLineDistance12: 0,
    realConflictSalsesArea12: 0,
    realConflictParkingArea12: 0,
    realConflictBrand13: '',
    realConflictStoreName13: '',
    realConflictAlcoholTobacco13: '',
    realConflictBusinessHours13: '',
    realConflictLineDistance13: 0,
    realConflictSalsesArea13: 0,
    realConflictParkingArea13: 0,
    realConflictBrand14: '',
    realConflictStoreName14: '',
    realConflictAlcoholTobacco14: '',
    realConflictBusinessHours14: '',
    realConflictLineDistance14: 0,
    realConflictSalsesArea14: 0,
    realConflictParkingArea14: 0,
    realConflictBrand15: '',
    realConflictStoreName15: '',
    realConflictAlcoholTobacco15: '',
    realConflictBusinessHours15: '',
    realConflictLineDistance15: 0,
    realConflictSalsesArea15: 0,
    realConflictParkingArea15: 0,
    realConflictBrand16: '',
    realConflictStoreName16: '',
    realConflictAlcoholTobacco16: '',
    realConflictBusinessHours16: '',
    realConflictLineDistance16: 0,
    realConflictSalsesArea16: 0,
    realConflictParkingArea16: 0,
    realConflictBrand17: '',
    realConflictStoreName17: '',
    realConflictAlcoholTobacco17: '',
    realConflictBusinessHours17: '',
    realConflictLineDistance17: 0,
    realConflictSalsesArea17: 0,
    realConflictParkingArea17: 0,
    realConflictBrand18: '',
    realConflictStoreName18: '',
    realConflictAlcoholTobacco18: '',
    realConflictBusinessHours18: '',
    realConflictLineDistance18: 0,
    realConflictSalsesArea18: 0,
    realConflictParkingArea18: 0,
    realConflictBrand19: '',
    realConflictStoreName19: '',
    realConflictAlcoholTobacco19: '',
    realConflictBusinessHours19: '',
    realConflictLineDistance19: 0,
    realConflictSalsesArea19: 0,
    realConflictParkingArea19: 0,
    realConflictBrand20: '',
    realConflictStoreName20: '',
    realConflictAlcoholTobacco20: '',
    realConflictBusinessHours20: '',
    realConflictLineDistance20: 0,
    realConflictSalsesArea20: 0,
    realConflictParkingArea20: 0,
};


type TrafficVolumeTypeList = {
    carMorningTrafficVolumeSurvey: string;
    carMorningTrafficVolumeStartTime: string;
    carMorningTrafficVolumeTotalCountFrontage: number;
    carMorningTrafficVolumeLightVehicle: number;
    carMorningTrafficVolumeLargeVehicle: number;
    carMorningTrafficVolumeTotalCountSideWayA: number;
    carMorningTrafficVolumeLightVehicle2: number;
    carMorningTrafficVolumeLargeVehicle2: number;
    carMorningTrafficVolumeTotalCountSideWayB: number;
    carMorningTrafficVolumeLightVehicle3: number;
    carMorningTrafficVolumeLargeVehicle3: number;
    carMorningTrafficVolumeTotalCountBackstreet: number;
    carMorningTrafficVolumeLightVehicle4: number;
    carMorningTrafficVolumeLargeVehicle4: number;
    carMorningTrafficJamClosureTotalCountFrontage: number;
    carMorningTrafficJamClosureTotalCountSideWayB: number;
    carMiddayTrafficVolumeSurvey: string;
    carMiddayTrafficVolumeStartTime: string;
    carMiddayTrafficVolumeTotalCountFrontage: number;
    carMiddayTrafficVolumeLightVehicle: number;
    carMiddayTrafficVolumeLargeVehicle: number;
    carMiddayTrafficVolumeTotalCountSideWayA: number;
    carMiddayTrafficVolumeLightVehicle2: number;
    carMiddayTrafficVolumeLargeVehicle2: number;
    carMiddayTrafficVolumeTotalCountSideWayB: number;
    carMiddayTrafficVolumeLightVehicle3: number;
    carMiddayTrafficVolumeLargeVehicle3: number;
    carMiddayTrafficVolumeTotalCountBackstreet: number;
    carMiddayTrafficVolumeLightVehicle4: number;
    carMiddayTrafficVolumeLargeVehicle4: number;
    carMiddayTrafficJamClosureTotalCountFrontage: number;
    carMiddayTrafficJamClosureTotalCountSideWayB: number;
    carNightTrafficVolumeSurvey: string;
    carNightTrafficVolumeStartTime: string;
    carNightTrafficVolumeTotalCountFrontage: number;
    carNightTrafficVolumeLightVehicle: number;
    carNightTrafficVolumeLargeVehicle: number;
    carNightTrafficVolumeTotalCountSideWayA: number;
    carNightTrafficVolumeLightVehicle2: number;
    carNightTrafficVolumeLargeVehicle2: number;
    carNightTrafficVolumeTotalCountSideWayB: number;
    carNightTrafficVolumeLightVehicle3: number;
    carNightTrafficVolumeLargeVehicle3: number;
    carNightTrafficVolumeTotalCountBackstreet: number;
    carNightTrafficVolumeLightVehicle4: number;
    carNightTrafficVolumeLargeVehicle4: number;
    carNightTrafficJamClosureTotalCountFrontage: number;
    carNightTrafficJamClosureTotalCountSideWayB: number;
    carSaturdayHolidayResortTrafficVolumeSurvey: string;
    carSaturdayHolidayResortTrafficVolumeStartTime: string;
    carSaturdayHolidayResortTrafficVolumeTotalCountFrontage: number;
    carSaturdayHolidayResortTrafficVolumeLightVehicle: number;
    carSaturdayHolidayResortTrafficVolumeLargeVehicle: number;
    carSaturdayHolidayResortTrafficVolumeTotalCountSideWayA: number;
    carSaturdayHolidayResortTrafficVolumeLightVehicle2: number;
    carSaturdayHolidayResortTrafficVolumeLargeVehicle2: number;
    carSaturdayHolidayResortTrafficVolumeTotalCountSideWayB: number;
    carSaturdayHolidayResortTrafficVolumeLightVehicle3: number;
    carSaturdayHolidayResortTrafficVolumeLargeVehicle3: number;
    carSaturdayHolidayResortTrafficVolumeTotalCountBackstreet: number;
    carSaturdayHolidayResortTrafficVolumeLightVehicle4: number;
    carSaturdayHolidayResortTrafficVolumeLargeVehicle4: number;
    carSaturdayHolidayResortTrafficJamClosureTotalCountFrontage: number;
    carSaturdayHolidayResortTrafficJamClosureTotalCountSideWayB: number;
    humanIn5mMorningTrafficVolumeSurvey: string;
    humanIn5mMorningTrafficVolumeStartTime: string;
    humanIn5mMorningTrafficVolumeSecondarySchoolStudents: number;
    humanIn5mMorningTrafficVolumeAgeGroup65MoreMale: number;
    humanIn5mMorningTrafficVolumeAgeGroup65MoreFemale: number;
    humanIn5mMorningTrafficVolumeOtherMale: number;
    humanIn5mMorningTrafficVolumeOtherFemale: number;
    humanIn5mMorningTrafficVolumeTotalCount: number;
    humanIn5mMorningTrafficVolumeBicycle: number;
    humanIn5mMorningSpecificTrafficVolumeSurvey: string;
    humanIn5mMorningSpecificTrafficVolumeStartTime: string;
    humanIn5mMorningSpecificTrafficVolumeSecondarySchoolStudents: number;
    humanIn5mMorningSpecificTrafficVolumeAgeGroup65MoreMale: number;
    humanIn5mMorningSpecificTrafficVolumeAgeGroup65MoreFemale: number;
    humanIn5mMorningSpecificTrafficVolumeOtherMale: number;
    humanIn5mMorningSpecificTrafficVolumeOtherFemale: number;
    humanIn5mMorningSpecificTrafficVolumeTotalCount: number;
    humanIn5mMorningSpecificTrafficVolumeBicycle: number;
    humanIn5mMiddayTrafficVolumeSurvey: string;
    humanIn5mMiddayTrafficVolumeStartTime: string;
    humanIn5mMiddayTrafficVolumeSecondarySchoolStudents: number;
    humanIn5mMiddayTrafficVolumeAgeGroup65MoreMale: number;
    humanIn5mMiddayTrafficVolumeAgeGroup65MoreFemale: number;
    humanIn5mMiddayTrafficVolumeOtherMale: number;
    humanIn5mMiddayTrafficVolumeOtherFemale: number;
    humanIn5mMiddayTrafficVolumeTotalCount: number;
    humanIn5mMiddayTrafficVolumeBicycle: number;
    humanIn5mMiddaySpecificTrafficVolumeSurvey: string;
    humanIn5mMiddaySpecificTrafficVolumeStartTime: string;
    humanIn5mMiddaySpecificTrafficVolumeSecondarySchoolStudents: number;
    humanIn5mMiddaySpecificTrafficVolumeAgeGroup65MoreMale: number;
    humanIn5mMiddaySpecificTrafficVolumeAgeGroup65MoreFemale: number;
    humanIn5mMiddaySpecificTrafficVolumeOtherMale: number;
    humanIn5mMiddaySpecificTrafficVolumeOtherFemale: number;
    humanIn5mMiddaySpecificTrafficVolumeTotalCount: number;
    humanIn5mMiddaySpecificTrafficVolumeBicycle: number;
    humanIn5mNightTrafficVolumeSurvey: string;
    humanIn5mNightTrafficVolumeStartTime: string;
    humanIn5mNightTrafficVolumeSecondarySchoolStudents: number;
    humanIn5mNightTrafficVolumeAgeGroup65MoreMale: number;
    humanIn5mNightTrafficVolumeAgeGroup65MoreFemale: number;
    humanIn5mNightTrafficVolumeOtherMale: number;
    humanIn5mNightTrafficVolumeOtherFemale: number;
    humanIn5mNightTrafficVolumeTotalCount: number;
    humanIn5mNightTrafficVolumeBicycle: number;
    humanIn5mEveningSpecificTrafficVolumeSurvey: string;
    humanIn5mEveningSpecificTrafficVolumeStartTime: string;
    humanIn5mEveningSpecificTrafficVolumeSecondarySchoolStudents: number;
    humanIn5mEveningSpecificTrafficVolumeAgeGroup65MoreMale: number;
    humanIn5mEveningSpecificTrafficVolumeAgeGroup65MoreFemale: number;
    humanIn5mEveningSpecificTrafficVolumeOtherMale: number;
    humanIn5mEveningSpecificTrafficVolumeOtherFemale: number;
    humanIn5mEveningSpecificTrafficVolumeTotalCount: number;
    humanIn5mEveningSpecificTrafficVolumeBicycle: number;
    humanIn5mSaturdayHolidayResortTrafficVolumeSurvey: string;
    humanIn5mSaturdayHolidayResortTrafficVolumeStartTime: string;
    humanIn5mSaturdayHolidayResortTrafficVolumeSecondarySchoolStudents: number;
    humanIn5mSaturdayHolidayResortTrafficVolumeAgeGroup65MoreMale: number;
    humanIn5mSaturdayHolidayResortTrafficVolumeAgeGroup65MoreFemale: number;
    humanIn5mSaturdayHolidayResortTrafficVolumeOtherMale: number;
    humanIn5mSaturdayHolidayResortTrafficVolumeOtherFemale: number;
    humanIn5mSaturdayHolidayResortTrafficVolumeTotalCount: number;
    humanIn5mSaturdayHolidayResortTrafficVolumeBicycle: number;
    humanIn5mOtherStationEntraces: string;
    humanIn5mOtherWayDistance: number;
    humanIn5mOtherPassengerCount: number;
    humanIn5mOtherUniversityAndJuniorCollege: string;
    humanIn5mOtherUniversityAndJuniorCollegeRange: number;
    humanIn5mOtherUniversityAndJuniorCollegeRate: number;
    humanIn5mOtherOfficeGroup: string;
    humanIn5mOtherOfficeGroupLineDistance: number;
    humanIn5mOtherOfficeGroupRate: number;
    humanIn5mOtherShoppingStreet: string;
    humanIn5mOtherShoppingStreetLineDistance: number;
    humanIn5mOtherShoppingStreetRate: number;
    humanIn5mOtherMedicalAndPublicFacilities: string;
    humanIn5mOtherMedicalAndPublicFacilitiesLineDistance: number;
    humanIn5mOtherMedicalAndPublicFacilitiesRate: number;
    humanIn5mOtherEntertainmentFacilities: string;
    humanIn5mOtherEntertainmentFacilitiesLineDistance: number;
    humanIn5mOtherEntertainmentFacilitiesRate: number;
    humanOut5mMorningTrafficVolumeSurvey: string;
    humanOut5mMorningTrafficVolumeStartTime: string;
    humanOut5mMorningTrafficVolumeSecondarySchoolStudents: number;
    humanOut5mMorningTrafficVolumeAgeGroup65MoreMale: number;
    humanOut5mMorningTrafficVolumeAgeGroup65MoreFemale: number;
    humanOut5mMorningTrafficVolumeOtherMale: number;
    humanOut5mMorningTrafficVolumeOtherFemale: number;
    humanOut5mMorningTrafficVolumeTotalCount: number;
    humanOut5mMorningTrafficVolumeBicycle: number;
    humanOut5mMorningSpecificTrafficVolumeSurvey: string;
    humanOut5mMorningSpecificTrafficVolumeStartTime: string;
    humanOut5mMorningSpecificTrafficVolumeSecondarySchoolStudents: number;
    humanOut5mMorningSpecificTrafficVolumeAgeGroup65MoreMale: number;
    humanOut5mMorningSpecificTrafficVolumeAgeGroup65MoreFemale: number;
    humanOut5mMorningSpecificTrafficVolumeOtherMale: number;
    humanOut5mMorningSpecificTrafficVolumeOtherFemale: number;
    humanOut5mMorningSpecificTrafficVolumeTotalCount: number;
    humanOut5mMorningSpecificTrafficVolumeBicycle: number;
    humanOut5mMiddayTrafficVolumeSurvey: string;
    humanOut5mMiddayTrafficVolumeStartTime: string;
    humanOut5mMiddayTrafficVolumeSecondarySchoolStudents: number;
    humanOut5mMiddayTrafficVolumeAgeGroup65MoreMale: number;
    humanOut5mMiddayTrafficVolumeAgeGroup65MoreFemale: number;
    humanOut5mMiddayTrafficVolumeOtherMale: number;
    humanOut5mMiddayTrafficVolumeOtherFemale: number;
    humanOut5mMiddayTrafficVolumeTotalCount: number;
    humanOut5mMiddayTrafficVolumeBicycle: number;
    humanOut5mMiddaySpecificTrafficVolumeSurvey: string;
    humanOut5mMiddaySpecificTrafficVolumeStartTime: string;
    humanOut5mMiddaySpecificTrafficVolumeSecondarySchoolStudents: number;
    humanOut5mMiddaySpecificTrafficVolumeAgeGroup65MoreMale: number;
    humanOut5mMiddaySpecificTrafficVolumeAgeGroup65MoreFemale: number;
    humanOut5mMiddaySpecificTrafficVolumeOtherMale: number;
    humanOut5mMiddaySpecificTrafficVolumeOtherFemale: number;
    humanOut5mMiddaySpecificTrafficVolumeTotalCount: number;
    humanOut5mMiddaySpecificTrafficVolumeBicycle: number;
    humanOut5mNightTrafficVolumeSurvey: string;
    humanOut5mNightTrafficVolumeStartTime: string;
    humanOut5mNightTrafficVolumeSecondarySchoolStudents: number;
    humanOut5mNightTrafficVolumeAgeGroup65MoreMale: number;
    humanOut5mNightTrafficVolumeAgeGroup65MoreFemale: number;
    humanOut5mNightTrafficVolumeOtherMale: number;
    humanOut5mNightTrafficVolumeOtherFemale: number;
    humanOut5mNightTrafficVolumeTotalCount: number;
    humanOut5mNightTrafficVolumeBicycle: number;
    humanOut5mEveningSpecificTrafficVolumeSurvey: string;
    humanOut5mEveningSpecificTrafficVolumeStartTime: string;
    humanOut5mEveningSpecificTrafficVolumeSecondarySchoolStudents: number;
    humanOut5mEveningSpecificTrafficVolumeAgeGroup65MoreMale: number;
    humanOut5mEveningSpecificTrafficVolumeAgeGroup65MoreFemale: number;
    humanOut5mEveningSpecificTrafficVolumeOtherMale: number;
    humanOut5mEveningSpecificTrafficVolumeOtherFemale: number;
    humanOut5mEveningSpecificTrafficVolumeTotalCount: number;
    humanOut5mEveningSpecificTrafficVolumeBicycle: number;
    humanOut5mSaturdayHolidayResortTrafficVolumeSurvey: string;
    humanOut5mSaturdayHolidayResortTrafficVolumeStartTime: string;
    humanOut5mSaturdayHolidayResortTrafficVolumeSecondarySchoolStudents: number;
    humanOut5mSaturdayHolidayResortTrafficVolumeAgeGroup65MoreMale: number;
    humanOut5mSaturdayHolidayResortTrafficVolumeAgeGroup65MoreFemale: number;
    humanOut5mSaturdayHolidayResortTrafficVolumeOtherMale: number;
    humanOut5mSaturdayHolidayResortTrafficVolumeOtherFemale: number;
    humanOut5mSaturdayHolidayResortTrafficVolumeTotalCount: number;
    humanOut5mSaturdayHolidayResortTrafficVolumeBicycle: number;
    humanOut5mTRCheckClearPath: boolean;
    humanOut5mTRCheckVisible: boolean;
    humanOut5mTRCheckStepless: boolean;
    humanOut5mTRCheckUnimpeded: boolean;
    humanInOut5mMorningTrafficVolumeSurvey: string;
    humanInOut5mMorningTrafficVolumeStartTime: string;
    humanInOut5mMorningTrafficVolumeSecondarySchoolStudents: number;
    humanInOut5mMorningTrafficVolumeAgeGroup65MoreMale: number;
    humanInOut5mMorningTrafficVolumeAgeGroup65MoreFemale: number;
    humanInOut5mMorningTrafficVolumeOtherMale: number;
    humanInOut5mMorningTrafficVolumeOtherFemale: number;
    humanInOut5mMorningTrafficVolumeTotalCount: number;
    humanInOut5mMorningTrafficVolumeBicycle: number;
    humanInOut5mMorningSpecificTrafficVolumeSurvey: string;
    humanInOut5mMorningSpecificTrafficVolumeStartTime: string;
    humanInOut5mMorningSpecificTrafficVolumeSecondarySchoolStudents: number;
    humanInOut5mMorningSpecificTrafficVolumeAgeGroup65MoreMale: number;
    humanInOut5mMorningSpecificTrafficVolumeAgeGroup65MoreFemale: number;
    humanInOut5mMorningSpecificTrafficVolumeOtherMale: number;
    humanInOut5mMorningSpecificTrafficVolumeOtherFemale: number;
    humanInOut5mMorningSpecificTrafficVolumeTotalCount: number;
    humanInOut5mMorningSpecificTrafficVolumeBicycle: number;
    humanInOut5mMiddayTrafficVolumeSurvey: string;
    humanInOut5mMiddayTrafficVolumeStartTime: string;
    humanInOut5mMiddayTrafficVolumeSeconbdarySchoolStudents: number;
    humanInOut5mMiddayTrafficVolumeAgeGroup65MoreMale: number;
    humanInOut5mMiddayTrafficVolumeAgeGroup65MoreFemale: number;
    humanInOut5mMiddayTrafficVolumeOtherMale: number;
    humanInOut5mMiddayTrafficVolumeOtherFemale: number;
    humanInOut5mMiddayTrafficVolumeTotalCount: number;
    humanInOut5mMiddayTrafficVolumeBicycle: number;
    humanInOut5mMiddaySpecificVolumeSurvey: string;
    humanInOut5mMiddaySpecificVolumeStartTime: string;
    humanInOut5mMiddaySpecificVolumeSeconbdarySchoolStudents: number;
    humanInOut5mMiddaySpecificVolumeAgeGroup65MoreMale: number;
    humanInOut5mMiddaySpecificVolumeAgeGroup65MoreFemale: number;
    humanInOut5mMiddaySpecificVolumeOtherMale: number;
    humanInOut5mMiddaySpecificVolumeOtherFemale: number;
    humanInOut5mMiddaySpecificVolumeTotalCount: number;
    humanInOut5mMiddaySpecificVolumeBicycle: number;
    humanInOut5mNightTrafficVolumeSurvey: string;
    humanInOut5mNightTrafficVolumeStartTime: string;
    humanInOut5mNightTrafficVolumeSeconbdarySchoolStudents: number;
    humanInOut5mNightTrafficVolumeAgeGroup65MoreMale: number;
    humanInOut5mNightTrafficVolumeAgeGroup65MoreFemale: number;
    humanInOut5mNightTrafficVolumeOtherMale: number;
    humanInOut5mNightTrafficVolumeOtherFemale: number;
    humanInOut5mNightTrafficVolumeTotalCount: number;
    humanInOut5mNightTrafficVolumeBicycle: number;
    humanInOut5mEveningSpecificTrafficVolumeSurvey: string;
    humanInOut5mEveningSpecificTrafficVolumeStartTime: string;
    humanInOut5mEveningSpecificTrafficVolumeSecondarySchoolStudents: number;
    humanInOut5mEveningSpecificTrafficVolumeAgeGroup65MoreMale: number;
    humanInOut5mEveningSpecificTrafficVolumeAgeGroup65MoreFemale: number;
    humanInOut5mEveningSpecificTrafficVolumeOtherMale: number;
    humanInOut5mEveningSpecificTrafficVolumeOtherFemale: number;
    humanInOut5mEveningSpecificTrafficVolumeTotalCount: number;
    humanInOut5mEveningSpecificTrafficVolumeBicycle: number;
    humanInOut5mSaturdayHolidayResortTrafficVolumeSurvey: string;
    humanInOut5mSaturdayHolidayResortTrafficVolumeStartTime: string;
    humanInOut5mSaturdayHolidayResortTrafficVolumeSecondarySchoolStudents: number;
    humanInOut5mSaturdayHolidayResortTrafficVolumeAgeGroup65MoreMale: number;
    humanInOut5mSaturdayHolidayResortTrafficVolumeAgeGroup65MoreFemale: number;
    humanInOut5mSaturdayHolidayResortTrafficVolumeOtherMale: number;
    humanInOut5mSaturdayHolidayResortTrafficVolumeOtherFemale: number;
    humanInOut5mSaturdayHolidayResortTrafficVolumeTotalCount: number;
    humanInOut5mSaturdayHolidayResortTrafficVolumeBicycle: number;
};

const initTrafficVolumeObj: TrafficVolumeTypeList = {
    carMorningTrafficVolumeSurvey: '',
    carMorningTrafficVolumeStartTime: '',
    carMorningTrafficVolumeTotalCountFrontage: 0,
    carMorningTrafficVolumeLightVehicle: 0,
    carMorningTrafficVolumeLargeVehicle: 0,
    carMorningTrafficVolumeTotalCountSideWayA: 0,
    carMorningTrafficVolumeLightVehicle2: 0,
    carMorningTrafficVolumeLargeVehicle2: 0,
    carMorningTrafficVolumeTotalCountSideWayB: 0,
    carMorningTrafficVolumeLightVehicle3: 0,
    carMorningTrafficVolumeLargeVehicle3: 0,
    carMorningTrafficVolumeTotalCountBackstreet: 0,
    carMorningTrafficVolumeLightVehicle4: 0,
    carMorningTrafficVolumeLargeVehicle4: 0,
    carMorningTrafficJamClosureTotalCountFrontage: 0,
    carMorningTrafficJamClosureTotalCountSideWayB: 0,
    carMiddayTrafficVolumeSurvey: '',
    carMiddayTrafficVolumeStartTime: '',
    carMiddayTrafficVolumeTotalCountFrontage: 0,
    carMiddayTrafficVolumeLightVehicle: 0,
    carMiddayTrafficVolumeLargeVehicle: 0,
    carMiddayTrafficVolumeTotalCountSideWayA: 0,
    carMiddayTrafficVolumeLightVehicle2: 0,
    carMiddayTrafficVolumeLargeVehicle2: 0,
    carMiddayTrafficVolumeTotalCountSideWayB: 0,
    carMiddayTrafficVolumeLightVehicle3: 0,
    carMiddayTrafficVolumeLargeVehicle3: 0,
    carMiddayTrafficVolumeTotalCountBackstreet: 0,
    carMiddayTrafficVolumeLightVehicle4: 0,
    carMiddayTrafficVolumeLargeVehicle4: 0,
    carMiddayTrafficJamClosureTotalCountFrontage: 0,
    carMiddayTrafficJamClosureTotalCountSideWayB: 0,
    carNightTrafficVolumeSurvey: '',
    carNightTrafficVolumeStartTime: '',
    carNightTrafficVolumeTotalCountFrontage: 0,
    carNightTrafficVolumeLightVehicle: 0,
    carNightTrafficVolumeLargeVehicle: 0,
    carNightTrafficVolumeTotalCountSideWayA: 0,
    carNightTrafficVolumeLightVehicle2: 0,
    carNightTrafficVolumeLargeVehicle2: 0,
    carNightTrafficVolumeTotalCountSideWayB: 0,
    carNightTrafficVolumeLightVehicle3: 0,
    carNightTrafficVolumeLargeVehicle3: 0,
    carNightTrafficVolumeTotalCountBackstreet: 0,
    carNightTrafficVolumeLightVehicle4: 0,
    carNightTrafficVolumeLargeVehicle4: 0,
    carNightTrafficJamClosureTotalCountFrontage: 0,
    carNightTrafficJamClosureTotalCountSideWayB: 0,
    carSaturdayHolidayResortTrafficVolumeSurvey: '',
    carSaturdayHolidayResortTrafficVolumeStartTime: '',
    carSaturdayHolidayResortTrafficVolumeTotalCountFrontage: 0,
    carSaturdayHolidayResortTrafficVolumeLightVehicle: 0,
    carSaturdayHolidayResortTrafficVolumeLargeVehicle: 0,
    carSaturdayHolidayResortTrafficVolumeTotalCountSideWayA: 0,
    carSaturdayHolidayResortTrafficVolumeLightVehicle2: 0,
    carSaturdayHolidayResortTrafficVolumeLargeVehicle2: 0,
    carSaturdayHolidayResortTrafficVolumeTotalCountSideWayB: 0,
    carSaturdayHolidayResortTrafficVolumeLightVehicle3: 0,
    carSaturdayHolidayResortTrafficVolumeLargeVehicle3: 0,
    carSaturdayHolidayResortTrafficVolumeTotalCountBackstreet: 0,
    carSaturdayHolidayResortTrafficVolumeLightVehicle4: 0,
    carSaturdayHolidayResortTrafficVolumeLargeVehicle4: 0,
    carSaturdayHolidayResortTrafficJamClosureTotalCountFrontage: 0,
    carSaturdayHolidayResortTrafficJamClosureTotalCountSideWayB: 0,
    humanIn5mMorningTrafficVolumeSurvey: '',
    humanIn5mMorningTrafficVolumeStartTime: '',
    humanIn5mMorningTrafficVolumeSecondarySchoolStudents: 0,
    humanIn5mMorningTrafficVolumeAgeGroup65MoreMale: 0,
    humanIn5mMorningTrafficVolumeAgeGroup65MoreFemale: 0,
    humanIn5mMorningTrafficVolumeOtherMale: 0,
    humanIn5mMorningTrafficVolumeOtherFemale: 0,
    humanIn5mMorningTrafficVolumeTotalCount: 0,
    humanIn5mMorningTrafficVolumeBicycle: 0,
    humanIn5mMorningSpecificTrafficVolumeSurvey: '',
    humanIn5mMorningSpecificTrafficVolumeStartTime: '',
    humanIn5mMorningSpecificTrafficVolumeSecondarySchoolStudents: 0,
    humanIn5mMorningSpecificTrafficVolumeAgeGroup65MoreMale: 0,
    humanIn5mMorningSpecificTrafficVolumeAgeGroup65MoreFemale: 0,
    humanIn5mMorningSpecificTrafficVolumeOtherMale: 0,
    humanIn5mMorningSpecificTrafficVolumeOtherFemale: 0,
    humanIn5mMorningSpecificTrafficVolumeTotalCount: 0,
    humanIn5mMorningSpecificTrafficVolumeBicycle: 0,
    humanIn5mMiddayTrafficVolumeSurvey: '',
    humanIn5mMiddayTrafficVolumeStartTime: '',
    humanIn5mMiddayTrafficVolumeSecondarySchoolStudents: 0,
    humanIn5mMiddayTrafficVolumeAgeGroup65MoreMale: 0,
    humanIn5mMiddayTrafficVolumeAgeGroup65MoreFemale: 0,
    humanIn5mMiddayTrafficVolumeOtherMale: 0,
    humanIn5mMiddayTrafficVolumeOtherFemale: 0,
    humanIn5mMiddayTrafficVolumeTotalCount: 0,
    humanIn5mMiddayTrafficVolumeBicycle: 0,
    humanIn5mMiddaySpecificTrafficVolumeSurvey: '',
    humanIn5mMiddaySpecificTrafficVolumeStartTime: '',
    humanIn5mMiddaySpecificTrafficVolumeSecondarySchoolStudents: 0,
    humanIn5mMiddaySpecificTrafficVolumeAgeGroup65MoreMale: 0,
    humanIn5mMiddaySpecificTrafficVolumeAgeGroup65MoreFemale: 0,
    humanIn5mMiddaySpecificTrafficVolumeOtherMale: 0,
    humanIn5mMiddaySpecificTrafficVolumeOtherFemale: 0,
    humanIn5mMiddaySpecificTrafficVolumeTotalCount: 0,
    humanIn5mMiddaySpecificTrafficVolumeBicycle: 0,
    humanIn5mNightTrafficVolumeSurvey: '',
    humanIn5mNightTrafficVolumeStartTime: '',
    humanIn5mNightTrafficVolumeSecondarySchoolStudents: 0,
    humanIn5mNightTrafficVolumeAgeGroup65MoreMale: 0,
    humanIn5mNightTrafficVolumeAgeGroup65MoreFemale: 0,
    humanIn5mNightTrafficVolumeOtherMale: 0,
    humanIn5mNightTrafficVolumeOtherFemale: 0,
    humanIn5mNightTrafficVolumeTotalCount: 0,
    humanIn5mNightTrafficVolumeBicycle: 0,
    humanIn5mEveningSpecificTrafficVolumeSurvey: '',
    humanIn5mEveningSpecificTrafficVolumeStartTime: '',
    humanIn5mEveningSpecificTrafficVolumeSecondarySchoolStudents: 0,
    humanIn5mEveningSpecificTrafficVolumeAgeGroup65MoreMale: 0,
    humanIn5mEveningSpecificTrafficVolumeAgeGroup65MoreFemale: 0,
    humanIn5mEveningSpecificTrafficVolumeOtherMale: 0,
    humanIn5mEveningSpecificTrafficVolumeOtherFemale: 0,
    humanIn5mEveningSpecificTrafficVolumeTotalCount: 0,
    humanIn5mEveningSpecificTrafficVolumeBicycle: 0,
    humanIn5mSaturdayHolidayResortTrafficVolumeSurvey: '',
    humanIn5mSaturdayHolidayResortTrafficVolumeStartTime: '',
    humanIn5mSaturdayHolidayResortTrafficVolumeSecondarySchoolStudents: 0,
    humanIn5mSaturdayHolidayResortTrafficVolumeAgeGroup65MoreMale: 0,
    humanIn5mSaturdayHolidayResortTrafficVolumeAgeGroup65MoreFemale: 0,
    humanIn5mSaturdayHolidayResortTrafficVolumeOtherMale: 0,
    humanIn5mSaturdayHolidayResortTrafficVolumeOtherFemale: 0,
    humanIn5mSaturdayHolidayResortTrafficVolumeTotalCount: 0,
    humanIn5mSaturdayHolidayResortTrafficVolumeBicycle: 0,
    humanIn5mOtherStationEntraces: '',
    humanIn5mOtherWayDistance: 0,
    humanIn5mOtherPassengerCount: 0,
    humanIn5mOtherUniversityAndJuniorCollege: '',
    humanIn5mOtherUniversityAndJuniorCollegeRange: 0,
    humanIn5mOtherUniversityAndJuniorCollegeRate: 0,
    humanIn5mOtherOfficeGroup: '',
    humanIn5mOtherOfficeGroupLineDistance: 0,
    humanIn5mOtherOfficeGroupRate: 0,
    humanIn5mOtherShoppingStreet: '',
    humanIn5mOtherShoppingStreetLineDistance: 0,
    humanIn5mOtherShoppingStreetRate: 0,
    humanIn5mOtherMedicalAndPublicFacilities: '',
    humanIn5mOtherMedicalAndPublicFacilitiesLineDistance: 0,
    humanIn5mOtherMedicalAndPublicFacilitiesRate: 0,
    humanIn5mOtherEntertainmentFacilities: '',
    humanIn5mOtherEntertainmentFacilitiesLineDistance: 0,
    humanIn5mOtherEntertainmentFacilitiesRate: 0,
    humanOut5mMorningTrafficVolumeSurvey: '',
    humanOut5mMorningTrafficVolumeStartTime: '',
    humanOut5mMorningTrafficVolumeSecondarySchoolStudents: 0,
    humanOut5mMorningTrafficVolumeAgeGroup65MoreMale: 0,
    humanOut5mMorningTrafficVolumeAgeGroup65MoreFemale: 0,
    humanOut5mMorningTrafficVolumeOtherMale: 0,
    humanOut5mMorningTrafficVolumeOtherFemale: 0,
    humanOut5mMorningTrafficVolumeTotalCount: 0,
    humanOut5mMorningTrafficVolumeBicycle: 0,
    humanOut5mMorningSpecificTrafficVolumeSurvey: '',
    humanOut5mMorningSpecificTrafficVolumeStartTime: '',
    humanOut5mMorningSpecificTrafficVolumeSecondarySchoolStudents: 0,
    humanOut5mMorningSpecificTrafficVolumeAgeGroup65MoreMale: 0,
    humanOut5mMorningSpecificTrafficVolumeAgeGroup65MoreFemale: 0,
    humanOut5mMorningSpecificTrafficVolumeOtherMale: 0,
    humanOut5mMorningSpecificTrafficVolumeOtherFemale: 0,
    humanOut5mMorningSpecificTrafficVolumeTotalCount: 0,
    humanOut5mMorningSpecificTrafficVolumeBicycle: 0,
    humanOut5mMiddayTrafficVolumeSurvey: '',
    humanOut5mMiddayTrafficVolumeStartTime: '',
    humanOut5mMiddayTrafficVolumeSecondarySchoolStudents: 0,
    humanOut5mMiddayTrafficVolumeAgeGroup65MoreMale: 0,
    humanOut5mMiddayTrafficVolumeAgeGroup65MoreFemale: 0,
    humanOut5mMiddayTrafficVolumeOtherMale: 0,
    humanOut5mMiddayTrafficVolumeOtherFemale: 0,
    humanOut5mMiddayTrafficVolumeTotalCount: 0,
    humanOut5mMiddayTrafficVolumeBicycle: 0,
    humanOut5mMiddaySpecificTrafficVolumeSurvey: '',
    humanOut5mMiddaySpecificTrafficVolumeStartTime: '',
    humanOut5mMiddaySpecificTrafficVolumeSecondarySchoolStudents: 0,
    humanOut5mMiddaySpecificTrafficVolumeAgeGroup65MoreMale: 0,
    humanOut5mMiddaySpecificTrafficVolumeAgeGroup65MoreFemale: 0,
    humanOut5mMiddaySpecificTrafficVolumeOtherMale: 0,
    humanOut5mMiddaySpecificTrafficVolumeOtherFemale: 0,
    humanOut5mMiddaySpecificTrafficVolumeTotalCount: 0,
    humanOut5mMiddaySpecificTrafficVolumeBicycle: 0,
    humanOut5mNightTrafficVolumeSurvey: '',
    humanOut5mNightTrafficVolumeStartTime: '',
    humanOut5mNightTrafficVolumeSecondarySchoolStudents: 0,
    humanOut5mNightTrafficVolumeAgeGroup65MoreMale: 0,
    humanOut5mNightTrafficVolumeAgeGroup65MoreFemale: 0,
    humanOut5mNightTrafficVolumeOtherMale: 0,
    humanOut5mNightTrafficVolumeOtherFemale: 0,
    humanOut5mNightTrafficVolumeTotalCount: 0,
    humanOut5mNightTrafficVolumeBicycle: 0,
    humanOut5mEveningSpecificTrafficVolumeSurvey: '',
    humanOut5mEveningSpecificTrafficVolumeStartTime: '',
    humanOut5mEveningSpecificTrafficVolumeSecondarySchoolStudents: 0,
    humanOut5mEveningSpecificTrafficVolumeAgeGroup65MoreMale: 0,
    humanOut5mEveningSpecificTrafficVolumeAgeGroup65MoreFemale: 0,
    humanOut5mEveningSpecificTrafficVolumeOtherMale: 0,
    humanOut5mEveningSpecificTrafficVolumeOtherFemale: 0,
    humanOut5mEveningSpecificTrafficVolumeTotalCount: 0,
    humanOut5mEveningSpecificTrafficVolumeBicycle: 0,
    humanOut5mSaturdayHolidayResortTrafficVolumeSurvey: '',
    humanOut5mSaturdayHolidayResortTrafficVolumeStartTime: '',
    humanOut5mSaturdayHolidayResortTrafficVolumeSecondarySchoolStudents: 0,
    humanOut5mSaturdayHolidayResortTrafficVolumeAgeGroup65MoreMale: 0,
    humanOut5mSaturdayHolidayResortTrafficVolumeAgeGroup65MoreFemale: 0,
    humanOut5mSaturdayHolidayResortTrafficVolumeOtherMale: 0,
    humanOut5mSaturdayHolidayResortTrafficVolumeOtherFemale: 0,
    humanOut5mSaturdayHolidayResortTrafficVolumeTotalCount: 0,
    humanOut5mSaturdayHolidayResortTrafficVolumeBicycle: 0,
    humanOut5mTRCheckClearPath: false,
    humanOut5mTRCheckVisible: false,
    humanOut5mTRCheckStepless: false,
    humanOut5mTRCheckUnimpeded: false,
    humanInOut5mMorningTrafficVolumeSurvey: '',
    humanInOut5mMorningTrafficVolumeStartTime: '',
    humanInOut5mMorningTrafficVolumeSecondarySchoolStudents: 0,
    humanInOut5mMorningTrafficVolumeAgeGroup65MoreMale: 0,
    humanInOut5mMorningTrafficVolumeAgeGroup65MoreFemale: 0,
    humanInOut5mMorningTrafficVolumeOtherMale: 0,
    humanInOut5mMorningTrafficVolumeOtherFemale: 0,
    humanInOut5mMorningTrafficVolumeTotalCount: 0,
    humanInOut5mMorningTrafficVolumeBicycle: 0,
    humanInOut5mMorningSpecificTrafficVolumeSurvey: '',
    humanInOut5mMorningSpecificTrafficVolumeStartTime: '',
    humanInOut5mMorningSpecificTrafficVolumeSecondarySchoolStudents: 0,
    humanInOut5mMorningSpecificTrafficVolumeAgeGroup65MoreMale: 0,
    humanInOut5mMorningSpecificTrafficVolumeAgeGroup65MoreFemale: 0,
    humanInOut5mMorningSpecificTrafficVolumeOtherMale: 0,
    humanInOut5mMorningSpecificTrafficVolumeOtherFemale: 0,
    humanInOut5mMorningSpecificTrafficVolumeTotalCount: 0,
    humanInOut5mMorningSpecificTrafficVolumeBicycle: 0,
    humanInOut5mMiddayTrafficVolumeSurvey: '',
    humanInOut5mMiddayTrafficVolumeStartTime: '',
    humanInOut5mMiddayTrafficVolumeSeconbdarySchoolStudents: 0,
    humanInOut5mMiddayTrafficVolumeAgeGroup65MoreMale: 0,
    humanInOut5mMiddayTrafficVolumeAgeGroup65MoreFemale: 0,
    humanInOut5mMiddayTrafficVolumeOtherMale: 0,
    humanInOut5mMiddayTrafficVolumeOtherFemale: 0,
    humanInOut5mMiddayTrafficVolumeTotalCount: 0,
    humanInOut5mMiddayTrafficVolumeBicycle: 0,
    humanInOut5mMiddaySpecificVolumeSurvey: '',
    humanInOut5mMiddaySpecificVolumeStartTime: '',
    humanInOut5mMiddaySpecificVolumeSeconbdarySchoolStudents: 0,
    humanInOut5mMiddaySpecificVolumeAgeGroup65MoreMale: 0,
    humanInOut5mMiddaySpecificVolumeAgeGroup65MoreFemale: 0,
    humanInOut5mMiddaySpecificVolumeOtherMale: 0,
    humanInOut5mMiddaySpecificVolumeOtherFemale: 0,
    humanInOut5mMiddaySpecificVolumeTotalCount: 0,
    humanInOut5mMiddaySpecificVolumeBicycle: 0,
    humanInOut5mNightTrafficVolumeSurvey: '',
    humanInOut5mNightTrafficVolumeStartTime: '',
    humanInOut5mNightTrafficVolumeSeconbdarySchoolStudents: 0,
    humanInOut5mNightTrafficVolumeAgeGroup65MoreMale: 0,
    humanInOut5mNightTrafficVolumeAgeGroup65MoreFemale: 0,
    humanInOut5mNightTrafficVolumeOtherMale: 0,
    humanInOut5mNightTrafficVolumeOtherFemale: 0,
    humanInOut5mNightTrafficVolumeTotalCount: 0,
    humanInOut5mNightTrafficVolumeBicycle: 0,
    humanInOut5mEveningSpecificTrafficVolumeSurvey: '',
    humanInOut5mEveningSpecificTrafficVolumeStartTime: '',
    humanInOut5mEveningSpecificTrafficVolumeSecondarySchoolStudents: 0,
    humanInOut5mEveningSpecificTrafficVolumeAgeGroup65MoreMale: 0,
    humanInOut5mEveningSpecificTrafficVolumeAgeGroup65MoreFemale: 0,
    humanInOut5mEveningSpecificTrafficVolumeOtherMale: 0,
    humanInOut5mEveningSpecificTrafficVolumeOtherFemale: 0,
    humanInOut5mEveningSpecificTrafficVolumeTotalCount: 0,
    humanInOut5mEveningSpecificTrafficVolumeBicycle: 0,
    humanInOut5mSaturdayHolidayResortTrafficVolumeSurvey: '',
    humanInOut5mSaturdayHolidayResortTrafficVolumeStartTime: '',
    humanInOut5mSaturdayHolidayResortTrafficVolumeSecondarySchoolStudents: 0,
    humanInOut5mSaturdayHolidayResortTrafficVolumeAgeGroup65MoreMale: 0,
    humanInOut5mSaturdayHolidayResortTrafficVolumeAgeGroup65MoreFemale: 0,
    humanInOut5mSaturdayHolidayResortTrafficVolumeOtherMale: 0,
    humanInOut5mSaturdayHolidayResortTrafficVolumeOtherFemale: 0,
    humanInOut5mSaturdayHolidayResortTrafficVolumeTotalCount: 0,
    humanInOut5mSaturdayHolidayResortTrafficVolumeBicycle: 0,
};


//寄付きページ項目名の型定義
export type DonationTypeList = {
    drivingLanePositiveFrontage: string;
    drivingLanePositiveSideWayA: string;
    drivingLanePositiveSideWayB: string;
    drivingLanePositiveBackstreet: string;
    drivingLaneOppositeFrontage: string;
    drivingLaneOppositeSideWayA: string;
    drivingLaneOppositeSideWayB: string;
    drivingLaneOppositeBackstreet: string;
    medianStripSelect: string;
    medianStripSelect2: string;
    medianStripSelect3: string;
    medianStripSelect4: string;
    medianStripHeight: string;
    medianStripHeight2: string;
    medianStripHeight3: string;
    medianStripHeight4: string;
    sidewalkSelect: string;
    sidewalkSelect2: string;
    sidewalkSelect3: string;
    sidewalkSelect4: string;
    sidewalkHeight: string;
    sidewalkHeight2: string;
    sidewalkHeight3: string;
    sidewalkHeight4: string;
    roadWidthDoor: string;
    roadWidthDoor2: string;
    roadWidthDoor3: string;
    roadWidthDoor4: string;
    roadWidthForward: string;
    roadWidthForward2: string;
    roadWidthForward3: string;
    roadWidthForward4: string;
    roadWidthFrontLocation: string;
    roadWidthFrontLocation2: string;
    roadWidthFrontLocation3: string;
    roadWidthFrontLocation4: string;
    slopeClimb: string;
    slopeClimb2: string;
    slopeClimb3: string;
    slopeClimb4: string;
    slopeDescend: string;
    slopeDescend2: string;
    slopeDescend3: string;
    slopeDescend4: string;
    trafficSignalEtcForwardSelect: string;
    trafficSignalEtcForwardSelect2: string;
    trafficSignalEtcForwardSelect3: string;
    trafficSignalEtcForwardSelect4: string;
    trafficSignalEtcForwardDistance: string;
    trafficSignalEtcForwardDistance2: string;
    trafficSignalEtcForwardDistance3: string;
    trafficSignalEtcForwardDistance4: string;
    trafficSignalEtcFrontLocationSelect: string;
    trafficSignalEtcFrontLocationSelect2: string;
    trafficSignalEtcFrontLocationSelect3: string;
    trafficSignalEtcFrontLocationSelect4: string;
    trafficSignalEtcFrontLocationDistance: string;
    trafficSignalEtcFrontLocationDistance2: string;
    trafficSignalEtcFrontLocationDistance3: string;
    trafficSignalEtcFrontLocationDistance4: string;
    visibilityFactorBuilding: string;
    visibilityFactorBuilding2: string;
    visibilityFactorBuilding3: string;
    visibilityFactorBuilding4: string;
    visibilityFactorSignPost: string;
    visibilityFactorSignPost2: string;
    visibilityFactorSignPost3: string;
    visibilityFactorSignPost4: string;
    parkingArea: string;
    parkingtsuboArea: string;
    parkingCapacity: string;
    parkingTangentLineFrontage: string;
    parkingTangentLineSideWayA: string;
    parkingTangentLineSideWayB: string;
    parkingTangentLineBackstreet: string;
    no1EntranceFrontage: string;
    no1EntranceSideWayA: string;
    no1EntranceSideWayB: string;
    no1EntranceBackstreet: string;
    no1DepthFrontage: string;
    no1DepthSideWayA: string;
    no1DepthSideWayB: string;
    no1DepthBackstreet: string;
    no2EntranceFrontage: string;
    no2EntranceSideWayA: string;
    no2EntranceSideWayB: string;
    no2EntranceBackstreet: string;
    no2DepthFrontage: string;
    no2DepthSideWayA: string;
    no2DepthSideWayB: string;
    no2DepthBackstreet: string;
    completeSchedule: string;
    completeScheduleYear: string;
    completeScheduleMonth: string;
    [donationStringKey: string]: string; //string型でキーを指定するため
};

//寄付きページ項目名の初期値
export const initDonationObj: DonationTypeList = {
    drivingLanePositiveFrontage: '',
    drivingLanePositiveSideWayA: '',
    drivingLanePositiveSideWayB: '',
    drivingLanePositiveBackstreet: '',
    drivingLaneOppositeFrontage: '',
    drivingLaneOppositeSideWayA: '',
    drivingLaneOppositeSideWayB: '',
    drivingLaneOppositeBackstreet: '',
    medianStripSelect: '',
    medianStripSelect2: '',
    medianStripSelect3: '',
    medianStripSelect4: '',
    medianStripHeight: '',
    medianStripHeight2: '',
    medianStripHeight3: '',
    medianStripHeight4: '',
    sidewalkSelect: '',
    sidewalkSelect2: '',
    sidewalkSelect3: '',
    sidewalkSelect4: '',
    sidewalkHeight: '',
    sidewalkHeight2: '',
    sidewalkHeight3: '',
    sidewalkHeight4: '',
    roadWidthDoor: '',
    roadWidthDoor2: '',
    roadWidthDoor3: '',
    roadWidthDoor4: '',
    roadWidthForward: '',
    roadWidthForward2: '',
    roadWidthForward3: '',
    roadWidthForward4: '',
    roadWidthFrontLocation: '',
    roadWidthFrontLocation2: '',
    roadWidthFrontLocation3: '',
    roadWidthFrontLocation4: '',
    slopeClimb: '',
    slopeClimb2: '',
    slopeClimb3: '',
    slopeClimb4: '',
    slopeDescend: '',
    slopeDescend2: '',
    slopeDescend3: '',
    slopeDescend4: '',
    trafficSignalEtcForwardSelect: '',
    trafficSignalEtcForwardSelect2: '',
    trafficSignalEtcForwardSelect3: '',
    trafficSignalEtcForwardSelect4: '',
    trafficSignalEtcForwardDistance: '',
    trafficSignalEtcForwardDistance2: '',
    trafficSignalEtcForwardDistance3: '',
    trafficSignalEtcForwardDistance4: '',
    trafficSignalEtcFrontLocationSelect: '',
    trafficSignalEtcFrontLocationSelect2: '',
    trafficSignalEtcFrontLocationSelect3: '',
    trafficSignalEtcFrontLocationSelect4: '',
    trafficSignalEtcFrontLocationDistance: '',
    trafficSignalEtcFrontLocationDistance2: '',
    trafficSignalEtcFrontLocationDistance3: '',
    trafficSignalEtcFrontLocationDistance4: '',
    visibilityFactorBuilding: '',
    visibilityFactorBuilding2: '',
    visibilityFactorBuilding3: '',
    visibilityFactorBuilding4: '',
    visibilityFactorSignPost: '',
    visibilityFactorSignPost2: '',
    visibilityFactorSignPost3: '',
    visibilityFactorSignPost4: '',
    parkingArea: '',
    parkingtsuboArea: '',
    parkingCapacity: '',
    parkingTangentLineFrontage: '',
    parkingTangentLineSideWayA: '',
    parkingTangentLineSideWayB: '',
    parkingTangentLineBackstreet: '',
    no1EntranceFrontage: '',
    no1EntranceSideWayA: '',
    no1EntranceSideWayB: '',
    no1EntranceBackstreet: '',
    no1DepthFrontage: '',
    no1DepthSideWayA: '',
    no1DepthSideWayB: '',
    no1DepthBackstreet: '',
    no2EntranceFrontage: '',
    no2EntranceSideWayA: '',
    no2EntranceSideWayB: '',
    no2EntranceBackstreet: '',
    no2DepthFrontage: '',
    no2DepthSideWayA: '',
    no2DepthSideWayB: '',
    no2DepthBackstreet: '',
    completeSchedule: '',
    completeScheduleYear: '',
    completeScheduleMonth: '',
};

//寄付きページのboolean型の定義
export type DonationBooleanTypeList = {
    drivingLanePositiveFrontage: boolean;
    drivingLanePositiveSideWayA: boolean;
    drivingLanePositiveSideWayB: boolean;
    drivingLanePositiveBackstreet: boolean;
    drivingLaneOppositeFrontage: boolean;
    drivingLaneOppositeSideWayA: boolean;
    drivingLaneOppositeSideWayB: boolean;
    drivingLaneOppositeBackstreet: boolean;
    medianStripSelect: boolean;
    medianStripSelect2: boolean;
    medianStripSelect3: boolean;
    medianStripSelect4: boolean;
    medianStripHeight: boolean;
    medianStripHeight2: boolean;
    medianStripHeight3: boolean;
    medianStripHeight4: boolean;
    sidewalkSelect: boolean;
    sidewalkSelect2: boolean;
    sidewalkSelect3: boolean;
    sidewalkSelect4: boolean;
    sidewalkHeight: boolean;
    sidewalkHeight2: boolean;
    sidewalkHeight3: boolean;
    sidewalkHeight4: boolean;
    roadWidthDoor: boolean;
    roadWidthDoor2: boolean;
    roadWidthDoor3: boolean;
    roadWidthDoor4: boolean;
    roadWidthForward: boolean;
    roadWidthForward2: boolean;
    roadWidthForward3: boolean;
    roadWidthForward4: boolean;
    roadWidthFrontLocation: boolean;
    roadWidthFrontLocation2: boolean;
    roadWidthFrontLocation3: boolean;
    roadWidthFrontLocation4: boolean;
    slopeClimb: boolean;
    slopeClimb2: boolean;
    slopeClimb3: boolean;
    slopeClimb4: boolean;
    slopeDescend: boolean;
    slopeDescend2: boolean;
    slopeDescend3: boolean;
    slopeDescend4: boolean;
    trafficSignalEtcForwardSelect: boolean;
    trafficSignalEtcForwardSelect2: boolean;
    trafficSignalEtcForwardSelect3: boolean;
    trafficSignalEtcForwardSelect4: boolean;
    trafficSignalEtcForwardDistance: boolean;
    trafficSignalEtcForwardDistance2: boolean;
    trafficSignalEtcForwardDistance3: boolean;
    trafficSignalEtcForwardDistance4: boolean;
    trafficSignalEtcFrontLocationSelect: boolean;
    trafficSignalEtcFrontLocationSelect2: boolean;
    trafficSignalEtcFrontLocationSelect3: boolean;
    trafficSignalEtcFrontLocationSelect4: boolean;
    trafficSignalEtcFrontLocationDistance: boolean;
    trafficSignalEtcFrontLocationDistance2: boolean;
    trafficSignalEtcFrontLocationDistance3: boolean;
    trafficSignalEtcFrontLocationDistance4: boolean;
    visibilityFactorBuilding: boolean;
    visibilityFactorBuilding2: boolean;
    visibilityFactorBuilding3: boolean;
    visibilityFactorBuilding4: boolean;
    visibilityFactorSignPost: boolean;
    visibilityFactorSignPost2: boolean;
    visibilityFactorSignPost3: boolean;
    visibilityFactorSignPost4: boolean;
    parkingArea: boolean;
    parkingtsuboArea: boolean;
    parkingCapacity: boolean;
    parkingTangentLineFrontage: boolean;
    parkingTangentLineSideWayA: boolean;
    parkingTangentLineSideWayB: boolean;
    parkingTangentLineBackstreet: boolean;
    no1EntranceFrontage: boolean;
    no1EntranceSideWayA: boolean;
    no1EntranceSideWayB: boolean;
    no1EntranceBackstreet: boolean;
    no1DepthFrontage: boolean;
    no1DepthSideWayA: boolean;
    no1DepthSideWayB: boolean;
    no1DepthBackstreet: boolean;
    no2EntranceFrontage: boolean;
    no2EntranceSideWayA: boolean;
    no2EntranceSideWayB: boolean;
    no2EntranceBackstreet: boolean;
    no2DepthFrontage: boolean;
    no2DepthSideWayA: boolean;
    no2DepthSideWayB: boolean;
    no2DepthBackstreet: boolean;
    completeSchedule: boolean;
    [donationBooleanKey: string]: boolean; //string型でキーを指定するため
};

//寄付きページのアイコン全体の表示非表示のステータスの初期値
export const initDonationShowIconObj: DonationBooleanTypeList = {
    drivingLanePositiveFrontage: false,
    drivingLanePositiveSideWayA: false,
    drivingLanePositiveSideWayB: false,
    drivingLanePositiveBackstreet: false,
    drivingLaneOppositeFrontage: false,
    drivingLaneOppositeSideWayA: false,
    drivingLaneOppositeSideWayB: false,
    drivingLaneOppositeBackstreet: false,
    medianStripSelect: false,
    medianStripSelect2: false,
    medianStripSelect3: false,
    medianStripSelect4: false,
    medianStripHeight: false,
    medianStripHeight2: false,
    medianStripHeight3: false,
    medianStripHeight4: false,
    sidewalkSelect: false,
    sidewalkSelect2: false,
    sidewalkSelect3: false,
    sidewalkSelect4: false,
    sidewalkHeight: false,
    sidewalkHeight2: false,
    sidewalkHeight3: false,
    sidewalkHeight4: false,
    roadWidthDoor: false,
    roadWidthDoor2: false,
    roadWidthDoor3: false,
    roadWidthDoor4: false,
    roadWidthForward: false,
    roadWidthForward2: false,
    roadWidthForward3: false,
    roadWidthForward4: false,
    roadWidthFrontLocation: false,
    roadWidthFrontLocation2: false,
    roadWidthFrontLocation3: false,
    roadWidthFrontLocation4: false,
    slopeClimb: false,
    slopeClimb2: false,
    slopeClimb3: false,
    slopeClimb4: false,
    slopeDescend: false,
    slopeDescend2: false,
    slopeDescend3: false,
    slopeDescend4: false,
    trafficSignalEtcForwardSelect: false,
    trafficSignalEtcForwardSelect2: false,
    trafficSignalEtcForwardSelect3: false,
    trafficSignalEtcForwardSelect4: false,
    trafficSignalEtcForwardDistance: false,
    trafficSignalEtcForwardDistance2: false,
    trafficSignalEtcForwardDistance3: false,
    trafficSignalEtcForwardDistance4: false,
    trafficSignalEtcFrontLocationSelect: false,
    trafficSignalEtcFrontLocationSelect2: false,
    trafficSignalEtcFrontLocationSelect3: false,
    trafficSignalEtcFrontLocationSelect4: false,
    trafficSignalEtcFrontLocationDistance: false,
    trafficSignalEtcFrontLocationDistance2: false,
    trafficSignalEtcFrontLocationDistance3: false,
    trafficSignalEtcFrontLocationDistance4: false,
    visibilityFactorBuilding: false,
    visibilityFactorBuilding2: false,
    visibilityFactorBuilding3: false,
    visibilityFactorBuilding4: false,
    visibilityFactorSignPost: false,
    visibilityFactorSignPost2: false,
    visibilityFactorSignPost3: false,
    visibilityFactorSignPost4: false,
    parkingArea: false,
    parkingtsuboArea: false,
    parkingCapacity: false,
    parkingTangentLineFrontage: false,
    parkingTangentLineSideWayA: false,
    parkingTangentLineSideWayB: false,
    parkingTangentLineBackstreet: false,
    no1EntranceFrontage: false,
    no1EntranceSideWayA: false,
    no1EntranceSideWayB: false,
    no1EntranceBackstreet: false,
    no1DepthFrontage: false,
    no1DepthSideWayA: false,
    no1DepthSideWayB: false,
    no1DepthBackstreet: false,
    no2EntranceFrontage: false,
    no2EntranceSideWayA: false,
    no2EntranceSideWayB: false,
    no2EntranceBackstreet: false,
    no2DepthFrontage: false,
    no2DepthSideWayA: false,
    no2DepthSideWayB: false,
    no2DepthBackstreet: false,
    completeSchedule: false,
};

//寄付きページの鉛筆アイコンの表示非表示のステータスの初期値 trueが表示、falseが非表示
export const initDonationShowEditIconObj: DonationBooleanTypeList = {
    drivingLanePositiveFrontage: true,
    drivingLanePositiveSideWayA: true,
    drivingLanePositiveSideWayB: true,
    drivingLanePositiveBackstreet: true,
    drivingLaneOppositeFrontage: true,
    drivingLaneOppositeSideWayA: true,
    drivingLaneOppositeSideWayB: true,
    drivingLaneOppositeBackstreet: true,
    medianStripSelect: true,
    medianStripSelect2: true,
    medianStripSelect3: true,
    medianStripSelect4: true,
    medianStripHeight: true,
    medianStripHeight2: true,
    medianStripHeight3: true,
    medianStripHeight4: true,
    sidewalkSelect: true,
    sidewalkSelect2: true,
    sidewalkSelect3: true,
    sidewalkSelect4: true,
    sidewalkHeight: true,
    sidewalkHeight2: true,
    sidewalkHeight3: true,
    sidewalkHeight4: true,
    roadWidthDoor: true,
    roadWidthDoor2: true,
    roadWidthDoor3: true,
    roadWidthDoor4: true,
    roadWidthForward: true,
    roadWidthForward2: true,
    roadWidthForward3: true,
    roadWidthForward4: true,
    roadWidthFrontLocation: true,
    roadWidthFrontLocation2: true,
    roadWidthFrontLocation3: true,
    roadWidthFrontLocation4: true,
    slopeClimb: true,
    slopeClimb2: true,
    slopeClimb3: true,
    slopeClimb4: true,
    slopeDescend: true,
    slopeDescend2: true,
    slopeDescend3: true,
    slopeDescend4: true,
    trafficSignalEtcForwardSelect: true,
    trafficSignalEtcForwardSelect2: true,
    trafficSignalEtcForwardSelect3: true,
    trafficSignalEtcForwardSelect4: true,
    trafficSignalEtcForwardDistance: true,
    trafficSignalEtcForwardDistance2: true,
    trafficSignalEtcForwardDistance3: true,
    trafficSignalEtcForwardDistance4: true,
    trafficSignalEtcFrontLocationSelect: true,
    trafficSignalEtcFrontLocationSelect2: true,
    trafficSignalEtcFrontLocationSelect3: true,
    trafficSignalEtcFrontLocationSelect4: true,
    trafficSignalEtcFrontLocationDistance: true,
    trafficSignalEtcFrontLocationDistance2: true,
    trafficSignalEtcFrontLocationDistance3: true,
    trafficSignalEtcFrontLocationDistance4: true,
    visibilityFactorBuilding: true,
    visibilityFactorBuilding2: true,
    visibilityFactorBuilding3: true,
    visibilityFactorBuilding4: true,
    visibilityFactorSignPost: true,
    visibilityFactorSignPost2: true,
    visibilityFactorSignPost3: true,
    visibilityFactorSignPost4: true,
    parkingArea: true,
    parkingtsuboArea: false,
    parkingCapacity: true,
    parkingTangentLineFrontage: true,
    parkingTangentLineSideWayA: true,
    parkingTangentLineSideWayB: true,
    parkingTangentLineBackstreet: true,
    no1EntranceFrontage: true,
    no1EntranceSideWayA: true,
    no1EntranceSideWayB: true,
    no1EntranceBackstreet: true,
    no1DepthFrontage: true,
    no1DepthSideWayA: true,
    no1DepthSideWayB: true,
    no1DepthBackstreet: true,
    no2EntranceFrontage: true,
    no2EntranceSideWayA: true,
    no2EntranceSideWayB: true,
    no2EntranceBackstreet: true,
    no2DepthFrontage: true,
    no2DepthSideWayA: true,
    no2DepthSideWayB: true,
    no2DepthBackstreet: true,
    completeSchedule: true,
};

//寄付きページのstring型の定義
export type DonationStringTypeList = {
    drivingLanePositiveFrontage: string;
    drivingLanePositiveSideWayA: string;
    drivingLanePositiveSideWayB: string;
    drivingLanePositiveBackstreet: string;
    drivingLaneOppositeFrontage: string;
    drivingLaneOppositeSideWayA: string;
    drivingLaneOppositeSideWayB: string;
    drivingLaneOppositeBackstreet: string;
    medianStripSelect: string;
    medianStripSelect2: string;
    medianStripSelect3: string;
    medianStripSelect4: string;
    medianStripHeight: string;
    medianStripHeight2: string;
    medianStripHeight3: string;
    medianStripHeight4: string;
    sidewalkSelect: string;
    sidewalkSelect2: string;
    sidewalkSelect3: string;
    sidewalkSelect4: string;
    sidewalkHeight: string;
    sidewalkHeight2: string;
    sidewalkHeight3: string;
    sidewalkHeight4: string;
    roadWidthDoor: string;
    roadWidthDoor2: string;
    roadWidthDoor3: string;
    roadWidthDoor4: string;
    roadWidthForward: string;
    roadWidthForward2: string;
    roadWidthForward3: string;
    roadWidthForward4: string;
    roadWidthFrontLocation: string;
    roadWidthFrontLocation2: string;
    roadWidthFrontLocation3: string;
    roadWidthFrontLocation4: string;
    slopeClimb: string;
    slopeClimb2: string;
    slopeClimb3: string;
    slopeClimb4: string;
    slopeDescend: string;
    slopeDescend2: string;
    slopeDescend3: string;
    slopeDescend4: string;
    trafficSignalEtcForwardSelect: string;
    trafficSignalEtcForwardSelect2: string;
    trafficSignalEtcForwardSelect3: string;
    trafficSignalEtcForwardSelect4: string;
    trafficSignalEtcForwardDistance: string;
    trafficSignalEtcForwardDistance2: string;
    trafficSignalEtcForwardDistance3: string;
    trafficSignalEtcForwardDistance4: string;
    trafficSignalEtcFrontLocationSelect: string;
    trafficSignalEtcFrontLocationSelect2: string;
    trafficSignalEtcFrontLocationSelect3: string;
    trafficSignalEtcFrontLocationSelect4: string;
    trafficSignalEtcFrontLocationDistance: string;
    trafficSignalEtcFrontLocationDistance2: string;
    trafficSignalEtcFrontLocationDistance3: string;
    trafficSignalEtcFrontLocationDistance4: string;
    visibilityFactorBuilding: string;
    visibilityFactorBuilding2: string;
    visibilityFactorBuilding3: string;
    visibilityFactorBuilding4: string;
    visibilityFactorSignPost: string;
    visibilityFactorSignPost2: string;
    visibilityFactorSignPost3: string;
    visibilityFactorSignPost4: string;
    parkingArea: string;
    parkingtsuboArea: string;
    parkingCapacity: string;
    parkingTangentLineFrontage: string;
    parkingTangentLineSideWayA: string;
    parkingTangentLineSideWayB: string;
    parkingTangentLineBackstreet: string;
    no1EntranceFrontage: string;
    no1EntranceSideWayA: string;
    no1EntranceSideWayB: string;
    no1EntranceBackstreet: string;
    no1DepthFrontage: string;
    no1DepthSideWayA: string;
    no1DepthSideWayB: string;
    no1DepthBackstreet: string;
    no2EntranceFrontage: string;
    no2EntranceSideWayA: string;
    no2EntranceSideWayB: string;
    no2EntranceBackstreet: string;
    no2DepthFrontage: string;
    no2DepthSideWayA: string;
    no2DepthSideWayB: string;
    no2DepthBackstreet: string;
    completeSchedule: string;
    [donationStringKey: string]: string; //string型でキーを指定するため
};

//寄付きページのモーダル内のテキストフィールドの初期値
export const initDonationCommentTextFieldObj: DonationStringTypeList = {
    drivingLanePositiveFrontage: '',
    drivingLanePositiveSideWayA: '',
    drivingLanePositiveSideWayB: '',
    drivingLanePositiveBackstreet: '',
    drivingLaneOppositeFrontage: '',
    drivingLaneOppositeSideWayA: '',
    drivingLaneOppositeSideWayB: '',
    drivingLaneOppositeBackstreet: '',
    medianStripSelect: '',
    medianStripSelect2: '',
    medianStripSelect3: '',
    medianStripSelect4: '',
    medianStripHeight: '',
    medianStripHeight2: '',
    medianStripHeight3: '',
    medianStripHeight4: '',
    sidewalkSelect: '',
    sidewalkSelect2: '',
    sidewalkSelect3: '',
    sidewalkSelect4: '',
    sidewalkHeight: '',
    sidewalkHeight2: '',
    sidewalkHeight3: '',
    sidewalkHeight4: '',
    roadWidthDoor: '',
    roadWidthDoor2: '',
    roadWidthDoor3: '',
    roadWidthDoor4: '',
    roadWidthForward: '',
    roadWidthForward2: '',
    roadWidthForward3: '',
    roadWidthForward4: '',
    roadWidthFrontLocation: '',
    roadWidthFrontLocation2: '',
    roadWidthFrontLocation3: '',
    roadWidthFrontLocation4: '',
    slopeClimb: '',
    slopeClimb2: '',
    slopeClimb3: '',
    slopeClimb4: '',
    slopeDescend: '',
    slopeDescend2: '',
    slopeDescend3: '',
    slopeDescend4: '',
    trafficSignalEtcForwardSelect: '',
    trafficSignalEtcForwardSelect2: '',
    trafficSignalEtcForwardSelect3: '',
    trafficSignalEtcForwardSelect4: '',
    trafficSignalEtcForwardDistance: '',
    trafficSignalEtcForwardDistance2: '',
    trafficSignalEtcForwardDistance3: '',
    trafficSignalEtcForwardDistance4: '',
    trafficSignalEtcFrontLocationSelect: '',
    trafficSignalEtcFrontLocationSelect2: '',
    trafficSignalEtcFrontLocationSelect3: '',
    trafficSignalEtcFrontLocationSelect4: '',
    trafficSignalEtcFrontLocationDistance: '',
    trafficSignalEtcFrontLocationDistance2: '',
    trafficSignalEtcFrontLocationDistance3: '',
    trafficSignalEtcFrontLocationDistance4: '',
    visibilityFactorBuilding: '',
    visibilityFactorBuilding2: '',
    visibilityFactorBuilding3: '',
    visibilityFactorBuilding4: '',
    visibilityFactorSignPost: '',
    visibilityFactorSignPost2: '',
    visibilityFactorSignPost3: '',
    visibilityFactorSignPost4: '',
    parkingArea: '',
    parkingtsuboArea: '',
    parkingCapacity: '',
    parkingTangentLineFrontage: '',
    parkingTangentLineSideWayA: '',
    parkingTangentLineSideWayB: '',
    parkingTangentLineBackstreet: '',
    no1EntranceFrontage: '',
    no1EntranceSideWayA: '',
    no1EntranceSideWayB: '',
    no1EntranceBackstreet: '',
    no1DepthFrontage: '',
    no1DepthSideWayA: '',
    no1DepthSideWayB: '',
    no1DepthBackstreet: '',
    no2EntranceFrontage: '',
    no2EntranceSideWayA: '',
    no2EntranceSideWayB: '',
    no2EntranceBackstreet: '',
    no2DepthFrontage: '',
    no2DepthSideWayA: '',
    no2DepthSideWayB: '',
    no2DepthBackstreet: '',
    completeSchedule: '',
};

//寄付きページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initDonationReadOnlyObj: DonationBooleanTypeList = {
    drivingLanePositiveFrontage: true,
    drivingLanePositiveSideWayA: true,
    drivingLanePositiveSideWayB: true,
    drivingLanePositiveBackstreet: true,
    drivingLaneOppositeFrontage: true,
    drivingLaneOppositeSideWayA: true,
    drivingLaneOppositeSideWayB: true,
    drivingLaneOppositeBackstreet: true,
    medianStripSelect: true,
    medianStripSelect2: true,
    medianStripSelect3: true,
    medianStripSelect4: true,
    medianStripHeight: true,
    medianStripHeight2: true,
    medianStripHeight3: true,
    medianStripHeight4: true,
    sidewalkSelect: true,
    sidewalkSelect2: true,
    sidewalkSelect3: true,
    sidewalkSelect4: true,
    sidewalkHeight: true,
    sidewalkHeight2: true,
    sidewalkHeight3: true,
    sidewalkHeight4: true,
    roadWidthDoor: true,
    roadWidthDoor2: true,
    roadWidthDoor3: true,
    roadWidthDoor4: true,
    roadWidthForward: true,
    roadWidthForward2: true,
    roadWidthForward3: true,
    roadWidthForward4: true,
    roadWidthFrontLocation: true,
    roadWidthFrontLocation2: true,
    roadWidthFrontLocation3: true,
    roadWidthFrontLocation4: true,
    slopeClimb: true,
    slopeClimb2: true,
    slopeClimb3: true,
    slopeClimb4: true,
    slopeDescend: true,
    slopeDescend2: true,
    slopeDescend3: true,
    slopeDescend4: true,
    trafficSignalEtcForwardSelect: true,
    trafficSignalEtcForwardSelect2: true,
    trafficSignalEtcForwardSelect3: true,
    trafficSignalEtcForwardSelect4: true,
    trafficSignalEtcForwardDistance: true,
    trafficSignalEtcForwardDistance2: true,
    trafficSignalEtcForwardDistance3: true,
    trafficSignalEtcForwardDistance4: true,
    trafficSignalEtcFrontLocationSelect: true,
    trafficSignalEtcFrontLocationSelect2: true,
    trafficSignalEtcFrontLocationSelect3: true,
    trafficSignalEtcFrontLocationSelect4: true,
    trafficSignalEtcFrontLocationDistance: true,
    trafficSignalEtcFrontLocationDistance2: true,
    trafficSignalEtcFrontLocationDistance3: true,
    trafficSignalEtcFrontLocationDistance4: true,
    visibilityFactorBuilding: true,
    visibilityFactorBuilding2: true,
    visibilityFactorBuilding3: true,
    visibilityFactorBuilding4: true,
    visibilityFactorSignPost: true,
    visibilityFactorSignPost2: true,
    visibilityFactorSignPost3: true,
    visibilityFactorSignPost4: true,
    parkingArea: true,
    parkingtsuboArea: true,
    parkingCapacity: true,
    parkingTangentLineFrontage: true,
    parkingTangentLineSideWayA: true,
    parkingTangentLineSideWayB: true,
    parkingTangentLineBackstreet: true,
    no1EntranceFrontage: true,
    no1EntranceSideWayA: true,
    no1EntranceSideWayB: true,
    no1EntranceBackstreet: true,
    no1DepthFrontage: true,
    no1DepthSideWayA: true,
    no1DepthSideWayB: true,
    no1DepthBackstreet: true,
    no2EntranceFrontage: true,
    no2EntranceSideWayA: true,
    no2EntranceSideWayB: true,
    no2EntranceBackstreet: true,
    no2DepthFrontage: true,
    no2DepthSideWayA: true,
    no2DepthSideWayB: true,
    no2DepthBackstreet: true,
    completeSchedule: true,
};


export type SalesForecastSummaryMainElementListObjTypeList = {
    pdfPath: string;
    temp_sales_forecast_path: string;
    temp_sales_forecast_upload_date: string;
    temp_sales_forecast_upload_user: string;
    temp_sales_forecast_upload_mail: string;
    sales_forecast_path: string;
    sales_forecast_upload_date: string;
    sales_forecast_upload_user: string;
    sales_forecast_upload_mail: string;
}
export const initSalesForecastSummaryMainElementListObj: SalesForecastSummaryMainElementListObjTypeList = {
    pdfPath: '',
    temp_sales_forecast_path: '',
    temp_sales_forecast_upload_date: '',
    temp_sales_forecast_upload_user: '',
    temp_sales_forecast_upload_mail: '',
    sales_forecast_path: '',
    sales_forecast_upload_date: '',
    sales_forecast_upload_user: '',
    sales_forecast_upload_mail: '',
}

export type PdfSummaryObjTypeList = {
    pdfPath: string;
}
export const initPdfSummaryObjTypeList: PdfSummaryObjTypeList = {
    pdfPath: '',
}


export type AddConflictObjTypeList = {
    competitiveTarget: string;
    walkWithInCommercialArea: string;
    chainName: string;
    storeName: string;
    prefectures: string;
    address: string;
    businessType: string;
    businessHours: string;
    closingTime: string;
    liquorLicense: string;
    tobaccoLicense: string;
    location: string;
    salesFloorArea: string;
    gondolaScale: string;
    parkingAvailable: string;
    storeFrontage: string;
    parkingArea: string;
    numberOfParkingSpaces: string;
    frontParkingLotTangent: string;
    frontFirstEntrance: string;
    frontFirstDepth: string;
    frontSecondEntrance: string;
    frontSecondDepth: string;
    typeOfSideRoad: string;
    sideRoadParkingLotTangent: string;
    sideRoadFirstEntrance: string;
    sideRoadFirstDepth: string;
    sideRoadSecondEntrance: string;
    sideRoadSecondDepth: string;
    estimatedDailSsales: string;
    directDistance: string;
    roadDistance: string;
    commonRoadInFrontOfCandidate: string;
    direction: string;
    commonRoadBeforeCompetition: string;
    presenceOfCommonRoadSeparationStripBeforeCompetition: string;
    leftRightTurnIndicator: string;
    straightGauge: string;
    roadShape: google.maps.Polyline | null;
}

export const initAddConflictObj: AddConflictObjTypeList = {
    competitiveTarget: '',                              // 競合対象
    walkWithInCommercialArea: '',                       // 徒歩5分商圏内外
    chainName: '',                                      // チェーン名
    storeName: '',                                      // 店名
    prefectures: '',                                    // 都道府県
    address: '',                                        // 住所
    businessType: '',                                   // 業態
    businessHours: '',                                  // 営業時間
    closingTime: '',                                    // 閉店時間
    liquorLicense: '無',                                 // 酒免許
    tobaccoLicense: '無',                                // 煙草免許
    location: '',                                       // 立地
    salesFloorArea: '',                                 // 売場面積(㎡)
    gondolaScale: '',                                   // ゴンドラ尺数
    parkingAvailable: '無',                              // 駐車場有無
    storeFrontage: '',                                  // 店舗間口
    parkingArea: '',                                    // 駐車場面積(㎡)
    numberOfParkingSpaces: '',                          // 駐車場台数
    frontParkingLotTangent: '',                         // 前面駐車場接線(m)
    frontFirstEntrance: '',                             // 前面第一進入口(m)
    frontFirstDepth: '',                                // 前面第一奥行き(m)
    frontSecondEntrance: '',                            // 前面第二進入口(m)
    frontSecondDepth: '',                               // 前面第二奥行き(m)
    typeOfSideRoad: '',                                 // 側道有無種類
    sideRoadParkingLotTangent: '',                      // 側道(裏道)駐車場接線(m)
    sideRoadFirstEntrance: '',                          // 側道(裏道)第一進入口(m)
    sideRoadFirstDepth: '',                             // 側道(裏道)第一奥行き(m)
    sideRoadSecondEntrance: '',                         // 側道第二進入口(m)
    sideRoadSecondDepth: '',                            // 側道第二奥行き(m)
    estimatedDailSsales: '',                            // 推定日販(千円)
    directDistance: '',                                 // 直線距離(m)
    roadDistance: '',                                   // 道なり距離(m)
    commonRoadInFrontOfCandidate: '',                   // 候補前共通道路
    direction: '',                                      // 方向
    commonRoadBeforeCompetition: '',                    // 競合前共通道路
    presenceOfCommonRoadSeparationStripBeforeCompetition: '',  // 競合前共通道路分離帯有無
    leftRightTurnIndicator: '',                         // 右左折計
    straightGauge: '',                                  // 直進計
    roadShape: null                                     // 道なり距離形状
}


//地図画面 プレビューpdfパス 型定義
export type mapPdfObjTypeList = {
    pdfPath: string;
};

//地図画面 プレビューpdfパス 初期値
export const initMapPdfObj: mapPdfObjTypeList = {
    pdfPath: '',
};

export type appHeaderObjTypeList = {
    planAreaId: string,
    planAreaName: string,
    citycode: string,
    centerlat: string,
    centerlon: string
};

export const initAddHeaderObj: appHeaderObjTypeList = {
    planAreaId: "",
    planAreaName: "",
    citycode: "",
    centerlat: "",
    centerlon: ""
};

export type longPageParamTypeList = {
    planAreaId: string,
    branch: string
};

export const initLongPageParamObj: longPageParamTypeList = {
    planAreaId: "",
    branch: "",
};

export type longPageSaveStatusTypeList = {
    LongPage: boolean,
    AddSurvey: boolean,
    Cover: boolean,
    StoreOpeningConditions: boolean,
    TradeArea: boolean,
    Picture: boolean,
    Donation: boolean,
    TrafficVolume: boolean,
    Conflict: boolean,
    ConflictMarketCut: boolean,
    Impact: boolean,
    Map: boolean,
    Drawing: boolean
};

export const initLongPageSaveStatusObj: longPageSaveStatusTypeList = {
    LongPage: false,
    AddSurvey: false,
    Cover: false,
    StoreOpeningConditions: false,
    TradeArea: false,
    Picture: false,
    Donation: false,
    TrafficVolume: false,
    Conflict: false,
    ConflictMarketCut: false,
    Impact: false,
    Map: false,
    Drawing: false
};

export type UnitEditTypeList = {
    TrafficType0: boolean;          //車 朝
    TrafficType1: boolean;          //車 昼
    TrafficType2: boolean;          //車 夜
    TrafficType4: boolean;          //車 土曜、休祝日又は行楽地のトップシーズン
    TrafficType6In5m: boolean;      //人 5m内 朝
    TrafficType7In5m: boolean;      //人 5m内 朝特定TR
    TrafficType8In5m: boolean;      //人 5m内 昼
    TrafficType9In5m: boolean;      //人 5m内 昼特定TR
    TrafficType10In5m: boolean;     //人 5m内 夜
    TrafficType12In5m: boolean;     //人 5m内 夕特定TR
    TrafficType13In5m: boolean;     //人 5m内 土曜、休祝日又は行楽地のトップシーズン
    TrafficType6Out5m: boolean;     //人 5m外 朝
    TrafficType7Out5m: boolean;     //人 5m外 朝特定TR
    TrafficType8Out5m: boolean;     //人 5m外 昼
    TrafficType9Out5m: boolean;     //人 5m外 昼特定TR
    TrafficType10Out5m: boolean;    //人 5m外 夜
    TrafficType12Out5m: boolean;    //人 5m外 夕特定TR
    TrafficType13Out5m: boolean;    //人 5m外 土曜、休祝日又は行楽地のトップシーズン
    ConflictCVSOwn: boolean;        //競合CVS 自店
    ConflictCVS: boolean;           //競合CVS 競合店
    ConflictSM: boolean;            //競合SM 競合
};

export const initUnitEditObj: UnitEditTypeList = {
    TrafficType0: false,          //車 朝
    TrafficType1: false,          //車 昼
    TrafficType2: false,          //車 夜
    TrafficType4: false,          //車 土曜、休祝日又は行楽地のトップシーズン
    TrafficType6In5m: false,      //人 5m内 朝
    TrafficType7In5m: false,      //人 5m内 朝特定TR
    TrafficType8In5m: false,      //人 5m内 昼
    TrafficType9In5m: false,      //人 5m内 昼特定TR
    TrafficType10In5m: false,     //人 5m内 夜
    TrafficType12In5m: false,     //人 5m内 夕特定TR
    TrafficType13In5m: false,     //人 5m内 土曜、休祝日又は行楽地のトップシーズン
    TrafficType6Out5m: false,     //人 5m外 朝
    TrafficType7Out5m: false,     //人 5m外 朝特定TR
    TrafficType8Out5m: false,     //人 5m外 昼
    TrafficType9Out5m: false,     //人 5m外 昼特定TR
    TrafficType10Out5m: false,    //人 5m外 夜
    TrafficType12Out5m: false,    //人 5m外 夕特定TR
    TrafficType13Out5m: false,    //人 5m外 土曜、休祝日又は行楽地のトップシーズン
    ConflictCVSOwn: false,        //競合CVS 自店
    ConflictCVS: false,           //競合CVS 競合店
    ConflictSM: false             //競合SM 競合
};

export type locationBaseTypeList = {
    plan_area_id: string,
    branch: string,
    location_type: string,
    location_excel_flag: string,
    location_excel_sequence: string,
    child_location: string,
    parent_location: string,
    temp_sales_forecast_path: string,
    temp_sales_forecast_upload_user: string,
    temp_sales_forecast_upload_mail: string,
    sales_forecast_path: string,
    sales_forecast_upload_user: string,
    sales_forecast_upload_mail: string,
    shp_flag: string,
    task_id: string,
    status_id: string,
    status_seq: any,
    sb_document_excel_path: string,
    sb_document_pdf_path: string,
    other_document_excel_path: any[],
    other_document_pdf_path: any[],
    rr_document_pdf_path: any[],
    map_excel_path: string,
    map_excel_user: string,
    map_pdf_path: string,
    map_pdf_user: string,
    impact_judge_pdf_path: string,
    impact_judge_pdf_user: string,
    daily_sales_year_1: string,
    daily_sales_year_2: string,
    daily_sales_year_3: string,
    daily_sales_year_4: string,
    daily_sales_year_5: string,
    impact_store_name_1: string,
    impact_store_1: string,
    impact_store_name_2: string,
    impact_store_2: string,
    impact_store_name_3: string,
    impact_store_3: string,
    impact_store_name_4: string,
    impact_store_4: string
};

export const initLocationBaseObj: locationBaseTypeList = {
    plan_area_id: "",
    branch: "",
    location_type: "",
    location_excel_flag: "",
    location_excel_sequence: "0",
    child_location: "",
    parent_location: "",
    temp_sales_forecast_path: "",
    temp_sales_forecast_upload_user: "",
    temp_sales_forecast_upload_mail: "",
    sales_forecast_path: "",
    sales_forecast_upload_user: "",
    sales_forecast_upload_mail: "",
    shp_flag: "",
    task_id: "",
    status_id: "",
    status_seq: 0,
    sb_document_excel_path: "",
    sb_document_pdf_path: "",
    other_document_excel_path: [],
    other_document_pdf_path: [],
    rr_document_pdf_path: [],
    map_excel_path: "",
    map_excel_user: "",
    map_pdf_path: "",
    map_pdf_user: "",
    impact_judge_pdf_path: "",
    impact_judge_pdf_user: "",
    daily_sales_year_1: "",
    daily_sales_year_2: "",
    daily_sales_year_3: "",
    daily_sales_year_4: "",
    daily_sales_year_5: "",
    impact_store_name_1: "",
    impact_store_1: "",
    impact_store_name_2: "",
    impact_store_2: "",
    impact_store_name_3: "",
    impact_store_3: "",
    impact_store_name_4: "",
    impact_store_4: ""
};

export type portalObjTypeList = {
    conversionCreateDate: string,   //依頼受付日
    statusId: string[],             //ステータス
    picType: string[],              //担当依頼
    picStaffNumber: string[],       //依頼担当者
    taskId: string[],               //依頼タスク
    planAreaId: string,             //候補地No
    departmentCode: string[],       //部
    districtCode: string[],         //地区
    planAreaName: string,           //候補地名
    planAreaRfc: string[],             //RFC
    statusValues: any[],            //「ステータス」項目配列保持用
    picTypeValues: any[],           //「担当依頼」項目配列保持用
    taskValues: any[],              //「依頼タスク」配列保持用
    picNameValues: any[],           //「依頼担当者」配列保持用
    departmentValues: any[],        //「部」配列保持用
    districtValues: any[],          //「地区」配列保持用
    localStorageFlag: boolean       //ローカルストレージの更新判定フラグ
}

export const initPortalObj: portalObjTypeList = {
    conversionCreateDate: "", //依頼受付日
    statusId: [],             //ステータス
    picType: [],              //担当依頼
    picStaffNumber: [],       //依頼担当者
    taskId: [],               //依頼タスク
    planAreaId: "",           //候補地No
    departmentCode: [],       //部
    districtCode: [],         //地区
    planAreaName: "",         //候補地名
    planAreaRfc: [],          //RFC
    statusValues: [],         //「ステータス」項目配列保持用
    picTypeValues: [],        //「担当依頼」項目配列保持用
    taskValues: [],           //「依頼タスク」配列保持用
    picNameValues: [],        //「依頼担当者」配列保持用
    departmentValues: [],     //「部」配列保持用
    districtValues: [],       //「地区」配列保持用
    localStorageFlag: false   //ローカルストレージの更新フラグ
}

//図面ページ項目名の型定義
export type DrawingTypeList = {
    uploadFilePaths: any[];
    isGreenGem: boolean;
    isGrave: boolean;
    isMedianStrip: boolean;
    isBusStopBlackout: boolean;
    isInGreenGem: boolean;
    isOtherTenantParking: boolean;
    isExpresswayEtcPole: boolean;
    isBusLaneEtc: boolean;
    isElectricityPole: boolean;
    isSidewalk: boolean;
    isOneWayStreet: boolean;
    isTramTrack: boolean;
    isElectricityPoleBranchLine: boolean;
    isCrosswalk: boolean;
    isNoEntryRoad: boolean;
    isParkingMeter: boolean;
    isHighVoltageTowers: boolean;
    isGuardrail: boolean;
    isWaterwayEtc: boolean;
    isOthersPlace: boolean;
    isHydrantEtc: boolean;
    isStreetTreesEtc: boolean;
    isRailroadCrossing: boolean;
    isOldRoad: boolean;
    isBillboardTowerEtc: boolean;
    isStreetLightsEtc: boolean;
    isBridge: boolean;
    isUnpavedRoad: boolean;
    isGarbageDump: boolean;
    isRoadSigns: boolean;
    isOverpass: boolean;
    isUnmaintainedEtc: boolean;
    isOther: boolean;
    otherLongText: string;
    handDrawnLayout: any[];
    handDrawnLayoutTotal: number;
    handDrawnLayoutTotalTsubo: number;
};

//図面ページ項目名の初期値 
export const initDrawingObj: DrawingTypeList = {
    uploadFilePaths: [],
    isGreenGem: false,
    isGrave: false,
    isMedianStrip: false,
    isBusStopBlackout: false,
    isInGreenGem: false,
    isOtherTenantParking: false,
    isExpresswayEtcPole: false,
    isBusLaneEtc: false,
    isElectricityPole: false,
    isSidewalk: false,
    isOneWayStreet: false,
    isTramTrack: false,
    isElectricityPoleBranchLine: false,
    isCrosswalk: false,
    isNoEntryRoad: false,
    isParkingMeter: false,
    isHighVoltageTowers: false,
    isGuardrail: false,
    isWaterwayEtc: false,
    isOthersPlace: false,
    isHydrantEtc: false,
    isStreetTreesEtc: false,
    isRailroadCrossing: false,
    isOldRoad: false,
    isBillboardTowerEtc: false,
    isStreetLightsEtc: false,
    isBridge: false,
    isUnpavedRoad: false,
    isGarbageDump: false,
    isRoadSigns: false,
    isOverpass: false,
    isUnmaintainedEtc: false,
    isOther: false,
    otherLongText: '',
    handDrawnLayout: [],
    handDrawnLayoutTotal: 0,
    handDrawnLayoutTotalTsubo: 0,
};

export type DrawingBooleanTypeList = {
    isGreenGem: boolean;
    isGrave: boolean;
    isMedianStrip: boolean;
    isBusStopBlackout: boolean;
    isInGreenGem: boolean;
    isOtherTenantParking: boolean;
    isExpresswayEtcPole: boolean;
    isBusLaneEtc: boolean;
    isElectricityPole: boolean;
    isSidewalk: boolean;
    isOneWayStreet: boolean;
    isTramTrack: boolean;
    isElectricityPoleBranchLine: boolean;
    isCrosswalk: boolean;
    isNoEntryRoad: boolean;
    isParkingMeter: boolean;
    isHighVoltageTowers: boolean;
    isGuardrail: boolean;
    isWaterwayEtc: boolean;
    isOthersPlace: boolean;
    isHydrantEtc: boolean;
    isStreetTreesEtc: boolean;
    isRailroadCrossing: boolean;
    isOldRoad: boolean;
    isBillboardTowerEtc: boolean;
    isStreetLightsEtc: boolean;
    isBridge: boolean;
    isUnpavedRoad: boolean;
    isGarbageDump: boolean;
    isRoadSigns: boolean;
    isOverpass: boolean;
    isUnmaintainedEtc: boolean;
    isOther: boolean;
    otherLongText: boolean;
    handDrawnLayoutFormula: boolean;
    handDrawnLayoutSubTotal: boolean;
    handDrawnLayoutTotal: boolean;
    handDrawnLayoutTotalTsubo: boolean;
    ObstaclesAndHindrancesComingToStore: boolean;
    HandDrawnLayout: boolean;
    [drawingBooleanKey: string]: boolean; //string型でキーを指定
};

//図面ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initDrawingReadOnlyObj: DrawingBooleanTypeList = {
    isGreenGem: true,
    isGrave: true,
    isMedianStrip: true,
    isBusStopBlackout: true,
    isInGreenGem: true,
    isOtherTenantParking: true,
    isExpresswayEtcPole: true,
    isBusLaneEtc: true,
    isElectricityPole: true,
    isSidewalk: true,
    isOneWayStreet: true,
    isTramTrack: true,
    isElectricityPoleBranchLine: true,
    isCrosswalk: true,
    isNoEntryRoad: true,
    isParkingMeter: true,
    isHighVoltageTowers: true,
    isGuardrail: true,
    isWaterwayEtc: true,
    isOthersPlace: true,
    isHydrantEtc: true,
    isStreetTreesEtc: true,
    isRailroadCrossing: true,
    isOldRoad: true,
    isBillboardTowerEtc: true,
    isStreetLightsEtc: true,
    isBridge: true,
    isUnpavedRoad: true,
    isGarbageDump: true,
    isRoadSigns: true,
    isOverpass: true,
    isUnmaintainedEtc: true,
    isOther: true,
    otherLongText: true,
    handDrawnLayoutFormula: true,
    handDrawnLayoutSubTotal: true,
    handDrawnLayoutTotal: true,
    handDrawnLayoutTotalTsubo: true,
    ObstaclesAndHindrancesComingToStore: true,
    HandDrawnLayout: true,
};

export const initDrawingShowIconsObj: DrawingBooleanTypeList = {
    isGreenGem: false,
    isGrave: false,
    isMedianStrip: false,
    isBusStopBlackout: false,
    isInGreenGem: false,
    isOtherTenantParking: false,
    isExpresswayEtcPole: false,
    isBusLaneEtc: false,
    isElectricityPole: false,
    isSidewalk: false,
    isOneWayStreet: false,
    isTramTrack: false,
    isElectricityPoleBranchLine: false,
    isCrosswalk: false,
    isNoEntryRoad: false,
    isParkingMeter: false,
    isHighVoltageTowers: false,
    isGuardrail: false,
    isWaterwayEtc: false,
    isOthersPlace: false,
    isHydrantEtc: false,
    isStreetTreesEtc: false,
    isRailroadCrossing: false,
    isOldRoad: false,
    isBillboardTowerEtc: false,
    isStreetLightsEtc: false,
    isBridge: false,
    isUnpavedRoad: false,
    isGarbageDump: false,
    isRoadSigns: false,
    isOverpass: false,
    isUnmaintainedEtc: false,
    isOther: false,
    otherLongText: false,
    handDrawnLayoutFormula: false,
    handDrawnLayoutSubTotal: false,
    handDrawnLayoutTotal: false,
    handDrawnLayoutTotalTsubo: false,
    ObstaclesAndHindrancesComingToStore: false,
    HandDrawnLayout: false,
}

//図面ページの鉛筆アイコンの表示非表示のステータスの初期値 trueが表示、falseが非表示
export const initDrawingShowEditIconObj: DrawingBooleanTypeList = {
    isGreenGem: true,
    isGrave: true,
    isMedianStrip: true,
    isBusStopBlackout: true,
    isInGreenGem: true,
    isOtherTenantParking: true,
    isExpresswayEtcPole: true,
    isBusLaneEtc: true,
    isElectricityPole: true,
    isSidewalk: true,
    isOneWayStreet: true,
    isTramTrack: true,
    isElectricityPoleBranchLine: true,
    isCrosswalk: true,
    isNoEntryRoad: true,
    isParkingMeter: true,
    isHighVoltageTowers: true,
    isGuardrail: true,
    isWaterwayEtc: true,
    isOthersPlace: true,
    isHydrantEtc: true,
    isStreetTreesEtc: true,
    isRailroadCrossing: true,
    isOldRoad: true,
    isBillboardTowerEtc: true,
    isStreetLightsEtc: true,
    isBridge: true,
    isUnpavedRoad: true,
    isGarbageDump: true,
    isRoadSigns: true,
    isOverpass: true,
    isUnmaintainedEtc: true,
    isOther: true,
    otherLongText: true,
    handDrawnLayoutFormula: true,
    handDrawnLayoutSubTotal: true,
    handDrawnLayoutTotal: true,
    handDrawnLayoutTotalTsubo: true,
    ObstaclesAndHindrancesComingToStore: true,
    HandDrawnLayout: true,
};

//図面ページのstring型の型定義
export type DrawingStringTypeList = {
    ObstaclesAndHindrancesComingToStore: string;
    otherLongText: string;
    HandDrawnLayout: string;
    drawingUpload: string;
    [coverStringKey: string]: string; //string型でキーを指定するため
};

//図面ページのモーダル内のテキストフィールドの初期値
export const initDrawingCommentTextFieldObj: DrawingStringTypeList = {
    ObstaclesAndHindrancesComingToStore: '',
    otherLongText: '',
    HandDrawnLayout: '',
    drawingUpload: ''
}


// 不備コメント一覧のテーブル 型定義
export type DeficiencyCommentList = {
    id: number;
    flg: string;
    createDate: string;         //日付
    tableName: string;          //ページ
    columnName: string;         //項目
    comment: string;            //コメント
    initComment: string;        //コメント初期値 キャンセルで戻すため
    deficiencyStatus: string    //不備ステータス
    editStatus: boolean;        //編集の可否 trueが編集不可 falseが編集可
};

// 不備コメント一覧のテーブル
export const initDeficiencyCommentList: DeficiencyCommentList[] = [];

export type locationTraffic5mcutTypeList = {
    plan_area_id: string,
    branch: string,
    create_date: string,
    update_date: string,
    delete_flag: string,
    excel_path: string,
    layout_img_path: string,
    photo_img_path: string,
    check_planting: string,
    check_visibility: string,
    check_stairs: string,
    check_count: string,
    car_another_survey: string,
    people_another_survey: string,
    cut_survey: string,
    others_facility_1: string,
    others_name_1: string,
    others_distance_1: string,
    others_value_1: string,
    others_facility_2: string,
    others_name_2: string,
    others_distance_2: string,
    others_value_2: string,
    others_facility_3: string,
    others_name_3: string,
    others_distance_3: string,
    others_value_3: string,
    others_facility_4: string,
    others_name_4: string,
    others_distance_4: string,
    others_value_4: string,
    approval_confirm_flag: string,
    approval_confirm_user: string,
    approval_confirm_department: string,
    approval_confirm_date: string,
    approval_confirm_stamp: string,
    approval_research_mgr_flag: string,
    approval_research_mgr_user: string,
    approval_research_mgr_department: string,
    approval_research_mgr_date: string,
    approval_research_mgr_stamp: string,
    approval_research_gm_flag: string,
    approval_research_gm_user: string,
    approval_research_gm_department: string,
    approval_research_gm_date: string,
    approval_research_gm_stamp: string,
    approval_mgr_flag: string,
    approval_mgr_user: string,
    approval_mgr_department: string,
    approval_mgr_date: string,
    approval_mgr_stamp: string,
    approval_gm_flag: string,
    approval_gm_user: string,
    approval_gm_department: string,
    approval_gm_date: string,
    approval_gm_stamp: string
};

export const locationTraffic5mcutObj: locationTraffic5mcutTypeList = {
    plan_area_id: "",
    branch: "",
    create_date: "",
    update_date: "",
    delete_flag: "",
    excel_path: "",
    layout_img_path: "",
    photo_img_path: "",
    check_planting: "0",
    check_visibility: "0",
    check_stairs: "0",
    check_count: "0",
    car_another_survey: "0",
    people_another_survey: "0",
    cut_survey: "0",
    others_facility_1: "0",
    others_name_1: "",
    others_distance_1: "",
    others_value_1: "",
    others_facility_2: "1",
    others_name_2: "",
    others_distance_2: "",
    others_value_2: "",
    others_facility_3: "",
    others_name_3: "",
    others_distance_3: "",
    others_value_3: "",
    others_facility_4: "",
    others_name_4: "",
    others_distance_4: "",
    others_value_4: "",
    approval_confirm_flag: "",
    approval_confirm_user: "",
    approval_confirm_department: "",
    approval_confirm_date: "",
    approval_confirm_stamp: "",
    approval_research_mgr_flag: "",
    approval_research_mgr_user: "",
    approval_research_mgr_department: "",
    approval_research_mgr_date: "",
    approval_research_mgr_stamp: "",
    approval_research_gm_flag: "",
    approval_research_gm_user: "",
    approval_research_gm_department: "",
    approval_research_gm_date: "",
    approval_research_gm_stamp: "",
    approval_mgr_flag: "",
    approval_mgr_user: "",
    approval_mgr_department: "",
    approval_mgr_date: "",
    approval_mgr_stamp: "",
    approval_gm_flag: "",
    approval_gm_user: "",
    approval_gm_department: "",
    approval_gm_date: "",
    approval_gm_stamp: ""
};

//通行量 その他 駅出入口の行のテーブルデータ取得型定義
export type locationTrafficStationTypeList = {
    station_entrance_name: string; //駅出入口の行 名称
    road_distance: string;      //駅出入口の行 距離
};

//通行量 その他 駅出入口の行のテーブル データ初期値
export const locationTrafficStationObj: locationTrafficStationTypeList = {
    station_entrance_name: '', //駅出入口の行 名称
    road_distance: '',      //駅出入口の行 距離
};

//通行量 その他 大学短大の行のテーブル データ取得型定義
export type locationTrafficSchoolTypeList = {
    display_name: string;          //大学短大の行 名称 
    trade_area_value: string;      //大学短大の行 距離
};

//通行量 その他 大学短大の行のテーブル データ初期値
export const locationTrafficSchoolObj: locationTrafficSchoolTypeList = {
    display_name: '',          //大学短大の行 名称 
    trade_area_value: ''       //大学短大の行 距離
};

//通行量 その他 入力可能項目 型定義
export type locationTraffic5mcutTextTypeList = {
    others_value_1: string;
    others_value_2: string;
    others_facility_3: string;
    others_name_3: string;
    others_distance_3: string;
    others_value_3: string;
    others_facility_4: string;
    others_name_4: string;
    others_distance_4: string;
    others_value_4: string;
};

//通行量 その他 入力可能項目 初期値
export const locationTraffic5mcutTextObj: locationTraffic5mcutTextTypeList = {
    others_value_1: "",
    others_value_2: "",
    others_facility_3: "",
    others_name_3: "",
    others_distance_3: "",
    others_value_3: "",
    others_facility_4: "",
    others_name_4: "",
    others_distance_4: "",
    others_value_4: ""
};

//通行量 その他 string型の型定義
export type LocationTrafficOthersStringTypeList = {
    others_traffic_volume: string
    [coverStringKey: string]: string; //string型でキーを指定するため
};

//通行量　その他のモーダル内のテキストフィールドの初期値
export const initLocationTrafficOthersCommentTextFieldObj: LocationTrafficOthersStringTypeList = {
    others_traffic_volume: ""
};

//通行量　その他のboolean型の定義
export type LocationTrafficOthersBooleanTypeList = {
    others_traffic_volume: boolean;
};

//表紙ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initLocationTrafficOthersReadOnlyObj: LocationTrafficOthersBooleanTypeList = {
    others_traffic_volume: true,
};

export type CodeMasterTypeList = {
    uuid: string;
    create_date: string;
    update_date: string;
    delete_flag: string;
    common_item_flag: string;
    item_name_display: string;
    item_name_value: string;
    code_display: string;
    code_value: string;
    sort_column: string;
};

export const initCodeMasterObj: CodeMasterTypeList[] = [];

export type pdfDataList = {
    fileName: string,
    uploadDate: string,
    uploader: string,
    pdfPath: string,
    previewPdfPath: string,
};

export const initPdfData: pdfDataList = {
    fileName: "",
    uploadDate: "",
    uploader: "",
    pdfPath: "",
    previewPdfPath: "",
};

export type locationAddSurveyTypeList = {
    planAreaId: string,
    branch: string
    create_date: string,
    update_date: string,
    delete_flag: string,
    cover_excel_path: string,
    cover_excel_user: string,
    cover_pdf_path: string,
    cover_pdf_user: string,
    doc_pdf_path: any[],
    reference_value_1: string,
    reference_value_2: string,
    reference_value_3: string,
    reference_value_4: string,
    reference_value_5: string,
    approval_mgr_flag: string,
    approval_mgr_user: string,
    approval_mgr_department: string,
    approval_mgr_date: string,
    approval_mgr_stamp: string,
    approval_gm_flag: string,
    approval_gm_user: string,
    approval_gm_department: string,
    approval_gm_date: string,
    approval_gm_stamp: string,
    approval_research_mgr_flag: string,
    approval_research_mgr_user: string,
    approval_research_mgr_department: string,
    approval_research_mgr_date: string,
    approval_research_mgr_stamp: string,
    approval_research_gm_flag: string,
    approval_research_gm_user: string,
    approval_research_gm_department: string,
    approval_research_gm_date: string,
    approval_research_gm_stamp: string,
};


export const initLocationAddSurveyObj: locationAddSurveyTypeList = {
    planAreaId: "",
    branch: "",
    create_date: "",
    update_date: "",
    delete_flag: "",
    cover_excel_path: "",
    cover_excel_user: "",
    cover_pdf_path: "",
    cover_pdf_user: "",
    doc_pdf_path: [],
    reference_value_1: "",
    reference_value_2: "",
    reference_value_3: "",
    reference_value_4: "",
    reference_value_5: "",
    approval_mgr_flag: "",
    approval_mgr_user: "",
    approval_mgr_department: "",
    approval_mgr_date: "",
    approval_mgr_stamp: "",
    approval_gm_flag: "",
    approval_gm_user: "",
    approval_gm_department: "",
    approval_gm_date: "",
    approval_gm_stamp: "",
    approval_research_mgr_flag: "",
    approval_research_mgr_user: "",
    approval_research_mgr_department: "",
    approval_research_mgr_date: "",
    approval_research_mgr_stamp: "",
    approval_research_gm_flag: "",
    approval_research_gm_user: "",
    approval_research_gm_department: "",
    approval_research_gm_date: "",
    approval_research_gm_stamp: "",
};

export type editParamsList = {
    selectTab: string,
};

export const initEditParams: editParamsList = {
    selectTab: "",
};


//表紙ページ項目名の型定義
export type Approval5mcutTypeList = {
    fiveMeters: string;
    marketCut: string;
};

//表紙ページ項目名の初期値
export const initApproval5mcutObj: Approval5mcutTypeList = {
    fiveMeters: '',
    marketCut: '',
};

//追加調査ページ 不備コメント用 string型の型定義
export type additionalResearchStringTypeList = {
    add_survey_cover: string;
    add_survey_document: string;
    other_document_excel: string;
    other_document_pdf: string;
    [additionalResearchString: string]: string; //string型でキーを指定するため
};

//追加調査ページ 不備コメント用 string型の型定義
export const initAdditionalResearchCommentTextFieldObj: additionalResearchStringTypeList = {
    add_survey_cover: '',
    add_survey_document: '',
    other_document_excel: '',
    other_document_pdf: ''
};

//地図ページ 不備コメント用 string型の型定義
export type mapStringTypeList = {
    map_upload: string;
    [mapString: string]: string; //string型でキーを指定するため
};

//地図ページ 不備コメント用 string型の型定義
export const initMapCommentTextFieldObj: mapStringTypeList = {
    map_upload: ''
};


//影響度ページのboolean型の定義
export type LocationImpactBooleanTypeList = {
    store_position: boolean;
    common_road: boolean;
    plan_median_strip: boolean;
    store_median_strip: boolean;
    straight_count: boolean;
    turn_count: boolean;
    railroad_crossing: boolean;
    river_width: boolean;
    crossing_road_count: boolean;
    conflict_sej: boolean;
    conflict_main_cvs: boolean;
    conflict_other_cvs: boolean;
    near_store_code: boolean;
    average_common_ratio: boolean;
    road_distance: boolean;
    salesfloor: boolean;
    parkingarea: boolean;
    car_front_store_count: boolean;
    car_total_share_ratio: boolean;
    people_front_store_count: boolean;
    people_total_share_ratio: boolean;
    [locationImpactBooleanKey: string]: boolean; //string型でキーを指定するため
};


//影響度ページのアイコン全体の表示非表示のステータスの初期値
export const initLocationImpactShowIconObj: LocationImpactBooleanTypeList = {
    store_position: false,
    common_road: false,
    plan_median_strip: false,
    store_median_strip: false,
    straight_count: false,
    turn_count: false,
    railroad_crossing: false,
    river_width: false,
    crossing_road_count: false,
    conflict_sej: false,
    conflict_main_cvs: false,
    conflict_other_cvs: false,
    near_store_code: false,
    average_common_ratio: false,
    road_distance: false,
    salesfloor: false,
    parkingarea: false,
    car_front_store_count: false,
    car_total_share_ratio: false,
    people_front_store_count: false,
    people_total_share_ratio: false,
};

//影響度ページの鉛筆アイコンの表示非表示のステータスの初期値 trueが表示、falseが非表示
export const initLocationImpactShowEditIconObj: LocationImpactBooleanTypeList = {
    store_position: true,
    common_road: true,
    plan_median_strip: true,
    store_median_strip: true,
    straight_count: true,
    turn_count: true,
    railroad_crossing: true,
    river_width: true,
    crossing_road_count: true,
    conflict_sej: true,
    conflict_main_cvs: true,
    conflict_other_cvs: true,
    near_store_code: true,
    average_common_ratio: true,
    road_distance: true,
    salesfloor: true,
    parkingarea: true,
    car_front_store_count: true,
    car_total_share_ratio: true,
    people_front_store_count: true,
    people_total_share_ratio: true,
};

//影響度ページの項目値を編集可能にするステータスの初期値 trueが入力不可、falseが入力可
export const initLocationImpactReadOnlyObj: LocationImpactBooleanTypeList = {
    store_position: true,
    common_road: true,
    plan_median_strip: true,
    store_median_strip: true,
    straight_count: true,
    turn_count: true,
    railroad_crossing: true,
    river_width: true,
    crossing_road_count: true,
    conflict_sej: true,
    conflict_main_cvs: true,
    conflict_other_cvs: true,
    near_store_code: true,
    average_common_ratio: true,
    road_distance: true,
    salesfloor: true,
    parkingarea: true,
    car_front_store_count: true,
    car_total_share_ratio: true,
    people_front_store_count: true,
    people_total_share_ratio: true,
};


//影響度ページのstring型の型定義
export type LocationImpactStringTypeList = {
    store_position: string;
    common_road: string;
    plan_median_strip: string;
    store_median_strip: string;
    straight_count: string;
    turn_count: string;
    railroad_crossing: string;
    river_width: string;
    crossing_road_count: string;
    conflict_sej: string;
    conflict_main_cvs: string;
    conflict_other_cvs: string;
    near_store_code: string;
    average_common_ratio: string;
    road_distance: string;
    salesfloor: string;
    parkingarea: string;
    car_front_store_count: string;
    car_total_share_ratio: string;
    people_front_store_count: string;
    people_total_share_ratio: string;
    [locationImpactStringKey: string]: string; //string型でキーを指定するため
};

//影響度ページのモーダル内のテキストフィールドの初期値
export const initLocationImpactCommentTextFieldObj: LocationImpactStringTypeList = {
    store_position: '',
    common_road: '',
    plan_median_strip: '',
    store_median_strip: '',
    straight_count: '',
    turn_count: '',
    railroad_crossing: '',
    river_width: '',
    crossing_road_count: '',
    conflict_sej: '',
    conflict_main_cvs: '',
    conflict_other_cvs: '',
    near_store_code: '',
    average_common_ratio: '',
    road_distance: '',
    salesfloor: '',
    parkingarea: '',
    car_front_store_count: '',
    car_total_share_ratio: '',
    people_front_store_count: '',
    people_total_share_ratio: '',
};


//表紙ページ項目名の型定義
export type ApprovalSurveyTypeList = {
    additionalResearch: string;
};

//表紙ページ項目名の初期値
export const initApprovalSurveyObj: ApprovalSurveyTypeList = {
    additionalResearch: '',
};


export type locationSalesForecastTypeList = {
    planAreaId: string,
    branch: string
    create_date: string,
    update_date: string,
    delete_flag: string,
    temp_flag: string,
    sales_forecast_excel_path: string,
    sales_forecast_pdf_path: string,
    sales_forecast_upload_user: string,
    sales_forecast_upload_mail: string,
    sales_forecast_color: string,
    daily_sales_year_1: string,
    daily_sales_year_2: string,
    daily_sales_year_3: string,
    daily_sales_year_4: string,
    daily_sales_year_5: string,
    impact_store_name_1: string,
    impact_store_1: string,
    impact_store_name_2: string,
    impact_store_2: string,
    impact_store_name_3: string,
    impact_store_3: string,
    impact_store_name_4: string,
    impact_store_4: string,
    approval_research_output_flag: string,
    approval_research_output_user: string,
    approval_research_output_department: string,
    approval_research_output_date: string,
    approval_research_output_stamp: string,
    approval_research_mgr_flag: string,
    approval_research_mgr_user: string,
    approval_research_mgr_department: string,
    approval_research_mgr_date: string,
    approval_research_mgr_stamp: string,
    approval_research_gm_flag: string,
    approval_research_gm_user: string,
    approval_research_gm_department: string,
    approval_research_gm_date: string,
    approval_research_gm_stamp: string,
};


export const locationSalesForecastObj: locationSalesForecastTypeList = {
    planAreaId: "",
    branch: "",
    create_date: "",
    update_date: "",
    delete_flag: "",
    temp_flag: "",
    sales_forecast_excel_path: "",
    sales_forecast_pdf_path: "",
    sales_forecast_upload_user: "",
    sales_forecast_upload_mail: "",
    sales_forecast_color: "",
    daily_sales_year_1: "",
    daily_sales_year_2: "",
    daily_sales_year_3: "",
    daily_sales_year_4: "",
    daily_sales_year_5: "",
    impact_store_name_1: "",
    impact_store_1: "",
    impact_store_name_2: "",
    impact_store_2: "",
    impact_store_name_3: "",
    impact_store_3: "",
    impact_store_name_4: "",
    impact_store_4: "",
    approval_research_output_flag: "",
    approval_research_output_user: "",
    approval_research_output_department: "",
    approval_research_output_date: "",
    approval_research_output_stamp: "",
    approval_research_mgr_flag: "",
    approval_research_mgr_user: "",
    approval_research_mgr_department: "",
    approval_research_mgr_date: "",
    approval_research_mgr_stamp: "",
    approval_research_gm_flag: "",
    approval_research_gm_user: "",
    approval_research_gm_department: "",
    approval_research_gm_date: "",
    approval_research_gm_stamp: "",
};