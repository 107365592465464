import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useAppSelector } from './app/hooks';
import ListItemIcon from '@mui/material/ListItemIcon';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {
  LOCAL_CONSTANT,
} from './page/Const'
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  createTheme,
  ThemeProvider,
  CssBaseline,
} from "@mui/material";
import { useUserContext } from './user-provider';
import { setPermissionFunc } from './page/components/PermissionFunc';


interface ListItemProps {
  props:{
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  }
}

const theme = createTheme({
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#00AC4C',
            color: '#FFFFFF',
            border: "solid 1px #bfbfbf;",
          },
        },
      },
    },
  },
});

const SecondaryListItems:React.FC<ListItemProps> = ({props}) => {

  //-----スタイル-----//
  //ハンバーガーメニュースタイル
  const naviBoxStyle = props.open === false ?{
    //メニュークローズ時
    position:"fixed",
    width: '56px',
    color: '#0E6F55',
  }:{
    //メニューオープン時
    position:"fixed",
    width: '240px',
    color: '#0E6F55',
  };
  //個別画面（リスト部分）
  const indiPageStyle = props.open === false ?{
    //メニュークローズ時
    padding: 0,
    margin: 0,
    borderBottom: "solid 1px #bfbfbf;",
  }:{
    //メニューオープン時
    height:"45px",
    borderBottom: "solid 1px #bfbfbf;",
    "& .MuiButtonBase-root": {
      paddingTop:"6px",
      paddingBottom:"6px",
    },
    "& .MuiSvgIcon-fontSizeMedium": {
      position: "absolute",
      top: "10px",
      left: "15px"
    },
  };
  //ロングページ（リスト部分）
  const longPageStyle = props.open === false ?{
    //メニュークローズ時
    padding: 0,
    margin: 0,
    borderBottom: "solid 1px #bfbfbf;",
  }:{
    //メニューオープン時
    "& .MuiButtonBase-root":{
      padding: 0,
      borderBottom: "solid 1px #bfbfbf;",
    }
  };
  //個別画面（テキスト部分）
  const indiPageItemTextStyle = props.open === false?{
    //メニュークローズ時
    marginTop: 0,
    marginBottom: 0,
    '& .MuiTypography-root':{
      textAlign: "center",
      fontSize: "0.55rem",
      whiteSpace:"normal",
    },
    '& > span': {
      position: 'absolute',
      top: '0',
      left: '0',
      paddingTop: '27px',
      width: '56px',
      height: '-webkit-fill-available'
    },
    '& > span > p': {
      margin: 0,
    },
  }:{
    //メニューオープン時
    '& > span': {
      fontSize: "0.85rem",
    }
  };
  //ロングページ（テキスト部分）
  const longPageItemLongTextStyle = props.open === false ?{
    //メニュークローズ時
    marginTop: 0,
    marginBottom: '2px',
    '& .MuiTypography-root': {
      textAlign: "center",
      fontSize: "0.55rem",
      whiteSpace:"normal",
    },
    '& > span': {
      position: 'absolute',
      top: '10px',
      left: '0px',
      width: '56px',
    },
    '& > span > p': {
      margin: 0,
    },
  }:{
    //メニューオープン時
    '& > span': {
      paddingLeft: '16px',
      fontSize: "0.85rem",
    },
  };
  //ロングページ（テキスト部分）
  const longPageItemTextStyle = props.open === false ?{
    //メニュークローズ時
    marginTop: 0,
    marginBottom: '2px',
    '& .MuiTypography-root': {
      textAlign: "center",
      fontSize: "0.65rem",
      whiteSpace:"normal",
    },
    '& > span': {
      position: 'absolute',
      top: '10px',
      left: '0px',
      width: '56px',
    },
    '& > span > p': {
      margin: 0,
    },
  }:{
    //メニューオープン時
    '& > span': {
      paddingLeft: '16px',
      fontSize: "0.85rem",
    },
  };
  //ロングページ(ボタン部分)
  const longPageButtonStyle = props.open === false ?{
    //メニュークローズ時
    paddingTop: "13px",
    paddingBottom: "13px",
  }:{
    //メニューオープン時
  };
  // Linkタグレイアウト崩れ修正
  const linkStyle = {
    textDecoration: 'none',
    color: 'inherit',
    width: '100%',
    marginbottom: '2px',
  };
  const linkIconStyle = props.open === false ?{
    //メニュークローズ時
    textDecoration: 'none',
    color: 'inherit',
    width: '100%',
  }:{
    //メニューオープン時
    textDecoration: 'none',
    color: 'inherit',
    width: '100%',
    marginTop: "6px",
  };
  
  //ロケーション登録
  const location = useLocation();
  //ナビゲート登録
  const navigate = useNavigate();
  //メニュー選択項目値
  const [selectedItem, setSelectedItem] = useState(0);
  //URLパラメータ
  const [searchParams] = useSearchParams();
  const planAreaId = searchParams.get("planAreaId");
  const branch = searchParams.get("branch");
  //HashLinkタグのID要素取得
  const salesForecastSummaryMainId = document.getElementById("SalesForecastSummaryMainId"); //予測集計表等
  const additionalResearchId = document.getElementById("AdditionalResearchId"); //追加調査
  const otherDocumentId = document.getElementById("OtherDocumentId"); //その他資料
  const rrInternalUseId = document.getElementById("RRInternalUseId"); //RR内部用
  const coverId = document.getElementById("CoverId"); //表紙
  const storeOpeningConditionsId = document.getElementById("StoreOpeningConditionsId"); //出店条件
  const tradeAreaId = document.getElementById("TradeAreaId"); //商圏
  const pictureId = document.getElementById("PictureId"); //現況写真
  const donationId = document.getElementById("DonationId"); //寄付き
  const trafficVolumeId = document.getElementById("TrafficVolumeId"); //通行量
  const conflictId = document.getElementById("ConflictId"); //競合
  const impactId = document.getElementById("ImpactId"); //影響度
  const mapId = document.getElementById("MapId"); //地図
  const drawingId = document.getElementById("DrawingId"); //図面
  //ロングページのタイトル要素の位置変数
  let salesForecastSummaryMainElementListPoint = 0; //予測集計表等
  let additionalResearchPoint = 0; //追加調査
  let otherDocumentPoint = 0; //その他資料
  let rrInternalUsePoint = 0; //RR内部用
  let coverPoint = 0; //表紙
  let storeOpeningConditionsPoint = 0; //出店条件
  let tradeAreaPoint = 0; //商圏
  let picturePoint = 0; //現況写真
  let donationPoint = 0; //寄付き
  let trafficVolumePoint = 0; //通行量
  let conflictPoint = 0; //競合
  let impactPoint = 0; //影響度
  let mapPoint = 0; //地図
  let drawingPoint = 0; //図面

  //要素の絶対位置取得関数
  const getAbsolutePosition = (elem:string):number => {
    const elemId = document.getElementById(elem);
    const contentInner = document.getElementById("contentInner");
    let PositionY = 0;
    if(elemId && contentInner) {
      const bounds = elemId.getBoundingClientRect();
      const html = document.documentElement;
      PositionY = bounds.top + (contentInner.scrollTop) - html.clientTop;
    }
    //小数点切り捨て
    PositionY = Math.ceil(PositionY);
    return PositionY;
  };
  
  //ロングページ内のタイトル要素の絶対位置取得
  const getElemPosition = () => {
    salesForecastSummaryMainElementListPoint = getAbsolutePosition("salesForecastSummaryMainElementListPoint");
    additionalResearchPoint = getAbsolutePosition("AdditionalResearch") - 100;
    otherDocumentPoint = getAbsolutePosition("OtherDocument") - 100;
    rrInternalUsePoint = getAbsolutePosition("RRinternalUse") - 100;
    coverPoint = getAbsolutePosition("Cover") - 100;
    storeOpeningConditionsPoint = getAbsolutePosition("StoreOpeningConditions") - 100;
    tradeAreaPoint = getAbsolutePosition("TradeArea") - 100;
    picturePoint = getAbsolutePosition("Picture") - 100;
    donationPoint = getAbsolutePosition("Donation") - 100;
    trafficVolumePoint = getAbsolutePosition("TrafficVolume") - 100;
    conflictPoint = getAbsolutePosition("Conflict") - 100;
    impactPoint = getAbsolutePosition("Impact") - 100;
    mapPoint = getAbsolutePosition("Map") - 100;
    drawingPoint = getAbsolutePosition("Drawing") - 100;
  };

  //縦スクロール位置によってstyle変更
  const handleScroll = useCallback(() => {
    const contentInner = document.getElementById("contentInner");
        if(contentInner){
      //スクロールポジション
      const position = contentInner.scrollTop;
      //各タイトル要素の絶対値取得
      getElemPosition();
      if(position < additionalResearchPoint){
        setSelectedItem(2);  //予測集計表・主要素一覧
      }else if(additionalResearchPoint < position && position < otherDocumentPoint){
        setSelectedItem(3);  //追加調査
      }else if (otherDocumentPoint < position && position < rrInternalUsePoint) {
        setSelectedItem(4);  //その他資料
      }else if(rrInternalUsePoint < position && position < coverPoint){
        setSelectedItem(5);  //RR内部用  
      }else if(coverPoint < position && position < storeOpeningConditionsPoint){
        setSelectedItem(6);  //表紙
      }else if(storeOpeningConditionsPoint < position && position < tradeAreaPoint){
        setSelectedItem(7);  //出店条件
      }else if(tradeAreaPoint < position && position < picturePoint){
        setSelectedItem(8);  //商圏
      }else if(picturePoint < position && position < donationPoint){
        setSelectedItem(9);  //現況写真
      }else if(donationPoint < position && position < trafficVolumePoint){
        setSelectedItem(10);  //寄付き
      }else if(trafficVolumePoint < position && position < conflictPoint){
        setSelectedItem(11);  //通行量
      }else if(conflictPoint < position && position < impactPoint){
        setSelectedItem(12);  //競合
      }else if(impactPoint < position && position < mapPoint){
        setSelectedItem(13);  //影響度
      }else if(mapPoint < position && position < drawingPoint){
        setSelectedItem(14);  //地図
      }else if(drawingPoint < position){
        setSelectedItem(15);  //図面
      }
    }
  },[]);

  //メニュー選択値チェック
  const urlCheck = () => {

    const path = location.pathname;
    const hash = location.hash;
    
    //ロングページ初期表示時
    if(path === "/LongPage" && !hash) {
      setSelectedItem(2);
      //回覧履歴
    } else if(path === "/CircularHistory") {
      setSelectedItem(0);
    //項目変更履歴
    } else if(path === "/") {
      setSelectedItem(1);
    //その他画面
    } else {
      setSelectedItem(16);
    }
  };

  //メニュー押下時
  const handleButtonClick = (url:string) => {
    // ハンバーガーメニューを閉じる処理
    props.setOpen(false);
    //現在パス取得
    const path = location.pathname;

    //個別画面項目の場合
    if(!url.includes("/LongPage")) {
      // 個別画面項目の場合は別タブで開く
      const baseUrl = window.location.origin;
      const fullUrl = `${baseUrl}${url}?planAreaId=${planAreaId}&branch=${branch}`;
      window.open(fullUrl);
      
    //ロングページ項目の場合
    } else {
      //現在地がロングページではない場合ロングページへ遷移
      if(path !== "/LongPage"){ navigate(`/LongPage?planAreaId=${planAreaId}&branch=${branch}`); }
      //押下されたボタンからHashLinkをクリック
      if(url === "/LongPage#SalesForecastSummaryMainElementList" && salesForecastSummaryMainId){
        salesForecastSummaryMainId.click();
      }else if(url === "/LongPage#AdditionalResearch" && additionalResearchId){
        additionalResearchId.click();
      }else if(url === "/LongPage#OtherDocument" && otherDocumentId){
        otherDocumentId.click();
      }else if(url === "/LongPage#RRinternalUse" && rrInternalUseId){
        rrInternalUseId.click();
      }else if(url === "/LongPage#Cover" && coverId){
        coverId.click();
      }else if(url === "/LongPage#StoreOpeningConditions" && storeOpeningConditionsId){
        storeOpeningConditionsId.click();
      }else if(url === "/LongPage#TradeArea" && tradeAreaId){
        tradeAreaId.click();
      }else if(url === "/LongPage#Picture" && pictureId){
        pictureId.click();
      }else if(url === "/LongPage#Donation" && donationId){
        donationId.click();
      }else if(url === "/LongPage#TrafficVolume" && trafficVolumeId){
        trafficVolumeId.click();
      }else if(url === "/LongPage#Conflict" && conflictId){
        conflictId.click();
      }else if(url === "/LongPage#Impact" && impactId){
        impactId.click();
      }else if(url === "/LongPage#Map" && mapId){
        mapId.click();
      }else if(url === "/LongPage#Drawing" && drawingId){
        drawingId.click();
      }
    }
  };

  //URL監視
  useEffect(() => {
    //URL変更時メニュー選択値適正化
    urlCheck();

    //ロングページの基準要素に対してスクロールイベント登録
    const contentInner = document.getElementById("contentInner");
    if(contentInner) {
      contentInner.addEventListener("scroll", handleScroll);
      return ()=>{contentInner.removeEventListener("scroll", handleScroll);}
    }
  }, [location.pathname]);

  //サイドメニュー文字列分割関数
  const breakedText = (text: string) => {
    if(props.open){return <span>{text}</span>}
    return text.split("\n").map((line, key) => <span key={key}>{line}<br /></span>);
  };

  //調査種別プルダウンの配列でハンバーガーメニュー活性非活性対応
  const selectedSurveyType = useAppSelector((state) => state.longPage.surveyType);

  //調査種別プルダウン時、ハンバーガーメニューを非活性 trueが非活性
  const objLocationBase = useAppSelector((state) => state.longPage.locationBase);

  // 影響度を非活性
  const impactInactive: boolean = (selectedSurveyType === "02"  //セットバック(改造後)を選択
                                || selectedSurveyType === "03"  //立地評価(タイプ変更後)を選択 
                                || selectedSurveyType === "05"  //S&B(S店)を選択
                                || selectedSurveyType === "06"  //セットバック(改造前・接道変化無し)を選択
                                || selectedSurveyType === "07"  //セットバック(改造前・接道変化有り)を選択
                                || selectedSurveyType === "08"  //立地評価(タイプ変更前・店格改善無し)を選択
                                || selectedSurveyType === "09"  //立地評価(タイプ変更前・店格改善有り)を選択
                                  );
  //現況写真を非活性
  const pictureInactive: boolean = (selectedSurveyType === "06"  //セットバック(改造前・接道変化無し)を選択
                                 || selectedSurveyType === "07"  //セットバック(改造前・接道変化有り)を選択
                                 || selectedSurveyType === "08"  //立地評価(タイプ変更前・店格改善無し)を選択
                                 || selectedSurveyType === "09"  //立地評価(タイプ変更前・店格改善有り)を選択
                                  );
  //商圏を非活性
  const tradeAreaInactive: boolean = (selectedSurveyType === "06"  //セットバック(改造前・接道変化無し)を選択
                                   || selectedSurveyType === "07"  //セットバック(改造前・接道変化有り)を選択
                                   || selectedSurveyType === "08"  //立地評価(タイプ変更前・店格改善無し)を選択
                                   || selectedSurveyType === "09"  //立地評価(タイプ変更前・店格改善有り)を選択
                                    );
  //通行量を非活性
  const trafficVolumeInactive: boolean = (selectedSurveyType === "06"  //セットバック(改造前・接道変化無し)を選択
                                       || selectedSurveyType === "08"  //立地評価(タイプ変更前・店格改善無し)を選択
                                       || selectedSurveyType === "09"  //立地評価(タイプ変更前・店格改善有り)を選択
                                        );
  //競合を非活性
  const conflictInactive: boolean = (selectedSurveyType === "06"  //セットバック(改造前・接道変化無し)を選択
                                  || selectedSurveyType === "08"  //立地評価(タイプ変更前・店格改善無し)を選択
                                  || selectedSurveyType === "09"  //立地評価(タイプ変更前・店格改善有り)を選択
                                    );
  //地図を非活性
  const mapInactive: boolean = (selectedSurveyType === "06"  //セットバック(改造前・接道変化無し)を選択
                             || selectedSurveyType === "08"  //立地評価(タイプ変更前・店格改善無し)を選択
                             || selectedSurveyType === "09"  //立地評価(タイプ変更前・店格改善有り)を選択
                              );
  //寄付きを非活性
  const donationInactive: boolean = (selectedSurveyType === "08");  //立地評価(タイプ変更前・店格改善無し)を選択
  //子調査書の場合、回覧履歴を非活性
  const circularHistoryInactive: boolean = ((planAreaId?.startsWith("X") || planAreaId?.startsWith("Y")) === true) ? true : false; 

  const user = useUserContext();
  //ユーザー種別を格納  
  const [userType, setUserType] = useState('');
  
  //ユーザー取得タイミングによる画面制御
  useEffect(() => {
    if (JSON.parse(user.userData || 'null') !== null) {
      setUserType(JSON.parse(user.userData || 'null').user_type);
    }
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Box id="naviBox" sx={naviBoxStyle}>

        {/* 個別画面項目 */}
        <ListItem disablePadding sx={indiPageStyle}>
          <ListItemButton
            onClick={()=>{handleButtonClick("/CircularHistory")}}
            selected={location.pathname === "/CircularHistory"}
            disabled={circularHistoryInactive}>
            <ListItemIcon>
              <Link to={"/CircularHistory"} style={linkIconStyle} >
                <LaunchIcon fontSize="small"/>
              </Link>
            </ListItemIcon>
            <ListItemText 
              sx={indiPageItemTextStyle}
              primary={breakedText(LOCAL_CONSTANT.NAVI.CIRCULAR_HISTORY)}/>
          </ListItemButton>
        </ListItem>
        {/* スペーサー */}
        <ListItem 
          sx={{ borderBottom: "solid 1px #bfbfbf",
                paddingTop:"1px",
                paddingBottom:"1px",
                backgroundColor:"#bbbbbb40"
              }}>
        </ListItem>

        {/* ロングページ項目 */}
        <ListItem disablePadding sx={longPageStyle}>
          <ListItemButton 
            onClick={ () => {handleButtonClick("/LongPage#SalesForecastSummaryMainElementList")} }
            selected={selectedItem === 2}
            sx={longPageButtonStyle}
            disabled={setPermissionFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST,'menu')}>
            <HashLink smooth to={`?planAreaId=${planAreaId}&branch=${branch}#SalesForecastSummaryMainElementList`}  style={linkStyle} id={"SalesForecastSummaryMainId"}>
              <ListItemText
                sx={longPageItemLongTextStyle}
                primary={breakedText(LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST)}/>
            </HashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={longPageStyle}>
          <ListItemButton 
            onClick={ () => {handleButtonClick("/LongPage#AdditionalResearch")} }
            selected={selectedItem === 3}
            sx={longPageButtonStyle}>
            <HashLink smooth to={`?planAreaId=${planAreaId}&branch=${branch}#AdditionalResearch`} style={linkStyle} id={"AdditionalResearchId"}>
              <ListItemText
                sx={longPageItemTextStyle}
                primary={breakedText(LOCAL_CONSTANT.NAVI.ADDITIONAL_RESEARCH)}/>
            </HashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={longPageStyle}>
          <ListItemButton 
            onClick={ () => {handleButtonClick("/LongPage#OtherDocument")} }
            selected={selectedItem === 4}
            sx={longPageButtonStyle}>
            <HashLink smooth to={`?planAreaId=${planAreaId}&branch=${branch}#OtherDocument`} style={linkStyle} id={"OtherDocumentId"}>
              <ListItemText
                sx={longPageItemTextStyle}
                primary={breakedText(LOCAL_CONSTANT.NAVI.OTHER_DOCUMENT)}/>
            </HashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={longPageStyle}>
          <ListItemButton 
            onClick={ () => {handleButtonClick("/LongPage#RRinternalUse")} }
            selected={selectedItem === 5}
            sx={longPageButtonStyle}
            disabled={userType!=='z001'}>
            <HashLink smooth to={`?planAreaId=${planAreaId}&branch=${branch}#RRinternalUse`} style={linkStyle} id={"RRInternalUseId"}>
              <ListItemText
                sx={longPageItemTextStyle}
                primary={breakedText(LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE)}/>
            </HashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={longPageStyle}>
          <ListItemButton 
            onClick={ () => {handleButtonClick("/LongPage#Cover")} }
            selected={selectedItem === 6}
            sx={longPageButtonStyle}
            disabled={false}>
            <HashLink smooth to={`?planAreaId=${planAreaId}&branch=${branch}#Cover`} style={linkStyle} id={"CoverId"}>
              <ListItemText
                sx={longPageItemTextStyle}
                primary={breakedText(LOCAL_CONSTANT.NAVI.COVER)}/>
            </HashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={longPageStyle}>
          <ListItemButton 
            onClick={ () => {handleButtonClick("/LongPage#StoreOpeningConditions")} }
            selected={selectedItem === 7}
            sx={longPageButtonStyle}>
            <HashLink smooth to={`?planAreaId=${planAreaId}&branch=${branch}#StoreOpeningConditions`} style={linkStyle} id={"StoreOpeningConditionsId"}>
              <ListItemText
                sx={longPageItemTextStyle}
                primary={breakedText(LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS)}/>
            </HashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={longPageStyle}>
          <ListItemButton 
            onClick={ () => {handleButtonClick("/LongPage#TradeArea")} }
            selected={selectedItem === 8}
            sx={longPageButtonStyle}
            disabled={tradeAreaInactive}>
            <HashLink smooth to={`?planAreaId=${planAreaId}&branch=${branch}#TradeArea`} style={linkStyle} id={"TradeAreaId"}>
              <ListItemText
                sx={longPageItemTextStyle}
                primary={breakedText(LOCAL_CONSTANT.NAVI.TRADE_AREA)}/>
            </HashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={longPageStyle}>
          <ListItemButton 
            onClick={ () => {handleButtonClick("/LongPage#Picture")} }
            selected={selectedItem === 9}
            sx={longPageButtonStyle}
            disabled={pictureInactive}>
            <HashLink smooth to={`?planAreaId=${planAreaId}&branch=${branch}#Picture`} style={linkStyle} id={"PictureId"}>
              <ListItemText
                sx={longPageItemTextStyle}
                primary={breakedText(LOCAL_CONSTANT.NAVI.PICTURE)}/>
            </HashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={longPageStyle}>
          <ListItemButton 
            onClick={ () => {handleButtonClick("/LongPage#Donation")} }
            selected={selectedItem === 10}
            sx={longPageButtonStyle}
            disabled={donationInactive}>
            <HashLink smooth to={`?planAreaId=${planAreaId}&branch=${branch}#Donation`} style={linkStyle} id={"DonationId"}>
              <ListItemText
                sx={longPageItemTextStyle}
                primary={breakedText(LOCAL_CONSTANT.NAVI.DONATION)}/>
            </HashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={longPageStyle}>
          <ListItemButton 
            onClick={ () => {handleButtonClick("/LongPage#TrafficVolume")} }
            selected={selectedItem === 11}
            sx={longPageButtonStyle}
            disabled={trafficVolumeInactive}>
            <HashLink smooth to={`?planAreaId=${planAreaId}&branch=${branch}#TrafficVolume`} style={linkStyle} id={"TrafficVolumeId"}>
              <ListItemText
                sx={longPageItemTextStyle}
                primary={breakedText(LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME)}/>
            </HashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={longPageStyle}>
          <ListItemButton 
            onClick={ () => {handleButtonClick("/LongPage#Conflict")} }
            selected={selectedItem === 12}
            sx={longPageButtonStyle}
            disabled={conflictInactive}>
            <HashLink smooth to={`?planAreaId=${planAreaId}&branch=${branch}#Conflict`} style={linkStyle} id={"ConflictId"}>
              <ListItemText
                sx={longPageItemTextStyle}
                primary={breakedText(LOCAL_CONSTANT.NAVI.CONFLICT)}/>
            </HashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={longPageStyle}>
          <ListItemButton 
            onClick={ () => {handleButtonClick("/LongPage#Impact")} }
            selected={selectedItem === 13}
            sx={longPageButtonStyle}
            disabled={impactInactive}>
            <HashLink smooth to={`?planAreaId=${planAreaId}&branch=${branch}#Impact`} style={linkStyle} id={"ImpactId"}>
              <ListItemText
                sx={longPageItemTextStyle}
                primary={breakedText(LOCAL_CONSTANT.NAVI.IMPACT)}/>
            </HashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={longPageStyle}>
          <ListItemButton 
            onClick={ () => {handleButtonClick("/LongPage#Map")} }
            selected={selectedItem === 14}
            sx={longPageButtonStyle}
            disabled={mapInactive}>
            <HashLink smooth to={`?planAreaId=${planAreaId}&branch=${branch}#Map`} style={linkStyle} id={"MapId"}>
              <ListItemText
                sx={longPageItemTextStyle}
                primary={breakedText(LOCAL_CONSTANT.NAVI.MAP)}/>
            </HashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={longPageStyle}>
          <ListItemButton 
            onClick={ () => {handleButtonClick("/LongPage#Drawing")} }
            selected={selectedItem === 15}
            sx={longPageButtonStyle}>
            <HashLink smooth to={`?planAreaId=${planAreaId}&branch=${branch}#Drawing`} style={linkStyle} id={"DrawingId"}>
              <ListItemText
                sx={longPageItemTextStyle}
                primary={breakedText(LOCAL_CONSTANT.NAVI.DRAWING)}/>
            </HashLink>
          </ListItemButton>
        </ListItem>

        {/* スペーサー */}
        <ListItem 
          sx={{ borderBottom: "solid 1px #bfbfbf",
                paddingTop:"1px",
                paddingBottom:"1px",
                backgroundColor:"#bbbbbb40"
              }}>
        </ListItem>

        {/* 個別画面項目 */}
        <ListItem disablePadding sx={indiPageStyle}>
          <ListItemButton
            onClick={()=>{handleButtonClick("/ItemChangeHistory")}}
            selected={location.pathname === "/ItemChangeHistory"}>
            <ListItemIcon>
              <Link to={"/ItemChangeHistory"} style={linkIconStyle} >
                <LaunchIcon fontSize="small"/>
              </Link>
            </ListItemIcon>
            <ListItemText 
              sx={indiPageItemTextStyle}
              primary={breakedText(LOCAL_CONSTANT.NAVI.ITEM_CHANGE_HISTORY)}/>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={indiPageStyle}>
          <ListItemButton
            onClick={()=>{handleButtonClick("/EvaluateChangeHistory")}}
            selected={location.pathname === "/EvaluateChangeHistory"}>
            <ListItemIcon>
              <Link to={"/EvaluateChangeHistory"} style={linkIconStyle} >
                <LaunchIcon fontSize="small"/>
              </Link>
            </ListItemIcon>
            <ListItemText 
              sx={indiPageItemTextStyle}
              primary={breakedText(LOCAL_CONSTANT.NAVI.EVALUATE_CHANGE_HISTORY)}/>
          </ListItemButton>
        </ListItem>
      </Box>
    </ThemeProvider>
  );
}

export default SecondaryListItems;
