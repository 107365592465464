import { createSlice } from '@reduxjs/toolkit';
import { initLocationAddSurveyObj } from '../data/type';



//Sliceの作成
export const locationAddSurveySlice = createSlice({
    name: 'obj',
    initialState: {
        obj: initLocationAddSurveyObj
    },

    reducers: {
        setLocationAddSurveyObj: (state, action) => {
            state.obj = action.payload;
        },
        setLocationAddSurveyDocPdfPath: (state, action) => { //追加調査ページ
            state.obj.doc_pdf_path = action.payload; //追加調査_説明資料_pdfパス
        },
    }

});

export const {
    setLocationAddSurveyObj,
    setLocationAddSurveyDocPdfPath,
} = locationAddSurveySlice.actions;
export default locationAddSurveySlice.reducer;