import * as React from "react";

import AddImpact from "./AddImpact";
import ImpactTable from "./ImpactTable";
import { LOCAL_CONSTANT } from "../../Const";

import {
    Select,
    MenuItem,
    SelectChangeEvent,
    TextField,
    InputLabel,
    Button,
    Grid,
    Divider,
    Box,
    styled,
    FormControl,
    IconButton,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import { apiRequest } from "../../../api/ApiCall";
import { useUtilityContext } from "../../../utility-provider";
import ConfirmDialog from "../../components/ConfirmDialog";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setPermissionFunc } from "../../components/PermissionFunc";
import { setObjEditImpactData } from "../../../features/impactSlice";
import { setSaveStatus } from "../../../features/longPageSlice";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import EditIcon from "@mui/icons-material/Edit";
import {
    chatBubbleCircleSx,
    chatBubbleIconSx,
    editIconCircleSx,
    iconHover,
} from "../../components/ChatBubbleAndEditIconStyle";
import { useUserContext } from "../../../user-provider";
import {
    LocationImpactBooleanTypeList,
    initLocationImpactShowIconObj,
    initLocationImpactShowEditIconObj,
    initLocationImpactReadOnlyObj,
    LocationImpactStringTypeList,
    initLocationImpactCommentTextFieldObj,
} from "../../../data/type";
import InputWithIcons from "../../components/InputWithIcons";
import DeficiencyCommentRegistModal from "../../components/DeficiencyCommentRegistModal";

export interface SubData1Props {
    props: {
        plan_area_id: string;
        locationImpact: any | [];
        impactData: any;
        handleSetReadApi: any;
        expanded: boolean;
        latlng: any;
    };
}

const ImpactLabel = () => {
    return [
        [
            {
                size: LOCAL_CONSTANT.NUMBER.SIX,
                labelName: "",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "ルート変更",
                keyName: "change_route",
                inputType: "update-route",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "change",
                keyName: "update",
                inputType: "update",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "削除",
                keyName: "delete",
                inputType: "delete",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: LOCAL_CONSTANT.LABEL.STORE_NO,
                keyName: "store_code",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: LOCAL_CONSTANT.LABEL.STORE_NO,
                keyName: "store_code",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: LOCAL_CONSTANT.LABEL.STORE_NAME,
                keyName: "tenpo_name",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: LOCAL_CONSTANT.LABEL.STORE_NAME,
                keyName: "tenpo_name",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "道なり距離",
                keyName: "road_distance",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "道なり距離",
                keyName: "road_distance",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "売場面積",
                keyName: "salesfloor",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "売場面積",
                keyName: "salesfloor",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "駐車場面積",
                keyName: "parkingarea",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "駐車場面積",
                keyName: "parkingarea",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "たばこ取扱",
                keyName: "tobaccolicense",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "たばこ取扱",
                keyName: "tobaccolicense",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "既存店位置",
                keyName: "store_position",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "既存店位置",
                keyName: "store_position",
                inputType: "select",
                selecter: SELECTER2,
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "車",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "店前総数",
                keyName: "label",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "店前総数",
                keyName: "car_front_store_count",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "累積共通比",
                keyName: "competitiveTarget",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "累積共通比",
                keyName: "car_total_share_ratio",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "人",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "店前総数",
                keyName: "label",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "店前総数",
                keyName: "people_front_store_count",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "累積共通比",
                keyName: "label",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "累積共通比",
                keyName: "people_total_share_ratio",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "候補店共通道路",
                keyName: "label",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "候補店共通道路",
                keyName: "common_road",
                inputType: "select",
                selecter: SELECTER3,
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "候補店\n中央分離帯高さ",
                keyName: "label",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "候補店\n中央分離帯高さ",
                keyName: "plan_median_strip",
                inputType: "input",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "既存店\n中央分離帯高さ",
                keyName: "label",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "既存店\n中央分離帯高さ",
                keyName: "store_median_strip",
                inputType: "input",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "経路",
                keyName: "store_median_strip",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "直進回数",
                keyName: "straight_count",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "直進回数",
                keyName: "straight_count",
                inputType: "input",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "右左折回数",
                keyName: "turn_count",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "右左折回数",
                keyName: "turn_count",
                inputType: "input",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                newLine: "label",
                inputType: "newLine",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "線路横断",
                keyName: "railroad_crossing",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "線路横断",
                keyName: "railroad_crossing",
                inputType: "select",
                selecter: SELECTER,
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "河川幅",
                keyName: "river_width",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "河川幅",
                keyName: "river_width",
                inputType: "input",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "横断車線数合計",
                keyName: "crossing_road_count",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "横断車線数合計",
                keyName: "crossing_road_count",
                inputType: "input",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "経路競合",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "セブンイレブン",
                keyName: "conflict_sej",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "セブンイレブン",
                keyName: "conflict_sej",
                inputType: "select",
                selecter: SELECTER,
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "LW/FM\nセイコーマート",
                keyName: "conflict_main_cvs",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "LW/FM\nセイコーマート",
                keyName: "conflict_main_cvs",
                inputType: "select",
                selecter: SELECTER,
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "その他CVS",
                keyName: "conflict_other_cvs",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "その他CVS",
                keyName: "conflict_other_cvs",
                inputType: "select",
                selecter: SELECTER,
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                newLine: "label",
                inputType: "newLine",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "周辺既存店店番",
                keyName: "average_common_ratio",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "周辺既存店店番",
                keyName: "average_common_ratio",
                inputType: "input",
            },
        ],
    ];
};

const SELECTER = () => {
    return [{ selectKey: "" }, { selectKey: "あり" }, { selectKey: "なし" }];
};

const SELECTER2 = () => {
    return [
        { selectKey: "" },
        { selectKey: "A" },
        { selectKey: "B" },
        { selectKey: "反A" },
        { selectKey: "反B" },
    ];
};

const SELECTER3 = () => {
    return [
        { selectKey: "" },
        { selectKey: LOCAL_CONSTANT.LABEL.FRONT },
        { selectKey: LOCAL_CONSTANT.LABEL.SIDE_A },
        { selectKey: LOCAL_CONSTANT.LABEL.SIDE_B },
        { selectKey: LOCAL_CONSTANT.LABEL.BACK },
    ];
};

//アスタリスク
const RedAsterisk = styled("span")({
    color: "red",
    marginLeft: "4px",
    display: "flex",
    alignItems: "center",
});

export const LocationImpactDetail: React.FC<SubData1Props> = ({ props }) => {
    //オブジェクトの宣言
    const [open, setOpen] = React.useState<boolean>(false);
    const [targetImpact, setTargetImpact] = React.useState<any>();
    const utilityCtx = useUtilityContext();
    const [impactData, setImpactData] = React.useState<any>(undefined);
    const [actionFlag, setActionFlag] = React.useState<boolean>(false);
    const [confirmDialogOpen, setConfirmDialogOpen] =
        React.useState<boolean>(false);
    //調査種別プルダウンの値を取得
    const selectedSurveyType = useAppSelector(
        (state) => state.longPage.surveyType
    );
    //タスクIDを取得するため
    const objLocationBase = useAppSelector(
        (state) => state.longPage.locationBase
    );
    // 編集影響度
    const objEditImpactData = useAppSelector(
        (state) => state.impact.editImpactData
    );
    const dispatch = useAppDispatch();
    //パラメーターオブジェクト
    const objLongPageSaveStatus = useAppSelector(
        (state) => state.longPage.saveStatus
    );
    //ユーザー種別を格納
    const [userType, setUserType] = React.useState("");
    //項目値を編集可能にするステータスの型定義
    const [locationImpactReadOnlyObj, setLocationImpactReadOnlyObj] =
        React.useState<LocationImpactBooleanTypeList>(
            initLocationImpactReadOnlyObj
        );
    //アイコン全体の表示非表示のステータス
    const [locationImpactShowIconsObj, setLocationImpactShowIconsObj] =
        React.useState<LocationImpactBooleanTypeList>(
            initLocationImpactShowIconObj
        );
    //不備コメントモーダル項目名
    const [columnNameStr, setColumnNameStr] = React.useState<string>("");
    //不備コメントモーダルラベル名
    const [labelNameStr, setLabelNameStr] = React.useState<string>("");
    //不備コメントモーダル開閉フラグ
    const [deficiencyComentModalFlag, setDeficiencyComentModalFlag] =
        React.useState(false);
    //不備コメントモーダル内、TextFieldのコメント
    const [
        locationImpactCommentTextFieldObj,
        setLocationImpactCommentTextFieldObj,
    ] = React.useState<LocationImpactStringTypeList>(
        initLocationImpactCommentTextFieldObj
    );
    const user = useUserContext();

    //ユーザー取得タイミングによる画面制御
    React.useEffect(() => {
        if (JSON.parse(user.userData || "null") !== null) {
            setUserType(JSON.parse(user.userData || "null").user_type);
        }
    }, [user]);

    React.useEffect(() => {
        if (props.impactData) {
            const copyData = JSON.parse(JSON.stringify(props.impactData));
            setImpactData(copyData);
        }
    }, [props.impactData]);

    //インプットデータ取得
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        labelName: string
    ) => {
        // 入力した値をStoreへ登録する
        if (impactData !== undefined) {
            impactData[labelName] = e.target.value;
            editImpactData(impactData, labelName, e.target.value);
            setActionFlag(!actionFlag);
        }
    };

    //プルダウンデータ取得
    const handleSelectChange = (
        e: SelectChangeEvent<string>,
        labelName: string
    ) => {
        // 入力した値をStoreへ登録する
        if (impactData !== undefined) {
            impactData[labelName] = e.target.value;
            editImpactData(impactData, labelName, e.target.value);
            setActionFlag(!actionFlag);
        }
    };

    // 更新データ
    const editImpactData = (impactData: any, labelName: string, value: any) => {
        const temp: any = JSON.parse(JSON.stringify(objEditImpactData));
        if (impactData !== undefined) {
            if (temp.length === 0) {
                temp.push(JSON.parse(JSON.stringify(impactData)));
            } else {
                let hasStore = false;
                temp.map((row: any) => {
                    if (impactData.store_code === row.store_code) {
                        row[labelName] = value;
                        hasStore = true;
                    }
                });
                if (!hasStore) {
                    temp.push(JSON.parse(JSON.stringify(impactData)));
                }
            }
        }
        dispatch(setObjEditImpactData(temp));
        //更新フラグ
        if (!objLongPageSaveStatus.Impact)
            dispatch(
                setSaveStatus({ ...objLongPageSaveStatus, ["Impact"]: true })
            );
    };

    // ルート変更
    const onClickHandle = (e: any) => {
        setTargetImpact(props.impactData);
        setOpen(true);
    };

    const confirmDelete = () => {
        setConfirmDialogOpen(true);
    };

    const cancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    // 削除処理
    const onClickdelete = () => {
        interface Obj {
            [prop: string]: any;
        }
        const requestParams: Obj = {};
        requestParams.plan_area_id = props.impactData.plan_area_id;
        requestParams.branch = props.impactData.branch;
        requestParams.store_code = props.impactData.store_code;
        const params = {
            mode: "regist",
            endPoint: "/location/Impact/v1/delete",
            query: requestParams,
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                setConfirmDialogOpen(false);
                setActionFlag(false);
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                props.handleSetReadApi(true);
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    };

    const setBody = () => {
        let sentence = "";
        if (impactData !== undefined) {
            sentence =
                impactData["tenpo_name"] +
                "（" +
                impactData["store_code"] +
                "）を削除しますか？";
        }
        return sentence;
    };

    const inputValue = (data: any, key: string) => {
        let value = data[key];
        if (
            key === "car_total_share_ratio" ||
            key === "people_total_share_ratio"
        ) {
            if (Number(value) > 0) {
                value = (Math.round(data[key] * 10000) / 100).toString();
            }

            value =
                Number(value).toFixed(2).toString() +
                LOCAL_CONSTANT.LABEL.PERCENT;
        }
        return value;
    };

    // 吹き出しをクリックしたとき、モーダル画面を開く
    const handleChatBubbleClick = (columnName: string, labelName: string) => {
        setColumnNameStr(columnName);
        setLabelNameStr(labelName);
        setDeficiencyComentModalFlag(true);
        setLocationImpactCommentTextFieldObj({
            ...locationImpactCommentTextFieldObj,
            [labelName]: "",
        }); //テキストフィールドのコメントを初期化
    };

    // インプットからフォーカスがずれたら、インプット編集不可とアイコンを非表示にする
    const handleInputBlur = (
        e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
        labelName: string
    ) => {
        // インプット編集不可
        setLocationImpactReadOnlyObj({
            ...locationImpactReadOnlyObj,
            [labelName]: true,
        });

        //インプットからフォーカスが移動する先がnullの場合アイコンを閉じる
        if (!e.relatedTarget) {
            setLocationImpactShowIconsObj({
                ...locationImpactShowIconsObj,
                [labelName]: false,
            });
        }

        //インプットからフォーカスが移動する先がインプットタグの場合アイコンを閉じる
        if (e.relatedTarget && e.relatedTarget.tagName === "INPUT") {
            setLocationImpactShowIconsObj({
                ...locationImpactShowIconsObj,
                [labelName]: false,
            });
        }

        //テキストエリアからフォーカスが移動する先がテキストエリアタグの場合アイコンを閉じる
        if (e.relatedTarget && e.relatedTarget.tagName === "TEXTAREA") {
            setLocationImpactShowIconsObj({
                ...locationImpactShowIconsObj,
                [labelName]: false,
            });
        }
    };

    // インプット
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        labelName: string
    ) => {
        // 入力した値をStoreへ登録する
        if (impactData !== undefined) {
            impactData[labelName] = e.target.value;
            editImpactData(impactData, labelName, e.target.value);
            setActionFlag(!actionFlag);
        }
    };

    // 入力不可のインプットをクリックしたときアイコンを表示
    const handleInputClick = (labelName: string) => {
        // アイコン表示
        setLocationImpactShowIconsObj({
            ...locationImpactShowIconsObj,
            [labelName]: true,
        });
    };

    // 鉛筆をクリックしたとき、Input編集可
    const handleEditIconClick = (labelName: string) => {
        if (
            setPermissionFunc(
                userType,
                objLocationBase.task_id,
                LOCAL_CONSTANT.NAVI.IMPACT,
                labelName
            ) === false
        ) {
            //falseにすると、Input編集可
            setLocationImpactReadOnlyObj({
                ...locationImpactReadOnlyObj,
                [labelName]: false,
            });
        }
    };

    const selectBackgroundColor = (userType: string, labelName: string) => {
        let hasWidth = true;
        let hasBG = "#FFFCD6";
        if (userType === "z001") {
            if (locationImpactReadOnlyObj[labelName]) {
                hasBG = "rgba(0, 0, 0, 0.06)";
            }
        } else {
            hasWidth = false;
            if (userType === "z003") {
                if (
                    setPermissionFunc(
                        userType,
                        objLocationBase.task_id,
                        LOCAL_CONSTANT.NAVI.IMPACT,
                        labelName
                    )
                ) {
                    hasBG = "rgba(0, 0, 0, 0.06)";
                }
            } else {
                hasBG = "rgba(0, 0, 0, 0.06)";
            }
        }

        return {
            width: hasWidth ? "100%" : "60%",
            backgroundColor: hasBG,
        };
    };

    const selectDisabled = (userType: string, labelName: string) => {
        if (userType === "z003") {
            return setPermissionFunc(
                userType,
                objLocationBase.task_id,
                LOCAL_CONSTANT.NAVI.IMPACT,
                labelName
            );
        }

        return locationImpactReadOnlyObj[labelName];
    };

    //不備コメントモーダル内でのコメント取得
    const handleCommentChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        labelName: string
    ) => {
        setLocationImpactCommentTextFieldObj({
            ...locationImpactCommentTextFieldObj,
            [labelName]: e.target.value,
        });
    };

    //不備コメントモーダル内の保存ボタンをクリックしたとき不備コメントを保存
    const handleSaveComment = (columnName: string, labelName: string) => {
        //不備コメントを登録
        const params = {
            mode: "regist",
            endPoint: "/location/Deficiency/v1/regist",
            query: {
                plan_area_id: props.impactData.plan_area_id,
                branch: props.impactData.branch,
                status_seq: objLocationBase.status_seq,
                table_name: "location_impact",
                column_name: columnName,
                comment: locationImpactCommentTextFieldObj[labelName],
            },
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //不備コメントモーダルを閉じる
                setDeficiencyComentModalFlag(false);
                //アイコンを非表示
                setLocationImpactShowIconsObj({
                    ...locationImpactShowIconsObj,
                    [labelName]: false,
                });
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //不備コメントモーダルを閉じる
                setDeficiencyComentModalFlag(false);
                //アイコンを非表示
                setLocationImpactShowIconsObj({
                    ...locationImpactShowIconsObj,
                    [labelName]: false,
                });
            }
        })();
    };

    return (
        <>
            {
                // props.expanded === true &&
                <Box sx={{ margin: "10px" }}>
                    <Typography>調査内容</Typography>
                    {impactData !== undefined &&
                        ImpactLabel().map((row, index) => (
                            <Grid container key={index}>
                                {row.map((val: any, keyIndex) => (
                                    <Grid item key={keyIndex} xs={val.size}>
                                        {val.inputType === "update-route" && (
                                            <Button
                                                sx={{ margin: "20px 0" }}
                                                variant="contained"
                                                color="success"
                                                size="small"
                                                onClick={(e) =>
                                                    onClickHandle(e)
                                                }
                                                disabled={setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI.IMPACT,
                                                    val.keyName
                                                )}
                                            >
                                                {val.labelName}
                                            </Button>
                                        )}
                                        {val.inputType === "delete" && (
                                            <Button
                                                sx={{ margin: "20px 0" }}
                                                variant="outlined"
                                                color="error"
                                                size="small"
                                                onClick={(e) => confirmDelete()}
                                                disabled={setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI.IMPACT,
                                                    val.keyName
                                                )}
                                            >
                                                {val.labelName}
                                            </Button>
                                        )}
                                        {val.inputType === "header" &&
                                            index === 0 && (
                                                <InputLabel
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    {val.labelName}
                                                </InputLabel>
                                            )}
                                        {/* 調査種別プルダウンが純増の場合に、項目にアスタリスクを表示 */}
                                        {val.inputType === "header" &&
                                            index !== 0 &&
                                            selectedSurveyType === "01" && (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <InputLabel
                                                        sx={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {val.labelName}
                                                    </InputLabel>
                                                    <RedAsterisk>
                                                        ＊＊
                                                    </RedAsterisk>
                                                </Box>
                                            )}
                                        {val.inputType === "header" &&
                                            index !== 0 &&
                                            selectedSurveyType !== "01" && (
                                                <InputLabel
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    {val.labelName}
                                                </InputLabel>
                                            )}
                                        {val.inputType === "label" && (
                                            <InputLabel
                                                sx={{
                                                    fontSize: "0.8em",
                                                    padding: "20px 0",
                                                    fontWeight: "bold",
                                                    whiteSpace: "pre-wrap",
                                                }}
                                            >
                                                {val.labelName}
                                            </InputLabel>
                                        )}
                                        {
                                            //テキストフィールド 常に非活性
                                            val.inputType ===
                                                "disabledInput" && (
                                                <TextField
                                                    sx={{
                                                        backgroundColor:
                                                            "rgba(0, 0, 0, 0.06)",
                                                    }}
                                                    disabled={true}
                                                    value={inputValue(
                                                        impactData,
                                                        val.keyName
                                                    )}
                                                ></TextField>
                                            )
                                        }
                                        {val.inputType === "input" && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .IMPACT,
                                                        val.keyName
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .IMPACT,
                                                    columnName: val.keyName,
                                                    labelName: val.keyName,
                                                    startAdornment: "",
                                                    endAdornment: "",
                                                    InputValueObj: impactData,
                                                    ReadOnlyObj:
                                                        locationImpactReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setLocationImpactReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initLocationImpactShowEditIconObj,
                                                    ShowIconsObj:
                                                        locationImpactShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {val.inputType === "select" && (
                                            <Box sx={{ display: "flex" }}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        onChange={(e) =>
                                                            handleSelectChange(
                                                                e,
                                                                val.keyName
                                                            )
                                                        }
                                                        value={
                                                            impactData[
                                                                val.keyName
                                                            ]
                                                        }
                                                        sx={selectBackgroundColor(
                                                            userType,
                                                            val.keyName
                                                        )}
                                                        disabled={selectDisabled(
                                                            userType,
                                                            val.keyName
                                                        )}
                                                    >
                                                        {val
                                                            .selecter()
                                                            .map((row: any) => (
                                                                <MenuItem
                                                                    key={
                                                                        props
                                                                            .impactData
                                                                            .store_code +
                                                                        "_" +
                                                                        row.selectKey
                                                                    }
                                                                    value={
                                                                        row.selectKey
                                                                    }
                                                                >
                                                                    {
                                                                        row.selectKey
                                                                    }
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                                {userType === "z001" && (
                                                    <IconButton
                                                        disabled={setPermissionFunc(
                                                            userType,
                                                            objLocationBase.task_id,
                                                            LOCAL_CONSTANT.NAVI
                                                                .IMPACT,
                                                            val.keyName
                                                        )}
                                                        onClick={() => {
                                                            handleChatBubbleClick(
                                                                val.keyName,
                                                                val.keyName
                                                            );
                                                        }}
                                                        sx={iconHover}
                                                    >
                                                        <Box
                                                            sx={chatBubbleCircleSx(
                                                                setPermissionFunc(
                                                                    userType,
                                                                    objLocationBase.task_id,
                                                                    LOCAL_CONSTANT
                                                                        .NAVI
                                                                        .IMPACT,
                                                                    val.keyName
                                                                )
                                                            )}
                                                        >
                                                            <ChatBubbleIcon
                                                                sx={
                                                                    chatBubbleIconSx
                                                                }
                                                            />
                                                        </Box>
                                                    </IconButton>
                                                )}
                                                {userType === "z001" && (
                                                    <IconButton
                                                        onClick={() => {
                                                            handleEditIconClick(
                                                                val.keyName
                                                            );
                                                        }}
                                                        sx={iconHover}
                                                    >
                                                        <Box
                                                            sx={
                                                                editIconCircleSx
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </Box>
                                                    </IconButton>
                                                )}
                                            </Box>
                                        )}
                                        {val.inputType === "Divider" && (
                                            <Divider sx={{ my: 1 }} />
                                        )}
                                        {val.inputType === "newLine" && <br />}
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    <ImpactTable
                        props={{
                            impactData: impactData,
                        }}
                    />
                    <AddImpact
                        props={{
                            open: open,
                            handleModal: setOpen,
                            mode: "edit",
                            latlng: props.latlng,
                            locationImpactList: props.locationImpact,
                            targetImpact: targetImpact,
                            handleSetReadApi: props.handleSetReadApi,
                        }}
                    />
                    <ConfirmDialog
                        props={{
                            open: confirmDialogOpen,
                            handleModal: setConfirmDialogOpen,
                            onCancel: cancelDelete,
                            onExecution: onClickdelete,
                            mode: "deleteConfirm",
                            body: setBody(),
                            confirmCancel: LOCAL_CONSTANT.LABEL.CANCEL,
                            confirmOk: LOCAL_CONSTANT.LABEL.DELETE,
                        }}
                    />
                    {/*不備コメント モーダル画面 */}
                    <DeficiencyCommentRegistModal
                        props={{
                            modalOpen: deficiencyComentModalFlag,
                            setModalOpen: setDeficiencyComentModalFlag,
                            CommentTextFieldObj:
                                locationImpactCommentTextFieldObj,
                            columnName: columnNameStr,
                            labelName: labelNameStr,
                            handleCommentChange: handleCommentChange,
                            handleSaveComment: handleSaveComment,
                        }}
                    />
                </Box>
            }
        </>
    );
};

export default LocationImpactDetail;
