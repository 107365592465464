import * as React from 'react';

import { graphqlRequest } from "../api/ApiCall";

/**
 * 候補地取得GraphQl
 * @returns 
 * 
 */
export function planAreaSearchById(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        id: apiParams.id,
        manageCitiesFlg: apiParams.manageCitiesFlg,
        manageCities: apiParams.manageCities,
        manageStep: apiParams.manageStep,
        managePlansOutsideCities: apiParams.managePlansOutsideCities,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }

    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.planAreaSearchById;
                    if (res.length > 0) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 既存店取得GraphQl
 * @returns 
 * 
 */
export function existingSejStoreSearchByDistance(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        longitude: apiParams.longitude,
        latitude: apiParams.latitude,
        distance: apiParams.distance,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }

    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.existingSejStoreSearchByDistance;
                    if (res.length > 0) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * use_plan_area_id取得GraphQl
 * @returns 
 * 
 */
export function getExistingSejStoreUsePlanAreaId(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        branch: apiParams.branch,
        child_location: apiParams.child_location,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }

    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getExistingSejStoreUsePlanAreaId;
                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 影響度取得GraphQl
 * @returns 
 * 
 */
export function getImpactCounter(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        longitude: apiParams.longitude,
        latitude: apiParams.latitude,
        uuid: apiParams.uuid,
        distance: apiParams.distance,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }
    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getImpactCounter;
                    if (res.length > 0) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}


/**
 * 競合店取得GraphQl
 * @returns 
 * 
 */
export function getConflictStores(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        longitude: apiParams.longitude,
        latitude: apiParams.latitude,
        distance: apiParams.distance,
        type: apiParams.type,
        hasClosed: apiParams.hasClosed,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }

    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getConflictStores;
                    if (res.length > 0) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 競合調査取得GraphQl
 * @returns 
 * 
 */
export function getLocationConflict(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        branch: apiParams.branch,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }

    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getLocationConflict;

                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 表紙画面取得GraphQl
 * @returns 
 * 
 */
export function getLocationCover(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        branch: apiParams.branch,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }

    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getLocationCover;
                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 出店条件画面取得GraphQl
 * @returns 
 * 
 */
export function getLocationStoreOpeningConditions(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        branch: apiParams.branch,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }

    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getLocationStoreOpeningConditions;
                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 国勢調査データ取得GraphQl
 * @returns 
 * 
 */
export function getNationalTaxAudit(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        city_code: apiParams.city_code,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }

    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getNationalTaxAudit;
                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 車5分商圏データ取得GraphQl
 * @returns 
 * 
 */
export function getCar5MinMp(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        excel_sequence: apiParams.excel_sequence,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }
    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getCar5MinMp;
                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 勾配加味徒歩5分商圏データと商圏データ取得を一括取得するGraphQl
 * 商圏画面の③勾配加味徒歩5分商圏
 * 商圏画面の④商圏内データ
 * @returns 
 * 
 */
export function getLocationTradeAreaMpData(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        branch: apiParams.branch,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }
    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getLocationTradeAreaMpData;
                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
  * 勾配加味徒歩5分商圏データと商圏データ取得を一括取得するGraphQl
 * 商圏画面の③勾配加味徒歩5分商圏
 * 商圏画面の④商圏内データ(実査項目と商圏sequenceは除く)
 * @returns 
 * 
 */
export function getGradientWalk5MinMpData(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        excel_sequence: apiParams.excel_sequence
    }];
    const params: any = {
        query: 'query',
        params: param,
    }
    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getGradientWalk5MinMpData;
                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 寄付き画面データ取得GraphQl
 * @returns 
 * 
 */
export function getLocationDonation(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        branch: apiParams.branch,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }
    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getLocationDonation;
                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 影響度調査取得GraphQl
 * @returns 
 * 
 */
export function getLocationImpact(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        branch: apiParams.branch,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }
    return new Promise((resolve, reject) => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getLocationImpact;
                    resolve(res)
                } else {
                    reject(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 通行量取得GraphQl
 * @returns 
 * 
 */
export function getTrafficVolume(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        count_type: apiParams.count_type,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }
    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getTrafficVolume;

                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 通行量取得GraphQl
 * @returns 
 * 
 */
export function getLocationTraffic(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        branch: apiParams.branch,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }
    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getLocationTraffic;

                    resolve(res);
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 図面画面データ取得GraphQl
 * @returns 
 * 
 */
export function getLocationDrawing(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        branch: apiParams.branch,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }
    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getLocationDrawing;
                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * ポータル画面取得GraphQl
 * @returns 
 * 
 */
export function getPortalList(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        create_date: apiParams.conversion_create_date,
        status_id: apiParams.status_id,
        pic_type: apiParams.pic_type,
        pic_staff_number: apiParams.pic_staff_number,
        task_id: apiParams.task_id,
        plan_area_id: apiParams.plan_area_id,
        department_code: apiParams.department_code,
        district_code: apiParams.district_code,
        plan_area_name: apiParams.plan_area_name,
        plan_area_rfc: apiParams.plan_area_rfc,
        login_user_id: apiParams.login_user_id,
        userType: apiParams.userType
    }];
    const params: any = {
        query: 'query',
        params: param,
    }

    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getPortalList;

                    resolve(res);
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 候補地Noリスト取得GraphQl
 * @returns 
 * 
 */
export function getPlanAreaList(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }

    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getPlanAreaList;

                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * N15追加商圏取得GraphQl
 * @returns 
 * 
 */
export function getN15AddMarket(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        location_type: apiParams.location_type
    }];
    const params: any = {
        query: 'query',
        params: param,
    }
    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getN15AddMarket;

                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 既存店、競合店取得GraphQl
 * @returns 
 * 
 */
export function getExistingSejConflictStores(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        longitude: apiParams.longitude,
        latitude: apiParams.latitude,
        distance: apiParams.distance,
        propSiteNo: apiParams.propSiteNo,
        excelSequence: apiParams.excelSequence,
        type: apiParams.type,
        hasClosed: apiParams.hasClosed,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }
    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getExistingSejConflictStores;
                    if (res.length > 0) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 不備コメント取得GraphQl
 * @returns 
 * 
 */
export function getDeficiencyComment(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        branch: apiParams.branch,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }
    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getDeficiencyComment;
                    if (res.length > 0) {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 複数取得graphQl
 * @returns 
 * 
 */
export function getLongPageData(apiParams: any) {
    let res: any = [];
    const params: any = {
        query: 'query',
        params: apiParams,
    }
    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data;
                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 依頼/コメント画面取得GraphQl
 * @returns 
 * 
 */
export function getChangeStatus(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        branch: apiParams.branch,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }

    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getChangeStatus;

                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * タスク情報取得GraphQl
 * @returns 
 * 
 */
export function getTaskManage(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        task_id: apiParams.task_id,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }

    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getTaskManage;

                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * ポータル画面取得GraphQl
 * @returns 
 * 
 */
export function getCircularHistory(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        plan_area_name: apiParams.plan_area_name,
        fromDate: apiParams.fromDate,
        toDate: apiParams.toDate,
        comment_type: apiParams.comment_type,
        commentPoA: apiParams.commentPoA,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }

    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getCircularHistory;

                    resolve(res);
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 通行量その他データ取得GraphQl
 * location_excel_24_1km_stationからテーブルから取得
 * @returns 
 * 
 */
export function getTrafficOtherStation(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        excel_sequence: apiParams.excel_sequence,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }
    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getTrafficOtherStation;
                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 通行量その他データ取得GraphQl
 * location_excel_18_3_schoolからテーブルから取得
 * @returns 
 * 
 */
export function getTrafficOtherSchool(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        excel_sequence: apiParams.excel_sequence,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }
    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getTrafficOtherSchool;
                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 依頼/コメント画面取得GraphQl
 * @returns 
 * 
 */
export function getReceptionBookByIdAndBranch(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        branch: apiParams.branch,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }

    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getReceptionBookByIdAndBranch;

                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}

/**
 * 基本情報取得GraphQl
 * @returns 
 * 
 */
export function getLocationBase(apiParams: any) {
    let res: any = [];
    let param: any = [{
        mode: apiParams.mode,
        plan_area_id: apiParams.plan_area_id,
        branch: apiParams.branch,
    }];
    const params: any = {
        query: 'query',
        params: param,
    }

    return new Promise(resolve => {
        (async () => {
            try {
                let result: any = await graphqlRequest(params);
                if (result.data.data != null) {
                    res = result.data.data.getLocationBase;

                    resolve(res)
                }
            } catch (e) {
                console.log(e);
            }
        })();
    })
}
