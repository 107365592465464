import dayjs from 'dayjs';

export const addSurveyData = (res:any) => {
    const DataObj = {
        plan_area_id: res[0].plan_area_id,
        branch: res[0].branch,
        create_date: res[0].create_date,
        update_date: res[0].update_date,
        delete_flag: res[0].delete_flag,
        cover_excel_path: res[0].cover_excel_path,
        cover_excel_user: res[0].cover_excel_user,
        cover_pdf_path: res[0].cover_pdf_path,
        cover_pdf_user: res[0].cover_pdf_user,
        doc_pdf_path: JSON.parse(res[0].doc_pdf_path),
        reference_value_1: res[0].reference_value_1,
        reference_value_2: res[0].reference_value_2,
        reference_value_3: res[0].reference_value_3,
        reference_value_4: res[0].reference_value_4,
        reference_value_5: res[0].reference_value_5,
        approval_mgr_flag: res[0].approval_mgr_flag,
        approval_mgr_user: res[0].approval_mgr_user,
        approval_mgr_department: res[0].approval_mgr_department,
        approval_mgr_date: res[0].approval_mgr_date,
        approval_mgr_stamp: res[0].approval_mgr_stamp,
        approval_gm_flag: res[0].approval_gm_flag,
        approval_gm_user: res[0].approval_gm_user,
        approval_gm_department: res[0].approval_gm_department,
        approval_gm_date: res[0].approval_gm_date,
        approval_gm_stamp: res[0].approval_gm_stamp,
        approval_research_mgr_flag: res[0].approval_research_mgr_flag,
        approval_research_mgr_user: res[0].approval_research_mgr_user,
        approval_research_mgr_department: res[0].approval_research_mgr_department,
        approval_research_mgr_date: res[0].approval_research_mgr_date,
        approval_research_mgr_stamp: res[0].approval_research_mgr_stamp,
        approval_research_gm_flag: res[0].approval_research_gm_flag,
        approval_research_gm_user: res[0].approval_research_gm_user,
        approval_research_gm_department: res[0].approval_research_gm_department,
        approval_research_gm_date: res[0].approval_research_gm_date,
        approval_research_gm_stamp: res[0].approval_research_gm_stamp,
    };
    return DataObj;
};

//調査書基本テーブル ファイルアップロード
export const upsertLocationAddSurveyFileParam = (objLocationAddSurvey:any, plan_area_id:any, branch:any, uploadFile:any, column:string, user_id:string, fileName: string, status: string) => {
    const params = {
        mode: "regist",
        endPoint: "/location/AddSurvey/v1/regist",
        query: locationAddSurveyQuery(objLocationAddSurvey, plan_area_id, branch, uploadFile, column, user_id, fileName, status)
    }
    return params;
}

  
//追加調査テーブル ファイルアップロードquery
export const locationAddSurveyQuery = (objLocationAddSurvey:any, plan_area_id:any, branch:any, uploadFile:any, column:string, user_id:string, fileName:string, status: string) => {
    let cover_excel_path = objLocationAddSurvey.cover_excel_path;
    let cover_excel_user = objLocationAddSurvey.cover_excel_user;
    let cover_pdf_path = objLocationAddSurvey.cover_pdf_path;
    let cover_pdf_user = objLocationAddSurvey.cover_pdf_user;
    let doc_pdf_path = objLocationAddSurvey.doc_pdf_path;
    let isUpload = true;

    if (column === 'cover_excel_path') {
        cover_excel_path = uploadFile;
        cover_excel_user = user_id;
    } else if (column === 'doc_pdf_path') {
        doc_pdf_path = [].concat(objLocationAddSurvey.doc_pdf_path, uploadFile);
    } else if (column === 'delete_doc_pdf_path') {
        column = 'doc_pdf_path'
        doc_pdf_path = uploadFile;
    }

    if (status === 'cover_excel_delete') {
        cover_excel_path = ''
        cover_excel_user = ''
        cover_pdf_path = ''
        cover_pdf_user = ''
        isUpload = false
    }

    const logStatus = setLogStatus(column, isUpload, fileName)

    return {   
        plan_area_id:plan_area_id,
        branch:branch,
        cover_excel_path: cover_excel_path,
        cover_excel_user: cover_excel_user,
        cover_pdf_path: cover_pdf_path,
        cover_pdf_user: cover_pdf_user,
        doc_pdf_path: JSON.stringify(doc_pdf_path),
        reference_value_1: objLocationAddSurvey.reference_value_1,
        reference_value_2: objLocationAddSurvey.reference_value_2,
        reference_value_3: objLocationAddSurvey.reference_value_3,
        reference_value_4: objLocationAddSurvey.reference_value_4,
        reference_value_5: objLocationAddSurvey.reference_value_5,
        approval_mgr_flag: objLocationAddSurvey.approval_mgr_flag,
        approval_mgr_user: objLocationAddSurvey.approval_mgr_user,
        approval_mgr_department: objLocationAddSurvey.approval_mgr_department,
        approval_mgr_date: objLocationAddSurvey.approval_mgr_date,
        approval_mgr_stamp: objLocationAddSurvey.approval_mgr_stamp,
        approval_gm_flag: objLocationAddSurvey.approval_gm_flag,
        approval_gm_user: objLocationAddSurvey.approval_gm_user,
        approval_gm_department: objLocationAddSurvey.approval_gm_department,
        approval_gm_date: objLocationAddSurvey.approval_gm_date,
        approval_gm_stamp: objLocationAddSurvey.approval_gm_stamp,
        approval_research_mgr_flag: objLocationAddSurvey.approval_research_mgr_flag,
        approval_research_mgr_user: objLocationAddSurvey.approval_research_mgr_user,
        approval_research_mgr_department: objLocationAddSurvey.approval_research_mgr_department,
        approval_research_mgr_date: objLocationAddSurvey.approval_research_mgr_date,
        approval_research_mgr_stamp: objLocationAddSurvey.approval_research_mgr_stamp,
        approval_research_gm_flag: objLocationAddSurvey.approval_research_gm_flag,
        approval_research_gm_user: objLocationAddSurvey.approval_research_gm_user,
        approval_research_gm_department: objLocationAddSurvey.approval_research_gm_department,
        approval_research_gm_date: objLocationAddSurvey.approval_research_gm_date,
        approval_research_gm_stamp: objLocationAddSurvey.approval_research_gm_stamp,
        // ログ用
        log_type:logStatus.log_type,
        target_table:logStatus.target_table,
        target_column:logStatus.target_column,
        target_before_value:logStatus.target_before_value,
        target_after_value:logStatus.target_after_value
    }
}


export const upsertLocationAddSurvey = (objLocationAddSurvey: any, plan_area_id:any, branch:any) => {
    const params = {
        mode: "regist",
        endPoint: "/location/AddSurvey/v1/regist",
        query: {
            plan_area_id:plan_area_id,
            branch:branch,
            cover_excel_path: objLocationAddSurvey.cover_excel_path,
            cover_excel_user: objLocationAddSurvey.cover_excel_user,
            cover_pdf_path: objLocationAddSurvey.cover_pdf_path,
            cover_pdf_user: objLocationAddSurvey.cover_pdf_user,
            doc_pdf_path: JSON.stringify(objLocationAddSurvey.doc_pdf_path),
            reference_value_1: objLocationAddSurvey.reference_value_1,
            reference_value_2: objLocationAddSurvey.reference_value_2,
            reference_value_3: objLocationAddSurvey.reference_value_3,
            reference_value_4: objLocationAddSurvey.reference_value_4,
            reference_value_5: objLocationAddSurvey.reference_value_5,
            approval_mgr_flag: objLocationAddSurvey.approval_mgr_flag,
            approval_mgr_user: objLocationAddSurvey.approval_mgr_user,
            approval_mgr_department: objLocationAddSurvey.approval_mgr_department,
            approval_mgr_date: objLocationAddSurvey.approval_mgr_date,
            approval_mgr_stamp: objLocationAddSurvey.approval_mgr_stamp,
            approval_gm_flag: objLocationAddSurvey.approval_gm_flag,
            approval_gm_user: objLocationAddSurvey.approval_gm_user,
            approval_gm_department: objLocationAddSurvey.approval_gm_department,
            approval_gm_date: objLocationAddSurvey.approval_gm_date,
            approval_gm_stamp: objLocationAddSurvey.approval_gm_stamp,
            approval_research_mgr_flag: objLocationAddSurvey.approval_research_mgr_flag,
            approval_research_mgr_user: objLocationAddSurvey.approval_research_mgr_user,
            approval_research_mgr_department: objLocationAddSurvey.approval_research_mgr_department,
            approval_research_mgr_date: objLocationAddSurvey.approval_research_mgr_date,
            approval_research_mgr_stamp: objLocationAddSurvey.approval_research_mgr_stamp,
            approval_research_gm_flag: objLocationAddSurvey.approval_research_gm_flag,
            approval_research_gm_user: objLocationAddSurvey.approval_research_gm_user,
            approval_research_gm_department: objLocationAddSurvey.approval_research_gm_department,
            approval_research_gm_date: objLocationAddSurvey.approval_research_gm_date,
            approval_research_gm_stamp: objLocationAddSurvey.approval_research_gm_stamp,
        }
    }

    return params;
}

    
// ログステータス
const setLogStatus = (column: string, isUpload: boolean, fileName:string) => {
    let log_type = ""
    let target_table = ""
    let target_column = ""
    let target_before_value = ""
    let target_after_value = ""
    let target_file_name = fileName
  
    switch (column) {
      case "cover_excel_path":
        log_type = "7"
        target_table = "追加調査"
        target_file_name = "表紙"
        break
      case "doc_pdf_path":
        log_type = "7"
        target_table = "追加調査"
        break
    }
    target_column = isUpload ? "ファイルアップロード" : "ファイル削除"
    target_before_value = isUpload ? "" : target_file_name
    target_after_value = isUpload ? target_file_name : ""
  
    return {
      log_type: log_type,
      target_table: target_table,
      target_column: target_column,
      target_before_value: target_before_value,
      target_after_value: target_after_value,
    }
}
  
//アップロード JSON形式データ加工
export const uploadFileJsonPathFunc = (res:any, userId: string) => {
    let filePath = '';
    
    if (res.fullPath.includes('/excel')) {
      filePath = '/excel/'+res.name;
    } else if (res.fullPath.includes('/pdf')) {
      filePath = '/pdf/'+res.name;
    } else if (res.fullPath.includes('/rr_document')) {
      filePath = '/rr_document/'+res.name;
    } else if (res.fullPath.includes('/drawing')) {
      filePath = '/drawing/'+res.name;
    }
  
    return {
      file_name:res.name,
      file_path:filePath,
      upload_date:dayjs(res.timeCreated).format('YYYY/MM/DD HH:mm:ss'),
      upload_staff_number:userId
    }
};